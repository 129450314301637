import React, { useEffect, useState, useReducer } from 'react';
import axios from 'axios';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from 'react-modal';
function AdminStudentRequest() {
  const [listOfItems, setListOfItems] = useState([]); // To store list of all items
  const [currentClickedId, setCurrentClickedId] = useState(''); // To Store the Clicked Id for Deletion
  const [editValues, setEditValues] = useState(null); // To store the values for Edit Modal (FORM)
  const [reducerValue, forceUpdate] = useReducer((x) => x + 1, 0); // To tell the useEffect that a row has been added
  const [selectedImage, setSelectedImage] = useState(null); // To show the Image full view in Modal
  const initialValues = {
    studentUserName: '',
    instructorUserName: '',
    scheduleId: null,
    isAccepted: false,
    isRejected: false,
  };
  const validationSchema = Yup.object().shape({
    studentUserName: Yup.string().required(),
    instructorUserName: Yup.string().required(),
    scheduleId: Yup.number().required(),
    isAccepted: Yup.boolean().required(),
    isRejected: Yup.boolean().required(),
  });
  useEffect(() => {
    const fetchStudentRequests = async () => {
      try {
        const response = await fetch(
          'http://csmserver.csmdrivingschool.ca/StudentRequest.php'
        );
        if (response.ok) {
          const data = await response.json();
          setListOfItems(data);
          console.log(data);
        } else {
          console.error('Failed to fetch student requests');
        }
      } catch (error) {
        console.error('Error fetching student requests:', error);
      }
    };

    fetchStudentRequests();
  }, [reducerValue]);

  const showTostify = (msg) => {
    toast.success(msg, {
      position: 'bottom-right',
      autoClose: 5000, // in milliseconds, set to 5000 for 5 seconds
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      style: {
        background: 'rgba(0, 0, 0, 0.7)', // Transparent black background
        color: 'white',
      },
    });
  };
  const openImageModal = (imageData) => {
    setSelectedImage(imageData);
  };
  const closeImageModal = () => {
    setSelectedImage(null);
  };
  const onSubmit = async (data, { resetForm, setSubmitting }) => {
    try {
      console.log('data', data);
      const response = await fetch(
        'http://csmserver.csmdrivingschool.ca/StudentRequest.php',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        }
      );

      if (response.ok) {
        alert('data saved');
        console.log('it worked', await response.json());
        resetForm();
      } else {
        throw new Error('Failed to save data');
      }
    } catch (error) {
      alert('Failed to save data. Please try again.');
      console.error('Error while submitting', error);
    } finally {
      setSubmitting(false);
    }
  };

  const onUpdate = async (data, { resetForm, setSubmitting }) => {
    try {
      console.log('data', data);
      const response = await fetch(
        'http://csmserver.csmdrivingschool.ca/StudentRequest.php',
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        }
      );

      if (response.ok) {
        alert('data updated');
        resetForm();
      } else {
        throw new Error('Failed to update data');
      }
    } catch (error) {
      console.error('Error while updating the data', error);
    } finally {
      setSubmitting(false);
    }
  };

  const handleEditClick = (value) => {
    setEditValues(value);
  };
  const showModalScreen = (itemId) => {
    setCurrentClickedId(itemId);
  };
  const hideModalScreen = () => {};

  const onDelete = async () => {
    try {
      const response = await fetch(
        `http://csmserver.csmdrivingschool.ca/StudentRequest.php?id=${currentClickedId}`,
        {
          method: 'DELETE',
        }
      );

      if (response.ok) {
        setListOfItems(
          listOfItems.filter((item) => item.id !== currentClickedId)
        );
        toast.success('Item deleted successfully', {
          position: 'bottom-right',
          autoClose: 5000, // in milliseconds, set to 5000 for 5 seconds
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: 'rgba(0, 0, 0, 0.7)', // Transparent black background
            color: 'white',
          },
        });
        hideModalScreen();
      } else {
        throw new Error('Failed to delete item');
      }
    } catch (error) {
      console.error('Error deleting item:', error);
    }
  };

  return (
    <div className="AdminContainer container">
      <ToastContainer />
      <section className="middleArea">
        {/* Modal for delete */}

        <div
          class="modal fade"
          id="deleteModal"
          tabindex="-1"
          aria-labelledby="deleteModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="deleteModalLabel">
                  Delete Confirm
                </h5>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
                <p>Are you sure you want to delete</p>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary mx-2"
                  data-bs-dismiss="modal"
                >
                  No
                </button>
                <button
                  type="button"
                  class="btn btn-primary"
                  onClick={onDelete}
                  data-bs-dismiss="modal"
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* Modal for delete */}

        <Modal
          isOpen={selectedImage !== null}
          onRequestClose={closeImageModal}
          contentLabel="Full Size Image"
          style={{
            content: {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              border: '1px solid #ccc',
              background: '#fff',
              borderRadius: '4px',
              padding: '20px',
              maxWidth: '80%',
              maxHeight: '80%',
              overflow: 'auto',
              margin: 'auto',
            },
          }}
        >
          {selectedImage && (
            <>
              <img
                src={selectedImage}
                alt="Full Size"
                style={{
                  maxWidth: '100%',
                  maxHeight: '100%',
                  display: 'block',
                  margin: 'auto',
                }}
              />
              <button
                onClick={closeImageModal}
                style={{
                  position: 'absolute',
                  top: '10px',
                  right: '15px',
                  background: 'none',
                  border: 'none',
                  fontSize: '16px',
                  cursor: 'pointer',
                }}
              >
                Close
              </button>
            </>
          )}
        </Modal>

        {/* Modal for Edit */}
        <div
          class="modal fade"
          id="exampleModal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  Edit Item
                </h5>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
                {editValues && (
                  <Formik
                    initialValues={editValues}
                    validationSchema={validationSchema}
                    enableReinitialize={true}
                    onSubmit={onUpdate}
                  >
                    {({ values, setFieldValue, isSubmitting }) => (
                      <Form>
                        <ErrorMessage name="studentUserName" component="span" />
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="studentUserName">
                            Student UserName
                          </label>

                          <Field
                            type="text"
                            name="studentUserName"
                            id="studentUserName"
                            placeholder="Enter Student's UserName"
                            class="form-control"
                          />
                        </div>
                        <ErrorMessage
                          name="instructorUserName"
                          component="span"
                        />
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="instructorUserName">
                            Instructor UserName
                          </label>

                          <Field
                            type="text"
                            name="instructorUserName"
                            id="instructorUserName"
                            placeholder="Enter Instructor UserName"
                            class="form-control"
                          />
                        </div>
                        <ErrorMessage name="scheduleId" component="span" />
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="scheduleId">
                            scheduleId
                          </label>

                          <Field
                            type="text"
                            name="phoneNumber"
                            id="phoneNumber"
                            placeholder="Enter Phone Number"
                            class="form-control"
                          />
                        </div>
                        <ErrorMessage name="isAccepted" component="span" />
                        <div class="form-group fieldStyle">
                          <label for="isAccepted" htmlFor="isAccepted">
                            Is Accepted
                          </label>

                          <Field
                            type="text"
                            name="isAccepted"
                            id="isAccepted"
                            placeholder="Enter isAccepted"
                            class="form-control"
                          />
                        </div>
                        <ErrorMessage name="isRejected" component="span" />
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="isRejected">
                            isRejected
                          </label>
                          <Field
                            type="text"
                            name="isRejected"
                            id="isRejected"
                            placeholder="isRejected"
                            class="form-control"
                          />
                        </div>

                        <div class="modal-footer">
                          <button type="submit" class="btn btn-primary mx-3">
                            Update
                          </button>
                          <button
                            class="btn btn-secondary"
                            data-bs-dismiss="modal"
                          >
                            Close
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* Modal for Detail */}
        <div
          class="modal fade"
          id="detailModal"
          tabindex="-1"
          aria-labelledby="detailModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="detailModalLabel">
                  Detail
                </h5>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
                {editValues && (
                  <div>
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="studentUserName">
                        studentUserName
                      </label>

                      <b name="studentUserName">{editValues.studentUserName}</b>
                    </div>
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="instructorUserName">
                        instructorUserName
                      </label>

                      <b name="address">{editValues.instructorUserName}</b>
                    </div>
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="scheduleId">
                        scheduleId
                      </label>

                      <b name="scheduleId">{editValues.scheduleId}</b>
                    </div>
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="isAccepted">
                        isAccepted
                      </label>

                      <b name="isAccepted">{editValues.isAccepted}</b>
                    </div>
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="isRejected">
                        isRejected
                      </label>

                      <b name="lessonSheets">{editValues.isRejected}</b>
                    </div>

                    <div class="modal-footer">
                      <button class="btn btn-secondary" data-bs-dismiss="modal">
                        Close
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* Modal for Detail */}
        <div class="container mt-5">
          <div class="row">
            <div class="col-12">
              <h2 class="text-center">
                {' '}
                <i class="fa-solid fa-list"></i> Student Requests
              </h2>
              <p class="text-center"> Manage Student Requests here.</p>
            </div>
          </div>
          <div class="row">
            {/* <div class="col-md-12">
              <div class="card">
                <div class="card-header">
                  <h4 class="card-title">Add Student Request</h4>
                </div>
                <div class="card-body">
                  <Formik
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    validationSchema={validationSchema}
                  >
                    {({ values, setFieldValue, isSubmitting }) => (
                      <Form>
                        <ErrorMessage name="studentUserName" component="span" />
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="studentUserName">
                            Student UserName
                          </label>
                          <Field
                            type="text"
                            name="studentUserName"
                            id="studentUserName"
                            placeholder="Enter Student's UserName"
                            class="form-control"
                          />
                        </div>
                        <ErrorMessage
                          name="instructorUserName"
                          component="span"
                        />
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="instructorUserName">
                            Instructor's UserName
                          </label>
                          <Field
                            type="text"
                            name="instructorUserName"
                            id="instructorUserName"
                            placeholder="Enter Instructor's UserName"
                            class="form-control"
                          />
                        </div>
                        <ErrorMessage name="scheduleId" component="span" />
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="scheduleId">
                            Time
                          </label>
                          <Field
                            type="text"
                            name="scheduleId"
                            id="scheduleId"
                            placeholder="Enter Time"
                            class="form-control"
                          />
                        </div>
                        <ErrorMessage name="email" component="span" />
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="email">
                            Email
                          </label>
                          <Field
                            type="text"
                            name="email"
                            id="email"
                            placeholder="Enter Email"
                            class="form-control"
                          />
                        </div>
                        <ErrorMessage name="lessonSheets" component="span" />
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="lessonSheets">
                            Lesson Sheets
                          </label>
                          <Field
                            type="text"
                            name="lessonSheets"
                            id="lessonSheets"
                            placeholder="Enter Lesson Sheets"
                            class="form-control"
                          />
                        </div>
                        <ErrorMessage name="calendar" component="span" />
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="calendar">
                            Session Date
                          </label>
                          <Field
                            type="text"
                            name="calendar"
                            id="calendar"
                            placeholder="Enter a calendar"
                            class="form-control"
                          />
                        </div>
                        <button
                          type="submit"
                          class="btn btn-primary"
                          disabled={isSubmitting}
                        >
                          Add
                        </button>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div> */}
            <div class="col-md-12">
              <div class="card">
                <div class="card-header">
                  <h4 class="card-title">Student's Request List</h4>
                </div>
                <div class="card-body">
                  <table class="table">
                    <thead>
                      <tr>
                        <th>Student UserName</th>
                        <th>Instructor UserName</th>
                        <th>Schedule Id</th>
                        {/* <th>Session Time</th>
                        <th>Session Date</th> */}
                        <th>isAccepted</th>
                        <th>isRejected</th>

                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {listOfItems.map((value, key) => {
                        return (
                          <tr key={key}>
                            <td>{value.studentUserName}</td>
                            <td>{value.instructorUserName}</td>
                            <td>{value.scheduleId}</td>
                            {/* <td>{value.sessionDate}</td> */}
                            <td>{value.isAccepted}</td>
                            <td>{value.isRejected}</td>

                            <td>
                              <a class="nav-item dropdown">
                                <a
                                  class="fa-solid fa-circle-chevron-down"
                                  data-bs-toggle="dropdown"
                                  href="#"
                                  role="button"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                ></a>
                                <div class="dropdown-menu">
                                  <Link
                                    class="dropdown-item"
                                    data-bs-toggle="modal"
                                    data-bs-target="#exampleModal"
                                    onClick={() => handleEditClick(value)}
                                  >
                                    Edit
                                  </Link>
                                  <Link
                                    class="dropdown-item"
                                    data-bs-toggle="modal"
                                    data-bs-target="#detailModal"
                                    onClick={() => handleEditClick(value)}
                                  >
                                    Detail
                                  </Link>
                                  <div class="dropdown-divider"></div>
                                  <Link
                                    class="dropdown-item"
                                    onClick={() => showModalScreen(value.id)}
                                    msgId={value.id}
                                    data-bs-toggle="modal"
                                    data-bs-target="#deleteModal"
                                  >
                                    Delete
                                  </Link>
                                </div>
                              </a>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default AdminStudentRequest;
