import React from 'react';
import { Link } from 'react-router-dom';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import axios from 'axios';
import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ROUTES from '../../navigations/Routes';
function Instructor() {
  const navigate = useNavigate();
  const [instructorPackage, setInstructorPackage] = useState([]);
  const [selectedPackagePrice, setSelectedPackagePrice] = useState(null);
  const [listOfStates, setListOfStates] = useState([]);
  let { id } = useParams();
  const [listOfItems, setListOfItems] = useState([]);
  const [location, setLocation] = useState([]);
  const [prerequisitesChecked, setPrerequisitesChecked] = useState(false);
  const [banned, setBanned] = useState('no');
  const [formDisabled, setFormDisabled] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const initialValues = {
    firstName: '',
    lastName: '',
    address: '',
    addressLine2: '',
    city: '',
    province: '',
    email: '',
    confirmEmail: '',
    phoneNumber: '',
    licenseNumber: '',
    licenseType: '',
    postalCode: '',
    penaltyPoints: '',
    banned: '',
    licenseImage: '',
    educationalCertificate: '',
    courseRegistration: '',
    courseType: '',
    trainingLocation: '',
    price: '',
    paymentMethod: '',
    totalAmount: '',
    conditions: '',
  };
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('First Name is required!'),
    lastName: Yup.string().required('Last Name is required!'),
    address: Yup.string().required('address is required!'),
    addressLine2: Yup.string().required('address is required!'),
    city: Yup.string().required('province is required!'),
    province: Yup.string().required('city is required!'),
    email: Yup.string().required('Email is required'),
    confirmEmail: Yup.string()
      .oneOf([Yup.ref('email'), null], 'Email must match')
      .required('Required'),
    phoneNumber: Yup.number().required('Required!!'),
    licenseImage: Yup.string().required('Required!!'),
    licenseNumber: Yup.string().required('Required!!'),
    licenseType: Yup.string().required('Required!!'),
    postalCode: Yup.string().required('Required!!'),
    penaltyPoints: Yup.string().required('Required!!'),
    banned: Yup.string().required('Required!!'),
    educationalCertificate: Yup.string().required('Required!!'),
    courseType: Yup.string().required('Required!!'),
    virtualClasses: Yup.boolean().required('Required!!'),
    prerequisites: Yup.boolean().required('Required!!'), // Make sure it's checked
    trainingLocation: Yup.string().required('Required!!'),
    price: Yup.string().required('Required!!'),
    paymentMethod: Yup.string().required('Required!!'),
    conditions: Yup.boolean().required('Required!!'),
  });
  const onSubmit = async (data, { setSubmitting }) => {
    try {
      if (parseInt(data.penaltyPoints) > 3) {
        alert("You're not eligible for this.");
        return; // Prevent form submission
      }
      const formData = new FormData();
      formData.append('licenseImage', data.licenseImage);
      formData.append('firstName', data.firstName);
      formData.append('lastName', data.lastName);
      formData.append('address', data.address);
      formData.append('addressLine2', data.addressLine2);
      formData.append('city', data.city);
      formData.append('province', data.province);
      formData.append('email', data.email);
      formData.append('confirmEmail', data.confirmEmail);
      formData.append('phoneNumber', data.phoneNumber);
      formData.append('licenseNumber', data.licenseNumber);
      formData.append('licenseType', data.licenseType);
      formData.append('postalCode', data.postalCode);
      formData.append('penaltyPoints', data.penaltyPoints);
      formData.append('banned', data.banned);
      formData.append('educationalCertificate', data.educationalCertificate);
      formData.append('courseRegistration', data.courseRegistration);
      formData.append('courseType', data.courseType);
      formData.append('virtualClasses', data.virtualClasses);
      formData.append('prerequisites', data.prerequisites);
      formData.append('trainingLocation', data.trainingLocation);
      formData.append('price', data.price);
      formData.append('paymentMethod', data.paymentMethod);
      formData.append('totalAmount', data.totalAmount);
      formData.append('conditions', data.conditions);
      console.log(data, 'data');
      await axios.post('https://localhost:3001/insRegistration', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      alert('data');
      console.log(data);
    } catch (error) {
      console.error(error);
    } finally {
      setSubmitting(false);
    }
  };
  const handlePackageSelection = (price) => {
    setSelectedPackagePrice(price);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch instructorPackage data
        const instructorResponse = await fetch(
          'https://csmserver.csmdrivingschool.ca/InstructorPackage.php'
        );
        if (instructorResponse.ok) {
          const instructorData = await instructorResponse.json();
          setInstructorPackage(instructorData);
        } else {
          throw new Error('Network response was not ok.');
        }
      } catch (error) {
        console.error('Error while fetching data', error);
        // Handle error here
      }
    };

    fetchData();
  }, [refresh]);

  //
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch training location data
        const trainingLocationResponse = await fetch(
          'https://csmserver.csmdrivingschool.ca/TrainingLocation.php'
        );
        if (trainingLocationResponse.ok) {
          const trainingLocationData = await trainingLocationResponse.json();
          setLocation(trainingLocationData);
        } else {
          throw new Error('Network response was not ok.');
        }
        // Fetch courseCampus data
        const campusResponse = await fetch(
          'https://csmserver.csmdrivingschool.ca/CourseCampus.php'
        );
        if (campusResponse.ok) {
          const campusData = await campusResponse.json();
          setListOfItems(campusData);
        } else {
          throw new Error('Network response was not ok.');
        }

        // Fetch states data
        const statesResponse = await fetch(
          `https://csmserver.csmdrivingschool.ca/CanadaStates.php?unlock=true`
        );
        if (statesResponse.ok) {
          const statesData = await statesResponse.json();
          if (statesData.length > 0) {
            setListOfStates(statesData);
          } else {
            console.log('No unlocked locations found.');
          }
        } else {
          throw new Error('Network response was not ok.');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();

    // Cleanup function if needed
    return () => {
      // cleanup code if necessary
    };
  }, [refresh]);

  const [penaltyPoints, setPenaltyPoints] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleInputChange = (event) => {
    const points = event.target.value;
    setPenaltyPoints(points);
    setErrorMessage('');
    // Check if points is greater than '3'
    if (points && parseInt(points) > 3) {
      setErrorMessage("You're not eligible for this.");
    } else {
      setErrorMessage('');
    }
  };
  //
  const handleRadioChange = (event) => {
    const value = event.target.value;
    setBanned(value);

    if (value === 'yes') {
      // Display alert message
      alert("You're not eligible for this.");
      // Disable the form
      setFormDisabled(true);
    } else {
      // Enable the form if previously disabled
      setFormDisabled(false);
    }
  };
  return (
    <div className="main">
      <div className="homeContainer">
        {/*  */}
        <div class="container-fluid aboutInstructor">
          <div data-aos="fade-up" data-aos-delay="200">
            {/* <div class="col-lg-12 my-5 d-flex align-items-center justify-content-center "></div>
            <div className="justify-content-center mb-5 p"></div> */}
            <div className="untree_co-section col-12">
              <div className="row">
                <div className="col-12" data-aos="fade-up" data-aos-delay="100">
                  <div className="feature">
                    <h1>Decide if becoming an instructor is right for you</h1>

                    <p className="px-4 pt-4">
                      As much as we're biased towards it being a great career,
                      being a driving instructor isn't for everyone and you
                      should be aware of the time and financial investments
                      required to become one.
                    </p>
                    <h2 className=" py-3 px-4">
                      Pros of becoming a Driving Instructor
                    </h2>
                    <div className="">
                      <div className="col-12">
                        <p>
                          <span className="uil uil-check-circle"></span>Be your
                          own boss.
                        </p>
                        <p>
                          <span className="uil uil-check-circle"></span>Teaching
                          is rewarding.
                        </p>
                        <p>
                          <span className="uil uil-check-circle"></span>Balance
                          your work and life.
                        </p>
                      </div>
                      <div className="col-12">
                        <p>
                          <span className="uil uil-check-circle"></span>No day
                          is really the same.
                        </p>
                        <p>
                          <span className="uil uil-check-circle"></span>You'll
                          always have a modern car.
                        </p>
                        <p>
                          <span className="uil uil-check-circle"></span>Career
                          Progression.
                        </p>
                      </div>
                    </div>
                    <h2 className=" py-3 px-4">
                      Cons of becoming a Driving Instructor
                    </h2>
                    <div className="col-12">
                      <p>
                        <span className="uil uil-check-circle"></span>You need
                        to be personable.
                      </p>
                      <p>
                        <span className="uil uil-check-circle"></span>Able to
                        manage your time well.
                      </p>
                      <p>
                        <span className="uil uil-check-circle"></span>You need
                        to be patient.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*  */}
          <div class="untree_co-section" data-aos="fade-up" data-aos-delay="0">
            <div class="container-fluid">
              <div class="row">
                {instructorPackage.map((value, key) => {
                  return (
                    <div
                      key={value.id}
                      class="col-lg-4 col-md-6 col-sm-12 mb-4 mb-lg-0 instructorP"
                      data-aos="fade-up"
                      data-aos-delay="00"
                    >
                      <div class="pricing bg-white">
                        <div class="packageName pricing-body pBody">
                          <div class="priceHeading">
                            <h3 class="pt-3">{value.packageName}</h3>
                            <h5 class="px-2">{value.packageNameP}</h5>
                          </div>
                          <div class="priceP">
                            <span>{value.packagePrice}</span>
                            <br />
                          </div>
                          <p
                            class="descriptionP mb-4 text-black px-3"
                            style={{
                              whiteSpace: 'pre-line',
                            }}
                          >
                            <span class="d-inline-block my-2">
                              {value.description} <br />
                            </span>
                          </p>
                          <p>
                            <Link
                              // to={`${ROUTES.register.name}/${value.id}`}
                              // className="btn btn-outline-primary"
                              // onClick={() =>
                              //   handlePackageSelection(value.packagePrice)
                              // }
                              className="btn btn-outline-primary"
                              to={ROUTES.instructorForm.name}
                            >
                              
                              Sign Up!
                            </Link>
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Instructor;
