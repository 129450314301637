import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ROUTES from '../../navigations/Routes';
import { useState, useEffect } from 'react';

function AdminHome() {
  const [loggedIn, setLoggedIn] = useState('');
  const navigate = useNavigate();
  const [loggedAdmin, setLoggedInAdmin] = useState('');
  //
  useEffect(() => {
    const usr = localStorage.getItem('id');
    const userName = localStorage.getItem('currentAdmin');
    if (!usr) {
      // Redirect if user is not logged in

      navigate('/adminLogin');
    } else {
      setLoggedIn(usr);
      setLoggedInAdmin(userName);
      console.log(userName);
    }
  }, []);
  //
  return (
    <div className="AdminContainer container">
      <section className="middleArea">
        <h2 className="my-3">Welcome, {loggedAdmin}! </h2>
        <h5 className="my-4">
          Manage CSM's content and operations from this admin panel.
        </h5>
        <div className="actionButtons">
          <Link to={ROUTES.adminsList.name} className="lnkButton">
            Admins List
          </Link>
          <Link to={ROUTES.adminModule.name} className="lnkButton">
            Admin Module
          </Link>
          <Link to={ROUTES.bdePackage.name} class="lnkButton">
            BDE Package
          </Link>
          <Link to={ROUTES.bdeRegister.name} class="lnkButton mx-1">
            BDE Register
          </Link>

          <Link to={ROUTES.adminCourseCampus.name} className="lnkButton">
            Course Campus
          </Link>
          {/* <Link to={ROUTES.admincontact.name} class="lnkButton">
            Contact List
          </Link> */}
          <Link to={ROUTES.adminCanadaStates.name} className="lnkButton">
            Canada States
          </Link>

          <Link to={ROUTES.g2PlusAdmin.name} class="lnkButton">
            G2 Plus Registers
          </Link>
          <Link to={ROUTES.gPlusAdmin.name} class="lnkButton">
            G Plus Registers
          </Link>

          {/* <Link to={ROUTES.studentRequest.name} class="lnkButton">
            Student's Request
          </Link> */}
          <Link to={ROUTES.g2PlusPackage.name} class="lnkButton">
            G2 Plus Package
          </Link>
          <Link to={ROUTES.gPlusPackage.name} class="lnkButton">
            G Plus Package
          </Link>
          <Link to={ROUTES.adminInsRegistration.name} className="lnkButton">
            Instructor Course Registration
          </Link>
          <Link to={ROUTES.instructorAdmin.name} class="lnkButton">
            Instructor's List
          </Link>
          <Link to={ROUTES.adminInstructorPackage.name} className="lnkButton">
            Instructor Package
          </Link>
          <Link to={ROUTES.adminInstructorRegister.name} className="lnkButton">
            Instructor Register
          </Link>
          <Link to={ROUTES.location.name} class="lnkButton">
            Locations
          </Link>

          <Link to={ROUTES.questions.name} className="lnkButton">
            Questions
          </Link>
          <Link to={ROUTES.adminStudentRequest.name} class="lnkButton">
            Student Request
          </Link>
          <Link to={ROUTES.studentUser.name} className="lnkButton">
            Student User
          </Link>

          <Link to={ROUTES.adminStudentDetail.name} className="lnkButton">
            Student Detail
          </Link>
          <Link to={ROUTES.theorytestcategories.name} class="lnkButton">
            Theory Test Categories
          </Link>
          <Link to={ROUTES.adminTestResult.name} className="lnkButton">
            Test Result
          </Link>

          <Link to={ROUTES.adminTrainingLocation.name} className="lnkButton">
            Training Location
          </Link>
        </div>
      </section>
    </div>
  );
}

export default AdminHome;
