import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import axios from 'axios';
import { useEffect, useState } from 'react';
function TestResult() {
  const [resultData, setResultData] = useState(null); // State to store result data
  const [error, setError] = useState(null);
  const [loggedIn, setLoggedIn] = useState([]);
  const [moduleName, setModuleName] = useState('');
  const [categoryName, setCategoryName] = useState('');
  const [userName, setUserName] = useState('');
  useEffect(() => {
    const usr = localStorage.getItem('id');
    setLoggedIn(usr);
    console.log(usr);
  }, []);
  const location = useLocation();
  const { data } = location.state || {};
  const cardClass =
    data.resultstatus.toLowerCase() === 'pass'
      ? 'card resultCard pass'
      : 'card resultCard fail';

  useEffect(() => {
    if (data) {
      fetchModuleName(data.moduleid);
      fetchCategoryName(data.testCategoryId);
      fetchUserName(data.userId);
    }
  }, [data]);

  const fetchUserName = async (userId) => {
    console.log(userId);
    try {
      const response = await fetch(
        `https://csmserver.csmdrivingschool.ca/StudentRegister.php?id=${userId}`
      );
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      const userName = data[0].userName; // Corrected property name
      setUserName(userName);
      console.log(userName);
    } catch (error) {
      console.error('Error fetching user name:', error);
      setUserName('Unknown user');
    }
  };

  const fetchModuleName = async (moduleId) => {
    console.log(moduleId);
    try {
      const response = await fetch(
        `https://csmserver.csmdrivingschool.ca/Module.php?id=${moduleId}`
      );
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      const moduleName = data[0].name; // Corrected property name
      setModuleName(moduleName);
      console.log(moduleName);
    } catch (error) {
      console.error('Error fetching module name:', error);
      setModuleName('Unknown Module');
    }
  };

  const fetchCategoryName = async (testCategoryId) => {
    console.log(testCategoryId);
    try {
      const response = await axios.get(
        `https://csmserver.csmdrivingschool.ca/TheoryTestCategories.php?categoryById=${testCategoryId}`
      );
      console.log(response.data); // Check the response data structure
      const categoryName = response.data.categoryname; // Use the correct property name
      console.log(categoryName);
      setCategoryName(categoryName);
    } catch (error) {
      console.error('Error fetching category name:', error);
      setCategoryName('Unknown category');
    }
  };

  return (
    <div className="main">
      <div className="homeContainer">
        <div className="untree_co-section" style={{ marginTop: 90 }}>
          <div className="container-fluid">
            <div className="row d-flex justify-content-center align-items-stretch instructorPanel">
              <div
                className="col-12 my-4"
                data-aos="fade-up"
                data-aos-delay="0"
              >
                {/* <div className="card resultCard"> */}
                <div className={cardClass}>
                  <div className="card-header resultCardHeading">
                    Your Result
                  </div>
                  <div className="card-body">
                    {data ? (
                      <>
                        <div className="card-text d-flex resultCol">
                          <div className="label">
                            <h5>
                              <b>UserName</b>
                            </h5>
                          </div>
                          <div className="value">
                            <h5>{userName}</h5>
                          </div>
                        </div>
                        <div className="card-text d-flex resultCol">
                          <div className="label">
                            <h5>
                              <b>Theory TestCategory:</b>
                            </h5>
                          </div>
                          <div className="value">
                            <h5>{categoryName}</h5>
                          </div>
                        </div>
                        <div className="card-text d-flex resultCol">
                          <div className="label">
                            <h5>
                              <b>Module:</b>
                            </h5>
                          </div>
                          <div className="value">
                            <h5>{moduleName}</h5>
                          </div>
                        </div>
                        <div className="card-text d-flex resultCol">
                          <div className="label">
                            <h5>
                              <b>Correct Answers:</b>
                            </h5>
                          </div>
                          <div className="value">
                            <h5>{data.correctans}</h5>
                          </div>
                        </div>
                        <div className="card-text d-flex resultCol">
                          <div className="label">
                            <h5>
                              <b>Incorrect Answers:</b>
                            </h5>
                          </div>
                          <div className="value">
                            <h5>{data.incorrectans}</h5>
                          </div>
                        </div>
                        <div className="card-text d-flex resultCol">
                          <div className="label">
                            <h5>
                              <b>Date:</b>
                            </h5>
                          </div>
                          <div className="value">
                            <h5>{new Date(data.date).toLocaleString()}</h5>
                          </div>
                        </div>
                        <div className="card-text d-flex resultCol">
                          <div className="label">
                            <h5>
                              <b>Result Status:</b>
                            </h5>
                          </div>
                          <div className="value">
                            <h5>{data.resultstatus}</h5>
                          </div>
                        </div>
                      </>
                    ) : (
                      <p>{error}</p>
                    )}
                  </div>
                  <div className="card-footer text-muted"></div>
                </div>
                {/* </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TestResult;
