import React from 'react';

function RefresherLessons() {
  return (
    <div className="main">
      <div className="homeContainer">
        <div
          class="untree_co-hero inner-page container-fluid TheoryTest"
          style={{ height: 'auto' }}
        >
          <div class="row">
            <div class="col-12">
              <div class="row refesherLesson">
                <div class="col-lg-6 mb-5">
                  <div
                    class="section-title mb-3"
                    data-aos="fade-up"
                    data-aos-delay="0"
                  >
                    <h2 class="my-4 text-black">Refresher Driving Lessons</h2>
                  </div>
                  <h4
                    data-aos="fade-up"
                    data-aos-delay="100"
                    className=" text-black"
                    style={{ fontSize: 27 }}
                  >
                    Learn with CSM
                  </h4>
                  <button className="btn btn-primary mt-4 PriceG">
                    Get Prices
                  </button>
                </div>
                <div class="col-lg-6" data-aos="fade-up" data-aos-delay="0">
                  <figure class="img-wrap">
                    <img
                      src="images/speed.jpg"
                      alt="Image"
                      class="img-fluid"
                      //   width="400"
                      //height="400"
                    />
                    <div class="dotted"></div>
                  </figure>
                </div>
              </div>
              {/*  */}
              <div
                class="justify-content-center my-5"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <h4
                  className="text-black border-bottom"
                  style={{ fontSize: 35 }}
                >
                  Refresher Lessons, Improve your Driving Skills
                </h4>
              </div>
              <div className="lessonContent">
                <h3>What are refresher driving lessons?</h3>
                <p>
                  Refresher driving lessons are a perfect way to improve on
                  certain skills, bump up your road safety or boost your
                  confidence behind the wheel.
                </p>
                <p>
                  We understand that there are several reasons why drivers
                  require refresher lessons; from needing additional assurance
                  to get behind the wheel following an accident to building
                  confidence when driving on highway, highway 400 series and a
                  motorway or at night, that's why our instructors are trained
                  to listen to your needs and tailor your course accordingly.
                </p>

                <h3>
                  Do refresher courses include night-time or motorway driving
                  lessons?
                </h3>
                <p>
                  If that's what you require, then sure. You can actually cover
                  a wide range of driving topics in your refresher courses, here
                  are just a handful of them:
                </p>
                <p>1. Night driving</p>
                <p>2. Highway, Highway 400 series</p>
                <p>3. Motorway driving</p>
                <p>4. Roundabouts</p>
                <p>5. Parking (reverse manoeuvre)</p>
                <p>6. Traffic signs</p>
                <p>7. Speed awareness</p>
                <p>8. Confidence</p>
                <h3>
                  Can I specify what I'd like to cover during my refresher
                  lessons?
                </h3>
                <p>
                  You can, of course! Our instructors listen to your needs and
                  customize your refresher course since they are aware that
                  drivers of all ages come to CSM Driving School for refresher
                  classes for a variety of reasons.
                </p>
                <h3>How long is a driving refresher course?</h3>
                <p>
                  That is solely up to you. We typically advise scheduling five
                  hours of teaching if you're just starting to drive again after
                  a break from it or if you recently passed but are still a
                  little anxious. This will ensure that you're getting the most
                  out of your experience. We can easily top you up for a couple
                  extra hours if you need more time.
                </p>
                <h3>How much are refresher lesson?</h3>
                <p>
                  The cost of refresher lessons differs across the country, for
                  a price in your area get a quote online.
                </p>
                <h3>Refresher driving lessons near me</h3>
                <p>
                  Our expert instructors offer refresher driving lessons all
                  over the Ontario so we'll have you covered wherever you are.
                  Some of our more popular areas. Please book your refresher
                  lessons online and check our availablity of instructors in
                  your area.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          className="bookBox container-fluid"
          data-aos="fade-up"
          data-aos-delay="200"
        >
          <h3 className="text-white">Book your lessons online!</h3>
          <h5 className="text-white mt-3">
            Check availability and book your first lesson
          </h5>
          <button className="btn btn-primary btnPrice">See Prices</button>
        </div>
      </div>
    </div>
  );
}
export default RefresherLessons;
