import React from 'react';
import { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import ROUTES from '../../navigations/Routes';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function StudentRequest() {
  const [listOfStudentRequests, setListOfStudentRequests] = useState([]);
  const [editValues, setEditValues] = useState(null);
  const [currentClickedId, setCurrentClickedId] = useState('');
  let { userName } = useParams();
  const { id } = useParams();

  const [loggedIn, setLoggedIn] = useState('');
  const [stuserName, setStuserName] = useState('');
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [loggedInUser, setLoggedInUser] = useState('');
  const [hasFetched, setHasFetched] = useState(false);
  const [status, setStatus] = useState(() => {
    const savedStatus = localStorage.getItem('studentStatus');
    return savedStatus ? JSON.parse(savedStatus) : {};
  });
  const initialValues = {
    userName: '',
    address: '',
    phoneNumber: '',
    postalCode: '',
    email: '',
    dob: '',
    calendar: '',
    status: '',
  };
  useEffect(() => {
    const usr = localStorage.getItem('id');
    setLoggedIn(usr);
    console.log(usr);
    const userName = localStorage.getItem('currentInsName');
    setLoggedInUser(userName);
    console.log(userName);
  }, []);
  const handleEdit = (value) => {
    setEditValues(value);
  };
  const onNavigate = (value) => {
    const usr = localStorage.getItem('id');
    setLoggedIn(usr);
    localStorage.setItem('id', usr);
    const userName = localStorage.getItem('currentInsName');
    setLoggedInUser(userName);
    localStorage.setItem('currentInsName', userName);
    console.log(userName);
    console.log(value.id);
  };
  useEffect(() => {
    console.log('Logged In User:', loggedInUser);

    const fetchStudentRequests = async () => {
      try {
        const response = await fetch(
          `https://csmserver.csmdrivingschool.ca/StudentRequest.php?id=${loggedInUser}`
        );

        if (!response.ok) {
          throw new Error('Failed to fetch student requests');
        }

        const jsonResponse = await response.json();
        console.log('Response JSON:', jsonResponse);

        if (jsonResponse.error) {
          console.error('Error in response:', jsonResponse.error);
          setListOfStudentRequests([]); // Set state to empty array in case of error
        } else {
          const data = jsonResponse.data;
          console.log('Response Data:', data);

          if (!data || data.length === 0) {
            setListOfStudentRequests([]);
          } else {
            // Update studentUserName with actual username from each request
            const requestsWithUserNames = await Promise.all(
              data.map(async (request) => {
                const studentUserName = request.studentUserName;
                const studentNameResponse = await fetchStudentName(
                  studentUserName
                );
                const studentName =
                  studentNameResponse[0]?.userName || 'Unknown User'; // Ensure this matches your data structure
                return { ...request, studentUserName: studentName };
              })
            );
            setStatus((prevStatus) => {
              const updatedStatus = { ...prevStatus };
              data.forEach((request) => {
                if (!updatedStatus[request.id]) {
                  updatedStatus[request.id] = 'Pending';
                }
              });
              localStorage.setItem(
                'studentStatus',
                JSON.stringify(updatedStatus)
              );
              return updatedStatus;
            });
            setListOfStudentRequests(requestsWithUserNames);
          }
        }
      } catch (error) {
        console.error('Error fetching student requests:', error);
        setListOfStudentRequests([]); // Set state to empty array in case of error
      }
    };

    // Function to fetch the username of a student by their studentUserName
    const fetchStudentName = async (studentUserName) => {
      try {
        const response = await fetch(
          `https://csmserver.csmdrivingschool.ca/StudentRegister.php?id=${studentUserName}`
        );
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        return data;
      } catch (error) {
        console.error('Error fetching student name:', error);
        return [];
      }
    };

    if (!hasFetched && loggedInUser) {
      fetchStudentRequests();
      setHasFetched(true); // Update state to indicate fetch has been done
    }
  }, [loggedInUser, hasFetched]);
  const hideModalScreen = () => {};
  const showModalScreen = (msgId) => {
    setCurrentClickedId(msgId);
  };
  const onDelete = () => {
    console.log('id', currentClickedId);
    axios
      .delete(
        `https://csmserver.csmdrivingschool.ca/StudentRegister.php?id=${currentClickedId}`
      )
      .then((response) => {
        setListOfStudentRequests(
          listOfStudentRequests.filter(function (item) {
            return item.id !== currentClickedId;
          })
        );
      });
    toast.success('Item deleted successfully', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      style: {
        background: 'rgba(0, 0, 0, 0.7)',
        color: 'white',
      },
    });
    hideModalScreen();
  };

  const handleStudentSelection = (userName) => {
    setSelectedStudent(userName);
  };
  const handleStudentRejection = async (id) => {
    console.log(id);
    try {
      const response = await fetch(
        `https://csmserver.csmdrivingschool.ca/StudentRequest.php?id=${id}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            id: id,
            isAccepted: '0',
            isRejected: '1',
          }),
        }
      );

      if (!response.ok) {
        throw new Error('Failed to reject student request');
      }

      const contentType = response.headers.get('content-type');
      if (contentType && contentType.includes('application/json')) {
        const responseData = await response.json();
        console.log('Response data:', responseData);

        if (responseData.success) {
          setStatus((prevStatus) => {
            const updatedStatus = {
              ...prevStatus,
              [id]: 'Rejected',
            };
            localStorage.setItem(
              'studentStatus',
              JSON.stringify(updatedStatus)
            );
            return updatedStatus;
          });
        } else {
          throw new Error(
            responseData.message || 'Failed to reject student request'
          );
        }
      } else {
        const responseText = await response.text();
        console.error('Non-JSON response:', responseText);

        try {
          const responseData = JSON.parse(responseText);
          if (responseData.success) {
            setStatus((prevStatus) => {
              const updatedStatus = {
                ...prevStatus,
                [id]: 'Rejected',
              };
              localStorage.setItem(
                'studentStatus',
                JSON.stringify(updatedStatus)
              );
              return updatedStatus;
            });
            console.log('Student request accepted, status updated');
          } else {
            throw new Error(
              responseData.message || 'Failed to rejected student request'
            );
          }
        } catch (error) {
          console.error('Invalid JSON response:', responseText);
          throw new Error(
            `Failed to rejected student request: ${responseText}`
          );
        }
      }
    } catch (error) {
      console.error('Error while rejecting request:', error);
      // Handle error state or display error message as needed
    }
  };

  const handleStudentAcceptance = async (id) => {
    try {
      console.log('Sending PUT request with id:', id);

      const response = await fetch(
        `https://csmserver.csmdrivingschool.ca/StudentRequest.php?id=${id}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            id: id,
            isAccepted: '1', // Assuming isAccepted should always be '1'
          }),
        }
      );
      console.log('Response status:', response.status);
      const contentType = response.headers.get('content-type');
      if (contentType && contentType.includes('application/json')) {
        const responseData = await response.json();
        console.log('Response data:', responseData);

        if (responseData.success) {
          setStatus((prevStatus) => ({
            ...prevStatus,
            // [String(id)]: true,
            [String(id)]: 'Accepted',
          }));
          console.log('Student request accepted, status updated');
        } else {
          throw new Error(
            responseData.message || 'Failed to accept student request'
          );
        }
      } else {
        const responseText = await response.text();
        console.error('Non-JSON response:', responseText);

        try {
          const responseData = JSON.parse(responseText);
          if (responseData.success) {
            setStatus((prevStatus) => ({
              ...prevStatus,
              // [String(id)]: true,
              [String(id)]: 'Accepted',
            }));
            console.log('Student request accepted, status updated');
          } else {
            throw new Error(
              responseData.message || 'Failed to accept student request'
            );
          }
        } catch (error) {
          console.error('Invalid JSON response:', responseText);
          throw new Error(`Failed to accept student request: ${responseText}`);
        }
      }
    } catch (error) {
      console.error('Error while accepting request:', error);
      // Handle error state or display error message as needed
    }
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      month: 'short',
      day: '2-digit',
      year: 'numeric',
    });
  }
  const handleRejection = (value) => {
    //handleStudentRejection(value.id, value.studentUserName);
    handleStudentRejection(value.id);
    onNavigate(value);
  };
  const handleClick = (value) => {
    // handleStudentAcceptance(value.id, value.studentUserName);
    handleStudentAcceptance(value.id);
    console.log(value.id);
    // onNavigate(value); // Call the onNavigate function with the value parameter
  };
  return (
    <div className="main">
      <div className="homeContainer">
        <ToastContainer></ToastContainer>
        <div
          class="modal fade"
          id="deleteModal"
          tabindex="-1"
          aria-labelledby="deleteModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="deleteModalLabel">
                  Delete Confirm
                </h5>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  background-color: #f1f1f1;
                  <i class="fa-solid fa-xmark"></i>
                </button>
              </div>
              <div class="modal-body">
                <p>Are you sure you want to delete</p>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary mx-2"
                  data-bs-dismiss="modal"
                >
                  No
                </button>
                <button
                  type="button"
                  class="btn btn-primary"
                  onClick={onDelete}
                  data-bs-dismiss="modal"
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
        {/*  */}
        <div
          class="modal fade"
          id="detailModal"
          tabindex="-1"
          aria-labelledby="detailModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="detailModalLabel">
                  Detail
                </h5>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
                {editValues && (
                  <div>
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="studentUserName">
                        Name
                      </label>

                      <b name="studentUserName">{editValues.studentUserName}</b>
                    </div>

                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="scheduleId">
                        scheduleId
                      </label>

                      <b name="scheduleId">{editValues.scheduleId}</b>
                    </div>
                    <div class="modal-footer">
                      <button class="btn btn-secondary" data-bs-dismiss="modal">
                        Close
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>{' '}
        <div className="container-fluid studentList">
          <div className="d-flex justify-content-center">
            <h2>List Of Student's Request </h2>
          </div>
          <Link
            to={ROUTES.acceptedRequest.name}
            className="btn btn-primary mx-2 my-2"
            onClick={onNavigate}
          >
            Accepted
          </Link>
          <Link
            to={ROUTES.rejectedRequests.name}
            className="btn btn-primary"
            onClick={onNavigate}
          >
            Rejected
          </Link>

          <div class="card w-100" data-aos="fade-up" data-aos-delay="100">
            <div class="card-body">
              <table className="table table-striped table-hover">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Time</th>
                    <th>Date</th>
                    <th>Accept</th>
                    <th>Reject</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {listOfStudentRequests === null ? (
                    <tr>
                      <td>Loading...</td>
                    </tr>
                  ) : listOfStudentRequests.length === 0 ? (
                    <tr>
                      <td>No Student Requests</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  ) : (
                    listOfStudentRequests.map((value, index) => {
                      const studentNumber = index + 1;
                      //const requestStatus = Status[value.id];
                      const sessionTime = value.sessionTime || 'N/A';
                      const sessionDate = value.sessionDate || 'N/A';
                      return (
                        <tr key={value.id}>
                          <td>{`${studentNumber}. ${value.studentUserName}`}</td>
                          <td>{sessionTime}</td>
                          <td>{sessionDate}</td>
                          <td>
                            <Link
                              className="btn btn-info"
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                handleClick(value);
                              }}
                              // to={ROUTES.acceptedRequest.name}
                            >
                              Accept
                            </Link>
                          </td>
                          <td>
                            <Link
                              className="btn btn-danger"
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                handleRejection(value);
                              }}
                              // to={ROUTES.rejectedRequests.name}
                            >
                              Reject
                            </Link>
                          </td>
                          {/* <td>{Status[index.id] ? 'Accepted' : 'Rejected'}</td> */}
                          <td>{status[value.id]}</td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StudentRequest;
