import React from 'react';


import Home from '../Components/User/Home';
import About from '../Components/User/About';
import Contact from '../Components/User/Contact';
import LogIn from '../Components/Student/LogIn';
import Register from '../Components/User/Register';
import AdminContact from '../Components/Admin/AdminContact';
import ImageSlider from '../Components/ImageSlider';
import Package from '../Components/User/Package';
import LearnToDrive from '../Components/User/LearnToDrive';
import TheoryTest from '../Components/User/TheoryTest';
import Instructor from '../Components/User/Instructor';
import StudentRegister from '../Components/Student/StudentRegister';
import BDE from '../Components/User/BDE';
import G2Plus from '../Components/User/G2Plus';
import G2PlusRegister from '../Components/User/G2PlusRegister';
import GPlus from '../Components/User/GPlus';
import GPlusRegister from '../Components/User/GPlusRegister';
import Counter from '../Components/User/Counter';
import Evaluation from '../Components/User/Evaluation';
import Payment from '../Components/User/Payment';
import AdminPanel from '../Components/Admin/AdminPanel';
import BDERegister from '../Components/Admin/BDERegister';
import BDEPackage from '../Components/Admin/BDEPackage';
import G2PlusAdmin from '../Components/Admin/G2PlusAdmin';
import GPlusAdmin from '../Components/Admin/GPlusAdmin';
import InstructorAdmin from '../Components/Admin/InstructorAdmin';
import Index from '../Components/User/Index';
import Location from '../Components/Admin/Location';
import SliderText from '../Components/SliderText';
import Student from '../Components/Student/Student';
import AdminInstructorDetail from '../Components/Admin/AdminInstructorDetail';
import Slider2 from '../Components/Slider2';
import G2PlusPackage from '../Components/Admin/G2PlusPackage';
import GPlusPackage from '../Components/Admin/GPlusPackage';
import InstructorLogIn from '../Components/Instructor/InstructorLogIn';
import InstructorPanel from '../Components/Instructor/InstructorPanel';
import AdminStudentDetail from '../Components/Admin/AdminStudentDetail';
import InstructorsList from '../Components/Instructor/InstructorsList';
import StudentCourses from '../Components/Student/StudentCourses';
import AdminStudentPanel from '../Components/Admin/AdminStudentPanel';
import StudentUser from '../Components/Admin/StudentUser';
import StudentRequest from '../Components/Student/StudentRequest';
import AdminStudentRequest from '../Components/Admin/AdminStudentRequest';
import RejectedRequests from '../Components/Student/RejectedRequests';
import GuideToDrivingTest from '../Components/User/GuideToDrivingTest';
import RefresherLessons from '../Components/User/RefresherLessons';
import FemaleInstructor from '../Components/User/FemaleInstructor';
import AdvanceDrivingCourse from '../Components/User/AdvanceDrivingCourse';
import GuideToPracticalTest from '../Components/User/GuideToPracticalTest';
import LearnerDriverInsurance from '../Components/User/LearnerDriverInsurance';
import ObtainLicense from '../Components/User/ObtainLicense';
import AcceptedRequest from '../Components/Student/AcceptedRequest';
import NotificationList from '../Components/Instructor/NotificationList';
import JoinOurFleet from '../Components/User/JoinOurFleet';
import FleetRegister from '../Components/User/FleetRegister';
import TheoryTestCategories from '../Components/Admin/TheoryTestCategories';
import AdminHome from '../Components/Admin/AdminHome';
import Test from '../Components/Student/Test';
import TestModule from '../Components/Student/TestModule';
import GuideToBDE from '../Components/User/GuideToBDE';
import RegisterForm from '../Components/Instructor/RegisterForm';
import Questions from '../Components/Admin/Questions';
import LessonSheets from '../Components/Student/LessonSheets';
import PracticeTest from '../Components/Student/PracticeTest';
import AdminTestQuestions from '../Components/Admin/AdminTestQuestions';
import AdminTestResult from '../Components/Admin/AdminTestResult';
import TestResult from '../Components/Student/TestResult';
import AdminInstructorPackage from '../Components/Admin/AdminInstructorPackage';
import AdminCanadaStates from '../Components/Admin/AdminCanadaStates';
import PostCode from '../Components/Admin/PostCode';
import AdminCourseCampus from '../Components/Admin/AdminCourseCampus';
import AdminTrainingLocation from '../Components/Admin/AdminTrainingLocation';
import AdminInsRegistration from '../Components/Admin/AdminInsRegistration';
import AdminInstructorRegister from '../Components/Admin/AdminInstructorRegister';
import AdminModule from '../Components/Admin/AdminModule';
import AdminLogIn from '../Components/Admin/AdminLogIn';
import AdminRegister from '../Components/Admin/AdminRegister';
import AdminsList from '../Components/Admin/AdminsList';
import PracticeCategories from '../Components/Student/PracticeCategories';
import PracticeModules from '../Components/Student/PracticeModules';
import PracticeModuleQues from '../Components/Student/PracticeModuleQues';
import InstructorForm from '../Components/Instructor/InstructorForm';
import BookOnline from '../Components/Student/BookOnline';
import GetPostCode from '../Components/User/GetPostCode';
import GetTime from '../Components/User/GetTime';
import OfflineStudentRegistration from '../Components/User/OfflineStudentRegistration';
import StudentRegisterSummary from '../Components/User/StudentRegisterSummery';

const ROUTES = {
  index: {
    name: '/',
    component: <Index></Index>,
  },
  home: {
    name: '/',
    component: <Home></Home>,
  },
  getPostCode: {
    name: '/getPostCode',
    component: <GetPostCode></GetPostCode>,
  },
  studentRegisterSummary: {
    name: '/studentRegisterSummary',
    component: <StudentRegisterSummary></StudentRegisterSummary>,
  },
  getTime: {
    name: '/getTime',
    component: <GetTime></GetTime>,
  },
  offlineStudentRegistration: {
    name: '/offlineStudentRegistration',
    component: <OfflineStudentRegistration></OfflineStudentRegistration>,
  },
  about: {
    name: '/about',
    component: <About></About>,
  },
  contact: {
    name: '/contact',
    component: <Contact></Contact>,
  },
  package: {
    name: '/package',
    component: <Package></Package>,
  },
  login: {
    name: '/login',
    component: <LogIn></LogIn>,
  },
  register: {
    name: '/register',
    component: <Register></Register>,
  },

  imageslider: {
    name: '/imageslider',
    component: <ImageSlider></ImageSlider>,
  },
  learntodrive: {
    name: '/learntodrive',
    component: <LearnToDrive></LearnToDrive>,
  },
  theorytest: {
    name: '/theorytest',
    component: <TheoryTest></TheoryTest>,
  },
  instructor: {
    name: '/instructor',
    component: <Instructor></Instructor>,
  },
  studentregister: {
    name: '/studentregister',
    component: <StudentRegister></StudentRegister>,
  },
  bde: {
    name: '/bde',
    component: <BDE></BDE>,
  },
  g2plus: {
    name: '/g2plus',
    component: <G2Plus></G2Plus>,
  },
  g2plusregister: {
    name: '/g2plusregister',
    component: <G2PlusRegister></G2PlusRegister>,
  },
  gplus: {
    name: '/gplus',
    component: <GPlus></GPlus>,
  },
  gplusregister: {
    name: '/gplusregister',
    component: <GPlusRegister></GPlusRegister>,
  },
  counter: {
    name: '/counter',
    component: <Counter></Counter>,
  },
  evaluation: {
    name: '/evaluation',
    component: <Evaluation></Evaluation>,
  },
  payment: {
    name: '/payment',
    component: <Payment></Payment>,
  },
  sliderText: {
    name: '/sliderText',
    component: <SliderText></SliderText>,
  },
  sliderText: {
    name: '/slider',
    component: <Slider2></Slider2>,
  },
  student: {
    name: '/student',
    component: <Student></Student>,
  },
  instructorLogin: {
    name: '/instructorLogin',
    component: <InstructorLogIn></InstructorLogIn>,
  },
  instructorPanel: {
    name: '/instructorPanel',
    component: <InstructorPanel></InstructorPanel>,
  },
  acceptedRequest: {
    name: '/acceptedRequest',
    component: <AcceptedRequest></AcceptedRequest>,
  },
  instructorsList: {
    name: '/instructorsList',
    component: <InstructorsList></InstructorsList>,
  },
  studentCourses: {
    name: '/studentCourses',
    component: <StudentCourses></StudentCourses>,
  },
  studentRequest: {
    name: '/studentRequest',
    component: <StudentRequest></StudentRequest>,
  },
  rejectedRequests: {
    name: '/rejectedRequests',
    component: <RejectedRequests></RejectedRequests>,
  },
  guideToDrivingTest: {
    name: '/guideToDrivingTest',
    component: <GuideToDrivingTest></GuideToDrivingTest>,
  },
  guideToPracticalTest: {
    name: '/guideToPracticalTest',
    component: <GuideToPracticalTest></GuideToPracticalTest>,
  },
  refresherLessons: {
    name: '/refresherLessons',
    component: <RefresherLessons></RefresherLessons>,
  },
  femaleInstructor: {
    name: '/femaleInstructor',
    component: <FemaleInstructor></FemaleInstructor>,
  },
  advanceDrivingCourse: {
    name: '/advanceDrivingCourse',
    component: <AdvanceDrivingCourse></AdvanceDrivingCourse>,
  },
  learnerDriverInsurance: {
    name: '/learnerDriverInsurance',
    component: <LearnerDriverInsurance></LearnerDriverInsurance>,
  },
  obtainLicense: {
    name: '/obtainLicense',
    component: <ObtainLicense></ObtainLicense>,
  },
  notificationList: {
    name: '/notificationList',
    component: <NotificationList></NotificationList>,
  },
  joinOurFleet: {
    name: '/joinOurFleet',
    component: <JoinOurFleet></JoinOurFleet>,
  },
  fleetRegister: {
    name: '/fleetRegister',
    component: <FleetRegister></FleetRegister>,
  },
  test: {
    name: '/test',
    component: <Test></Test>,
  },
  testModule: {
    name: '/testModule',
    component: <TestModule></TestModule>,
  },
  guideToBDE: {
    name: '/guideToBDE',
    component: <GuideToBDE></GuideToBDE>,
  },
  registerForm: {
    name: '/registerForm',
    component: <RegisterForm></RegisterForm>,
  },
  lessonSheets: {
    name: '/lessonSheets',
    component: <LessonSheets></LessonSheets>,
  },
  practiceTest: {
    name: '/practiceTest',
    component: <PracticeTest></PracticeTest>,
  },
  practiceCategories: {
    name: '/practiceCategories',
    component: <PracticeCategories></PracticeCategories>,
  },
  practiceModules: {
    name: '/practiceModules',
    component: <PracticeModules></PracticeModules>,
  },
  practiceModuleQues: {
    name: '/practiceModuleQues',
    component: <PracticeModuleQues></PracticeModuleQues>,
  },
  testResult: {
    name: '/testResult',
    component: <TestResult></TestResult>,
  },
  instructorForm: {
    name: '/instructorForm',
    component: <InstructorForm></InstructorForm>,
  },
  bookOnline: {
    name: '/bookOnline',
    component: <BookOnline></BookOnline>,
  },

  // admin
  adminpanel: {
    name: '/',
    component: <AdminPanel></AdminPanel>,
  },
  admincontact: {
    name: '/admincontact',
    component: <AdminContact></AdminContact>,
  },
  adminhome: {
    name: '/adminhome',
    component: <AdminHome></AdminHome>,
  },
  bdeRegister: {
    name: '/bdeRegister',
    component: <BDERegister></BDERegister>,
  },
  bdePackage: {
    name: '/bdePackage',
    component: <BDEPackage></BDEPackage>,
  },
  g2PlusAdmin: {
    name: '/g2PlusAdmin',
    component: <G2PlusAdmin></G2PlusAdmin>,
  },
  gPlusAdmin: {
    name: '/gPlusAdmin',
    component: <GPlusAdmin></GPlusAdmin>,
  },
  instructorAdmin: {
    name: '/instructorAdmin',
    component: <InstructorAdmin></InstructorAdmin>,
  },
  location: {
    name: '/location',
    component: <Location></Location>,
  },
  adminInsructorDetail: {
    name: '/adminInstructorDetail',
    component: <AdminInstructorDetail></AdminInstructorDetail>,
  },
  g2PlusPackage: {
    name: '/g2PlusPackage',
    component: <G2PlusPackage></G2PlusPackage>,
  },
  gPlusPackage: {
    name: '/gPlusPackage',
    component: <GPlusPackage></GPlusPackage>,
  },
  adminStudentDetail: {
    name: '/adminStudentDetail',
    component: <AdminStudentDetail></AdminStudentDetail>,
  },
  adminStudentPanel: {
    name: '/adminStudentPanel',
    component: <AdminStudentPanel></AdminStudentPanel>,
  },
  studentUser: {
    name: '/studentUser',
    component: <StudentUser></StudentUser>,
  },
  adminStudentRequest: {
    name: '/adminStudentRequest',
    component: <AdminStudentRequest></AdminStudentRequest>,
  },
  theorytestcategories: {
    name: '/theorytestcategories',
    component: <TheoryTestCategories></TheoryTestCategories>,
  },
  questions: {
    name: '/questions',
    component: <Questions></Questions>,
  },
  adminTestQuestions: {
    name: '/adminTestQuestions',
    component: <AdminTestQuestions></AdminTestQuestions>,
  },
  adminTestResult: {
    name: '/adminTestResult',
    component: <AdminTestResult></AdminTestResult>,
  },
  adminInstructorPackage: {
    name: '/adminInstructorPackage',
    component: <AdminInstructorPackage></AdminInstructorPackage>,
  },
  adminCanadaStates: {
    name: '/adminCanadaStates',
    component: <AdminCanadaStates></AdminCanadaStates>,
  },
  adminPostCode: {
    name: '/PostCode',
    component: <PostCode></PostCode>,
  },
  adminCourseCampus: {
    name: '/adminCourseCampus',
    component: <AdminCourseCampus></AdminCourseCampus>,
  },
  adminTrainingLocation: {
    name: '/adminTrainingLocation',
    component: <AdminTrainingLocation></AdminTrainingLocation>,
  },
  adminInsRegistration: {
    name: '/adminInsRegistration',
    component: <AdminInsRegistration></AdminInsRegistration>,
  },
  adminInstructorRegister: {
    name: '/adminInstructorRegister',
    component: <AdminInstructorRegister></AdminInstructorRegister>,
  },
  adminModule: {
    name: '/adminModule',
    component: <AdminModule></AdminModule>,
  },
  adminRegister: {
    name: '/adminRegister',
    component: <AdminRegister></AdminRegister>,
  },
  adminLogIn: {
    name: '/adminLogIn',
    component: <AdminLogIn></AdminLogIn>,
  },
  adminsList: {
    name: '/adminsList',
    component: <AdminsList></AdminsList>,
  },
};

export default ROUTES;
