import React, { useEffect, useState } from 'react';
import ROUTES from '../../navigations/Routes';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
function BDE({ value }) {
  const [listOfBDEPackages, setlistOfBDEPackages] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    fetch('https://csmserver.csmdrivingschool.ca/BDEPackage.php')
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        setlistOfBDEPackages(data);
      })
      .catch((error) => {
        console.error('Fetch error:', error);
        // Handle errors here
      });
  }, []);

  const [selectedPackagePrice, setSelectedPackagePrice] = useState(null);
  const handlePackageSelection = (price) => {
    setSelectedPackagePrice(price);
  };
  return (
    <div className="main">
      <div className="homeContainer">
        <div
          class="untree_co-hero inner-page container-fluid"
          style={{
            backgroundImage: `url('/images/slider.jpg')`,
          }}
        >
          <div class="container-fluid">
            <div class="row align-items-center justify-content-center">
              <div class="col-12">
                <div class="row justify-content-center ">
                  <div class="col-lg-6 text-center ">
                    <h1
                      class="heading text-white"
                      data-aos="fade-up"
                      data-aos-delay="100"
                      style={{ marginTop: 100 }}
                    >
                      CSM- BDE Program Plus
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="untree_co-section pricingSection">
          <div class="container-fluid">
            <div class="row justify-content-center mb-5">
              <div
                class="col-lg-7 text-center"
                data-aos="fade-up"
                data-aos-delay="0"
              >
                <h2 class="line-bottom text-center mt-3">Pricing</h2>
              </div>
            </div>
            <div className="row">
              {listOfBDEPackages.map((value, key) => {
                return (
                  <div
                    key={value.id}
                    class="col-md-6 mb-4 mb-lg-0 col-lg-4"
                    data-aos="fade-up"
                    data-aos-delay="00"
                  >
                    <div class="pricing bg-white">
                      <div className="packageName pricing-body">
                        <h3 class="pricing-plan-title">{value.packageName}</h3>
                        <div className="price">
                          <span
                            className={`fig ${
                              value.discount != 0 ? 'discounted' : ''
                            }`}
                          >
                            {value.discount == 0 ? (
                              // If no discount, render the package price without any styling
                              `$${value.packagePrice}`
                            ) : (
                              <span>${value.packagePrice}</span>
                            )}
                          </span>

                          {value.discount != 0 && (
                            <>
                              <span className="discount mx-2">
                                ${value.discount}
                              </span>
                              <br />
                              {/* Display the "save" field only when discount is present */}
                              <span className="save mx-2">
                                Save ${value.save}
                              </span>
                            </>
                          )}
                          <br />
                          <span className="text-black">
                            + Taxes & Certification Fee
                          </span>
                        </div>

                        <p>
                          <Link
                            to={`${ROUTES.register.name}/${value.id}`}
                            className="btn btn-outline-primary"
                            onClick={() =>
                              handlePackageSelection(value.packagePrice)
                            }
                          >
                            Register
                          </Link>
                        </p>
                        <p
                          class="packageDescription mb-4 text-black"
                          style={{
                            whiteSpace: 'pre-line',
                          }}
                        >
                          <span class="d-inline-block my-2">
                            {value.description} <br />
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        {/*  */}
        <div class="untree_co-section">
          <div class="row justify-content-center">
            <div
              class="col-lg-7 text-center"
              data-aos="fade-up"
              data-aos-delay="0"
            >
              <h2 class="line-bottom text-center">What We Offer</h2>
            </div>
          </div>
          <div class="row">
            <div className="featureBackground d-flex flex-wrap">
              <div
                class="col-12  col-lg-3 featureBox"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <span className="">
                  <img
                    src="/images/training.png"
                    width="150"
                    height="100"
                  ></img>
                </span>
                <h3>Our Training</h3>
                <h6>
                  CSM Driving Schools are always on the lookout for new students
                  to join our prestigious Begginer Driver Program. Do you have a
                  teen or a loved one who is thinking about taking driver's
                  education? <br />
                  For more information, please see CSM Driving School training
                  programs and fees listed above. <br />
                  Sign up for Driving School right now!!
                </h6>
              </div>

              <div
                class="col-12 col-lg-3 featureBox"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <span className="">
                  <img src="/images/safety.png" width="150" height="100"></img>
                </span>
                <h3>Highway Safety</h3>
                <h6>
                  Following the speed limit.
                  <br />
                  Respecting other road users. <br />
                  Examining blind spots.
                  <br />
                  Use seatbelts.
                  <br />
                  Car seats for children and booster seats. <br />
                  Highway Dos and Don'ts
                </h6>
              </div>
              <div
                class="col-12 col-lg-3 featureBox"
                data-aos="fade-up"
                data-aos-delay="300"
              >
                <span>
                  <img
                    src="/images/customize.png"
                    width="150"
                    height="100"
                  ></img>
                </span>
                <h3>Customized Programs</h3>
                <h6>
                  Our goal is to make the roads of Ontario as safe as possible.
                  We effectively prepare our students for their driving tests
                  and train tomorrow's safe and responsible drivers. <br />
                  Our instructors have received extensive training. <br />
                  Offering Schedule flexibility.
                </h6>
              </div>

              <div
                class="col-12 col-lg-3  featureBox"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <span>
                  <img src="/images/traffic.png" width="150" height="100"></img>
                </span>
                <h3>Traffic Rules Test</h3>
                <h6>
                  We hired highly trained instructors to ensure that you
                  understand all of the Traffic Rules by the end of your course.
                  They will make every effort to provide knowledge of traffic
                  rules that will assist you in passing your test. <br />
                  Driving Tip: Get to know the area where you'll be taking your
                  test. Keep track of speed limits and street signs.
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default BDE;
