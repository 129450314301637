import React from 'react';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ROUTES from '../../navigations/Routes';
import { useParams } from 'react-router-dom';
import Modal from 'react-modal';
function AdminTestQuestions() {
  const { id } = useParams();
  const [listOfQuestions, setListOfQuestions] = useState([]);
  const [currentClickedId, setCurrentClickedId] = useState('');
  const [addValues, setAddValues] = useState('');
  const [editValues, setEditValues] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedImage, setSelectedImage] = useState(null); // To show the Image full view in Modal
  const [testCategories, setTestCategories] = useState([]);
  const [module, setModule] = useState([]);
  const [listOfItems, setListOfItems] = useState([]);
  const [selectedModule, setSelectedModule] = useState('');
  const handleModuleClick = (moduleId) => {
    console.log('Module clicked:', moduleId); // Debug: Log clicked module ID
    setSelectedModule(moduleId);
    const selectedModuleName = module.find(
      (item) => item.id === moduleId
    )?.name;
    console.log('Selected Module Name:', selectedModuleName); // Debug: Log selected module name
  };
  useEffect(() => {
    if (id) {
      fetchModules(id);
    }
  }, [id]);
  useEffect(() => {
    const fetchModules = async () => {
      try {
        const response = await fetch(
          `http://csmserver.csmdrivingschool.ca/Module.php?categoryId=${id}`
        );
        if (!response.ok) {
          throw new Error('Failed to fetch modules');
        }
        const data = await response.json();
        console.log('Fetched modules for category:', data); // Debug: Log fetched modules
        setModule(data);
      } catch (error) {
        console.error('Error fetching modules:', error);
      }
    };

    fetchModules();
  }, [id]);
  const handleSubmit = (value) => {
    setAddValues(value);
  };

  const handleEditClick = (value) => {
    setEditValues(value);
  };

  const showModalScreen = (itemId) => {
    setCurrentClickedId(itemId);
  };

  const hideModalScreen = () => {};
  const onDelete = () => {
    console.log('id', currentClickedId);
    fetch(
      `https://csmserver.csmdrivingschool.ca/Questions.php?id=${currentClickedId}`,
      {
        method: 'DELETE',
      }
    )
      .then((response) => {
        if (response.ok) {
          setListOfQuestions(
            listOfQuestions.filter(function (item) {
              return item.id !== currentClickedId;
            })
          );

          toast.success('Item deleted successfully', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            style: {
              background: 'rgba(0, 0, 0, 0.7)',
              color: 'white',
            },
          });
          hideModalScreen();
        } else {
          throw new Error('Network response was not ok.');
        }
      })
      .catch((error) => {
        console.error('Error while deleting', error);
        // Handle error here
      });
  };

  useEffect(() => {
    if (selectedModule) {
      fetchQuestions(selectedModule);
    }
  }, [selectedModule]);
  const fetchQuestions = async (moduleId) => {
    try {
      const response = await fetch(
        `http://csmserver.csmdrivingschool.ca/Questions.php?moduleId=${moduleId}`
      );
      if (!response.ok) {
        throw new Error('Failed to fetch questions');
      }
      console.log('Module::::', moduleId);
      const data = await response.json();
      console.log('Fetched questions:', data); // Debug: Log fetched questions
      setListOfQuestions(data);
    } catch (error) {
      console.error('Error fetching questions:', error);
    }
  };

  const fetchModules = async (categoryId) => {
    try {
      const response = await fetch(
        `http://csmserver.csmdrivingschool.ca/Module.php?categoryId=${categoryId}`
      );
      if (!response.ok) {
        throw new Error('Failed to fetch modules');
      }
      const data = await response.json();
      console.log('Fetched modules for category:', data); // Debug: Log fetched modules
      setModule(data);
    } catch (error) {
      console.error('Error fetching modules:', error);
    }
  };

  useEffect(() => {
    const fetchTestCategories = async () => {
      try {
        const response = await fetch(
          'http://csmserver.csmdrivingschool.ca/TheoryTestCategories.php'
        );
        if (!response.ok) {
          throw new Error('Failed to fetch test categories');
        }
        const data = await response.json();
        setTestCategories(data);
      } catch (error) {
        console.error('Error fetching test categories:', error);
      }
    };

    fetchTestCategories();
  }, []);
  //filtering data
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };
  // useEffect(() => {
  //   // Fetch test categories from your backend API
  //   axios
  //     //.get('http://csmserver.csmdrivingschool.ca/Module.php')
  //     .get('http://localhost:3001/module')
  //     .then((response) => {
  //       setModule(response.data);
  //       console.log(module);
  //     })
  //     .catch((error) => {
  //       console.error('Error fetching modules:', error);
  //     });
  // }, []);
  const filteredData = listOfQuestions.filter((item) => {
    const values = Object.values(item).map((value) =>
      String(value).toLowerCase()
    );
    return values.some((value) => value.includes(searchTerm.toLowerCase()));
  });

  const onUpdate = async (data) => {
    try {
      const formData = new FormData();
      formData.append('id', data.id);
      formData.append('categoryId', data.categoryId);
      formData.append('moduleId', data.moduleId);
      formData.append('image', data.image);
      formData.append('question', data.question);
      formData.append('option1', data.option1);
      formData.append('option2', data.option2);
      formData.append('option3', data.option3);
      formData.append('option4', data.option4);
      formData.append('correct', data.correct);
      formData.append('explanation', data.explanation);

      await fetch('http://csmserver.csmdrivingschool.ca/Questions.php', {
        method: 'PUT',
        body: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      window.location.reload();
    } catch (error) {
      console.error('Error updating:', error);
    }
  };

  const openImageModal = (imageData) => {
    setSelectedImage(imageData);
  };
  const closeImageModal = () => {
    setSelectedImage(null);
  };
  //
  const filteredQuestions = listOfQuestions.filter(
    (question) => question.module === selectedModule
  );
  return (
    <div>
      <ToastContainer></ToastContainer>
      <div
        class="modal fade"
        id="deleteModal"
        tabindex="-1"
        aria-labelledby="deleteModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="deleteModalLabel">
                Delete Confirm
              </h5>
              <button
                type="button"
                data-bs-dismiss="modal"
                aria-label="Close"
                style={{ border: 'none', backgroundColor: 'white' }}
              >
                <i class="bi bi-x-lg"></i>
              </button>
            </div>
            <div class="modal-body">
              <p>Are you sure you want to delete</p>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-primary mx-2"
                data-bs-dismiss="modal"
              >
                No
              </button>
              <button
                type="button"
                class="btn btn-primary"
                onClick={onDelete}
                data-bs-dismiss="modal"
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <h2 class="text-center">
            {' '}
            <i class="bi bi-journals"></i> List Of Questions
          </h2>
          <p class="text-center"> Manage Questions here.</p>
        </div>
      </div>
      <div class="row">
        <Modal
          isOpen={selectedImage !== null}
          onRequestClose={closeImageModal}
          contentLabel="Full Size Image"
          style={{
            content: {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              border: '1px solid #ccc',
              background: '#fff',
              borderRadius: '4px',
              padding: '20px',
              maxWidth: '80%',
              maxHeight: '80%',
              overflow: 'auto',
              margin: 'auto',
            },
          }}
        >
          {selectedImage && (
            <>
              <img
                src={selectedImage}
                alt="Full Size"
                style={{
                  maxWidth: '100%',
                  maxHeight: '100%',
                  display: 'block',
                  margin: 'auto',
                }}
              />
              <button
                onClick={closeImageModal}
                style={{
                  position: 'absolute',
                  top: '10px',
                  right: '15px',
                  background: 'none',
                  border: 'none',
                  fontSize: '16px',
                  cursor: 'pointer',
                }}
              >
                Close
              </button>
            </>
          )}
        </Modal>
        {/* Modal for Edit */}
        <div
          class="modal fade"
          id="exampleModal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog editModal">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  Edit Question
                </h5>
                <button
                  type="button"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  style={{ border: 'none', backgroundColor: 'white' }}
                >
                  <i class="bi bi-x-lg" style={{ fontSize: 24 }}></i>
                </button>
              </div>
              <div class="modal-body">
                {editValues && (
                  <Formik
                    initialValues={editValues}
                    //validationSchema={validationSchema}
                    enableReinitialize={true}
                    onSubmit={onUpdate}
                  >
                    {({ values, setFieldValue, isSubmitting }) => (
                      <Form>
                        <div className="form-group fieldStyle">
                          <label htmlFor="categoryId">Test Category</label>
                          <Field
                            as="select"
                            name="categoryId"
                            id="categoryId"
                            className="form-control"
                          >
                            <option value="">Select a test category</option>
                            {testCategories.map((category) => (
                              <option key={category.id} value={category.id}>
                                {category.categoryname}
                              </option>
                            ))}
                          </Field>
                        </div>

                        <div className="form-group fieldStyle">
                          <label htmlFor="moduleId">Module</label>
                          <Field
                            as="select"
                            name="moduleId"
                            id="moduleId"
                            className="form-control"
                          >
                            <option value="">Select a module</option>
                            {module.map((value) => (
                              <option key={value.id} value={value.id}>
                                {value.name}
                              </option>
                            ))}
                          </Field>
                          <ErrorMessage name="moduleId" component="span" />
                        </div>
                        <div class="form-group fieldStyle">
                          <label for="image" htmlFor="image">
                            image
                          </label>

                          <input
                            type="file"
                            onChange={(event) => {
                              setFieldValue('image', event.target.files[0]);
                            }}
                          ></input>
                        </div>
                        <ErrorMessage name="question" component="span" />
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="question">
                            question
                          </label>

                          <Field
                            type="text"
                            name="question"
                            id="question"
                            placeholder="Enter a question"
                            class="form-control"
                          />
                        </div>
                        <ErrorMessage name="option1" component="span" />
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="option1">
                            option1
                          </label>

                          <Field
                            type="text"
                            name="option1"
                            id="option1"
                            placeholder="Enter a option1"
                            class="form-control"
                          />
                        </div>
                        <ErrorMessage name="option2" component="span" />
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="option2">
                            option2
                          </label>

                          <Field
                            type="text"
                            name="option2"
                            id="option2"
                            placeholder="Enter a option2"
                            class="form-control"
                          />
                        </div>
                        <ErrorMessage name="option3" component="span" />
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="option3">
                            option3
                          </label>

                          <Field
                            type="text"
                            name="option3"
                            id="option3"
                            placeholder="Enter a option3"
                            class="form-control"
                          />
                        </div>
                        <ErrorMessage name="option4" component="span" />
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="option4">
                            option4
                          </label>

                          <Field
                            type="text"
                            name="option4"
                            id="option4"
                            placeholder="Enter a option4"
                            class="form-control"
                          />
                        </div>
                        <ErrorMessage name="correct" component="span" />
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="correct">
                            correct
                          </label>

                          <Field
                            type="text"
                            name="correct"
                            id="correct"
                            placeholder="Enter a correct"
                            class="form-control"
                          />
                        </div>
                        <ErrorMessage name="explanation" component="span" />
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="explanation">
                            explanation
                          </label>

                          <Field
                            type="text"
                            name="explanation"
                            id="explanation"
                            placeholder="Enter a explanation"
                            class="form-control"
                          />
                        </div>

                        <div class="modal-footer">
                          <button type="submit" class="btn btn-primary mx-3">
                            Update
                          </button>
                          <button
                            class="btn btn-primary"
                            data-bs-dismiss="modal"
                          >
                            Close
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* Modal for Edit */}
        {/* Modal for Detail */}
        <div
          class="modal fade"
          id="detailModal"
          tabindex="-1"
          aria-labelledby="detailModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog detailModal">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="detailModalLabel">
                  Detail
                </h5>
                <button
                  type="button"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  style={{ border: 'none', backgroundColor: 'white' }}
                >
                  <i class="bi bi-x-lg" style={{ fontSize: 24 }}></i>
                </button>
              </div>
              <div class="modal-body">
                {editValues && (
                  <div>
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="question">
                        Question
                      </label>

                      <p name="question">{editValues.question}</p>
                    </div>
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="option1">
                        Option 1
                      </label>

                      <p name="option1">{editValues.option1}</p>
                    </div>
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="option2">
                        Option 2
                      </label>

                      <p name="option2">{editValues.option2}</p>
                    </div>
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="option3">
                        Option 3
                      </label>

                      <p name="option3">{editValues.option3}</p>
                    </div>
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="option4">
                        Option 4
                      </label>

                      <p name="option4">{editValues.option4}</p>
                    </div>
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="correct">
                        Correct
                      </label>

                      <p name="correct">{editValues.correct}</p>
                    </div>
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="explanation">
                        Explanation
                      </label>

                      <p name="explanation" className="px-5">
                        {editValues.explanation}
                      </p>
                    </div>

                    <div class="modal-footer">
                      <div class="detailImageBoxContainer">
                        <div class="detailImageBox">
                          <img
                            src={
                              editValues.image
                                ? `../${editValues.image.replace(/\\/g, '/')}`
                                : '/images/common.png'
                            }
                            alt="image"
                            style={{
                              cursor: editValues.image ? 'pointer' : 'default',
                            }}
                          />
                        </div>
                      </div>
                      <button class="btn btn-primary" data-bs-dismiss="modal">
                        Close
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* Modal for Detail */}
        {/*  */}
        <div class="col-md-12 mt-5">
          <div className="row">
            <Link to={ROUTES.questions.name} className="btn btn-primary">
              Add/Back
            </Link>
            {/* search bar */}
            <input
              className=" form-control col-3 mt-4"
              type="text"
              placeholder="Search here"
              value={searchTerm}
              onChange={handleSearch}
              style={{ height: 40 }}
            />
          </div>
          <div className="row px-1">
            {module.map((value) => (
              <button
                onClick={() => handleModuleClick(value.id)}
                className={`btn btn-primary mx-1 ${
                  value.id === selectedModule ? 'active' : ''
                }`}
                key={value.id}
              >
                {value.name}
              </button>
            ))}
          </div>
          {selectedModule !== null && (
            <div class="col-md-12" style={{ width: '100%' }}>
              <div class="card">
                <div class="card-header">
                  {/* <h4 class="card-title">Questions List</h4> */}

                  <h2>
                    Questions for{' '}
                    {module.find((item) => item.id === selectedModule)?.name}
                  </h2>
                </div>
                <div class="card-body">
                  <table class="table">
                    <thead>
                      <tr>
                        <th>image</th>
                        {/* <th>Module</th> */}
                        <th>question</th>
                        <th>option1</th>
                        <th>option2</th>
                        <th>option3</th>
                        <th>option4</th>
                        <th>correct</th>
                        <th>explanation</th>

                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {listOfQuestions.map((value) => {
                        const fullExplanation = value.explanation;
                        const words = fullExplanation.split(' ');
                        const truncatedExplanation = words
                          .slice(0, 20)
                          .join(' ');
                        return (
                          <tr key={value.id}>
                            <td>
                              {value && (
                                <img
                                  src={
                                    value.image
                                      ? `https://csmdrivingschool.ca/${value.image.replace(
                                          /\\/g,
                                          '/'
                                        )}`
                                      : '/images/common.png'
                                  }
                                  alt="Question Image"
                                  onClick={
                                    value.image
                                      ? () => openImageModal(value.image)
                                      : null
                                  }
                                  style={{
                                    cursor: value.image ? 'pointer' : 'default',
                                    height: '100px',
                                    width: '100px',
                                  }} // Inline styles for height and width
                                />
                              )}
                            </td>

                            {/* <td>{value.testModule?.name}</td> */}
                            <td>{value.question}</td>
                            <td>{value.option1}</td>
                            <td>{value.option2}</td>
                            <td>{value.option3}</td>
                            <td>{value.option4}</td>
                            <td>{value.correct}</td>
                            <td>{truncatedExplanation}.....</td>

                            <td>
                              <a class="nav-item dropdown">
                                <a
                                  class="fa-solid fa-circle-chevron-down"
                                  data-bs-toggle="dropdown"
                                  href="#"
                                  role="button"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                ></a>
                                <div class="dropdown-menu">
                                  <Link
                                    class="dropdown-item"
                                    data-bs-toggle="modal"
                                    data-bs-target="#exampleModal"
                                    onClick={() => handleEditClick(value)}
                                  >
                                    Edit
                                  </Link>

                                  <Link
                                    class="dropdown-item"
                                    data-bs-toggle="modal"
                                    data-bs-target="#detailModal"
                                    onClick={() => handleEditClick(value)}
                                  >
                                    Detail
                                  </Link>

                                  <div class="dropdown-divider"></div>
                                  <Link
                                    class="dropdown-item"
                                    onClick={() => showModalScreen(value.id)}
                                    msgId={value.id}
                                    data-bs-toggle="modal"
                                    data-bs-target="#deleteModal"
                                  >
                                    Delete
                                  </Link>
                                </div>
                              </a>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default AdminTestQuestions;
