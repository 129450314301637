import React from 'react';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function PostCode() {
  const [listOfCodes, setListOfCodes] = useState([]);
  const [currentClickedId, setCurrentClickedId] = useState('');
  const [addValues, setAddValues] = useState('');
  const [editValues, setEditValues] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [lockStatus, setLockStatus] = useState({});
  const initialValues = {
    postCode: '',
    isLocked: '',
  };
  const validationSchema = Yup.object().shape({
    postCode: Yup.string().required(),
  });
  const handleSubmit = (value) => {
    setAddValues(value);
  };
  const handleEdit = (value) => {
    setEditValues(value);
  };
  const showModalScreen = (msgId) => {
    setCurrentClickedId(msgId);
  };
  const hideModalScreen = () => {};
  const onDelete = () => {
    console.log('id', currentClickedId);
    fetch(
      `https://csmserver.csmdrivingschool.ca/PostCodes.php?id=${currentClickedId}`,
      {
        method: 'DELETE',
      }
    )
      .then((response) => {
        if (response.ok) {
          console.log(listOfCodes);

          setListOfCodes(
            listOfCodes.filter((item) => item.id !== currentClickedId)
          );
          toast.success('Item deleted successfully', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            style: {
              background: 'rgba(0, 0, 0, 0.7)',
              color: 'white',
            },
          });
          hideModalScreen();
        } else {
          throw new Error('Network response was not ok.');
        }
      })
      .catch((error) => {
        console.error('Error while deleting:', error);
      });
  };

  useEffect(() => {
    fetch('https://csmserver.csmdrivingschool.ca/PostCodes.php')
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('Network response was not ok.');
        }
      })
      .then((data) => {
        setListOfCodes(data);
        console.log(data);

        const lockStatusMap = {};
        data.forEach((postCode) => {
          lockStatusMap[postCode.id] = postCode.is_locked;
        });
        setLockStatus(lockStatusMap);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);

  const lockLocation = async (id) => {
    try {
      const response = await fetch(
        'https://csmserver.csmdrivingschool.ca/PostCodes.php',
        {
          method: 'PUT',
          body: JSON.stringify({ id, isLocked: true }),
        }
      );
      if (response.ok) {
        setLockStatus((prevLockStatus) => ({
          ...prevLockStatus,
          [String(id)]: true,
        }));
      } else {
        throw new Error('Network response was not ok.');
      }
    } catch (error) {
      console.error('Error locking State:', error);
    }
  };

  useEffect(() => {
    console.log('Component Re-rendered after lockStatus update');
  }, [lockStatus]);

  const unlockLocation = async (id) => {
    try {
      const response = await fetch(
        'https://csmserver.csmdrivingschool.ca/PostCodes.php',
        {
          method: 'PUT',
          body: JSON.stringify({ id, isLocked: false }),
        }
      );
      if (response.ok) {
        setLockStatus((prevLockStatus) => ({
          ...prevLockStatus,
          [id]: false,
        }));
      } else {
        throw new Error('Network response was not ok.');
      }
    } catch (error) {
      console.error('Error unlocking state:', error);
    }
  };

  const onSubmit = async (data, { resetForm }) => {
    try {
      console.log('data', data);
      const response = await fetch(
        'https://csmserver.csmdrivingschool.ca/PostCodes.php',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        }
      );
      if (response.ok) {
        alert('data saved');
        console.log('it worked', response);
        resetForm();
      } else {
        throw new Error('Network response was not ok.');
      }
    } catch (error) {
      console.error('Error while submitting:', error);
    }
  };

  const onUpdate = async (data, { resetForm }) => {
    try {
      console.log('updating data', data);
      const response = await fetch(
        'https://csmserver.csmdrivingschool.ca/PostCodes.php',
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        }
      );
      if (response.ok) {
        alert('data updated');
        resetForm();
      } else {
        throw new Error('Network response was not ok.');
      }
    } catch (error) {
      console.error('Error while updating the data:', error);
    }
  };

  //filtering data
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };
  const filteredData = listOfCodes.filter((item) => {
    const values = Object.values(item).map((value) =>
      String(value).toLowerCase()
    );
    return values.some((value) => value.includes(searchTerm.toLowerCase()));
  });
  return (
    <div>
      <ToastContainer></ToastContainer>
      <div
        class="modal fade"
        id="deleteModal"
        tabindex="-1"
        aria-labelledby="deleteModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="deleteModalLabel">
                Delete Confirm
              </h5>
              <button
                type="button"
                data-bs-dismiss="modal"
                aria-label="Close"
                style={{ border: 'none', backgroundColor: 'white' }}
              >
                <i class="bi bi-x-lg"></i>
              </button>
            </div>
            <div class="modal-body">
              <p>Are you sure you want to delete</p>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-primary mx-2"
                data-bs-dismiss="modal"
              >
                No
              </button>
              <button
                type="button"
                class="btn btn-primary"
                onClick={onDelete}
                data-bs-dismiss="modal"
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <h2 class="text-center">
            {' '}
            <i class="bi bi-geo-alt"></i> List Of Post Codes
          </h2>
          <p class="text-center"> Manage Post Codes here.</p>
        </div>
      </div>
      <div class="row">
        <div
          class="modal fade"
          id="exampleSubmitModal"
          tabindex="-1"
          aria-labelledby="exampleSubmitModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleSubmitModalLabel">
                  Add Post Code
                </h5>
                <button
                  type="button"
                  aria-label="Close"
                  data-bs-dismiss="modal"
                >
                  <i class="bi bi-x-lg"></i>
                </button>
              </div>
              <div className="modal-body">
                {initialValues && (
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                  >
                    {({ values, setFieldValue, isSubmitting }) => (
                      <Form>
                        <ErrorMessage
                          name="location"
                          component="span"
                          style={{ color: 'red' }}
                        />
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="postCode">
                            Post Code
                          </label>
                          <Field
                            type="text"
                            name="postCode"
                            id="postCode"
                            placeholder="Add Post Code here"
                            class="form-control"
                          />
                        </div>
                        <div class="modal-footer">
                          <button type="submit" class="btn btn-primary mx-3">
                            Save
                          </button>
                          <button
                            class="btn btn-primary mx-3"
                            data-bs-dismiss="modal"
                          >
                            Close
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* edit modal */}
        <div
          class="modal fade"
          id="exampleModal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  Edit Post Code
                </h5>
                <button
                  type="button"
                  aria-label="Close"
                  data-bs-dismiss="modal"
                >
                  <i class="bi bi-x-lg"></i>
                </button>
              </div>
              <div className="modal-body">
                {editValues && (
                  <Formik
                    initialValues={editValues}
                    enableReinitialize={true}
                    onSubmit={onUpdate}
                  >
                    {({ values, setFieldValue, isSubmitting }) => (
                      <Form>
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="PostCode">
                            Post Code
                          </label>
                          <Field
                            type="text"
                            name="PostCode"
                            id="postCode"
                            placeholder="Add Post Code here"
                            class="form-control"
                          />
                        </div>
                        <div class="modal-footer">
                          <button type="submit" class="btn btn-primary mx-3">
                            Update
                          </button>
                          <button
                            class="btn btn-primary mx-3"
                            data-bs-dismiss="modal"
                          >
                            Close
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* detail modal */}
        <div
          class="modal fade"
          id="detailModal"
          tabindex="-1"
          aria-labelledby="detailModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="detailModalLabel">
                  Detail
                </h5>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i class="bi bi-x-circle"></i>
                </button>
              </div>
              <div class="modal-body">
                {editValues && (
                  <div>
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="postCode">
                        Post Code
                      </label>
                      <b name="postCode">{editValues.PostCode}</b>
                    </div>
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="isLocked">
                        Lock/Unlock
                      </label>
                      <b name="isLocked">{editValues.isLocked}</b>
                    </div>
                    <div class="modal-footer">
                      <div class="detailImageBoxContainer"></div>
                      <button class="btn btn-primary" data-bs-dismiss="modal">
                        Close
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {/*  */}
        <div class="col-md-12 mt-5">
          <div className="row">
            <button
              class="btn btn-primary mx-3"
              data-bs-toggle="modal"
              data-bs-target="#exampleSubmitModal"
              onClick={() => handleSubmit()}
            >
              Add
            </button>
            {/* search bar */}
            <input
              className=" form-control col-3 mt-4"
              type="text"
              placeholder="Search here"
              value={searchTerm}
              onChange={handleSearch}
              style={{ height: 40 }}
            />
          </div>
          <table class="table table-striped table-hover" id="searchable-table">
            <thead className="bg-dark text-white">
              <tr>
                <th>Post Code</th>
                <th>Lock/UnLock</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {filteredData.map((value, key) => {
                const isStateLocked = lockStatus[value.id];
                return (
                  <tr key={key}>
                    <td>{value.PostCode}</td>
                    <td>{isStateLocked ? 'Locked' : 'Unlocked'}</td>
                    <td>
                      <button
                        className="btn btn-info mx-1"
                        onClick={() => lockLocation(value.id)}
                        disabled={isStateLocked}
                      >
                        Lock
                      </button>
                      <button
                        className="btn btn-danger"
                        onClick={() => unlockLocation(value.id)}
                        disabled={!isStateLocked}
                      >
                        Unlock
                      </button>
                    </td>
                    <td>
                      <a class="nav-item dropdown">
                        <a
                          class="fa-solid fa-circle-chevron-down"
                          data-bs-toggle="dropdown"
                          href="#"
                          role="button"
                          aria-haspopup="true"
                          aria-expanded="false"
                        ></a>
                        <div class="dropdown-menu">
                          <Link
                            class="dropdown-item"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                            onClick={() => handleEdit(value)}
                          >
                            Edit
                          </Link>
                          <Link
                            class="dropdown-item"
                            data-bs-toggle="modal"
                            data-bs-target="#detailModal"
                            onClick={() => handleEdit(value)}
                          >
                            Detail
                          </Link>
                          <div class="dropdown-divider"></div>
                          <Link
                            class="dropdown-item"
                            onClick={() => showModalScreen(value.id)}
                            msgId={value.id}
                            data-bs-toggle="modal"
                            data-bs-target="#deleteModal"
                          >
                            Delete
                          </Link>
                        </div>
                      </a>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
export default PostCode;
