import React, { useEffect, useState, useReducer } from 'react';
import axios from 'axios';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Module() {
  const [listOfItems, setListOfItems] = useState([]); // To store list of all items
  const [currentClickedId, setCurrentClickedId] = useState(''); // To Store the Clicked Id for Deletion
  const [editValues, setEditValues] = useState(null); // To store the values for Edit Modal (FORM)
  const [reducerValue, forceUpdate] = useReducer((x) => x + 1, 0); // To tell the useEffect that a row has been added
  const [testCategories, setTestCategories] = useState([]);

  const initialValues = {
    // testCategory: '',
    name: '',
  };

  const validationSchema = Yup.object().shape({
    // testCategory: Yup.string().required(),
    name: Yup.string().required(),
  });

  useEffect(() => {
    fetch('http://csmserver.csmdrivingschool.ca/Module.php')
      .then((response) => response.json())
      .then((data) => {
        setListOfItems(data);
      })
      .catch((error) => {
        console.error('Error fetching module data:', error);
      });
  }, [reducerValue]);

  const showTostify = (msg) => {
    toast.success(msg, {
      position: 'bottom-right',
      autoClose: 5000, // in milliseconds, set to 5000 for 5 seconds
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      style: {
        background: 'rgba(0, 0, 0, 0.7)', // Transparent black background
        color: 'white',
      },
    });
  };

  const onSubmit = async (data, { setSubmitting, resetForm }) => {
    try {
      console.log('Submitting form data:', data); // Logging form data
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
      };
      const response = await fetch(
        'http://csmserver.csmdrivingschool.ca/Module.php',
        requestOptions
      );
      if (!response.ok) {
        throw new Error('Failed to save data');
      }
      const responseData = await response.json();
      console.log('Response:', responseData); // Logging response data
      showTostify('Module saved'); // Assuming `showTostify` is defined elsewhere
      alert('Data saved successfully'); // Alert on successful save
      resetForm(); // Reset the form fields
    } catch (error) {
      console.error('Error saving module:', error);
      alert('Failed to save data. Please try again.'); // Alert on error
      // Optionally, you can handle the error more gracefully by displaying an error message to the user
    }
  };

  const onUpdate = async (data) => {
    console.log('h');
    try {
      const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
      };
      await fetch(
        'http://csmserver.csmdrivingschool.ca/Module.php',
        requestOptions
      );
      window.location.reload();
    } catch (error) {
      console.error('Error updating module', error);
    }
  };

  const handleEditClick = (value) => {
    setEditValues(value);
  };

  const showModalScreen = (itemId) => {
    setCurrentClickedId(itemId);
  };

  const hideModalScreen = () => {};

  const onDelete = () => {
    fetch(
      `http://csmserver.csmdrivingschool.ca/Module.php?id=${currentClickedId}`,
      {
        method: 'DELETE',
      }
    )
      .then((response) => {
        if (response.ok) {
          // Filter out the deleted item from the list
          setListOfItems(
            listOfItems.filter((item) => item.id !== currentClickedId)
          );

          // Show success toast
          toast.success('Item deleted successfully', {
            position: 'bottom-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            style: {
              background: 'rgba(0, 0, 0, 0.7)',
              color: 'white',
            },
          });

          // Hide modal screen
          hideModalScreen();
        } else {
          throw new Error('Failed to delete item');
        }
      })
      .catch((error) => {
        console.error('Error deleting item:', error);
        // Optionally, you can show an error toast or handle the error in another way
      });
  };

  useEffect(() => {
    // Fetch test categories from your backend API
    axios
      .get('http://localhost:3001/theorytestcategories/categories')
      .then((response) => {
        setTestCategories(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.error('Error fetching test categories:', error);
      });
  }, []);
  return (
    <div className="AdminContainer container">
      <ToastContainer />
      <section className="middleArea">
        {/* Modal for delete */}

        <div
          class="modal fade"
          id="deleteModal"
          tabindex="-1"
          aria-labelledby="deleteModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="deleteModalLabel">
                  Delete Confirm
                </h5>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  style={{
                    border: 'none',
                    backgroundColor: 'transparent',
                    fontSize: 18,
                  }}
                >
                  <i class="fa-solid fa-xmark"></i>
                </button>
              </div>
              <div class="modal-body">
                <p>Are you sure you want to delete</p>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-primary mx-2"
                  data-bs-dismiss="modal"
                >
                  No
                </button>
                <button
                  type="button"
                  class="btn btn-primary"
                  onClick={onDelete}
                  data-bs-dismiss="modal"
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* Modal for delete */}

        {/* Modal for Edit */}
        <div
          class="modal fade"
          id="exampleModal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  Edit Item
                </h5>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  style={{
                    border: 'none',
                    backgroundColor: 'transparent',
                    fontSize: 18,
                  }}
                >
                  <i class="fa-solid fa-xmark"></i>
                </button>
              </div>
              <div class="modal-body">
                {editValues && (
                  <Formik
                    initialValues={editValues}
                    validationSchema={validationSchema}
                    enableReinitialize={true}
                    onSubmit={onUpdate}
                  >
                    {({ values, setFieldValue, isSubmitting }) => (
                      <Form>
                        <div className="form-group fieldStyle">
                          <label htmlFor="testCategory">Test Category</label>
                          <Field
                            as="select"
                            name="categoryId"
                            id="categoryId"
                            className="form-control"
                          >
                            <option value="">Select a test category</option>
                            {testCategories.map((category) => (
                              <option key={category.id} value={category.id}>
                                {category.categoryname}
                              </option>
                            ))}
                          </Field>
                          <ErrorMessage name="testCategory" component="span" />
                        </div>
                        <ErrorMessage name="name" component="span" />
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="name">
                            name
                          </label>

                          <Field
                            type="text"
                            name="name"
                            id="name"
                            placeholder="Enter a name"
                            class="form-control"
                          />
                        </div>

                        <div class="modal-footer">
                          <button type="submit" class="btn btn-primary mx-3">
                            Update
                          </button>
                          <button
                            class="btn btn-secondary"
                            data-bs-dismiss="modal"
                          >
                            Close
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* Modal for Edit */}

        {/* Modal for Detail */}
        <div
          class="modal fade"
          id="detailModal"
          tabindex="-1"
          aria-labelledby="detailModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="detailModalLabel">
                  Detail
                </h5>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  style={{
                    border: 'none',
                    backgroundColor: 'transparent',
                    fontSize: 18,
                  }}
                >
                  <i class="fa-solid fa-xmark"></i>
                </button>
              </div>
              <div class="modal-body">
                {editValues && (
                  <div>
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="testCategory">
                        Test Category
                      </label>

                      <b name="name">{editValues.categoryname}</b>
                    </div>
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="name">
                        name
                      </label>

                      <b name="name">{editValues.name}</b>
                    </div>

                    <div class="modal-footer">
                      <div class="detailImageBoxContainer"></div>
                      <button class="btn btn-primary" data-bs-dismiss="modal">
                        Close
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* Modal for Detail */}

        <div class="container mt-5">
          <div class="row">
            <div class="col-12">
              <h2 class="text-center">
                {' '}
                <i class="fa-solid fa-list"></i> Module
              </h2>
              <p class="text-center"> Manage Module here.</p>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <div class="card">
                <div class="card-header">
                  <h4 class="card-title">Add Module</h4>
                </div>
                <div class="card-body">
                  <Formik
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    //validationSchema={validationSchema}
                    enableDevTools={true}
                  >
                    {({ values, setFieldValue, isSubmitting }) => (
                      <Form>
                        <div className="form-group fieldStyle">
                          <label htmlFor="testCategory">Test Category</label>
                          <Field
                            as="select"
                            name="categoryId"
                            id="categoryId"
                            className="form-control"
                          >
                            <option value="">Select a test category</option>
                            {testCategories.map((category) => (
                              <option key={category.id} value={category.id}>
                                {category.categoryname}
                              </option>
                            ))}
                          </Field>
                          <ErrorMessage name="testCategory" component="span" />
                        </div>

                        <div className="form-group fieldStyle">
                          <label htmlFor="name">Name</label>
                          <Field
                            type="text"
                            name="name"
                            id="name"
                            placeholder="Enter a name"
                            className="form-control"
                          />
                          <ErrorMessage name="name" component="span" />
                        </div>

                        <button
                          type="submit"
                          className="btn btn-primary"
                          disabled={isSubmitting}
                        >
                          Add
                        </button>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>

            <div class="col-md-12">
              <div class="card">
                <div class="card-header">
                  <h4 class="card-title">Module List</h4>
                </div>
                <div class="card-body">
                  <table class="table">
                    <thead>
                      <tr>
                        <th>Test Category</th>
                        <th>name</th>

                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {listOfItems.map((value, key) => {
                        return (
                          <tr key={key}>
                            <td>{value.categoryname}</td>
                            <td>{value.name}</td>

                            <td>
                              <a class="nav-item dropdown">
                                <a
                                  class="fa-solid fa-circle-chevron-down"
                                  data-bs-toggle="dropdown"
                                  href="#"
                                  role="button"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                ></a>
                                <div class="dropdown-menu">
                                  <Link
                                    class="dropdown-item"
                                    data-bs-toggle="modal"
                                    data-bs-target="#exampleModal"
                                    onClick={() => handleEditClick(value)}
                                  >
                                    Edit
                                  </Link>

                                  <Link
                                    class="dropdown-item"
                                    data-bs-toggle="modal"
                                    data-bs-target="#detailModal"
                                    onClick={() => handleEditClick(value)}
                                  >
                                    Detail
                                  </Link>

                                  <div class="dropdown-divider"></div>
                                  <Link
                                    class="dropdown-item"
                                    onClick={() => showModalScreen(value.id)}
                                    msgId={value.id}
                                    data-bs-toggle="modal"
                                    data-bs-target="#deleteModal"
                                  >
                                    Delete
                                  </Link>
                                </div>
                              </a>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Module;
