import React from 'react';
import { useState, useEffect, useRef, useMemo } from 'react';
import axios from 'axios';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function AdminContact() {
  const [listOfContacts, setListOfContacts] = useState([]);
  const [currentClickedId, setCurrentClickedId] = useState('');
  const [addValues, setAddValues] = useState('');
  const [editValues, setEditValues] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const initialValues = {
    name: '',
    email: '',
    subject: '',
    message: '',
  };
  const validationSchema = Yup.object().shape({
    name: Yup.string().required(),
    email: Yup.string().required(),
    subject: Yup.string().required(),
    message: Yup.string().required(),
  });
  const handleSubmit = (value) => {
    setAddValues(value);
  };
  const handleEdit = (value) => {
    setEditValues(value);
  };
  const showModalScreen = (msgId) => {
    setCurrentClickedId(msgId);
  };
  const hideModalScreen = () => {};
  const onDelete = () => {
    console.log('id', currentClickedId);
    axios
      .delete(
        `http://csmserver.csmdrivingschool.ca/Contact.php?id=${currentClickedId}`
      )
      .then((response) => {
        setListOfContacts(
          listOfContacts.filter(function (item) {
            return item.id !== currentClickedId;
          })
        );
      });
    toast.success('Item deleted successfully', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      style: {
        background: 'rgba(0, 0, 0, 0.7)',
        color: 'white',
      },
    });
    hideModalScreen();
  };
  useEffect(() => {
    axios
      .get('http://csmserver.csmdrivingschool.ca/Contact.php')
      .then((response) => {
        setListOfContacts(response.data);
      });
  }, []);
  //filtering data
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };
  const filteredData = listOfContacts.filter((item) => {
    const values = Object.values(item).map((value) =>
      String(value).toLowerCase()
    );
    return values.some((value) => value.includes(searchTerm.toLowerCase()));
  });
  const onSubmit = async (data, { resetForm, setSubmitting }) => {
    try {
      console.log('data', data);
      await axios
        .post('http://csmserver.csmdrivingschool.ca/Contact.php', data, {})
        .then((res) => {
          alert('data saved');
          console.log('it worked', res);
          resetForm();
        });
    } catch (error) {
      console.error('Error while submitting', error);
    }
  };
  const onUpdate = async (data, { resetForm }) => {
    try {
      console.log('data', data);
      await axios
        .put('http://csmserver.csmdrivingschool.ca/Contact.php', data)
        .then((res) => {
          alert('data updated');
          resetForm();
        });
    } catch (error) {
      console.error('Error while updating the data', error);
    }
  };
  return (
    <div>
      <ToastContainer></ToastContainer>
      <div
        class="modal fade"
        id="deleteModal"
        tabindex="-1"
        aria-labelledby="deleteModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="deleteModalLabel">
                Delete Confirm
              </h5>
              <button
                type="button"
                data-bs-dismiss="modal"
                aria-label="Close"
                style={{ border: 'none', backgroundColor: 'white' }}
              >
                <i class="fa-solid fa-xmark" style={{ fontSize: 20 }}></i>
              </button>
            </div>
            <div class="modal-body">
              <p>Are you sure you want to delete</p>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-primary mx-2"
                data-bs-dismiss="modal"
              >
                No
              </button>
              <button
                type="button"
                class="btn btn-primary"
                onClick={onDelete}
                data-bs-dismiss="modal"
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <h2 class="text-center">
            {' '}
            <i class="fa-solid fa-address-book"></i> List Of Contacts
          </h2>
          <p class="text-center"> Manage your Contacts here.</p>
        </div>
      </div>
      <div class="row">
        <div
          class="modal fade"
          id="exampleSubmitModal"
          tabindex="-1"
          aria-labelledby="exampleSubmitModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleSubmitModalLabel">
                  Add Contact
                </h5>
                <button
                  type="button"
                  aria-label="Close"
                  data-bs-dismiss="modal"
                >
                  <i class="bi bi-x-lg"></i>
                </button>
              </div>
              <div className="modal-body">
                {initialValues && (
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                  >
                    {({ values, setFieldValue, isSubmitting }) => (
                      <Form>
                        <ErrorMessage
                          name="name"
                          component="span"
                          style={{ color: 'red' }}
                        />
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="name">
                            Name
                          </label>
                          <Field
                            type="text"
                            name="name"
                            id="name"
                            placeholder="Add a Name here"
                            class="form-control"
                          />
                        </div>
                        <ErrorMessage
                          name="email"
                          component="span"
                          style={{ color: 'red' }}
                        />
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="email">
                            Email
                          </label>
                          <Field
                            type="text"
                            name="email"
                            id="email"
                            placeholder="Add Email here"
                            class="form-control"
                          />
                        </div>
                        <ErrorMessage
                          name="subject"
                          component="span"
                          style={{ color: 'red' }}
                        />
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="subject">
                            Subject
                          </label>
                          <Field
                            type="text"
                            name="subject"
                            id="subject"
                            placeholder="Add Subject here"
                            class="form-control"
                          />
                        </div>
                        <ErrorMessage
                          name="message"
                          component="span"
                          style={{ color: 'red' }}
                        />
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="message">
                            Message
                          </label>
                          <Field
                            type="text"
                            name="message"
                            id="message"
                            placeholder="Add Subject here"
                            class="form-control"
                          />
                        </div>
                        <div class="modal-footer">
                          <button
                            type="submit"
                            class="btn btn-primary mx-3"
                            // disabled={isSubmitting}
                          >
                            Save
                          </button>
                          <button
                            class="btn btn-primary mx-3"
                            data-bs-dismiss="modal"
                          >
                            Close
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* edit modal */}
        <div
          class="modal fade"
          id="exampleModal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  Edit Item
                </h5>
                <button
                  type="button"
                  aria-label="Close"
                  data-bs-dismiss="modal"
                >
                  <i class="bi bi-x-lg"></i>
                </button>
              </div>
              <div className="modal-body">
                {editValues && (
                  <Formik
                    initialValues={editValues}
                    enableReinitialize={true}
                    onSubmit={onUpdate}
                  >
                    {({ values, setFieldValue, isSubmitting }) => (
                      <Form>
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="name">
                            Name
                          </label>
                          <Field
                            type="text"
                            name="name"
                            id="name"
                            placeholder="Add a Name here"
                            class="form-control"
                          />
                        </div>
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="email">
                            Email
                          </label>
                          <Field
                            type="text"
                            name="email"
                            id="email"
                            placeholder="Add Email here"
                            class="form-control"
                          />
                        </div>
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="subject">
                            Subject
                          </label>
                          <Field
                            type="text"
                            name="subject"
                            id="subject"
                            placeholder="Add Subject here"
                            class="form-control"
                          />
                        </div>
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="message">
                            Message
                          </label>
                          <Field
                            type="text"
                            name="message"
                            id="message"
                            placeholder="Add Message here"
                            class="form-control"
                          />
                        </div>
                        <div class="modal-footer">
                          <button
                            type="submit"
                            class="btn btn-primary mx-3"
                            // disabled={isSubmitting}
                          >
                            Update
                          </button>
                          <button
                            class="btn btn-primary mx-3"
                            data-bs-dismiss="modal"
                          >
                            Close
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* detail modal */}
        <div
          class="modal fade"
          id="detailModal"
          tabindex="-1"
          aria-labelledby="detailModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="detailModalLabel">
                  Detail
                </h5>
                <button
                  type="button"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  style={{ border: 'none', backgroundColor: 'white' }}
                >
                  <i
                    class="bi bi-x-lg"
                    style={{ color: 'black', fontSize: 20 }}
                  ></i>
                </button>
              </div>
              <div class="modal-body">
                {editValues && (
                  <div>
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="name">
                        Name
                      </label>
                      <b name="name">{editValues.name}</b>
                    </div>
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="email">
                        Email
                      </label>
                      <b name="email">{editValues.email}</b>
                    </div>
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="subject">
                        Subject
                      </label>
                      <b name="subject">{editValues.subject}</b>
                    </div>
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="message">
                        Message
                      </label>
                      <b name="message">{editValues.message}</b>
                    </div>
                    <div class="modal-footer">
                      <div class="detailImageBoxContainer"></div>
                      <button class="btn btn-primary" data-bs-dismiss="modal">
                        Close
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {/*  */}
        <div class="col-md-12 mt-5">
          <div className="row">
            <button
              class="btn btn-primary mx-3"
              data-bs-toggle="modal"
              data-bs-target="#exampleSubmitModal"
              onClick={() => handleSubmit()}
            >
              Add
            </button>
            {/* search bar */}
            <input
              className=" form-control col-3 mt-4"
              type="text"
              placeholder="Search here"
              value={searchTerm}
              onChange={handleSearch}
              style={{ height: 40 }}
            />
          </div>
          <table class="table table-striped table-hover" id="searchable-table">
            <thead className="bg-dark text-white">
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Subject</th>
                <th>Message</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {filteredData.map((value, key) => {
                return (
                  <tr key={key}>
                    {/* {Object.entries(value).map(([field, fieldValue]) => (
                      <td key={field}>{fieldValue}</td>
                    ))} */}
                    <td>{value.name}</td>
                    <td>{value.email}</td>
                    <td>{value.subject}</td>
                    <td>{value.message}</td>
                    <td>
                      <a class="nav-item dropdown">
                        <a
                          class="fa-solid fa-circle-chevron-down"
                          data-bs-toggle="dropdown"
                          href="#"
                          role="button"
                          aria-haspopup="true"
                          aria-expanded="false"
                        ></a>
                        <div class="dropdown-menu">
                          {/* <Link
                            class="dropdown-item"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                            onClick={() => handleEdit(value)}
                            // onClick={() => showModalScreen(value.id)}
                          >
                            Edit
                          </Link> */}
                          <Link
                            class="dropdown-item"
                            data-bs-toggle="modal"
                            data-bs-target="#detailModal"
                            onClick={() => handleEdit(value)}
                          >
                            Detail
                          </Link>
                          <div class="dropdown-divider"></div>
                          <Link
                            class="dropdown-item"
                            onClick={() => showModalScreen(value.id)}
                            msgId={value.id}
                            data-bs-toggle="modal"
                            data-bs-target="#deleteModal"
                          >
                            Delete
                          </Link>
                        </div>
                      </a>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default AdminContact;
