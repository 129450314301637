import React, { useState, useEffect } from 'react';
import ROUTES from '../../navigations/Routes';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';
function GPlusRegister() {
  const navigate = useNavigate();
  const [lessonPrice, setLessonPrice] = useState(0);
  const [lessonQuantity, setLessonQuantity] = useState(0);
  const [price, setPrice] = useState(0);
  let { id } = useParams();
  const [hstPrice, setHstPrice] = useState('');

  const initialValues = {
    firstName: '',
    middleName: '',
    lastName: '',
    gender: '',
    dob: null,
    guardian: '',
    licenseNumber: '',
    licenseExpiryDate: null,
    phoneNumber: '',
    alternatePhone: '',
    email: '',
    address: '',
    city: '',
    province: '',
    postalCode: '',
    advanceCourse: false,
    addon: '',
    extraLesson: '',
    lessonCount: 0,
    extraLessonPrice: '',
    g2TestLocation: '',
    g2Price: '',
    accommodation: '',
    accommodationType: '',
    find: '',
    conditions: false,
    courseFee: '',
    roadTestPrice: '',
    hstPrice: '',
    totalAmount: '',
  };
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('Name is required!'),
    lastName: Yup.string().required('Last Name is required!'),
    gender: Yup.string().required('Gender is required!'),
    dob: Yup.date().required('Required!!'),
    licenseNumber: Yup.number().required('Required!!'),
    licenseExpiryDate: Yup.date().required('Required!!'),
    phoneNumber: Yup.number().required('Phone Number is required!!'),
    email: Yup.string().email().required('Email is required!'),
    guardianPhone: Yup.string().required('Phone Number is required!!'),
    address: Yup.string().required('Address is required!!'),
    city: Yup.string().required('City is required!!'),
    province: Yup.string().notRequired(),
    postalCode: Yup.number().required('Required'),
    accommodation: Yup.string().required('Required'),
    find: Yup.string().required('Required!!'),
    conditions: Yup.boolean().required(),
  });
  const [selectedg2Package, setSelectedg2Package] = useState('');
  const handleg2PackageChange = (e) => {
    const selectedg2Package = e.target.value;
    setSelectedg2Package(selectedg2Package);
  };
  const getPriceForg2Package = (g2Package) => {
    switch (g2Package) {
      case '200hst':
        return '200';
      case '310hst':
        return '310';
      case '350hst':
        return '350';
      case '430hst':
        return '430';
      case '450hst':
        return '450';
      case '510hst':
        return '510';
      default:
        return '';
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://csmserver.csmdrivingschool.ca/GPlusPackage.php?id=${id}`
        );
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const res = await response.json();
        const packagePrice = res[0].packagePrice;
        const discount = res.discount;
        setPrice(discount ? `${discount}` : `${packagePrice}`);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [id]);
  async function onSubmit(data, { resetForm }) {
    try {
      console.log('data', data);
      const extraLessonPrice = Number(lessonPrice * lessonQuantity);
      data.extraLessonPrice = isNaN(extraLessonPrice)
        ? 'Invalid input'
        : extraLessonPrice;

      const roadTestPrice = Number(data.g2Price);
      data.roadTestPrice = isNaN(roadTestPrice)
        ? 'Invalid input'
        : roadTestPrice;

      const numericPrice = parseFloat(price.replace(/\D/g, '')) || 0;
      console.log('Numeric Price:', numericPrice);
      const courseFee = isNaN(numericPrice) ? 'Invalid input' : numericPrice;
      console.log('Course Fee:', courseFee);
      data.courseFee = courseFee;

      const totalAmount = extraLessonPrice + roadTestPrice + courseFee;
      const hstPrice = totalAmount * 0.13;
      data.hstPrice = hstPrice;
      setHstPrice(hstPrice);

      const totalAmountWithHST = totalAmount + hstPrice;
      data.totalAmount = isNaN(totalAmountWithHST)
        ? 'Invalid input'
        : totalAmountWithHST;

      const response = await fetch(
        'https://csmserver.csmdrivingschool.ca/GPlusRegister.php',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        }
      );

      if (response.ok) {
        alert('Successfully Registered');
        resetForm();
        //navigate('/gplusregister');
      } else {
        throw new Error('Network response was not ok');
      }
    } catch (error) {
      alert('error');
      console.error('Error while submitting form', error);
    }
  }

  return (
    <div className="main">
      <div className="homeContainer">
        <div
          class="untree_co-hero inner-page container-fluid"
          style={{ backgroundImage: `url('/images/ss.jpg')` }}
        >
          <div class="container-fluid">
            <div class="row align-items-center justify-content-center">
              <div class="col-12">
                <div class="row justify-content-center ">
                  <div class="col-lg-6 text-center ">
                    <h1
                      class="heading text-white"
                      data-aos="fade-up"
                      data-aos-delay="100"
                      style={{ marginTop: 200 }}
                    >
                      G Plus Register
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="container-fluid mt-5 text-center"
          data-aos="fade-up"
          data-aos-delay="100"
        >
          <h5 style={{ marginTop: 100 }}>
            You can combine a single lesson or several lessons in this package
            and use the instructor's car for a road test at a local test center.
            Get exclusive lessons to help you pass the G(Highway) driving test.
          </h5>
        </div>
        <div class="untree_co-section">
          <div class="container-fluid">
            <div class="row mb-5 justify-content-center">
              <div
                class="col-lg-10 mx-auto order-1"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <Formik
                  initialValues={initialValues}
                  onSubmit={onSubmit}
                  validationSchema={validationSchema}
                  enableDevTools={true}
                >
                  {({ isSubmitting, values, setFieldValue }) => (
                    <Form action="#" class=" col-lg-12 form-groupRegister">
                      <h4 className="mx-4">Personal information</h4>
                      <div className="row">
                        <div class="col-lg-4 mb-3">
                          <Field
                            class="form-control"
                            placeholder="First Name"
                            name="firstName"
                            id="Rname"
                          />
                          <ErrorMessage
                            name="firstName"
                            component="span"
                            style={{ color: 'red' }}
                          ></ErrorMessage>
                        </div>
                        <div class="col-lg-4">
                          <Field
                            class="form-control"
                            placeholder="Middle Name"
                            name="middleName"
                            id="mname"
                          />
                        </div>
                        <div class="col-lg-4">
                          <Field
                            class="form-control"
                            placeholder="Last Name"
                            name="lastName"
                            id="lname"
                          />
                          <ErrorMessage
                            name="lastName"
                            component="span"
                            style={{ color: 'red' }}
                          ></ErrorMessage>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-4 my-2">
                          <Field name="dob" autoComplete="off">
                            {({ field, form, meta }) => (
                              <div placeholder="Date of birth">
                                <DatePicker
                                  id="dob"
                                  className="form-control date-picker-input mx-2"
                                  {...field}
                                  placeholderText="Date Of Birth"
                                  selected={field.value}
                                  onChange={(dob) => {
                                    const adjustedDate = new Date(
                                      Date.UTC(
                                        dob.getUTCFullYear(),
                                        dob.getUTCMonth(),
                                        dob.getUTCDate()
                                      )
                                    );
                                    form.setFieldValue(
                                      field.name,
                                      adjustedDate
                                    );
                                  }}
                                />
                                <ErrorMessage
                                  name="dob"
                                  component="span"
                                  style={{ color: 'red' }}
                                ></ErrorMessage>
                              </div>
                            )}
                          </Field>
                        </div>
                        <div className="col-lg-4">
                          <Field
                            className="form-control"
                            placeholder="License Number"
                            name="licenseNumber"
                            id="licensenumber"
                            autoComplete="off"
                          />
                          <ErrorMessage
                            name="licenseNumber"
                            component="span"
                            style={{ color: 'red' }}
                          ></ErrorMessage>
                        </div>
                        <div>
                          <Field name="licenseExpiryDate" autoComplete="off">
                            {({ field, form, meta }) => (
                              <div>
                                <DatePicker
                                  id="licenseExpiryDate"
                                  className="form-control  date-picker-input mx-3 "
                                  {...field}
                                  placeholderText="License Expiry Date"
                                  selected={field.value}
                                  onChange={(licenseExpiryDate) => {
                                    const adjustedDate = new Date(
                                      Date.UTC(
                                        licenseExpiryDate.getUTCFullYear(),
                                        licenseExpiryDate.getUTCMonth(),
                                        licenseExpiryDate.getUTCDate()
                                      )
                                    );
                                    form.setFieldValue(
                                      field.name,
                                      adjustedDate
                                    );
                                  }}
                                />
                                <ErrorMessage
                                  name="licenseExpiryDate"
                                  component="span"
                                  style={{ color: 'red' }}
                                ></ErrorMessage>
                              </div>
                            )}
                          </Field>
                        </div>
                        <div className="col-lg-8">
                          <div>
                            <label className="mx-2 mt-3">Gender</label>
                          </div>
                          <Field
                            name="gender"
                            className="d-flex"
                            render={({ field }) => (
                              <div className="row">
                                <div className="radio-item mx-4">
                                  <input
                                    {...field}
                                    id="male"
                                    value="male"
                                    checked={field.value === 'male'}
                                    type="radio"
                                    className="mx-2"
                                  />
                                  <label htmlFor="male">Male</label>
                                </div>
                                <div className="radio-item">
                                  <input
                                    {...field}
                                    id="female"
                                    value="female"
                                    checked={field.value === 'female'}
                                    type="radio"
                                    className="mx-2"
                                  />
                                  <label htmlFor="female">Female</label>
                                </div>
                              </div>
                            )}
                          />
                          <ErrorMessage
                            name="gender"
                            component="span"
                            style={{ color: 'red' }}
                          ></ErrorMessage>
                        </div>
                      </div>
                      <h4
                        className="ml-3"
                        style={{
                          paddingRight: '70px',
                        }}
                      >
                        Contact Information
                      </h4>
                      <div className="row">
                        <div className="col-lg-4 mb-3">
                          <Field
                            className="form-control"
                            placeholder="Phone Number"
                            name="phoneNumber"
                            id="phoneNumber"
                            autoComplete="off"
                          />
                          <ErrorMessage
                            name="phoneNumber"
                            component="span"
                            style={{ color: 'red' }}
                          ></ErrorMessage>
                        </div>
                        <div className="col-lg-4">
                          <Field
                            className="form-control"
                            placeholder="Alternate Phone"
                            name="alternatePhone"
                            id="alternatePhone"
                            autoComplete="off"
                          />
                          <ErrorMessage
                            name="alternatePhone"
                            component="span"
                            style={{ color: 'red' }}
                          ></ErrorMessage>
                        </div>
                        <div className="col-lg-4">
                          <Field
                            className="form-control"
                            placeholder="Email"
                            name="email"
                            id="Remail"
                            autoComplete="off"
                          />
                          <ErrorMessage
                            name="email"
                            component="span"
                            style={{ color: 'red' }}
                          ></ErrorMessage>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-4 mb-3">
                          <Field
                            className="form-control"
                            placeholder="Guardian's Phone No. or Email"
                            name="guardianPhone"
                            id="phoneEmail"
                            autoComplete="off"
                          />
                          <ErrorMessage
                            name="guardianPhone"
                            component="span"
                            style={{ color: 'red' }}
                          ></ErrorMessage>
                        </div>
                        <div className="col-lg-4">
                          <Field
                            className="form-control"
                            placeholder="Address"
                            name="address"
                            id="address"
                            autoComplete="off"
                          />
                          <ErrorMessage
                            name="address"
                            component="span"
                            style={{ color: 'red' }}
                          ></ErrorMessage>
                        </div>
                        <div className="col-lg-4">
                          <Field
                            className="form-control"
                            placeholder="City"
                            name="city"
                            id="city"
                            autoComplete="off"
                          />
                          <ErrorMessage
                            name="city"
                            component="span"
                            style={{ color: 'red' }}
                          ></ErrorMessage>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-4 mb-3">
                          <Field
                            className="form-control"
                            placeholder="Province"
                            name="province"
                            id="province"
                            autoComplete="off"
                          />
                          <ErrorMessage
                            name="province"
                            component="span"
                            style={{ color: 'red' }}
                          ></ErrorMessage>
                        </div>
                        <div className="col-lg-4">
                          <Field
                            className="form-control"
                            placeholder="Postal Code"
                            name="postalCode"
                            id="postalCode"
                            autoComplete="off"
                          />
                          <ErrorMessage
                            name="postalCode"
                            component="span"
                            style={{ color: 'red' }}
                          ></ErrorMessage>
                        </div>
                        <div className="col-lg-8">
                          <label className="control control--checkbox">
                            Pass Plus Course Only For $...{' '}
                            <a href="#">View Details</a>
                            <Field type="checkbox" name="advanceCourse" />
                            <div className="control__indicator"></div>
                          </label>
                        </div>
                      </div>
                      <h4
                        className="ml-3"
                        style={{
                          paddingRight: '70px',
                        }}
                      >
                        Add Ons
                      </h4>
                      <h6>One time offer Savings. Would you like to add?</h6>
                      <div className="row mt-4">
                        <div className="col-lg-4">
                          <label>
                            <Field
                              name="addon"
                              render={({ field }) => (
                                <input
                                  {...field}
                                  type="radio"
                                  value="g2"
                                  className="mx-2"
                                  checked={field.value === 'g2'}
                                />
                              )}
                            ></Field>
                            Use of Car for G2 Test road in:
                          </label>
                        </div>
                        <Field
                          as="select"
                          name="g2TestLocation"
                          id="g2TestLocation"
                          onChange={(e) => {
                            const selectedg2Package = e.target.value;
                            setFieldValue('g2TestLocation', selectedg2Package);
                            setFieldValue(
                              'g2Price',
                              getPriceForg2Package(selectedg2Package)
                            );
                          }}
                          className="custom-select col-lg-4"
                        >
                          <option value="">Select city from here..</option>
                          <option value="200hst">city 1</option>
                          <option value="310hst">city 2</option>
                          <option value="350hst">city 3</option>
                          <option value="430hst">city 4</option>
                          <option value="510hst">city 5</option>
                        </Field>
                        <Field
                          name="g2Price"
                          placeholder="$"
                          className="col-lg-1 col-sm-2 form-control mx-1"
                          // value={getPriceForg2Package(values.g2TestLocation)}
                          readOnly
                        ></Field>
                      </div>
                      <div className="col-lg-8 my-5">
                        <h6 className="col-lg-8">
                          Add Extra In-Car Lessons for:
                        </h6>
                        <div class="row">
                          <Field
                            name="extraLesson"
                            className="d-flex"
                            render={({ field }) => (
                              <div className="row">
                                <div className="radio-item mx-4">
                                  <input
                                    {...field}
                                    id="g2Lesson"
                                    value="g2Lesson"
                                    checked={field.value === 'g2Lesson'}
                                    type="radio"
                                    className="mx-2"
                                    onChange={() => {
                                      field.onChange({
                                        target: {
                                          name: field.name,
                                          value: 'g2Lesson',
                                        },
                                      });
                                      setLessonPrice(20);
                                    }}
                                  />
                                  <label htmlFor="g2Lesson">G2</label>
                                </div>
                                <div className="radio-item">
                                  <input
                                    {...field}
                                    id="gLesson"
                                    value="gLesson"
                                    checked={field.value === 'gLesson'}
                                    type="radio"
                                    className="mx-2"
                                    onChange={() => {
                                      field.onChange({
                                        target: {
                                          name: field.name,
                                          value: 'gLesson',
                                        },
                                      });
                                      setLessonPrice(10);
                                    }}
                                  />
                                  <label htmlFor="gLesson">G</label>
                                </div>
                              </div>
                            )}
                          />
                          <Field
                            name="extraLessonPrice"
                            placeholder="$"
                            className="col-lg-2 col-sm-2 mx-5 border-0"
                            readOnly
                            value={lessonPrice * lessonQuantity}
                          ></Field>
                        </div>
                        <div className="mt-2">
                          <Field name="lessonCount">
                            {({ field, form }) => (
                              <div className="d-flex d-inline-block">
                                <button
                                  type="button"
                                  className="btn btn-dark mr-2"
                                  onClick={() => {
                                    form.setFieldValue(
                                      field.name,
                                      field.value + 1
                                    );
                                    setLessonQuantity(field.value + 1);
                                  }}
                                >
                                  <h2 className="mb-4">+</h2>
                                </button>
                                <div className="form-control col-2">
                                  {field.value}
                                </div>
                                <button
                                  type="button"
                                  className="btn btn-dark ml-2"
                                  onClick={() => {
                                    form.setFieldValue(
                                      field.name,
                                      field.value > 0 ? field.value - 1 : 0
                                    );
                                    setLessonQuantity(field.value - 1);
                                  }}
                                >
                                  <h2>-</h2>
                                </button>
                              </div>
                            )}
                          </Field>
                        </div>
                      </div>
                      <div className="col-lg-8">
                        <h6>Do you nedd any Accommodation?</h6>
                        <Field
                          name="accommodation"
                          className="d-flex"
                          render={({ field }) => (
                            <div className="row">
                              <div className="radio-item mx-4">
                                <input
                                  {...field}
                                  id="yes"
                                  value="yes"
                                  checked={field.value === 'yes'}
                                  type="radio"
                                  className="mx-2"
                                />
                                <label htmlFor="yes">Yes</label>
                              </div>
                              <div className="radio-item">
                                <input
                                  {...field}
                                  id="no"
                                  value="no"
                                  checked={field.value === 'no'}
                                  type="radio"
                                  className="mx-2"
                                />
                                <label htmlFor="no">No</label>
                              </div>
                            </div>
                          )}
                        />
                        <ErrorMessage
                          name="accommodation"
                          component="span"
                          style={{ color: 'red' }}
                        ></ErrorMessage>
                        <Field
                          type="text"
                          className="form-control"
                          placeholder="If yes, what that would be?"
                          name="accommodationType"
                          id="accommodationType"
                          disabled={values.accommodation !== 'yes'}
                        />
                      </div>
                      <div className="col-lg-10 mt-4">
                        <h6>Where did you find us?</h6>
                        <label>
                          <Field
                            name="find"
                            className="d-flex"
                            render={({ field }) => (
                              <div className="row">
                                <div className="radio-item ml-4">
                                  <input
                                    {...field}
                                    id="family"
                                    value="family"
                                    checked={field.value === 'family'}
                                    type="radio"
                                    className="mx-2"
                                  />
                                  <label htmlFor="family">Family</label>
                                </div>
                                <div className="radio-item">
                                  <input
                                    {...field}
                                    id="friends"
                                    value="friends"
                                    checked={field.value === 'friends'}
                                    type="radio"
                                    className="mx-2"
                                  />
                                  <label htmlFor="friends">Friends</label>
                                </div>
                                <div className="radio-item">
                                  <input
                                    {...field}
                                    id="communityCenter"
                                    value="communityCenter"
                                    checked={field.value === 'communityCenter'}
                                    type="radio"
                                    className="mx-2"
                                  />
                                  <label htmlFor="communityCenter">
                                    Community Center
                                  </label>
                                </div>
                                <div className="radio-item">
                                  <input
                                    {...field}
                                    id="google"
                                    value="google"
                                    checked={field.value === 'google'}
                                    type="radio"
                                    className="mx-2"
                                  />
                                  <label htmlFor="google">Google</label>
                                </div>
                                <div className="radio-item">
                                  <input
                                    {...field}
                                    id="newspaper"
                                    value="newspaper"
                                    checked={field.value === 'newspaper'}
                                    type="radio"
                                    className="mx-2"
                                  />
                                  <label htmlFor="newspaper">Newspaper</label>
                                </div>
                                <div className="radio-item">
                                  <input
                                    {...field}
                                    id="tv"
                                    value="tv"
                                    checked={field.value === 'tv'}
                                    type="radio"
                                    className="mx-2"
                                  />
                                  <label htmlFor="tv">Tv</label>
                                </div>
                              </div>
                            )}
                          />
                          <ErrorMessage
                            name="find"
                            component="span"
                            style={{ color: 'red' }}
                          ></ErrorMessage>
                        </label>
                      </div>
                      <div className="col-lg-8">
                        <label className="control control--checkbox">
                          <span className="caption">
                            Accept our <a href="#">terms and conditions</a>
                          </span>
                          <Field type="checkbox" name="conditions" />
                          <div className="control__indicator"></div>
                        </label>
                        <ErrorMessage
                          name="conditions"
                          component="span"
                          style={{ color: 'red' }}
                        ></ErrorMessage>
                      </div>
                      <div class="card text-center col-lg-8 mx-auto">
                        <div class="card-header">G Plus Program</div>
                        <div class="card-body">
                          <div className="row d-flex bd-highlight mb-1">
                            <h5 class="card-text bd-highlight">Course Fee</h5>
                            <h6 class="ml-auto px-2 bd-highlight">
                              {' '}
                              <Field
                                name="courseFee"
                                className="border-0 text-right"
                                value={price}
                              ></Field>
                            </h6>
                          </div>
                          <div className="row d-flex bd-highlight mb-1">
                            <h5 class="card-text bd-highlight">Road Test</h5>
                            <h6 class="ml-auto px-2 bd-highlight">
                              <Field name="roadTestPrice">
                                {({ field, form }) => (
                                  <div>
                                    {form.values && form.values.g2Price ? (
                                      <p>{form.values.g2Price}</p>
                                    ) : (
                                      <p>Price</p>
                                    )}
                                  </div>
                                )}
                              </Field>
                            </h6>
                          </div>
                          <div className="row d-flex bd-highlight mb-1">
                            <h5 class="card-text bd-highlight">
                              Extra Lessons
                            </h5>
                            <Field
                              name="extraLessonPrice"
                              className="row d-flex bd-highlight mb-1"
                            >
                              {({ field }) => (
                                <>
                                  <h5 className="card-text bd-highlight">
                                    {field.value}
                                  </h5>
                                  <h6 className="ml-auto px-2 bd-highlight">
                                    {(values && lessonPrice * lessonQuantity) ||
                                      'Price'}
                                  </h6>
                                </>
                              )}
                            </Field>
                          </div>
                          <div className="row d-flex bd-highlight mb-1">
                            <h5 class="card-text bd-highlight">HST</h5>
                            <h6 class="ml-auto px-2 bd-highlight">
                              {'$ '}
                              {isNaN(hstPrice)
                                ? 'Invalid input'
                                : Number(hstPrice)}
                            </h6>
                          </div>
                        </div>
                        <div className="card-footer text-left">
                          <Field name="totalAmount">
                            {({ form }) => {
                              const g2Price = Number(form.values.g2Price) || 0;
                              const courseFee =
                                typeof price === 'string'
                                  ? parseFloat(price.replace(/\D/g, '')) || 0
                                  : 0;
                              const totalAmount =
                                lessonPrice * lessonQuantity +
                                g2Price +
                                courseFee +
                                hstPrice;
                              return (
                                <div>
                                  Total: {'$ '}
                                  {isNaN(totalAmount)
                                    ? 'Invalid input'
                                    : totalAmount}
                                </div>
                              );
                            }}
                          </Field>
                        </div>
                      </div>
                      <div class="col-8 mx-auto text-center mt-3">
                        <button type="submit" class="btn btn-primary">
                          Register
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default GPlusRegister;
