import React from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import ROUTES from '../../navigations/Routes';
import axios from 'axios';
import Calendar from 'react-calendar';

function Student() {
  const [listOfInstructors, setListOfInstructors] = useState([]);
  const [progress, setProgress] = useState(20);
  const [editValues, setEditValues] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedDate, setSelectedDate] = useState(null);
  const { userName } = useParams();
  const [loggedIn, setLoggedIn] = useState('');
  const [loggedInUser, setLoggedInUser] = useState('');
  const navigate = useNavigate();
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  useEffect(() => {
    const usr = localStorage.getItem('id');
    const userName = localStorage.getItem('currentUserName');
    setLoggedIn(usr);
    setLoggedInUser(userName);
    console.log(usr);
  }, []); // Include 'id' in the dependency array

  const initialValues = {
    instructorPicture: '',
    name: '',
    carModel: '',
    carColor: '',
    picture: '',
    calendar: '',
  };
  const handleEdit = (value) => {
    setEditValues(value);
  };
  const onNavigate = (value) => {
    const usr = localStorage.getItem('id');
    setLoggedIn(usr);
    localStorage.setItem('id', usr);
    console.log(value.id);
  };

  return (
    <div className="main">
      <div className="homeContainer">
        <div
          class="modal fade"
          id="detailModal"
          tabindex="-1"
          aria-labelledby="detailModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="detailModalLabel">
                  Schedule
                </h5>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
                <Calendar
                  onChange={handleDateChange}
                  value={selectedDate || new Date()}
                ></Calendar>

                <div class="modal-footer">
                  <button class="btn btn-secondary" data-bs-dismiss="modal">
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*  */}
        <div
          class="untree_co-hero inner-page container-fluid"
          style={{ height: 70 }}
        >
          <div className="container-fluid">
            <div class="row align-items-center justify-content-center">
              <div class="col-12">
                <div class="row justify-content-center ">
                  <div class="col-lg-9 text-center ">
                    <h1
                      class="heading"
                      data-aos="fade-up"
                      data-aos-delay="100"
                      style={{ marginTop: 50, color: '#ec661f', fontSize: 35 }}
                    >
                      <u>{loggedInUser}</u>, Welcome to the Student Panel
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*  */}
        <div className="untree_co-section">
          <div className="container-fluid">
            <div className="row d-flex justify-content-center align-items-stretch instructorPanel">
              <div
                className="col-sm-6 col-md-6 col-lg-5 my-4"
                data-aos="fade-up"
                data-aos-delay="0"
              >
                <Link
                  to={ROUTES.instructorsList.name}
                  className="category d-flex align-items-start h-100"
                >
                  <div className="instructorIcon">
                    <i class="bi bi-people-fill"></i>
                  </div>
                  <div>
                    <h3 style={{ fontSize: 26 }}>List of Instructors</h3>
                  </div>
                </Link>
              </div>
              <div
                className="col-sm-6 col-md-6 col-lg-5 my-4"
                data-aos="fade-up"
                data-aos-delay="300"
              >
                <Link
                  to={ROUTES.studentCourses.name}
                  className="category d-flex align-items-start h-100"
                >
                  <div className="instructorIcon">
                    <i class="bi bi-journal-album"></i>
                  </div>
                  <div className="instructorIcon">
                    <h3 style={{ fontSize: 26 }}>Courses</h3>
                  </div>
                </Link>
              </div>
              <div
                className="col-sm-6 col-md-6 col-lg-5 mb-4"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <Link
                  to={ROUTES.practiceCategories.name}
                  className="category d-flex align-items-start h-100"
                >
                  <div className="instructorIcon">
                    <i class="bi bi-book-half"></i>
                  </div>
                  <div>
                    <h3 style={{ fontSize: 26 }}>Lesson Sheets</h3>
                  </div>
                </Link>
              </div>
              <div
                className="col-sm-6 col-md-6 col-lg-5 mb-4"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <Link
                  to={ROUTES.theorytest.name}
                  onClick={onNavigate}
                  className="category d-flex align-items-start h-100"
                >
                  <div className="instructorIcon">
                    <i class="bi bi-pencil-square"></i>
                  </div>
                  <div>
                    <h3 style={{ fontSize: 26 }}>Take Test</h3>
                  </div>
                </Link>
              </div>
              <div
                className="col-sm-6 col-md-6 col-lg-5 mb-4"
                data-aos="fade-up"
                data-aos-delay="300"
              >
                <Link
                  to={ROUTES.package.name}
                  className="category d-flex align-items-start h-100"
                >
                  <div className="instructorIcon">
                    <i class="bi bi-credit-card-2-front"></i>
                  </div>
                  <div className="instructorIcon">
                    <h3 style={{ fontSize: 26 }}>Payment</h3>
                  </div>
                </Link>
              </div>
              <div
                className="col-sm-6 col-md-6 col-lg-5 mb-4"
                data-aos="fade-up"
                data-aos-delay="300"
              >
                <Link
                  to={ROUTES.package.name}
                  className="category d-flex align-items-start h-100"
                >
                  <div className="instructorIcon">
                    <i class="bi bi-bookmark-check-fill"></i>
                  </div>
                  <div className="instructorIcon">
                    <h3 style={{ fontSize: 26 }}>Sessions</h3>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
        {/*  */}
      </div>
    </div>
  );
}

export default Student;
