import React from 'react';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import ROUTES from '../../navigations/Routes';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SchedulesStatus from '../Modules/SchedulesStatus';
function AcceptedRequest() {
  const [listOfStudentRequests, setListOfStudentRequests] = useState([]);
  const [editValues, setEditValues] = useState(null);
  const [currentClickedId, setCurrentClickedId] = useState('');

  const [email, setEmail] = useState('');
  const [postalCode, setPostalCode] = useState('');
  let { userName } = useParams();
  const [Id, setId] = useState([]);
  const [loggedIn, setLoggedIn] = useState('');
  const [loggedInUser, setLoggedInUser] = useState('');

  const initialValues = {
    userName: '',
    address: '',
    phoneNumber: '',
    email: '',
    lessonSheets: '',
    calendar: '',
  };
  useEffect(() => {
    const usr = localStorage.getItem('id');
    setLoggedIn(usr);
    console.log(usr);
    const userName = localStorage.getItem('currentInsName');
    setLoggedInUser(userName);
    console.log(userName);
    onNavigate();
  }, []);

  const onNavigate = (value) => {
    // const usr = localStorage.getItem('id');
    // setLoggedIn(usr);
    // localStorage.setItem('id', usr);
    const userName = localStorage.getItem('currentInsName');
    setLoggedInUser(userName);
    localStorage.setItem('currentInsName', userName);
    console.log(userName);
    // console.log(value.id);
  };
  const handleEdit = (value) => {
    setEditValues(value);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://csmserver.csmdrivingschool.ca/StudentRequest.php?id=${loggedInUser}&accepted`
        );

        if (!response.ok) {
          throw new Error('Failed to fetch student details');
        }

        const responseData = await response.json();
        const studentData = responseData.data;

        console.log('Fetched student data:', studentData);

        if (studentData.length === 0) {
          console.log('No students available');
        }

        setListOfStudentRequests(studentData); // Update state immediately
      } catch (error) {
        console.error('Error fetching student details:', error);
      }
    };

    fetchData();
  }, [loggedInUser]);
  const hideModalScreen = () => {};
  const showModalScreen = (msgId) => {
    setCurrentClickedId(msgId);
  };

  function isDateTimeBeforeNow(dateString, timeString) {
    // Convert timeString to Date object
    const [hours, minutes] = timeString.split(':');
    const time = new Date();
    time.setHours(parseInt(hours, 10));
    time.setMinutes(parseInt(minutes, 10));

    // Parse dateString to Date object and check if it's today
    const date = new Date(dateString);
    const today = new Date();
    const isToday =
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear();

    // Check if the date is today and the time is before now
    return isToday && time < new Date();
  }

  const onDelete = () => {
    console.log('id', currentClickedId);
    axios
      .delete(
        `https://csmserver.csmdrivingschool.ca/StudentRegister.php?id=${currentClickedId}`
      )
      .then((response) => {
        setListOfStudentRequests(
          listOfStudentRequests.filter(function (item) {
            return item.id !== currentClickedId;
          })
        );
      });
    toast.success('Item deleted successfully', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      style: {
        background: 'rgba(0, 0, 0, 0.7)',
        color: 'white',
      },
    });
    hideModalScreen();
  };
  //
  function formatDate(dateString) {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      month: 'short',
      day: '2-digit',
      year: 'numeric',
    });
  }
  return (
    <div className="main">
      <div className="homeContainer">
        <ToastContainer></ToastContainer>
        <div
          class="modal fade"
          id="deleteModal"
          tabindex="-1"
          aria-labelledby="deleteModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="deleteModalLabel">
                  Delete Confirm
                </h5>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i class="fa-solid fa-xmark"></i>
                </button>
              </div>
              <div class="modal-body">
                <p>Are you sure you want to delete</p>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary mx-2"
                  data-bs-dismiss="modal"
                >
                  No
                </button>
                <button
                  type="button"
                  class="btn btn-primary"
                  onClick={onDelete}
                  data-bs-dismiss="modal"
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
        {/*  */}
        <div
          class="modal fade"
          id="detailModal"
          tabindex="-1"
          aria-labelledby="detailModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="detailModalLabel">
                  Detail
                </h5>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
                {editValues && (
                  <div>
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="userName">
                        Name
                      </label>

                      <b name="userName">{editValues.userName}</b>
                    </div>
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="address">
                        Address
                      </label>

                      <b name="address">{editValues.address}</b>
                    </div>
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="phoneNumber">
                        Phone Number
                      </label>

                      <b name="phoneNumber">{editValues.phoneNumber}</b>
                    </div>
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="email">
                        Email
                      </label>

                      <b name="email">{editValues.email}</b>
                    </div>
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="lessonSheets">
                        Lesson Sheets
                      </label>

                      <b name="lessonSheets">{editValues.lessonSheets}</b>
                    </div>
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="calendar">
                        calendar
                      </label>

                      <b name="calendar">{editValues.calendar}</b>
                    </div>

                    <div class="modal-footer">
                      <button class="btn btn-secondary" data-bs-dismiss="modal">
                        Close
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>{' '}
        <div className="container-fluid studentList">
          <div className="d-flex justify-content-center">
            <h2 className="mb-5">List Of Accepted Requests </h2>
          </div>
          {loggedInUser && <SchedulesStatus id={loggedInUser} />}
        </div>
      </div>
    </div>
  );
}

export default AcceptedRequest;
