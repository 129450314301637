import axios from 'axios';
import React from 'react';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

function NotificationList() {
  const { id } = useParams();
  const [listOfStudentRequests, setListOfStudentRequests] = useState([]);
  const [loggedIn, setLoggedIn] = useState();
  const [loggedInUser, setLoggedInUser] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const onUpdate = async () => {
    try {
      const formData = new FormData();
      formData.append('instructorUserName', id);

      const response = await fetch(
        `https://csmserver.csmdrivingschool.ca/StudentRequest.php?userName=${id}`,
        {
          method: 'PUT',
          body: formData,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      if (!response.ok) {
        throw new Error('Failed to update student request');
      }
    } catch (error) {
      console.error('Error updating', error);
    }
  };
  const fetchStudentName = async (studentId) => {
    try {
      const response = await fetch(
        `https://csmserver.csmdrivingschool.ca/StudentRegister.php?id=${studentId}`
      );
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      return data.userName; // Assuming the response contains a 'name' field
    } catch (error) {
      console.error('Error fetching student name:', error);
      return 'Unknown'; // Return 'Unknown' if there's an error
    }
  };

  useEffect(() => {
    const fetchRequests = async () => {
      try {
        console.log(`Fetching data for ID: ${id}`);
        const response = await fetch(
          `https://csmserver.csmdrivingschool.ca/StudentRequest.php?id=${id}&notificationall`
        );
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        console.log('Fetched data:', data);

        // Fetch student names
        const requestsWithNames = await Promise.all(
          data.data.map(async (request) => {
            const studentName = await fetchStudentName(request.studentUserName);
            return { ...request, studentName };
          })
        );

        setListOfStudentRequests(requestsWithNames);
        onUpdate();
      } catch (error) {
        console.error('Error fetching student requests:', error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchRequests();
  }, [loggedIn, id, onUpdate]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }
  function formatDate(dateString) {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      month: 'short',
      day: '2-digit',
      year: 'numeric',
    });
  }
  return (
    <div className="main">
      <div className="homeContainer">
        <div className="container-fluid text-center py-5">
          <h1 style={{ color: '#ec661f' }}>Notification List {id}</h1>
        </div>
        <div class="card container-fluid">
          <div class="card-body">
            {Array.isArray(listOfStudentRequests) &&
            listOfStudentRequests.length > 0 ? (
              listOfStudentRequests.map((value, index) => (
                <div key={index} className="notification-item">
                  <h5 className="card-title">{value.studentUserName}</h5>
                  <div className="card-text py-1">
                    <b>{value.studentUserName}</b> has requested for session:
                    Date:
                    <b> {formatDate(value.sessionDate)}</b>
                    {'  '}Time: <b>{value.sessionTime}</b>
                  </div>
                  <div className="card-text py-2">
                    Requested On: {formatDate(value.createdAt)}
                  </div>
                </div>
              ))
            ) : (
              <div>No student requests available.</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default NotificationList;
