import React from 'react';
import axios from 'axios';
import ROUTES from '../../navigations/Routes';
import { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
function TestModule() {
  const [listOfItems, setListOfItems] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [userAnswers, setUserAnswers] = useState(
    Array(listOfItems.length).fill(null)
  );
  const [selectedOption, setSelectedOption] = useState(null);
  const [correctCount, setCorrectCount] = useState(0);
  const [incorrectCount, setIncorrectCount] = useState(0);
  const { id, userName } = useParams();
  const [loggedIn, setLoggedIn] = useState([]);
  const navigate = useNavigate();
  const [isAnswered, setIsAnswered] = useState(false);
  // const location = useLocation();
  // const name = location.state.name;
  //
  useEffect(() => {
    const usr = localStorage.getItem('id');
    setLoggedIn(usr);
    console.log(usr);
  }, []);
  //
  const location = useLocation();
  const { testCategoryId } = location.state || {};

  useEffect(() => {
    const fetchAndShuffleQuestions = async () => {
      try {
        console.log('Module ID:', id);

        const response = await fetch(
          `https://csmserver.csmdrivingschool.ca/Questions.php?moduleId=${id}`
        );
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const questions = await response.json();

        // Shuffle the questions
        const shuffledQuestions = questions.sort(() => Math.random() - 0.5);

        // Slice the first five questions
        // const firstFiveQuestions = shuffledQuestions.slice(0, 5);

        setListOfItems(shuffledQuestions);
        setUserAnswers(Array(shuffledQuestions.length).fill(null));
        console.log(shuffledQuestions);
        console.log('Selected Module:', id);
        console.log(
          'Constructed image path:',
          `../${shuffledQuestions[currentQuestionIndex]?.image.replace(
            /\\/g,
            '/'
          )}`
        );
        console.log('hello');
      } catch (error) {
        console.error('Error fetching questions:', error);
      }
    };

    fetchAndShuffleQuestions();
  }, [id]);

  useEffect(() => {
    // Reset isAnswered state when the current question index changes
    setIsAnswered(userAnswers[currentQuestionIndex] !== null);
  }, [currentQuestionIndex, userAnswers]);
  const handleNext = () => {
    if (currentQuestionIndex < listOfItems.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      setSelectedOption(null);
      setIsAnswered(true); // Reset isAnswered for the next question
    }
  };
  const handlePrev = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
      setSelectedOption(null);
    }
  };
  const handleOptionClick = (selectedOption) => {
    if (userAnswers[currentQuestionIndex] !== null) {
      return;
    }

    const correctOption = listOfItems[currentQuestionIndex]?.correct;
    const isCorrect = selectedOption === correctOption;

    const newAnswers = [...userAnswers];
    newAnswers[currentQuestionIndex] = selectedOption;
    setUserAnswers(newAnswers);
    setSelectedOption(selectedOption);
    setIsAnswered(true); // Mark the question as answered

    setCorrectCount(
      (prevCorrectCount) => prevCorrectCount + (isCorrect ? 1 : 0)
    );
    setIncorrectCount(
      (prevIncorrectCount) => prevIncorrectCount + (isCorrect ? 0 : 1)
    );

    if (
      newAnswers.filter((answer) => answer !== null).length ===
      listOfItems.length
    ) {
      handleTestCompletion();
    }
  };

  const getOptionStyle = (option) => {
    if (selectedOption) {
      if (selectedOption === option) {
        return option === listOfItems[currentQuestionIndex]?.correct
          ? { backgroundColor: '#90D26D' }
          : { backgroundColor: '#FFB1B1' };
      }
      if (listOfItems[currentQuestionIndex]?.correct === option) {
        return { backgroundColor: '#90D26D' };
      }
    }
    return {};
  };

  const handleTestCompletion = async () => {
    const totalQuestions = listOfItems.length;
    const totalIncorrect = totalQuestions - correctCount;
    const testResult = totalIncorrect > 25 ? 'Fail' : 'Pass';
    const resultData = {
      userId: loggedIn,
      testCategoryId: testCategoryId,
      moduleid: id,
      correctans: correctCount,
      incorrectans: totalIncorrect,
      date: new Date().toISOString(),
      resultstatus: testResult,
    };
    console.log('Result Data:', resultData);

    try {
      const response = await fetch(
        'https://csmserver.csmdrivingschool.ca/TestResults.php',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(resultData),
        }
      );

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const responseData = await response.json();
      console.log('Response:', responseData);
      navigate('/testResult', { state: { data: resultData } });
    } catch (error) {
      console.error('Error saving test result:', error);
    }
  };

  // .post('http://localhost:3001/testresult',

  return (
    <div className="main">
      <div className="homeContainer" style={{ textAlign: 'center' }}>
        {/* <h2>{loggedIn}</h2>
          <div>
            <h1>Test Component</h1>
            <p>Route ID: {id}</p>
            {testCategoryId ? (
              <p>Test Category ID: {testCategoryId}</p>
            ) : (
              <p>No Test Category ID found</p>
            )}
          </div> */}
        <div className="mainBox d-flex justify-content-center">
          <div className="testBox">
            <h4 className="text-black mx-3">Your Progress</h4>
            <div className="tinyBox">
              {listOfItems.length > 0 &&
                listOfItems.map((item, index) => (
                  <h5
                    className="mx-2"
                    key={index + 1}
                    style={{
                      backgroundColor:
                        userAnswers[index] === null
                          ? 'gray'
                          : userAnswers[index] === item.correct
                          ? '#8DF4A9' // Green for correct answer
                          : '#FF7C7C', // Red for incorrect answer
                      color: 'white',
                    }}
                  >
                    {index + 1}
                  </h5>
                ))}
              <div className="coin">
                <ul
                  className="d-flex justify-content-between pr-4"
                  style={{
                    marginTop: 200,
                    width: 290,
                  }}
                >
                  <li className="correct">{correctCount} Correct</li>
                  <li className="incorrect">{incorrectCount} Incorrect</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="QContainer">
            <div class="row1">
              {listOfItems.length > 0 && (
                <div className="Item">
                  <div className="ImageContainer1">
                    <img
                      src={
                        listOfItems[currentQuestionIndex]?.image
                          ? `https://csmdrivingschool.ca/${listOfItems[currentQuestionIndex].image}`
                          : '/images/common.png'
                      }
                      alt="Image"
                    />
                  </div>
                  <div className="contentContainer5">
                    <h5>Q: {listOfItems[currentQuestionIndex]?.question}</h5>
                    {['option1', 'option2', 'option3', 'option4'].map(
                      (option, index) => (
                        <div
                          key={index}
                          className="options"
                          onClick={() =>
                            handleOptionClick(
                              listOfItems[currentQuestionIndex]?.[option]
                            )
                          }
                          style={{
                            ...getOptionStyle(
                              listOfItems[currentQuestionIndex]?.[option]
                            ),
                            cursor:
                              userAnswers[currentQuestionIndex] !== null
                                ? 'not-allowed'
                                : 'pointer',
                          }}
                        >
                          <input
                            type="radio"
                            id={`radio${index + 1}`}
                            name="select1"
                            disabled={
                              userAnswers[currentQuestionIndex] !== null
                            }
                          />
                          <label htmlFor={`radio${index + 1}`}>
                            <p>{listOfItems[currentQuestionIndex]?.[option]}</p>
                            {selectedOption ===
                              listOfItems[currentQuestionIndex]?.[option] && (
                              <p>
                                {listOfItems[currentQuestionIndex]?.explanation}
                              </p>
                            )}
                          </label>
                        </div>
                      )
                    )}
                  </div>
                </div>
              )}

              <div class="buttonContainer">
                <button
                  class="btn btn-outline-primary "
                  onClick={handlePrev}
                  disabled={currentQuestionIndex === 0}
                >
                  prev
                </button>
                <button
                  class="btn btn-outline-primary"
                  onClick={handleNext}
                  // disabled={currentQuestionIndex === listOfItems.length - 1}
                  disabled={!isAnswered}
                >
                  {' '}
                  next
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TestModule;
