import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ROUTES from '../../navigations/Routes';
function Package() {
  const navigate = useNavigate();
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [selectedLearningMethod, setSelectedLearningMethod] = useState(null);
  // const handleSelection = (selectionType, value) => {
  //   if (selectionType === 'package') {
  //     setSelectedPackage(value);
  //   } else if (selectionType === 'learningMethod') {
  //     setSelectedLearningMethod(value);
  //   }
  // };
  // const handleContinue = () => {
  //   console.log('Selected Package:', selectedPackage);
  //   console.log('Selected Learning Method:', selectedLearningMethod);
  //   if (selectedLearningMethod) {
  //     console.log('Both selected, navigating...');
  //     navigate(ROUTES.bde.name);
  //   } else {
  //     console.log('error');
  //     alert('error');
  //   }
  // };
  return (
    <div className="main">
      <div className="homeContainer">
        <div class="container-fluid bgImg">
          <img src="images/teach2.jpg"></img>
        </div>
        {/*  */}
        <div
          class="untree_co-hero overlay container-fluid"
          style={{ height: 100, marginTop: 50 }}
        >
          <div className="">
            <div class="row align-items-center justify-content-center">
              <div class="col-12">
                <div class="row justify-content-center">
                  <div class="col-lg-12 text-center ">
                    <h5
                      class="heading text-white packageHeading"
                      style={{
                        // fontSize: 35,
                        textAlign: 'center',
                      }}
                      data-aos="fade-up"
                      data-aos-delay="100"
                    >
                      Connected learning, easily organised, intuitively
                      delivered
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*  */}
        <div class="untree_co-section boxPackage">
          <div class="container-fluid">
            <div class="row align-items-stretch">
              <div
                class="col-lg-4 mb-4"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <div class="media-h">
                  <div class="media-h-body col-12 ">
                    <h2 class="my-5 text-black">CSM - BDE Program Plus</h2>
                    <div className="d-flex justify-content-center flex-wrap">
                      <div className="packageBtn mx-2">
                        <Link to={ROUTES.bde.name} className="text-black">
                          E-learning
                        </Link>
                      </div>
                      <br />
                      <div className="packageBtn">
                        <Link className="text-black">V-learning</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="col-lg-4 mb-4"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <div class="media-h d-flex justify-content-center">
                  <div class="media-h-body col-12">
                    <h2 class="my-5">
                      <Link className="packageName">
                        Driver Improvement Program
                      </Link>
                    </h2>
                    <div className="d-flex justify-content-center flex-wrap">
                      <div className="packageBtn mx-2">
                        <Link className="text-black">E-learning</Link>
                      </div>
                      <br />
                      <div className="packageBtn">
                        <Link className="text-black">V-learning</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="col-lg-4 mb-4"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <div class="media-h d-flex">
                  <div class="media-h-body col-12">
                    <h2 class="my-5 text-black">Defensive Training Course</h2>
                    <div className="d-flex justify-content-center flex-wrap">
                      <div className="packageBtn mx-2">
                        <Link className="text-black">E-learning</Link>
                      </div>
                      <br />
                      <div className="packageBtn">
                        <Link className="text-black">V-learning</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="col-lg-4 mb-4"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <div class="media-h d-flex">
                  <div class="media-h-body col-12">
                    <h2 class="my-5 text-black">Pass Plus</h2>
                    <div className="d-flex justify-content-center flex-wrap">
                      <div className="packageBtn mx-2">
                        <Link className="text-black">E-learning</Link>
                      </div>
                      <br />
                      <div className="packageBtn">
                        <Link className="text-black">V-learning</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="col-lg-4 mb-4"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <div class="media-h d-flex">
                  <div class="media-h-body col-12">
                    <h2 class="my-5 text-black">CSM-Winter Driving Program </h2>
                    <div className="d-flex justify-content-center flex-wrap">
                      <div className="packageBtn mx-2">
                        <Link className="text-black">E-learning</Link>
                      </div>
                      <br />
                      <div className="packageBtn">
                        <Link className="text-black">V-learning</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="col-lg-4 mb-4"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <div class="media-h d-flex">
                  <div class="media-h-body col-12">
                    <h2 class="my-5 text-black">Evaluation Road Practice </h2>
                    <div className="d-flex justify-content-center flex-wrap">
                      <div className="packageBtn mx-2">
                        <Link
                          to={ROUTES.evaluation.name}
                          className="text-black"
                        >
                          Select
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="col-lg-4 mb-4"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <div class="media-h d-flex">
                  <div class="media-h-body col-12">
                    <h2 class="my-5 text-black">CSM G Plus (Package) </h2>
                    <div className="d-flex justify-content-center flex-wrap">
                      <div className="packageBtn mx-2">
                        <Link to={ROUTES.gplus.name} className="text-black">
                          Select
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="col-lg-4 mb-4"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <div class="media-h d-flex">
                  <div class="media-h-body col-12">
                    <h2 class="my-5 text-black">CSM G2 Plus (Package)</h2>
                    <div className="d-flex justify-content-center flex-wrap">
                      <div className="packageBtn mx-2">
                        <Link to={ROUTES.g2plus.name} className="text-black">
                          Select
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="col-lg-4 mb-4"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <div class="media-h d-flex">
                  <div class="media-h-body col-12">
                    <h2 class="my-5">
                      <Link className="packageName">Knowledge Practice</Link>
                    </h2>
                    <div className="d-flex justify-content-center flex-wrap">
                      <div className="packageBtn mx-2">
                        <Link to={ROUTES.login.name} className="text-black">
                          Select
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="contBtn">
              <button
                className="btn btn-outline-primary"
                // onClick={handleContinue}
              >
                Continue
              </button>
            </div> */}
          </div>
        </div>
        {/*  */}
        <div class="container-fluid pb-5 " style={{ marginTop: 30 }}>
          <div class="services-section px-5">
            <div class="row justify-content-between">
              <div class="col-lg-6 mb-5">
                <div
                  class="section-title mb-3"
                  data-aos="fade-up"
                  data-aos-delay="0"
                >
                  <h2 class="line-bottom my-4">E-learning System</h2>
                </div>
                <p
                  data-aos="fade-up"
                  data-aos-delay="100"
                  className="ml-3 text-justify"
                >
                  E-Learning, also known as Electronic Learning, is the delivery
                  of education and training via digital resources. Although
                  eLearning is based on formalized learning, it is delivered via
                  internet-connected electronic devices such as computers,
                  tablets, and even cellular phones. Students can access course
                  content and manage training delivery through their LMS, while
                  learners don't have to rely on scheduled training.
                </p>
              </div>
              <div class="col-lg-6" data-aos="fade-up" data-aos-delay="0">
                <figure class="img-wrap-2 hover-zoom">
                  <img
                    src="images/e.webp"
                    alt="Image"
                    class="img-fluid"
                    width="400"
                    height="300"
                  />
                  <div class="dotted"></div>
                </figure>
              </div>
              <div class="row justify-content-between">
                <div class="col-lg-6" data-aos="fade-up" data-aos-delay="0">
                  <figure class="img-wrap-2 hover-zoom">
                    <img
                      src="images/vlearning.jpg"
                      alt="Image"
                      class="img-fluid"
                      width="400"
                      height="400"
                    />
                    <div class="dotted"></div>
                  </figure>
                </div>
                <div class="col-lg-6 mb-5 mb-lg-0 ">
                  <div
                    class="section-title mb-3"
                    data-aos="fade-up"
                    data-aos-delay="0"
                  >
                    <h2 class="line-bottom my-4 ">Virtual Learning</h2>
                  </div>
                  <p
                    data-aos="fade-up"
                    data-aos-delay="100"
                    className="text-justify "
                  >
                    Virtual learning refers to an environment where students
                    study a digital-based curriculum taught by instructors that
                    lecture online via video or audio. This instruction can take
                    place either in a self-paced (asynchronous) environment or
                    in a real-time (synchronous) environment or Hybrid
                    environment. Virtual learning gives students access to
                    coursework from anywhere, at any time, making learning super
                    convenient.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Package;
