import React, { useEffect, useState, useReducer } from 'react';
import axios from 'axios';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from 'react-modal';
function AdminStudentPanel() {
  const [listOfItems, setListOfItems] = useState([]); // To store list of all items
  const [currentClickedId, setCurrentClickedId] = useState(''); // To Store the Clicked Id for Deletion
  const [editValues, setEditValues] = useState(null); // To store the values for Edit Modal (FORM)
  const [reducerValue, forceUpdate] = useReducer((x) => x + 1, 0); // To tell the useEffect that a row has been added
  const [selectedImage, setSelectedImage] = useState(null); // To show the Image full view in Modal
  const initialValues = {
    courses: '',
    instructor: '',
    lessonSheets: '',
    payment: '',
    session: '',
    date: '',
  };
  const validationSchema = Yup.object().shape({
    courses: Yup.string().required(),
    instructor: Yup.string().required(),
    lessonSheets: Yup.number().required(),
    payment: Yup.string().required(),
    session: Yup.string().required(),
    date: Yup.string().required(),
  });
  useEffect(() => {
    axios
      .get('http://csmserver.csmdrivingschool.ca/StudentPanel.php')
      .then((response) => {
        setListOfItems(response.data);
      });
  }, [reducerValue]);
  const showTostify = (msg) => {
    toast.success(msg, {
      position: 'bottom-right',
      autoClose: 5000, // in milliseconds, set to 5000 for 5 seconds
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      style: {
        background: 'rgba(0, 0, 0, 0.7)', // Transparent black background
        color: 'white',
      },
    });
  };
  const openImageModal = (imageData) => {
    setSelectedImage(imageData);
  };
  const closeImageModal = () => {
    setSelectedImage(null);
  };
  const onSubmit = async (data, { resetForm, setSubmitting }) => {
    try {
      console.log('data', data);
      await axios
        .post('http://csmserver.csmdrivingschool.ca/StudentPanel.php', data, {})
        .then((res) => {
          alert('data saved');
          console.log('it worked', res);
          resetForm();
        });
    } catch (error) {
      alert('Failed to save data. Please try again.');
      console.error('Error while submitting', error);
    }
  };
  const onUpdate = async (data, { resetForm, setSubmitting }) => {
    try {
      console.log('data', data);
      await axios
        .put('http://csmserver.csmdrivingschool.ca/StudentPanel.php', data)
        .then((res) => {
          alert('data updated');
          resetForm();
        });
    } catch (error) {
      console.error('Error while updating the data', error);
    }
  };
  const handleEditClick = (value) => {
    setEditValues(value);
  };
  const showModalScreen = (itemId) => {
    setCurrentClickedId(itemId);
  };
  const hideModalScreen = () => {};

  const onDelete = () => {
    axios
      .delete(
        `http://csmserver.csmdrivingschool.ca/StudentPanel.php?id=${currentClickedId}`
      )
      .then((response) => {
        setListOfItems(
          listOfItems.filter(function (item) {
            return item.id != currentClickedId;
          })
        );
      });

    toast.success('Item deleted successfully', {
      position: 'bottom-right',
      autoClose: 5000, // in milliseconds, set to 5000 for 5 seconds
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      style: {
        background: 'rgba(0, 0, 0, 0.7)', // Transparent black background
        color: 'white',
      },
    });
    hideModalScreen();
  };
  return (
    <div className="AdminContainer container">
      <ToastContainer />
      <section className="middleArea">
        {/* Modal for delete */}

        <div
          class="modal fade"
          id="deleteModal"
          tabindex="-1"
          aria-labelledby="deleteModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="deleteModalLabel">
                  Delete Confirm
                </h5>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
                <p>Are you sure you want to delete</p>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary mx-2"
                  data-bs-dismiss="modal"
                >
                  No
                </button>
                <button
                  type="button"
                  class="btn btn-primary"
                  onClick={onDelete}
                  data-bs-dismiss="modal"
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* Modal for delete */}

        <Modal
          isOpen={selectedImage !== null}
          onRequestClose={closeImageModal}
          contentLabel="Full Size Image"
          style={{
            content: {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              border: '1px solid #ccc',
              background: '#fff',
              borderRadius: '4px',
              padding: '20px',
              maxWidth: '80%',
              maxHeight: '80%',
              overflow: 'auto',
              margin: 'auto',
            },
          }}
        >
          {selectedImage && (
            <>
              <img
                src={selectedImage}
                alt="Full Size"
                style={{
                  maxWidth: '100%',
                  maxHeight: '100%',
                  display: 'block',
                  margin: 'auto',
                }}
              />
              <button
                onClick={closeImageModal}
                style={{
                  position: 'absolute',
                  top: '10px',
                  right: '15px',
                  background: 'none',
                  border: 'none',
                  fontSize: '16px',
                  cursor: 'pointer',
                }}
              >
                Close
              </button>
            </>
          )}
        </Modal>

        {/* Modal for Edit */}
        <div
          class="modal fade"
          id="exampleModal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  Edit Item
                </h5>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
                {editValues && (
                  <Formik
                    initialValues={editValues}
                    validationSchema={validationSchema}
                    enableReinitialize={true}
                    onSubmit={onUpdate}
                  >
                    {({ values, setFieldValue, isSubmitting }) => (
                      <Form>
                        <ErrorMessage name="name" component="span" />
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="courses">
                            Courses
                          </label>

                          <Field
                            type="text"
                            name="courses"
                            id="courses"
                            placeholder="Enter Course"
                            class="form-control"
                          />
                        </div>
                        <ErrorMessage name="address" component="span" />
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="instructor">
                            instructor
                          </label>

                          <Field
                            type="text"
                            name="instructor"
                            id="instructor"
                            placeholder="Enter instructor"
                            class="form-control"
                          />
                        </div>
                        <ErrorMessage name="lessonSheets" component="span" />
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="lessonSheets">
                            Lesson Sheets
                          </label>

                          <Field
                            type="text"
                            name="lessonSheets"
                            id="lessonSheets"
                            placeholder="Enter lessonSheets"
                            class="form-control"
                          />
                        </div>
                        <ErrorMessage name="payment" component="span" />
                        <div class="form-group fieldStyle">
                          <label for="payment" htmlFor="payment">
                            payment
                          </label>

                          <Field
                            type="text"
                            name="payment"
                            id="payment"
                            placeholder="Enter payment"
                            class="form-control"
                          />
                        </div>
                        <ErrorMessage name="session" component="span" />
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="session">
                            session
                          </label>
                          <Field
                            type="text"
                            name="session"
                            id="session"
                            placeholder="Enter session"
                            class="form-control"
                          />
                        </div>
                        <ErrorMessage name="date" component="span" />
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="date">
                            Calendar
                          </label>
                          <Field
                            type="text"
                            name="date"
                            id="date"
                            placeholder="Enter a date"
                            class="form-control"
                          />
                        </div>
                        <div class="modal-footer">
                          <button type="submit" class="btn btn-primary mx-3">
                            Update
                          </button>
                          <button
                            class="btn btn-secondary"
                            data-bs-dismiss="modal"
                          >
                            Close
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* Modal for Detail */}
        <div
          class="modal fade"
          id="detailModal"
          tabindex="-1"
          aria-labelledby="detailModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="detailModalLabel">
                  Detail
                </h5>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
                {editValues && (
                  <div>
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="courses">
                        courses
                      </label>

                      <b name="courses">{editValues.courses}</b>
                    </div>
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="instructor">
                        Instructor
                      </label>

                      <b name="instructor">{editValues.instructor}</b>
                    </div>
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="lessonSheets">
                        Lesson Sheets
                      </label>

                      <b name="lessonSheets">{editValues.lesonSheets}</b>
                    </div>
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="payment">
                        Payment
                      </label>

                      <b name="payment">{editValues.payment}</b>
                    </div>
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="session">
                        Session
                      </label>

                      <b name="session">{editValues.session}</b>
                    </div>
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="date">
                        Date
                      </label>

                      <b name="calendar">{editValues.date}</b>
                    </div>

                    <div class="modal-footer">
                      <button class="btn btn-secondary" data-bs-dismiss="modal">
                        Close
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* Modal for Detail */}
        <div class="container mt-5">
          <div class="row">
            <div class="col-12">
              <h2 class="text-center">
                {' '}
                <i class="fa-solid fa-list"></i> Student Panel
              </h2>
              <p class="text-center"> Manage Student Panel here.</p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="card">
                <div class="card-header">
                  <h4 class="card-title">Add Student Details</h4>
                </div>
                <div class="card-body">
                  <Formik
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    validationSchema={validationSchema}
                  >
                    {({ values, setFieldValue, isSubmitting }) => (
                      <Form>
                        <ErrorMessage name="name" component="span" />
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="courses">
                            Courses
                          </label>
                          <Field
                            type="text"
                            name="courses"
                            id="courses"
                            placeholder="Enter Course"
                            class="form-control"
                          />
                        </div>
                        <ErrorMessage name="address" component="span" />
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="instructor">
                            Instructor
                          </label>
                          <Field
                            type="text"
                            name="instructor"
                            id="instructor"
                            placeholder="Enter instructor"
                            class="form-control"
                          />
                        </div>
                        <ErrorMessage name="lessonSheets" component="span" />
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="lessonSheets">
                            lessonSheets
                          </label>
                          <Field
                            type="text"
                            name="lessonSheets"
                            id="lessonSheets"
                            placeholder="Enter lessonSheets"
                            class="form-control"
                          />
                        </div>
                        <ErrorMessage name="payment" component="span" />
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="payment">
                            payment
                          </label>
                          <Field
                            type="text"
                            name="payment"
                            id="payment"
                            placeholder="Enter payment"
                            class="form-control"
                          />
                        </div>
                        <ErrorMessage name="session" component="span" />
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="session">
                            session
                          </label>
                          <Field
                            type="text"
                            name="session"
                            id="session"
                            placeholder="Enter session"
                            class="form-control"
                          />
                        </div>
                        <ErrorMessage name="date" component="span" />
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="date">
                            calendar
                          </label>
                          <Field
                            type="text"
                            name="date"
                            id="date"
                            placeholder="Enter a date"
                            class="form-control"
                          />
                        </div>
                        <button
                          type="submit"
                          class="btn btn-primary"
                          disabled={isSubmitting}
                        >
                          Add
                        </button>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="card">
                <div class="card-header">
                  <h4 class="card-title">Student Details List</h4>
                </div>
                <div class="card-body">
                  <table class="table">
                    <thead>
                      <tr>
                        <th>Course</th>
                        <th>Instructor</th>
                        <th>Lesson Sheets</th>
                        <th>Payment</th>
                        <th>Session</th>
                        <th>Calendar</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {listOfItems.map((value, key) => {
                        return (
                          <tr key={key}>
                            <td>{value.courses}</td>
                            <td>{value.instructor}</td>
                            <td>{value.lessonSheets}</td>
                            <td>{value.payment}</td>
                            <td>{value.session}</td>
                            <td>{value.date}</td>
                            <td>
                              <a class="nav-item dropdown">
                                <a
                                  class="fa-solid fa-circle-chevron-down"
                                  data-bs-toggle="dropdown"
                                  href="#"
                                  role="button"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                ></a>
                                <div class="dropdown-menu">
                                  <Link
                                    class="dropdown-item"
                                    data-bs-toggle="modal"
                                    data-bs-target="#exampleModal"
                                    onClick={() => handleEditClick(value)}
                                  >
                                    Edit
                                  </Link>
                                  <Link
                                    class="dropdown-item"
                                    data-bs-toggle="modal"
                                    data-bs-target="#detailModal"
                                    onClick={() => handleEditClick(value)}
                                  >
                                    Detail
                                  </Link>
                                  <div class="dropdown-divider"></div>
                                  <Link
                                    class="dropdown-item"
                                    onClick={() => showModalScreen(value.id)}
                                    msgId={value.id}
                                    data-bs-toggle="modal"
                                    data-bs-target="#deleteModal"
                                  >
                                    Delete
                                  </Link>
                                </div>
                              </a>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default AdminStudentPanel;
