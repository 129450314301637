import React from 'react';
import ROUTES from '../../navigations/Routes';
import { Link } from 'react-router-dom';
function ObtainLicense() {
  return (
    <div className="main">
      <div className="homeContainer">
        <div
          class="untree_co-hero inner-page container-fluid TheoryTest"
          style={{ height: 'auto' }}
        >
          <div class="row align-items-center justify-content-center">
            <div class="col-12" data-aos="fade-up" data-aos-delay="200">
              <div class="row refesherLesson">
                <div class="col-lg-6 mb-5">
                  <div
                    class="section-title mb-3"
                    data-aos="fade-up"
                    data-aos-delay="0"
                  >
                    <h2 class="my-4 text-black">Proceed to obtain a licence</h2>
                  </div>
                  <h4
                    data-aos="fade-up"
                    data-aos-delay="100"
                    className=" text-black"
                    style={{ fontSize: 27 }}
                  >
                    Learn to Drive
                  </h4>
                </div>
                <div class="col-lg-6" data-aos="fade-up" data-aos-delay="0">
                  <figure class="img-wrap">
                    <img
                      src="images/femaleInstructor.png"
                      alt="Image"
                      class="img-fluid"
                      //   width="400"
                      //height="400"
                    />
                    <div class="dotted"></div>
                  </figure>
                </div>
              </div>
              <div className="lessonContent">
                <h3>Proceed to obtain a license</h3>
                <p className="mt-4">
                  To apply for a driver license in Ontario, you need to
                </p>
                <p>
                  1. At the time of application, you must be at least 16 years
                  old.{' '}
                </p>
                <p>
                  2. Successfully complete the vision or eye test ordered by the
                  transportation ministry.
                </p>
                <p>
                  3. Every learners must pass the driving theory or knowledge
                  test before it may take the practical exam.
                </p>
                <p>
                  4. To become a full licensed, pass the class G2 and G road
                  tests.
                </p>
                <h3>
                  {' '}
                  How to get a Class G1, Class G2, and finally a complete G
                  license?
                </h3>
                <p>You can complete the full process in up to five years.</p>
                <p>
                  You have the option to requalify as a Class G2 driver for an
                  extra five years if you have been driving for five years and
                  have not yet earned your full Class G license. However, there
                  will be fees associated with passing the road test and
                  licensing. Upon the expiration of you begginer license, you
                  might have to reapply at the class G1 level.
                </p>
                <h3>
                  The following is the procedure for getting a complete Class G
                  license, which includes the progressive licensing steps:{' '}
                </h3>
                <h4 className="mt-5">
                  1. Study for the written knowledge test
                </h4>
                <p className="mx-4">
                  <Link to={ROUTES.theorytest.name}>
                    CSM Knowledge Practice Test
                  </Link>{' '}
                  tool is a great resource for an Ontario and other province's.
                  In addition, new drivers choose to take a government-approved
                  beginner driver education (BDE) course. These courses are
                  recommended, but not required. However, those enroll in
                  beginner driver education course, they can sit for their class
                  G2 road exam after 8 months - rather than the customary 12
                  months.
                </p>
                <h4 className="mt-5">
                  2. Apply for a driver's license at a DriveTest Centre
                </h4>
                <p className="mx-4">
                  When submitting an application, you will need to fill out
                  multiple forms and provide a valid ID at the DriveTest Center.
                  In order to be a eligible for a license exchange, a new
                  resident of Ontario who currently possesses a driver's license
                  from another province or nation must apply for an Ontario
                  dirver's license within 60 days of moving here.
                </p>
                <h4 className="mt-5">
                  3. Take a vision test at the DriveTest Center
                </h4>
                <p className="mx-4">
                  When you apply, a customer service representative completes
                  the test, which takes only a few minutes, bring your
                  corrective lenses with you.
                </p>
                <h4 className="mt-5">
                  4. Pay the applicable fees at a DriveTest Center
                </h4>
                <p className="mx-4">
                  The MTO sets the fees, which are subject to modification.
                </p>
                <h4 className="mt-5">
                  5. Complete and pass a written knowledge test at a DriveTest
                  Center
                </h4>
                <p className="mx-4">
                  The knowledge test takes approximately 30 minute at the drive
                  test centre CSM knowledge test practice tool is path to get
                  pass your knowledge test 1st attempt. Pass a knowledge test
                  about the rules and traffic signs.
                </p>
                <h4 className="mt-5">
                  6. Get your temporary driver's license at a DriveTest Center
                  from a customer service representative.
                </h4>
                <p className="mx-4">
                  The paper-based temporary driver's license valid for ninety
                  days and allows you to drive. Kindly visit your nearest
                  Service Ontario office to request an extension if your
                  temporary driver's license expires before you recieve your
                  permanent, plastic driver's license photo card.
                </p>
                <h4 className="mt-5">
                  7. Practice, Practice with CSM qualified driver instructors
                </h4>
                <p className="mx-4">
                  The Class G1 license is the first of two graduated licensing
                  learning levels prior to getting a full Class G license.
                </p>
                <h4 className="mt-5">
                  8. After 8 or 12 months with Class G1 license. Book your test
                  and take your G2 road test.
                </h4>
                <p className="mx-4">
                  At a DriveTest Center, a driver examiner will evaluate your
                  fundamental driving abilities for the Class G2 road test (e.g.
                  turning left and right, stopping, parking). In just 8 months,
                  you can take your Class G2 driving exam if you successfully
                  finish a government-approved Beginner Driver Education course.
                  It is required that you practice with your G1 for at least a
                  year if you do not finish one of these courses.
                </p>
                <h4 className="mt-5">
                  9. Practice, practice, practice your driving with your Class
                  G2 license.
                </h4>
                <p className="mx-4">
                  Before obtaining a full Class G license, the G2 is the second
                  of the two graduated licensing learning levels. For a full
                  year, you have to practice using your G2. View the drive test
                  website's restrictions with a G2 license.
                </p>
                <h4 className="mt-5">
                  10. Book a road test and then take your Class G road test.
                </h4>
                <p className="mx-4">
                  A driver examiner at a DriveTest Center will evaluate more
                  advanced driving skills (such as parallel parking and highway
                  driving) for the Class G road test. In the event that you are
                  approved, a temporary driver's license and a complete Class G
                  license will be mailed to you.If you fail the road test, you
                  can retake it as long as your Class G2 license is still valid.
                  Should your G2 license have expired, you will need to renew it
                  and pay any relevant fees.
                </p>
                <h4 className="mb-5 mt-5">
                  11. Enjoy your Class G licence. Drive safely.
                </h4>
              </div>
            </div>
          </div>
        </div>
        <div
          className="bookBox container-fluid"
          data-aos="fade-up"
          data-aos-delay="200"
        >
          <h3 className="text-white">Book your lessons online!</h3>
          <h5 className="text-white mt-3">
            Check availability and book your first lesson
          </h5>
          <Link
            to={ROUTES.learntodrive.name}
            className="btn btn-primary btnPrice"
          >
            See Prices
          </Link>
        </div>
      </div>
    </div>
  );
}

export default ObtainLicense;
