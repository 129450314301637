import React from 'react';
import ROUTES from '../../navigations/Routes';
import { Link } from 'react-router-dom';
function AdvanceDrivingCourse() {
  return (
    <div className="main">
      <div className="homeContainer">
        <div
          class="untree_co-hero inner-page container-fluid TheoryTest"
          style={{ height: 'auto' }}
        >
          <div class="row align-items-center justify-content-center">
            <div class="col-12">
              <div class="row refesherLesson">
                <div class="col-6 mb-5">
                  <div
                    class="section-title mb-3"
                    data-aos="fade-up"
                    data-aos-delay="0"
                  >
                    <h2 class="my-4 text-black">Advance Driving Courses</h2>
                  </div>

                  <button className="btn btn-primary mt-4 PriceG ">
                    Get Prices
                  </button>
                </div>
                <div class="col-6" data-aos="fade-up" data-aos-delay="0">
                  <figure class="img-wrap">
                    <img
                      src="images/instructor.jpg"
                      alt="Image"
                      class="img-fluid"
                      //   width="400"
                      //height="400"
                    />
                    <div class="dotted"></div>
                  </figure>
                </div>
              </div>
              <div className="lessonContent">
                <h3>Improve your road skills</h3>
                <p>
                  Even if you're an experienced driver, enrolling in an advanced
                  driving course will improve your driving abilities. Do you
                  want to drive with more assurance? Or perhaps you simply feel
                  that you need some practice? You can become a driving expert
                  by enrolling in our courses for current license holders.
                </p>

                <h3>Build your own course</h3>
                <p>
                  We are happy to provide customized instruction for the area
                  you would like to focus on from our highly qualified
                  instructors. To schedule a specially designed{' '}
                  <Link to={ROUTES.refresherLessons.name}>
                    refresher course
                  </Link>
                  , get in contact right now.
                </p>
                <h3>What kind of advanced driving courses are there?</h3>
                <p>
                  We provide both the Pass Plus qualification and refresher
                  training.
                </p>
                <p>
                  Refresher courses should last at least two hours, but to get
                  the most out of them, we suggest five. These can be customized
                  to address any area you'd like to enhance, as previously said.
                  Although there is no requirement, we can assure you that the
                  outcome will increase your self-assurance and competence.
                </p>

                <h3>What can I learn on an advanced driving course?</h3>
                <p>
                  You are free to select the subjects and abilities you want to
                  focus on. Ultimately, though, the goal of any advanced course
                  for drivers with an active license is to make you a more
                  skilled and safe driver.
                </p>
                <p>
                  You can pick up abilities such as, but not restricted to:
                  <br />
                  Driving at night, in all types of weather, on highways, in
                  dual carriageways, and maintaining a safe speed are all
                  important.
                </p>
                <h3>Advanced driving lessons near me</h3>
                <p>
                  Our expert instructors offer refresher driving lessons all
                  over the Ontario so we'll have you covered wherever you are.
                  Some of our more popular areas. Please book your refresher
                  lessons online and check our availablity of instructors in
                  your area.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="bookBox container-fluid">
          <h3 className="text-white">Book your lessons online!</h3>
          <h5 className="text-white mt-3">
            Check availability and book your first lesson
          </h5>
          <button className="btn btn-primary btnPrice">See Prices</button>
        </div>
      </div>
    </div>
  );
}

export default AdvanceDrivingCourse;
