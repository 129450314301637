import React from 'react';
import { Link } from 'react-router-dom';
import ROUTES from '../../navigations/Routes';
import { useState, useEffect } from 'react';
import { validateYupSchema } from 'formik';
import axios from 'axios';
function G2Plus() {
  const [highlightedBox, setHighlightedBox] = useState(null);
  const [listOfG2PlusPackages, setListOfG2PlusPackages] = useState([]);

  const toggleHighlight = (boxKey) => {
    if (highlightedBox === boxKey) {
      setHighlightedBox(null);
    } else {
      setHighlightedBox(boxKey);
    }
  };
  useEffect(() => {
    fetch('https://csmserver.csmdrivingschool.ca/G2PlusPackage.php')
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('Network response was not ok.');
        }
      })
      .then((data) => {
        setListOfG2PlusPackages(data);
      })
      .catch((error) => {
        console.error('Error while fetching data', error);
        // Handle error here
      });
  }, []);

  const [formData, setFormData] = useState({
    selectedPackage: null,
  });
  const [selectedPackage, setSelectedPackage] = useState(null);

  const handlePackageSelection = (packageData) => {
    setSelectedPackage(packageData);
  };

  const handleContinueClick = () => {
    if (selectedPackage) {
      setFormData({
        ...formData,
        selectedPackage: {
          id: selectedPackage.id,
          packagePrice: selectedPackage.packagePrice,
        },
      });
    }
    console.log(selectedPackage);
  };

  useEffect(() => {
    if (listOfG2PlusPackages.length > 0) {
      handlePackageSelection(listOfG2PlusPackages[0]);
    }
  }, [listOfG2PlusPackages]);

  return (
    <div className="main">
      <div className="homeContainer">
        <div
          class="untree_co-hero inner-page container-fluid"
          style={{ backgroundImage: `url('/images/slider.jpg')` }}
        >
          <div class="container-fluid">
            <div class="row align-items-center justify-content-center">
              <div class="col-12">
                <div class="row justify-content-center ">
                  <div class="col-lg-6 text-center ">
                    <h1
                      class="heading text-white"
                      data-aos="fade-up"
                      data-aos-delay="100"
                      style={{ marginTop: 100 }}
                    >
                      CSM- G2 Plus Program
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*  */}
        <div class="untree_co-section" style={{ marginTop: 70 }}>
          <div class="container-fluid">
            <div class="row justify-content-center mb-3">
              <div
                class="col-lg-7 text-center"
                data-aos="fade-up"
                data-aos-delay="0"
              >
                <h2 class="line-bottom text-center mt-5">
                  Choose your Package Hours Included Local Road Test
                </h2>
              </div>
            </div>
            <div class="row d-flex justify-content-center">
              {listOfG2PlusPackages.map((value, key) => {
                const boxKey = `box${key}`;
                return (
                  <div
                    class="col-md-6 col-lg-3 mx-2"
                    data-aos="fade-up"
                    data-aos-delay="00"
                  >
                    <div
                      className={`box ${
                        highlightedBox === boxKey ? 'highlighted' : ''
                      }`}
                      onClick={() => toggleHighlight(boxKey)}
                    >
                      <div onClick={() => handlePackageSelection(value)}>
                        <div
                          class="pricing bg-white pricingG mb-3"
                          key={value.id}
                        >
                          <div class="pricing-body">
                            <span
                              className={`timing ${
                                highlightedBox === boxKey
                                  ? 'highlighted-text'
                                  : ''
                              }`}
                            >
                              {value.hours}
                            </span>{' '}
                            <br />
                            <span className="">HOURS</span>
                            <div class="priceG">
                              <span
                                className={`fig ${
                                  highlightedBox === boxKey
                                    ? 'highlighted-text'
                                    : ''
                                }`}
                                style={{ fontSize: 30 }}
                              >
                                {value.discount == 0 ? (
                                  <>${value.packagePrice}</>
                                ) : (
                                  <>
                                    <strike>${value.packagePrice}</strike>
                                  </>
                                )}
                              </span>
                              {value.discount != 0 && (
                                <>
                                  <span className="discount mx-2">
                                    ${value.discount}
                                  </span>
                                  <br />

                                  <span className="save mx-2">
                                    Save ${value.save}
                                  </span>
                                </>
                              )}
                              <br />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  //
                );
              })}
            </div>
          </div>
          {/*  */}
          <div className="d-flex justify-content-center mt-4">
            <Link
              to={
                selectedPackage
                  ? `${ROUTES.g2plusregister.name}/${selectedPackage.id}`
                  : ROUTES.g2plusregister.name
              }
              className="btn btn-outline-primary"
              onClick={handleContinueClick}
            >
              Continue
            </Link>
          </div>
        </div>
        {/*  */}
        <div className="container-fluid d-flex justify-content-center">
          <div class="card text-dark mb-3 col-lg-8 ml-5">
            <div class="card-header d-flex d-inline-block">
              <div>
                <img src="/images/dollar.png" height={40} width={50}></img>
              </div>

              <span className="font-weight-bold mx-3 mt-2">
                Full Refund on Unused Hours
              </span>
            </div>
            <div className=" mb-3 ml-2">
              Only Individual lessons, we will refund all your unused hours. No
              hidden charges. No messing around. Our <a href="#">Terms</a> may
              apply.
            </div>
          </div>
        </div>
      </div>
      <div className="site-footer">
        <div className="mt-5 csm">
          <h2 className="text-white my-4">About CSM Driving School</h2>
          <p className="text-white text-center mx-5">
            CSM Driving School's mission is to provide our customers with a
            driving school that they can rely on to train responsible and safe
            drivers of the future. We are committed to providing the best
            possible educational experiences.
            <br />
            Thank you for your continued partonage. We expect to be one of the
            largest driver training organizations in Ontario, and we are
            constantly growing. Our entire focus is on caring for our customers
            and preparing safe, responsible drivers for the future. Our approach
            to being technologically friendly has made us the first choice of
            today's generation of teens, while also being loved and referred by
            our customers from previous years.
          </p>
        </div>
      </div>
    </div>
  );
}
export default G2Plus;
