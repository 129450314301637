import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
function FleetRegister() {
  return (
    <div className="main">
      <div className="homeContainer">
        {' '}
        <div class="untree_co-section" style={{ paddingTop: 180 }}>
          <div class="container-fluid">
            <div class="row justify-content-center">
              <div
                class="col-lg-7 order-1"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <Formik
                //   initialValues={initialValues}
                //   onSubmit={onSubmit}
                //   validationSchema={validationSchema}
                >
                  <Form action="#" class="form-box">
                    <div class="row">
                      <div class="col-6 mb-3">
                        <Field
                          type="text"
                          class="form-control"
                          placeholder="First Name"
                          name="firstName"
                          id="Rname"
                        />
                        <ErrorMessage
                          name="firstName"
                          component="span"
                          style={{ color: 'red' }}
                        ></ErrorMessage>
                      </div>
                      <div class="col-6 mb-3">
                        <Field
                          type="text"
                          class="form-control"
                          placeholder="Last Name"
                          name="lastName"
                          id="lname"
                        />
                        <ErrorMessage
                          name="lastName"
                          component="span"
                          style={{ color: 'red' }}
                        ></ErrorMessage>
                      </div>
                      <div class="col-12 mb-3">
                        <Field
                          type="text"
                          class="form-control"
                          placeholder="Email"
                          name="email"
                          id="Remail"
                        />
                        <ErrorMessage
                          name="email"
                          component="span"
                          style={{ color: 'red' }}
                        ></ErrorMessage>
                      </div>
                      <div class="col-12 mb-3">
                        <Field
                          type="text"
                          className="form-control"
                          id="userName"
                          placeholder="User Name"
                          autoComplete="off"
                          name="userName"
                        />
                        <ErrorMessage
                          name="userName"
                          component="span"
                          style={{ color: 'red' }}
                        ></ErrorMessage>
                      </div>

                      <div class="col-6 mb-3">
                        <Field
                          type="password"
                          className="form-control"
                          id="password"
                          placeholder="Password"
                          autoComplete="off"
                          name="password"
                        />
                        <ErrorMessage
                          name="password"
                          component="span"
                          style={{ color: 'red' }}
                        ></ErrorMessage>
                      </div>
                      <div class="col-6 mb-3">
                        <Field
                          type="password"
                          className="form-control"
                          id="confirmPassword"
                          placeholder="Confirm Password"
                          autoComplete="off"
                          name="confirmPassword"
                        />
                        <ErrorMessage
                          name="confirmPassword"
                          component="span"
                          style={{ color: 'red' }}
                        ></ErrorMessage>
                      </div>
                      <div class="col-12 mb-3">
                        <Field
                          type="text"
                          class="form-control"
                          placeholder="Phone Number"
                          name="phoneNumber"
                          id="phoneNumber"
                        />
                      </div>
                      <ErrorMessage
                        name="phoneNumber"
                        component="span"
                        style={{ color: 'red' }}
                      ></ErrorMessage>
                      <div class="col-12 mb-3">
                        <Field
                          name="licenseType"
                          id="licenseType"
                          as="select"
                          type="text"
                          class="custom-select"
                        >
                          <option value="">License Type</option>
                          <option value="3orMoreYearLicense">
                            Full Ontario (CA) for 3 or more years
                          </option>
                          <option value="3orlessyearslicense">
                            {' '}
                            Full Ontario (CA) for 3 or less years
                          </option>
                        </Field>
                        <ErrorMessage
                          name="licenseType"
                          component="span"
                          style={{ color: 'red' }}
                        ></ErrorMessage>
                      </div>
                      <div class="col-12 mb-3">
                        <Field
                          class="form-control"
                          placeholder="Postal Code"
                          name="postalCode"
                          id="postalCode"
                        />
                        <ErrorMessage
                          name="postalCode"
                          component="span"
                          style={{ color: 'red' }}
                        ></ErrorMessage>
                      </div>
                      <div class="col-12 mb-3">
                        <Field
                          class="form-control"
                          placeholder="How many penalty points?"
                          name="penaltyPoints"
                          id="penaltyPoints"
                        />
                        <ErrorMessage
                          name="penaltyPoints"
                          component="span"
                          style={{ color: 'red' }}
                        ></ErrorMessage>
                      </div>
                      <div class="col-12">
                        <div>
                          <label className="mx-2">
                            Have you been banned from driving in the last four
                            years?
                          </label>
                        </div>
                        <Field
                          name="banned"
                          className="d-flex"
                          render={({ field }) => (
                            <div className="row">
                              <div className="radio-item mx-4">
                                <input
                                  {...field}
                                  id="yes"
                                  value="yes"
                                  checked={field.value === 'yes'}
                                  type="radio"
                                  className="mx-2"
                                />
                                <label htmlFor="yes">Yes</label>
                              </div>
                              <div className="radio-item">
                                <input
                                  {...field}
                                  id="no"
                                  value="no"
                                  checked={field.value === 'no'}
                                  type="radio"
                                  className="mx-2"
                                />
                                <label htmlFor="no">No</label>
                              </div>
                            </div>
                          )}
                        />
                      </div>
                      <div class="col-12 my-4">
                        <label class="control control--checkbox">
                          <span class="caption">
                            Accept our <a href="#">terms and conditions</a>
                          </span>
                          <Field type="checkbox" name="conditions" />
                          <div class="control__indicator"></div>
                        </label>
                      </div>
                      <div class="col-12">
                        <button type="submit" class="btn btn-primary">
                          Apply
                        </button>
                      </div>
                    </div>
                  </Form>
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FleetRegister;
