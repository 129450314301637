import React from 'react';
import { Link } from 'react-router-dom';
import ROUTES from '../../navigations/Routes';
function LearnerDriverInsurance() {
  return (
    <div className="main">
      <div className="homeContainer" style={{ height: '100vh' }}>
        <div className="py-5" style={{ height: '470px' }}>
          <h2 className="text-center" style={{ marginTop: 120 }}>
            Learner Driver Insurance Coming Soon....
          </h2>
        </div>
      </div>
    </div>
  );
}

export default LearnerDriverInsurance;
