import React, { useEffect, useState, useReducer } from 'react';
import axios from 'axios';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from 'react-modal';
import 'react-toastify/dist/ReactToastify.css';
import JoditEditor from 'jodit-react';

function TheoryTestCategories({ placeholder }) {
  const [listOfItems, setListOfItems] = useState([]); // To store list of all items
  const [currentClickedId, setCurrentClickedId] = useState(''); // To Store the Clicked Id for Deletion
  const [editValues, setEditValues] = useState(null); // To store the values for Edit Modal (FORM)
  const [reducerValue, forceUpdate] = useReducer((x) => x + 1, 0); // To tell the useEffect that a row has been added
  const [selectedImage, setSelectedImage] = useState(null); // To show the Image full view in Modal

  const initialValues = {
    image: '',
    categoryname: '',
    categorycontent: '',
  };

  const validationSchema = Yup.object().shape({
    image: Yup.string().required(),
    categoryname: Yup.string().required(),
    categorycontent: Yup.string().required(),
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          'http://csmserver.csmdrivingschool.ca/TheoryTestCategories.php'
        );
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setListOfItems(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [reducerValue]);

  const showTostify = (msg) => {
    toast.success(msg, {
      position: 'bottom-right',
      autoClose: 5000, // in milliseconds, set to 5000 for 5 seconds
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      style: {
        background: 'rgba(0, 0, 0, 0.7)', // Transparent black background
        color: 'white',
      },
    });
  };
  const openImageModal = (imageData) => {
    setSelectedImage(imageData);
  };
  const closeImageModal = () => {
    setSelectedImage(null);
  };

  const onSubmit = async (data, { setSubmitting }) => {
    try {
      const formData = new FormData();
      formData.append('image', data.image);
      formData.append('categoryname', data.categoryname);
      formData.append('categorycontent', data.categorycontent);

      const response = await fetch(
        'http://csmserver.csmdrivingschool.ca/TheoryTestCategories.php',
        {
          method: 'POST',
          body: formData,
          headers: {
            // 'Content-Type': 'multipart/form-data', // Note: This is not needed and will be automatically set by the browser
          },
        }
      );

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      forceUpdate();
      showTostify('TheoryTestCategories saved');
    } catch (error) {
      console.error(error);
    } finally {
      setSubmitting(false);
    }
  };

  const onUpdate = async (data) => {
    try {
      const formData = new FormData();
      formData.append('id', data.id);
      formData.append('image', data.image);
      formData.append('categoryname', data.categoryname);
      formData.append('categorycontent', data.categorycontent);

      const response = await fetch(
        'http://csmserver.csmdrivingschool.ca/TheoryTestCategories.php',
        {
          method: 'PUT',
          body: formData,
          headers: {
            // 'Content-Type': 'multipart/form-data', // Note: This is not needed and will be automatically set by the browser
          },
        }
      );

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      window.location.reload();
    } catch (error) {
      console.error('Error updating', error);
    }
  };

  const handleEditClick = (value) => {
    setEditValues(value);
  };

  const showModalScreen = (itemId) => {
    setCurrentClickedId(itemId);
  };

  const hideModalScreen = () => {};

  const onDelete = async () => {
    console.log(currentClickedId);

    try {
      const response = await fetch(
        `http://csmserver.csmdrivingschool.ca/TheoryTestCategories.php?id=${currentClickedId}`,
        {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      setListOfItems(listOfItems.filter((item) => item.id != currentClickedId));
      console.log(currentClickedId);

      toast.success('Item deleted successfully', {
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          background: 'rgba(0, 0, 0, 0.7)',
          color: 'white',
        },
      });
      hideModalScreen();
    } catch (error) {
      console.error('Error deleting item:', error);
    }
  };

  //jodit editor
  const config = {
    readonly: false,
    placeholder: placeholder || 'Start Typing...',
  };
  const [description, setDescription] = useState('');

  const removeHtmlTags = (html) => {
    // Create a temporary element
    const tempElement = document.createElement('div');
    // Set the HTML content
    tempElement.innerHTML = html;
    // Get the text content (excluding HTML tags)
    return tempElement.textContent || tempElement.innerText || '';
  };

  const editorRef = React.useRef();

  return (
    <div className="AdminContainer container">
      <ToastContainer />
      <section className="middleArea">
        {/* Modal for delete */}

        <div
          class="modal fade"
          id="deleteModal"
          tabindex="-1"
          aria-labelledby="deleteModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="deleteModalLabel">
                  Delete Confirm
                </h5>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
                <p>Are you sure you want to delete</p>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-primary mx-2"
                  data-bs-dismiss="modal"
                >
                  No
                </button>
                <button
                  type="button"
                  class="btn btn-primary"
                  onClick={onDelete}
                  data-bs-dismiss="modal"
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* Modal for delete */}

        {/* Modal for displaying the full-size image */}
        <Modal
          isOpen={selectedImage !== null}
          onRequestClose={closeImageModal}
          contentLabel="Full Size Image"
          style={{
            content: {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              border: '1px solid #ccc',
              background: '#fff',
              borderRadius: '4px',
              padding: '20px',
              maxWidth: '80%',
              maxHeight: '80%',
              overflow: 'auto',
              margin: 'auto',
            },
          }}
        >
          {selectedImage && (
            <>
              <img
                src={selectedImage}
                alt="Full Size"
                style={{
                  maxWidth: '100%',
                  maxHeight: '100%',
                  display: 'block',
                  margin: 'auto',
                }}
              />
              <button
                onClick={closeImageModal}
                style={{
                  position: 'absolute',
                  top: '10px',
                  right: '15px',
                  background: 'none',
                  border: 'none',
                  fontSize: '16px',
                  cursor: 'pointer',
                }}
              >
                Close
              </button>
            </>
          )}
        </Modal>

        {/* Modal for Edit */}
        <div
          class="modal fade"
          id="exampleModal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" style={{ maxWidth: 900 }}>
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  Edit Item
                </h5>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
                {editValues && (
                  <Formik
                    initialValues={editValues}
                    validationSchema={validationSchema}
                    enableReinitialize={true}
                    onSubmit={onUpdate}
                  >
                    {({ values, setFieldValue, isSubmitting }) => (
                      <Form>
                        <div class="form-group fieldStyle">
                          <label for="image" htmlFor="image">
                            Image
                          </label>

                          <input
                            type="file"
                            onChange={(event) => {
                              setFieldValue('image', event.target.files[0]);
                            }}
                          ></input>
                        </div>
                        <ErrorMessage name="categoryname" component="span" />
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="categoryname">
                            Category Name
                          </label>

                          <Field
                            type="text"
                            name="categoryname"
                            id="categoryname"
                            placeholder="Enter a Category Name"
                            class="form-control"
                          />
                        </div>
                        <ErrorMessage name="categorycontent" component="span" />
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="categorycontent">
                            Category Content
                          </label>
                          <Field name="categorycontent">
                            {({ field, form: { setFieldValue } }) => (
                              <div>
                                <JoditEditor
                                  ref={editorRef}
                                  value={field.value}
                                  onBlur={() => {
                                    const sanitizedValue = removeHtmlTags(
                                      editorRef.current.value
                                    );
                                    setFieldValue(
                                      'categorycontent',
                                      sanitizedValue
                                    );
                                  }}
                                />
                                <div>{removeHtmlTags(field.value)}</div>
                              </div>
                            )}
                          </Field>
                        </div>

                        <div class="modal-footer">
                          <button type="submit" class="btn btn-primary mx-3">
                            Update
                          </button>
                          <button
                            class="btn btn-primary"
                            data-bs-dismiss="modal"
                          >
                            Close
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* Modal for Edit */}

        {/* Modal for Detail */}
        <div
          class="modal fade"
          id="detailModal"
          tabindex="-1"
          aria-labelledby="detailModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="detailModalLabel">
                  Detail
                </h5>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
                {editValues && (
                  <div>
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="categoryname">
                        Category Name
                      </label>

                      <b name="categoryname">{editValues.categoryname}</b>
                    </div>
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="categorycontent">
                        Category Content
                      </label>

                      <b name="categorycontent">{editValues.categorycontent}</b>
                    </div>

                    <div class="modal-footer">
                      <div class="detailImageBoxContainer">
                        <div class="detailImageBox">
                          <img
                            src={editValues.image}
                            alt="image"
                            style={{ cursor: 'pointer' }}
                          />
                        </div>
                      </div>
                      <button class="btn btn-primary" data-bs-dismiss="modal">
                        Close
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* Modal for Detail */}

        <div class="container mt-5">
          <div class="row">
            <div class="col-12">
              <h2 class="text-center">
                {' '}
                <i class="fa-solid fa-list"></i> TheoryTest Categories
              </h2>
              <p class="text-center"> Manage Theory Test Categories here.</p>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <div class="card">
                <div class="card-header">
                  <h4 class="card-title">Add TheoryTest Categories</h4>
                </div>
                <div class="card-body">
                  <Formik
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    validationSchema={validationSchema}
                  >
                    {({ values, setFieldValue, isSubmitting }) => (
                      <Form>
                        <div class="form-group fieldStyle">
                          <label for="image" htmlFor="image">
                            Image
                          </label>

                          <input
                            type="file"
                            onChange={(event) => {
                              setFieldValue('image', event.target.files[0]);
                            }}
                          ></input>
                        </div>
                        <ErrorMessage name="categoryname" component="span" />
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="categoryname">
                            Category Name
                          </label>

                          <Field
                            type="text"
                            name="categoryname"
                            id="categoryname"
                            placeholder="Enter a CategoryName"
                            class="form-control"
                          />
                        </div>
                        <ErrorMessage name="categorycontent" component="span" />
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="categorycontent">
                            Category Content
                          </label>
                          <Field name="categorycontent">
                            {({ field, form: { setFieldValue } }) => (
                              <div>
                                <JoditEditor
                                  ref={editorRef}
                                  value={field.value}
                                  onBlur={() => {
                                    const sanitizedValue = removeHtmlTags(
                                      editorRef.current.value
                                    );
                                    setFieldValue(
                                      'categorycontent',
                                      sanitizedValue
                                    );
                                  }}
                                />
                                <div>{removeHtmlTags(field.value)}</div>
                              </div>
                            )}
                          </Field>
                        </div>

                        <button
                          type="submit"
                          class="btn btn-primary"
                          disabled={isSubmitting}
                        >
                          Add
                        </button>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>

            <div class="col-md-12 mt-5">
              <div class="card">
                <div class="card-header">
                  <h4 class="card-title">Theory Test Categorie's List</h4>
                </div>
                <div class="card-body">
                  <table class="table">
                    <thead>
                      <tr>
                        <th>Image</th>
                        <th>Category Name</th>
                        <th>Category Content</th>

                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {listOfItems.map((value, key) => {
                        return (
                          <tr key={key}>
                            <td>
                              {value.image ? (
                                <img
                                  src={`https://csmdrivingschool.ca/${value.image}`}
                                  //src={value.image}
                                  //  src={`https://csmdrivingschool.ca/${value.image}`}
                                  alt="TheoryTestCategories"
                                  onClick={() => openImageModal(value.image)}
                                  style={{ cursor: 'pointer' }}
                                  height="130px"
                                  width="150px"
                                  onError={(e) => {
                                    console.error('Error loading image:', e);
                                  }}
                                />
                              ) : (
                                <span>No image available</span>
                              )}
                            </td>

                            <td>{value.categoryname}</td>
                            <td>{value.categorycontent}</td>

                            <td>
                              <a class="nav-item dropdown">
                                <a
                                  class="fa-solid fa-circle-chevron-down"
                                  data-bs-toggle="dropdown"
                                  href="#"
                                  role="button"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                ></a>
                                <div class="dropdown-menu">
                                  <Link
                                    class="dropdown-item"
                                    data-bs-toggle="modal"
                                    data-bs-target="#exampleModal"
                                    onClick={() => handleEditClick(value)}
                                  >
                                    Edit
                                  </Link>

                                  <Link
                                    class="dropdown-item"
                                    data-bs-toggle="modal"
                                    data-bs-target="#detailModal"
                                    onClick={() => handleEditClick(value)}
                                  >
                                    Detail
                                  </Link>

                                  <div class="dropdown-divider"></div>
                                  <Link
                                    class="dropdown-item"
                                    onClick={() => showModalScreen(value.id)}
                                    msgId={value.id}
                                    data-bs-toggle="modal"
                                    data-bs-target="#deleteModal"
                                  >
                                    Delete
                                  </Link>
                                </div>
                              </a>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default TheoryTestCategories;
