import React from 'react';
import { Link, useParams } from 'react-router-dom';
import ROUTES from '../../navigations/Routes';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import axios from 'axios';
function InstructorLogIn() {
  const navigate = useNavigate();
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');

  // const login = () => {
  //   const data = { userName: userName, password: password };
  //   axios
  //     .post('http://localhost:3001/Instructor/login', data)
  //     .then((response) => {
  //       if (response.data.error) {
  //         alert(response.data.error);
  //       } else {
  //         localStorage.setItem('id', response.id);
  //         // setAuthState(true);
  //         navigate('/instructorPanel');
  //       }
  //     });
  // };

  const login = async () => {
    const data = { userName: userName, password: password };
    try {
      const response = await fetch(
        'https://csmserver.csmdrivingschool.ca/InstructorLogIn.php',

        {
          method: 'POST',

          headers: {
            'Content-Type': 'application/json',
          },

          body: JSON.stringify(data),
        }
      );
      if (response.ok) {
        const responseData = await response.json();
        if (responseData.error) {
          alert(responseData.error);
        } else {
          localStorage.setItem('id', responseData.id);
          localStorage.setItem('currentInsName', userName);

          navigate(ROUTES.instructorPanel.name, { replace: true });
          window.location.reload();
        }
      } else {
        // throw new Error('Network response was not ok.');
        alert('Wrong username or password');
      }
    } catch (error) {
      console.error('Error while logging in:', error);
    }
  };
  return (
    <div className="main">
      <div className="homeContainer">
        <div
          class="untree_co-hero inner-page container-fluid"
          style={{ backgroundImage: `url('images/studentRegister.jpg')` }}
        >
          <div class="container-fluid ">
            <div class="row align-items-center justify-content-center">
              <div class="col-12">
                <div class="row justify-content-center ">
                  <div class="col-lg-6 text-center ">
                    <h1
                      class="heading text-white"
                      data-aos="fade-up"
                      data-aos-delay="100"
                      style={{ marginTop: 150 }}
                    >
                      Instructor Login
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="untree_co-section logInBox">
          <div class="container-fluid">
            <div class="row mb-5 justify-content-center">
              <div
                class="col-lg-5 mx-auto order-1"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <form action="#" class="form-box">
                  <div class="row">
                    <div class="col-12 mb-3">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Username"
                        onChange={(event) => {
                          setUserName(event.target.value);
                        }}
                      />
                    </div>
                    <div class="col-12 mb-3">
                      <input
                        type="password"
                        class="form-control"
                        placeholder="Password"
                        onChange={(event) => {
                          setPassword(event.target.value);
                        }}
                      />
                    </div>
                    <div class="col-12 mb-1">
                      <label class="control control--checkbox">
                        <span class="caption">Remember me</span>
                        <input type="checkbox" checked="checked" />
                        <div class="control__indicator"></div>
                      </label>
                    </div>
                    <div class="col-12">
                      <Link class="btn btn-primary" onClick={login}>
                        Log In
                      </Link>
                    </div>
                    <div class="col-12 mt-3">
                      <h5>If you do not have an account, then</h5>
                      <Link to={ROUTES.registerForm.name}>
                        Instructor Registration Form
                      </Link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InstructorLogIn;
