import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';

function StudentRegisterSummary() {
    const location = useLocation();
    const { regId } = location.state || {};

    const [studentData, setStudentData] = useState(null);
    const [additionalData, setAdditionalData] = useState(null);
    const [instructorData, setInstructorData] = useState(null);
    const [scheduleData, setScheduleData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (regId) {
            // Fetch student data
            axios.get(`https://csmserver.csmdrivingschool.ca/StudentRegister.php?id=${regId}`)
                .then((response) => {
                    setStudentData(response.data);
                    fetchAdditionalData(); // Fetch additional data after getting student data
                })
                .catch((error) => {
                    setError(error.message);
                    setLoading(false);
                });
        }
    }, [regId]);

    const fetchAdditionalData = () => {
        axios.get(`https://csmserver.csmdrivingschool.ca/OfflineStudentRequest.php?studentRegisterId=${regId}`)
            .then((response) => {
                setAdditionalData(response.data);
                // Fetch schedule data
                const { scheduleId, instructorId } = response.data;
                fetchScheduleData(scheduleId);
                fetchInstructorData(instructorId);
            })
            .catch((error) => {
                setError(error.message);
                setLoading(false);
            });
    };

    const fetchScheduleData = (scheduleId) => {
        axios.get(`https://csmserver.csmdrivingschool.ca/InstructorSchedule.php?id=${scheduleId}`)
            .then((response) => {
                setScheduleData(response.data);

                setLoading(false); // Set loading false only after all data is fetched
            })
            .catch((error) => {
                setError(error.message);
                setLoading(false);
            });
    };

    const fetchInstructorData = (instructorId) => {
        axios.get(`https://csmserver.csmdrivingschool.ca/Instructor.php?id=${instructorId}`)
            .then((response) => {
                console.log(response.data)
                setInstructorData(response.data);
                setLoading(false); // Set loading false only after all data is fetched
            })
            .catch((error) => {
                setError(error.message);
                setLoading(false);
            });
    };

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;

    return (
        <div className="main bg-light">
            <div className="homeContainer pb-5">
                <div className="container-fluid">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-12">
                            <div className="row justify-content-center">
                                <div className="col-lg-6 text-center">
                                    <div className="mb-5 text-white desc mx-auto" data-aos="fade-up" data-aos-delay="200"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="bookOnlineBox5">
                        <div className="HalfOne">
                            <img src="images/car22.jpg" alt="Car" />
                        </div>
                        <div className="HalfTwo">
                            <div className="innerHalf">
                                <div className="instructor-info text-center">
                                    <h3 className="mb-4 text-center">Student Summary</h3>
                                    {studentData ? (
                                        <div className="container-fluid">
                                            <p><strong>id:</strong>{regId}</p>
                                            <p><strong>Name:</strong> {studentData.userName}</p>
                                            <p><strong>Email:</strong> {studentData.email}</p>
                                            <p><strong>Phone:</strong> {studentData.phoneNo}</p>
                                        </div>
                                    ) : (
                                        <p>No student data available.</p>
                                    )}

                                    {additionalData && (
                                        <div className="container-fluid">
                                            <p><strong>Selected Hours:</strong> {additionalData.selectedHours}</p>
                                            <p><strong>Selected Price:</strong> {additionalData.selectedPrice}</p>
                                            <p><strong>Gearbox Type:</strong> {additionalData.selectedGear}</p>
                                        </div>
                                    )}

                                    {scheduleData && (
                                        <div className="container-fluid">
                                            <p><strong>Schedule Date:</strong> {scheduleData.date}</p>
                                            <p><strong>Schedule Time:</strong> {scheduleData.time}</p>
                                        </div>
                                    )}

                                    {instructorData && (
                                        <div className="container-fluid">
                                            <p><strong>Instructor Name:</strong> {instructorData.name}</p>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default StudentRegisterSummary;
