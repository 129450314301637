import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
const SliderComponent = () => {
  const settings = {
    // dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };
  const images = [
    {
      src: '/images/learner.jpg',
    },
    {
      src: '/images/pass.jpg',
    },
    {
      src: '/images/slider3.jpg',
    },
  ];
  return (
    <Slider {...settings}>
      {images.map((item, index) => (
        <div key={index} className="slider-item">
          <div className="container-fluid">
            <img
              className="slider-image"
              src={item.src}
              alt={`slide-${index + 1}`}
            />
          </div>
        </div>
      ))}
    </Slider>
  );
};
export default SliderComponent;
