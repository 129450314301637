import React, { useReducer } from 'react';
import { Link, useParams } from 'react-router-dom';
import ROUTES from '../../navigations/Routes';
import { useState, useEffect } from 'react';
import axios from 'axios';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { Form, Formik, FieldArray, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function InstructorPanel() {
  const [editValues, setEditValues] = useState({ payRate: '' });
  const [isModalOpen, setModalOpen] = useState(false);
  let { userName } = useParams();
  const [listOfSchedules, setListOfSchedules] = useState([]);
  const [selectedUserDate, setSelectedUserDate] = useState('');
  const [selectedUserTime, setSelectedUserTime] = useState('');
  const [selectedInstructor, setSelectedInstructor] = useState(null);
  const [selectedButton, setSelectedButton] = useState(null);
  const [selectedSchedule, setSelectedSchedule] = useState([]);
  const [currentClickedId, setCurrentClickedId] = useState('');
  const [payRate, setPayRate] = useState('');
  const [listOfInstructors, setListOfInstructors] = useState('');
  const [loggedIn, setLoggedIn] = useState('');
  const [loggedInUser, setLoggedInUser] = useState('');

  const initialValues = {
    sessionTime: '',
    sessionDate: null,
    payRate: 0,
  };
  //

  useEffect(() => {
    const usr = localStorage.getItem('id');
    const userName = localStorage.getItem('currentInsName');
    setLoggedIn(usr);
    setLoggedInUser(userName);
    console.log(usr);
    handlePayRate();
  }, []); // Include 'id' in the dependency array

  const onNavigate = (value) => {
    const usr = localStorage.getItem('id');
    setLoggedIn(usr);
    localStorage.setItem('id', usr);
    const userName = localStorage.getItem('currentInsName');
    setLoggedInUser(userName);
    localStorage.setItem('currentInsName', userName);
    console.log(userName);
    console.log(value.id);
  };
  const validationSchema = Yup.object().shape({
    sessionTime: Yup.string().required(' required'),
    sessionDate: Yup.date().required('required'),
  });
  const onSubmit = async (data, { resetForm }) => {
    try {
      // Include the instructor's ID directly in the data
      data.instructorUsername = loggedIn; // Assuming loggedIn is the instructor's ID
      console.log(data.instructorUsername);
      data.isRunnning = 0;
      console.log(data.isRunnning);

      // Make the POST request with the updated data
      const response = await fetch(
        'https://csmserver.csmdrivingschool.ca/InstructorSchedule.php',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        }
      );

      // Handle the response as before
      if (response.ok) {
        const result = await response.json();
        if (result.success) {
          alert('Data saved');
          console.log(response);
          resetForm();
        } else {
          throw new Error(result.error || 'Failed to save data');
        }
      } else {
        const errorText = await response.text(); // Get response text for debugging
        console.error('Response Error Text:', errorText);
        throw new Error(
          `Failed to save data: ${response.status} - ${errorText}`
        );
      }
    } catch (error) {
      console.error('Error while saving the data', error);
      alert(`Error: ${error.message}`);
    }
  };

  //  .post('http://localhost:3001/instructorSchedule', data)
  const handlePayRate = async (values) => {
    try {
      const response = await fetch(
        `https://csmserver.csmdrivingschool.ca/Instructor.php?payRate=${loggedIn}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ payRate: values.payRate }),
        }
      );

      const data = await response.json();
      console.log(data.message); // Log the message from the response
      console.log('Hello');
    } catch (error) {
      console.error('Error updating pay rate:', error);
    }
  };

  const handleLinkClick = () => {
    setModalOpen(!isModalOpen);
  };
  const handleEdit = async () => {
    try {
      const response = await fetch(
        `https://csmserver.csmdrivingschool.ca/Instructor.php?id=${loggedIn}`
      );
      if (response.ok) {
        const data = await response.json();
        console.log('Pay rate fetched successfully:', data.payRate); // Log the pay rate value
        setEditValues({ payRate: data.payRate }); // Assuming response.data contains the payRate
      } else {
        throw new Error('Failed to fetch pay rate');
      }
    } catch (error) {
      console.error('Error fetching editValues:', error);
    }
  };

  //
  const schedulesByDate = {};
  listOfSchedules.forEach((value) => {
    const sessionDate = new Date(value.sessionDate);
    if (isNaN(sessionDate.getTime())) {
      return;
    }
    const formattedDate = sessionDate.toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
      weekday: 'short',
    });
    if (!schedulesByDate[formattedDate]) {
      schedulesByDate[formattedDate] = [];
    }

    const isAccepted =
      Array.isArray(value.requests) &&
      value.requests.some((request) => request.isAccepted);
    schedulesByDate[formattedDate].push({
      id: value.id,
      time: value.sessionTime,
      isAcc: isAccepted,
    });
  });

  //
  const handleCurrentSchedule = (value, isAcptd, sDate, sTime) => {
    setSelectedSchedule(value);
    setSelectedUserDate(sDate);
    setSelectedUserTime(sTime);
    setSelectedButton(value);
    console.log(sDate);
    const isConfirmed = window.confirm(
      'Are you sure you want to delete this schedule?'
    );
    if (isConfirmed) {
      onDelete(value); // Call the delete function passing the schedule id
    }
  };
  //
  const showSchedule = async () => {
    console.log('hello');
    try {
      const response = await fetch(
        `https://csmserver.csmdrivingschool.ca/InstructorSchedule.php?insId=${loggedIn}&type=sessions`
      );
      if (response.ok) {
        const data = await response.json();
        setListOfSchedules(data);
        setSelectedInstructor(loggedIn);
      } else {
        throw new Error('Failed to fetch schedules');
      }
    } catch (error) {
      console.error('Error fetching disabled dates:', error);
      alert('Unable to fetch schedules');
    }
  };

  //
  const handleSelectSchedule = (id) => {
    setSelectedSchedule((prevSelectedSchedule) => {
      const isSelected = prevSelectedSchedule.includes(id);

      if (isSelected) {
        return prevSelectedSchedule.filter((scheduleId) => scheduleId !== id);
      } else {
        return [...prevSelectedSchedule, id];
      }
    });

    setCurrentClickedId(id);
    console.log('Current clicked ID:', id); // Log the ID here
  };

  const hideModalScreen = () => { };

  const onDelete = async () => {
    console.log('Selected Schedules:', selectedSchedule); // Log selected schedules

    if (selectedSchedule.length === 0) {
      alert('Please select at least one schedule to delete.');
      return;
    }

    const confirmation = window.confirm(
      'Are you sure you want to delete the selected schedules?'
    );

    if (!confirmation) return;

    for (const currentClickedId of selectedSchedule) {
      try {
        console.log('Deleting schedule with ID:', currentClickedId); // Log ID being deleted

        const response = await fetch(
          `https://csmserver.csmdrivingschool.ca/InstructorSchedule.php?id=${currentClickedId}`,
          {
            method: 'DELETE',
          }
        );

        if (response.ok) {
          console.log('Schedule deleted with ID:', currentClickedId);
          setListOfSchedules((prevSchedules) =>
            prevSchedules.filter((item) => item.id !== currentClickedId)
          );
        } else {
          const errorText = await response.text(); // Get error message from response
          console.error('Failed to delete schedule:', errorText);
          throw new Error('Failed to delete schedule');
        }
      } catch (error) {
        console.error('Error deleting schedule:', error);
      }
    }
  };

  //
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://csmserver.csmdrivingschool.ca/InstructorSchedule.php?id=${loggedIn}`
        );
        if (response.ok) {
          const data = await response.json();
          setListOfInstructors(data); // Assuming response.data contains the pay rate data
          console.log('Pay rates fetched successfully:', data);
        } else {
          throw new Error('Failed to fetch pay rates');
        }
      } catch (error) {
        console.error('Error fetching pay rates:', error);
        // Handle error if needed
      }
    };

    fetchData();
  }, []);

  const showModalScreen = (msgId) => {
    setCurrentClickedId(msgId);
  };
  return (
    <div className="main">
      <div className="homeContainer">
        <div
          class="modal fade"
          id="detailModal"
          tabindex="-1"
          aria-labelledby="detailModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog scheduleModal">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="detailModalLabel">
                  Schedule
                </h5>

                <button
                  type="button"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  style={{ border: 'none', backgroundColor: 'white' }}
                >
                  <i class="bi bi-x-lg text-black" style={{ fontSize: 20 }}></i>
                </button>
              </div>
              <div class="modal-body">
                {editValues && (
                  <Formik
                    initialValues={editValues}
                    validationSchema={validationSchema}
                    enableReinitialize={true}
                    onSubmit={onSubmit}
                  >
                    {({ isSubmitting }) => (
                      <Form>
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="sessionDate">
                            Session Date
                          </label>
                          <Field name="sessionDate" autoComplete="off">
                            {({ field, form, meta }) => (
                              <div>
                                <DatePicker
                                  id="sessionDate"
                                  className="form-control date-picker-input sessionDate"
                                  {...field}
                                  placeholderText="Select Date"
                                  selected={field.value}
                                  onChange={(sessionDate) => {
                                    // Ensure sessionDate is a valid Date object
                                    if (
                                      sessionDate instanceof Date &&
                                      !isNaN(sessionDate)
                                    ) {
                                      // Set the field value directly
                                      form.setFieldValue(
                                        field.name,
                                        sessionDate
                                      );
                                    }
                                  }}
                                />
                              </div>
                            )}
                          </Field>
                        </div>
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="sessionTime">
                            Session Time
                          </label>
                          <Field
                            type="text"
                            name="sessionTime"
                            id="sessionTime"
                            placeholder="12:00 PM"
                            class="form-control date-picker-input sessionDate"
                          />
                        </div>

                        <button type="submit" class="btn btn-primary">
                          Submit
                        </button>
                      </Form>
                    )}
                  </Formik>
                )}
                <div className="border-top mt-5">
                  <div className="d-flex justify-content-between">
                    <h4 className="mt-5">My Schedule List</h4>
                    <button
                      className="btn btn-danger mt-5"
                      style={{ height: 50 }}
                      onClick={onDelete}
                    >
                      Delete
                    </button>
                  </div>
                  <h5 className="pb-2">"Select Schedule to delete"</h5>
                  <div className="d-flex justify-content-between">
                    <div className="">
                      {Object.entries(schedulesByDate).map(
                        ([date, timeSlots]) => {
                          const [monthDay, dayOfWeek] = date.split(', ');
                          return (
                            <div key={date} className="d-flex">
                              <div className="date">
                                <h5>{dayOfWeek}</h5>
                                <h6>{monthDay}</h6>
                              </div>
                              <div className="timeSlots d-flex">
                                {timeSlots.map(({ id, time, isAcc }, index) => {
                                  console.log('Current time slot ID:', id);
                                  return (
                                    <button
                                      key={index}
                                      className="timeSlot px-2"
                                      onClick={() => handleSelectSchedule(id)}
                                      style={{
                                        opacity: isAcc ? 0.6 : 1,
                                        backgroundColor:
                                          selectedSchedule &&
                                            selectedSchedule.includes(id)
                                            ? '#fcb48e'
                                            : 'initial',
                                      }}
                                    >
                                      {time}
                                    </button>
                                  );
                                })}
                              </div>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* pay modal */}
        <div
          className="modal fade"
          id="payModal"
          tabIndex="-1"
          aria-labelledby="payModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modalPay">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="payModalLabel">
                  Pay Rate
                </h5>
                <button
                  type="button"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  style={{ border: 'none', backgroundColor: 'white' }}
                >
                  <i class="bi bi-x-lg text-black" style={{ fontSize: 20 }}></i>
                </button>
              </div>
              <div className="modal-body">
                {Array.isArray(listOfInstructors) &&
                  listOfInstructors.map((values) => (
                    <div className="form-group fieldStyle" key={values.id}>
                      <label htmlFor="payRate">Your current Pay Rate</label>
                      <br />
                      <b name="payRate" className="text-danger">
                        {values.payRate}{' '}
                        {/* Access the payRate property here */}
                      </b>
                    </div>
                  ))}

                {editValues && (
                  <Formik
                    initialValues={editValues || { payRate: '' }} // Initialize with default values or
                    enableReinitialize={true}
                    onSubmit={(values, actions) => {
                      console.log('Form submitted:', values);
                      handlePayRate(values, userName);
                    }}
                  >
                    {({ values, setFieldValue, isSubmitting }) => (
                      <Form>
                        <div className="form-group fieldStyle">
                          <label htmlFor="payRate">Update your Pay Rate</label>
                          <Field
                            type="text"
                            id="payRate"
                            name="payRate"
                            value={values.payRate}
                            onChange={(e) => {
                              const newValue = e.target.value;
                              setFieldValue('payRate', newValue); // Update payRate field value
                            }}
                          />
                        </div>
                        <div className="modal-footer">
                          <button type="submit" className="btn btn-primary">
                            Update
                          </button>
                          <button
                            className="btn btn-primary"
                            data-bs-dismiss="modal"
                          >
                            Close
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                )}
              </div>
            </div>
          </div>
        </div>
        {/*  */}
        <div
          class="untree_co-hero inner-page container-fluid"
          // style={{ backgroundImage: `url('/images/home.jpg')` }}
          style={{ height: 100 }}
        >
          <div className="container-fluid">
            <div class="row align-items-center justify-content-center">
              <div class="col-12">
                <div class="row justify-content-center ">
                  <div class="col-lg-9 text-center ">
                    <h1
                      class="heading"
                      data-aos="fade-up"
                      data-aos-delay="100"
                      style={{ marginTop: 50, color: '#ec661f', fontSize: 35 }}
                    >
                      <u>{loggedInUser}</u>, Welcome To Instructor Panel
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*  */}
        <div className="untree_co-section">
          <div className="container-fluid">
            <div className="row d-flex justify-content-center align-items-stretch instructorPanel">
              <div
                className="col-sm-6 col-md-6 col-lg-5 my-4"
                data-aos="fade-up"
                data-aos-delay="0"
              >
                <Link
                  to={ROUTES.studentRequest.name}
                  className="category d-flex align-items-start h-100"
                  onClick={onNavigate}
                >
                  <div className="instructorIcon">
                    <i class="bi bi-people-fill"></i>
                  </div>
                  <div>
                    <h3 style={{ fontSize: 26 }}>Student's Requests</h3>
                  </div>
                </Link>
              </div>
              <div
                className="col-sm-6 col-md-6 col-lg-5 my-4"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <Link
                  className={`category d-flex align-items-start h-100  ${isModalOpen ? 'modal-open' : ''
                    }`}
                  data-bs-toggle="modal"
                  data-bs-target="#detailModal"
                  //onClick={showSchedule}
                  onClick={() => (handleLinkClick(), showSchedule(loggedIn))}
                >
                  <div className="instructorIcon">
                    <i className="bi bi-calendar-check"></i>
                  </div>
                  <div>
                    <h3 style={{ fontSize: 26 }}> Schedule</h3>
                  </div>
                  {isModalOpen && (
                    <div
                      className={`modal fade ${isModalOpen ? 'show' : ''}`}
                      id="detailModal"
                      tabIndex="-1"
                      aria-labelledby="exampleModalLabel"
                      aria-hidden={!isModalOpen}
                    ></div>
                  )}
                </Link>
              </div>
              <div
                className="col-sm-6 col-md-6 col-lg-5 mb-4"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <Link
                  to={ROUTES.package.name}
                  className="category d-flex align-items-start h-100"
                >
                  <div className="instructorIcon">
                    <i class="bi bi-book-half"></i>
                  </div>
                  <div>
                    <h3 style={{ fontSize: 26 }}>Lesson Sheets</h3>
                    {/* <img
                      src="../images/gs.jpg"
                      alt="Image"
                      class="img-fluid rounded mt-5"
                    /> */}
                  </div>
                </Link>
              </div>
              <div
                className="col-sm-6 col-md-6 col-lg-5 mb-4"
                data-aos="fade-up"
                data-aos-delay="300"
              >
                <Link
                  to={ROUTES.package.name}
                  className="category d-flex align-items-start h-100"
                >
                  <div className="instructorIcon">
                    <i class="bi bi-credit-card-2-front"></i>
                  </div>
                  <div className="instructorIcon">
                    <h3 style={{ fontSize: 26 }}>Payment</h3>
                  </div>
                </Link>
              </div>
              <div
                className="col-sm-6 col-md-6 col-lg-5 mb-4"
                data-aos="fade-up"
                data-aos-delay="300"
              >
                <Link
                  to={ROUTES.package.name}
                  className="category d-flex align-items-start h-100"
                >
                  <div className="instructorIcon">
                    <i class="bi bi-bookmark-check-fill"></i>
                  </div>
                  <div className="instructorIcon">
                    <h3 style={{ fontSize: 26 }}>Sessions</h3>
                  </div>
                </Link>
              </div>
              <div
                className="col-sm-6 col-md-6 col-lg-5 mb-4"
                data-aos="fade-up"
                data-aos-delay="300"
              >
                <Link
                  to={ROUTES.package.name}
                  className="category d-flex align-items-start h-100"
                >
                  <div className="instructorIcon">
                    <i class="bi bi-journal-album"></i>
                  </div>
                  <div className="instructorIcon">
                    <h3 style={{ fontSize: 26 }}>Courses</h3>
                  </div>
                </Link>
              </div>

              <div
                className="col-sm-6 col-md-6 col-lg-5 mb-4"
                data-aos="fade-up"
                data-aos-delay="300"
              >
                <Link
                  data-bs-toggle="modal"
                  data-bs-target="#payModal"
                  onClick={() => (handleEdit(userName), handleLinkClick())}
                  className={`category d-flex align-items-start h-100 ${isModalOpen ? 'modal-open' : ''
                    }`}
                >
                  <div className="instructorIcon">
                    <i className="bi bi-currency-dollar"></i>
                  </div>
                  <div className="instructorText">
                    <h3 style={{ fontSize: 26 }}>Pay Rate</h3>
                  </div>
                  {isModalOpen && (
                    <div
                      className={`modal fade ${isModalOpen ? 'show' : ''}`}
                      id="payModal"
                      tabIndex="-1"
                      aria-labelledby="payModalLabel"
                      aria-hidden={!isModalOpen}
                    ></div>
                  )}
                </Link>
              </div>
            </div>
          </div>
        </div>
        {/*  */}
      </div>
    </div>
  );
}

export default InstructorPanel;
