import React from 'react';
import ROUTES from '../../navigations/Routes';
import { Link } from 'react-router-dom';
function About() {
  return (
    <div>
      <div
        class="untree_co-hero"
        style={{ backgroundImage: `url('/images/about.jpg')` }}
      >
        <div class="container-fluid">
          <div class="row align-items-center justify-content-center">
            <div class="col-12">
              <div class="row justify-content-center ">
                <div class="col-lg-6 text-center ">
                  <h1
                    class="mb-4 heading text-white"
                    data-aos="fade-up"
                    data-aos-delay="100"
                  >
                    About Us
                  </h1>
                  <div
                    class="mb-5 text-white desc mx-auto"
                    data-aos="fade-up"
                    data-aos-delay="200"
                  ></div>
                  <p class="mb-0" data-aos="fade-up" data-aos-delay="300">
                    <Link to={ROUTES.package.name} class="btn btn-secondary">
                      Explore Packages
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid about">
        <div data-aos="fade-up" data-aos-delay="200">
          <div class="col-lg-12 my-5 d-flex align-items-center justify-content-center ">
            <h2>About Us</h2>
          </div>
          <div className="justify-content-center mb-5 p">
            <p>
              Learn to Drive with CSM , with qualified driving instructors
              provinces-wide, CSM instructors are trained to the highest
              standards, go through frequent background checks and drive new
              vehicles maintained to the highest standards. At CSM Driving
              School, we like to ensure that our students get the best quality
              road training during their driving sessions. Their safety on the
              road is of utmost importance to us. CSM Driving School offers
              Advance Driving Program along with the standard online BDE Course
              and also varitiy of the driving packages.
            </p>
          </div>
        </div>
      </div>
      <div class="untree_co-section">
        <div class="container-fluid">
          <div class="row">
            <div
              class="col-lg-5 mr-auto mb-5 mb-lg-0"
              data-aos="fade-up"
              data-aos-delay="0"
            >
              <img src="images/mirror.jpg" alt="image" class="img-fluid" />
            </div>
            <div
              class="col-lg-7 ml-auto"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <h3 class="line-bottom mb-4">Why Choose Us</h3>
              <p>
                Selecting the right driving school is a crucial decision on the
                journey to becoming a safe and skilled driver. At CSM Driving
                School, we believe our commitment to excellence sets us apart.{' '}
              </p>
              <div class="custom-accordion" id="accordion_1">
                <div class="accordion-item">
                  <h2 class="mb-0">
                    <button
                      class="btn btn-link"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      Focus on Safety
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    class="collapse show"
                    aria-labelledby="headingOne"
                    data-parent="#accordion_1"
                  >
                    <div class="accordion-body">
                      <div class="d-flex">
                        <div class="accordion-img mr-4">
                          <img
                            src="images/home.jpg"
                            alt="Image"
                            class="img-fluid"
                          />
                        </div>
                        <div>
                          <p>
                            At CSM Driving School, safety is not just a part of
                            our curriculum; it's our guiding principle. We
                            instill a deep respect for safety and responsibility
                            in every aspect of our instruction.{' '}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="mb-0">
                    <button
                      class="btn btn-link collapsed"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      Quality Instruction
                    </button>
                  </h2>
                  <div
                    id="collapseTwo"
                    class="collapse"
                    aria-labelledby="headingTwo"
                    data-parent="#accordion_1"
                  >
                    <div class="accordion-body">
                      <div class="d-flex">
                        <div class="accordion-img mr-4">
                          <img
                            src="images/instructor.jpg"
                            alt="Image"
                            class="img-fluid"
                          />
                        </div>
                        <div>
                          <p>
                            Our driving school is staffed by a team of highly
                            experienced and certified instructors who are
                            dedicated to your success. Our instructors have a
                            proven track record of helping students become safe
                            and confident drivers.{' '}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* .accordion-item --> */}
                <div class="accordion-item">
                  <h2 class="mb-0">
                    <button
                      class="btn btn-link collapsed"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      Supportive Environment
                    </button>
                  </h2>
                  <div
                    id="collapseThree"
                    class="collapse"
                    aria-labelledby="headingThree"
                    data-parent="#accordion_1"
                  >
                    <div class="accordion-body">
                      <div class="d-flex">
                        <div class="accordion-img mr-4">
                          <img
                            src="images/home.jpg"
                            alt="Image"
                            class="img-fluid"
                          />
                        </div>
                        <div>
                          <p>
                            Our instructors are not just educators but mentors
                            who create a friendly and approachable atmosphere.
                            Students can feel comfortable asking questions,
                            seeking guidance, and sharing any concerns they may
                            have.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="services-section bg-white">
        <div class="container-fluid">
          <div class="row justify-content-between">
            <div class="col-lg-4 mb-5 mb-lg-0">
              <div
                class="section-title mb-3"
                data-aos="fade-up"
                data-aos-delay="0"
              >
                <h2 class="line-bottom mb-4">Become an Instructor</h2>
              </div>
              <p data-aos="fade-up" data-aos-delay="100">
                Are you passionate about road safety and helping others become
                confident drivers? If so, we invite you to consider a rewarding
                career as a driving instructor with our esteemed driving school.
                At CSM Driving School, we're always looking for dedicated
                professionals who are committed to making a difference in the
                lives of aspiring drivers.
              </p>
              <p data-aos="fade-up" data-aos-delay="300">
                <Link to={ROUTES.instructor.name} class="btn btn-primary">
                  Get Started
                </Link>
              </p>
            </div>
            <div class="col-lg-6" data-aos="fade-up" data-aos-delay="0">
              <figure class="img-wrap-2">
                <img
                  src="images/instructor.jpg"
                  alt="Image"
                  class="img-fluid"
                />
                <div class="dotted"></div>
              </figure>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default About;
