import logo from './logo.svg';
import './App.css';
import './css/style.css';
// import './css/bootstrap.min.css';
import './css/flaticon/font/flaticon.css';
import './css/icomoon/style.css';

import Navigation from './navigations/Navigation';

function App() {
  return (
    <div>
      <Navigation></Navigation>
    </div>
  );
}
export default App;
