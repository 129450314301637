import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';

function OfflineStudentRegistration() {
    const location = useLocation();
    const navigate = useNavigate();

    const { instructorId, pricing, gearBoxName, hours, date, time, scheduleId } = location.state || {};

    const initialValues = {
        userName: '',
        email: '',
        dob: null,
        postalCode: '',
        phoneNo: '',
        pickupAddress: '',
        billingAddress: '',
        emailActive: false,
        smsActive: false,
        callActive: false,
        postActive: false,
        studentType: 'Offline',

        instructorId: instructorId,
        selectedPrice: pricing,
        selectedGear: gearBoxName,
        selectedHours: hours,


    };

    const validationSchema = Yup.object({
        userName: Yup.string().required('Username is required'),
        email: Yup.string().email('Invalid email format').required('Email is required'),
        dob: Yup.date().required('Date of Birth is required'),
        postalCode: Yup.string().required('Postal Code is required'),
        phoneNo: Yup.string().required('Phone Number is required'),
        pickupAddress: Yup.string().required('Pickup Address is required'),
        billingAddress: Yup.string().required('Billing Address is required'),
    });

    const onSubmit = async (values) => {
        try {
            // Submit to the first API
            const response = await fetch('https://csmserver.csmdrivingschool.ca/StudentRegister.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(values), // First API call
            });

            if (response.ok) {

                const newId = await fetch('https://csmserver.csmdrivingschool.ca/GetNewStudentId.php', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                // Parse the response as JSON
                const data = await newId.json();

                // Assuming your PHP API returns a JSON object with the ID as `max_id`
                const generatedId = data.max_id; // Adjust according to your API's actual response format

                console.log('Registration Successful, ID:', generatedId);




                alert('Successfully Registered');

                // Log all the variables to make sure they're defined

                // Verify the JSON data to be sent
                const secondApiData = {
                    studentRegisterId: generatedId,
                    instructorId: instructorId,
                    selectedPrice: pricing,
                    selectedGear: gearBoxName,
                    selectedHours: hours,
                    scheduleId: scheduleId
                };
                // Log the data before sending
                // Now use the generated ID for the second API call
                const secondApiResponse = await fetch('https://csmserver.csmdrivingschool.ca/OfflineStudentRequest.php', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(secondApiData), // Second API call
                });

                if (secondApiResponse.ok) {
                    console.log(`Data added to the second table successfully ${generatedId}`);

                } else {
                    throw new Error('Failed to add data to the second table');
                }
                navigate('/StudentRegisterSummary', {
                    state: {
                        regId: generatedId,
                    },
                });
            } else {
                throw new Error('First API network response was not ok.');
            }
        } catch (error) {
            console.error('Error while submitting', error);
        }
    };


    return (
        <div className="main bg-light">
            <div className="homeContainer pb-5">
                <div className="container-fluid">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-12">
                            <div className="row justify-content-center">
                                <div className="col-lg-6 text-center">
                                    <div className="mb-5 text-white desc mx-auto" data-aos="fade-up" data-aos-delay="200"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="bookOnlineBox5">
                        <div className="HalfOne">
                            <img src="images/car22.jpg" alt="Car" />
                        </div>
                        <div className="HalfTwo">
                            <div className="innerHalf">

                                {/* <div className="container mt-5">
                                    
                                    <h3>Instructor ID: {instructorId}</h3>
                                    <h3>Pricing: {pricing}</h3>
                                    <h3>Gear Box: {gearBoxName}</h3>
                                    <h3>Hours: {hours}</h3>
                                    <h3>Selected Date: {date}</h3>
                                    <h3>Selected Time: {time}</h3>
                                </div> */}

                                <div className="instructor-info text-center">
                                    <h3 className="mb-4 text-center">Now we just need the student's details</h3>
                                    <div className="container-fluid">

                                        {/* Formik Form */}
                                        <Formik
                                            initialValues={initialValues}
                                            onSubmit={onSubmit}
                                            validationSchema={validationSchema}
                                        >
                                            <Form>
                                                <div className="row ">
                                                    {/* Username Field */}
                                                    <div className="col-md-12">
                                                        <div className="formGroup">
                                                            <div className="mant">

                                                                <ErrorMessage name="userName" component="span" style={{ color: 'red' }} />
                                                            </div>
                                                            <Field
                                                                type="text"
                                                                className="formControl border-1 "
                                                                id="userName"
                                                                placeholder="Your Name"
                                                                name="userName"
                                                                autoComplete="off"
                                                            />
                                                        </div>
                                                    </div>
                                                    {/* Email Field */}
                                                    <div className="col-md-12">
                                                        <div className="formGroup">
                                                            <div className="mant">
                                                                <ErrorMessage name="email" component="span" style={{ color: 'red' }} />
                                                            </div>
                                                            <Field
                                                                type="email"
                                                                className="formControl border-1 "
                                                                id="email"
                                                                placeholder="Your Email"
                                                                name="email"
                                                                autoComplete="off"
                                                            />
                                                        </div>
                                                    </div>
                                                    {/* Date of Birth Field */}
                                                    <div className="col-md-6">
                                                        <div className="formGroup">
                                                            <div className="mant">
                                                                <ErrorMessage name="dob" component="span" style={{ color: 'red' }} />
                                                            </div>
                                                            <Field
                                                                type="date"
                                                                className="formControl border-1 "
                                                                id="dob"
                                                                placeholder="Date of Birth"
                                                                name="dob"
                                                                autoComplete="off"
                                                            />
                                                        </div>
                                                    </div>
                                                    {/* Postal Code Field */}
                                                    <div className="col-md-6">
                                                        <div className="formGroup">
                                                            <div className="mant">
                                                                <ErrorMessage name="postalCode" component="span" style={{ color: 'red' }} />
                                                            </div>
                                                            <Field
                                                                type="text"
                                                                className="formControl border-1 "
                                                                id="postalCode"
                                                                placeholder="Postal Code"
                                                                name="postalCode"
                                                                autoComplete="off"
                                                            />
                                                        </div>
                                                    </div>
                                                    {/* Phone Number Field */}
                                                    <div className="col-md-12">
                                                        <div className="formGroup">
                                                            <div className="mant">
                                                                <ErrorMessage name="phoneNo" component="span" style={{ color: 'red' }} />
                                                            </div>
                                                            <Field
                                                                type="text"
                                                                className="formControl border-1 "
                                                                id="phoneNo"
                                                                placeholder="Phone Number"
                                                                name="phoneNo"
                                                                autoComplete="off"
                                                            />
                                                        </div>
                                                    </div>
                                                    {/* Pickup Address Field */}
                                                    <div className="col-md-12">
                                                        <div className="formGroup">
                                                            <div className="mant">
                                                                <ErrorMessage name="pickupAddress" component="span" style={{ color: 'red' }} />
                                                            </div>
                                                            <Field
                                                                type="text"
                                                                className="formControl border-1 "
                                                                id="pickupAddress"
                                                                placeholder="Pickup Address"
                                                                name="pickupAddress"
                                                                autoComplete="off"
                                                            />
                                                        </div>
                                                    </div>
                                                    {/* Billing Address Field */}
                                                    <div className="col-md-12">
                                                        <div className="formGroup">
                                                            <div className="mant">
                                                                <ErrorMessage name="billingAddress" component="span" style={{ color: 'red' }} />
                                                            </div>
                                                            <Field
                                                                type="text"
                                                                className="formControl border-1 "
                                                                id="billingAddress"
                                                                placeholder="Billing Address"
                                                                name="billingAddress"
                                                                autoComplete="off"

                                                            />
                                                        </div>
                                                    </div>


                                                    <div className="d-flex w-100 g-3 mt-3">
                                                        <div className="col-md-3">
                                                            <div className="form-check">
                                                                <Field
                                                                    type="checkbox"
                                                                    className="form-check-input"
                                                                    id="emailActive"
                                                                    name="emailActive"
                                                                />
                                                                <label htmlFor="emailActive" className="form-check-label">Email Active</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="form-check">
                                                                <Field
                                                                    type="checkbox"
                                                                    className="form-check-input"
                                                                    id="smsActive"
                                                                    name="smsActive"
                                                                />
                                                                <label htmlFor="smsActive" className="form-check-label">SMS Active</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="form-check">
                                                                <Field
                                                                    type="checkbox"
                                                                    className="form-check-input"
                                                                    id="callActive"
                                                                    name="callActive"
                                                                />
                                                                <label htmlFor="callActive" className="form-check-label">Call Active</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="form-check">
                                                                <Field
                                                                    type="checkbox"
                                                                    className="form-check-input"
                                                                    id="postActive"
                                                                    name="postActive"
                                                                />
                                                                <label htmlFor="postActive" className="form-check-label">Post Active</label>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12">
                                                        <div className="formGroup">

                                                            <Field
                                                                type="hidden"
                                                                id="studentType"
                                                                name="studentType"
                                                                value="offline"

                                                            />
                                                        </div>
                                                    </div>
                                                    {/* Submit Button */}
                                                    <div className="col-12 text-center">
                                                        <button type="submit" className="btn btn-primary mt-4">Continue</button>
                                                    </div>
                                                </div>
                                            </Form>
                                        </Formik>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default OfflineStudentRegistration;
