import React from 'react';

import { Link } from 'react-router-dom';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import axios from 'axios';
import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
function InstructorForm() {
  const navigate = useNavigate();
  const [instructorPackage, setInstructorPackage] = useState([]);
  const [selectedPackagePrice, setSelectedPackagePrice] = useState(null);
  const [listOfStates, setListOfStates] = useState([]);
  let { id } = useParams();
  const [listOfItems, setListOfItems] = useState([]);
  const [location, setLocation] = useState([]);
  const [prerequisitesChecked, setPrerequisitesChecked] = useState(false);
  const [banned, setBanned] = useState('no');
  const [formDisabled, setFormDisabled] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const initialValues = {
    firstName: '',
    lastName: '',
    address: '',
    addressLine2: '',
    city: '',
    province: '',
    email: '',
    confirmEmail: '',
    phoneNumber: '',
    licenseNumber: '',
    licenseType: '',
    postalCode: '',
    penaltyPoints: '',
    banned: '',
    licenseImage: '',
    educationalCertificate: '',
    courseRegistration: '',
    courseType: '',
    trainingLocation: '',
    price: '',
    paymentMethod: '',
    totalAmount: '',
    conditions: '',
  };
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('First Name is required!'),
    lastName: Yup.string().required('Last Name is required!'),
    address: Yup.string().required('address is required!'),
    addressLine2: Yup.string().required('address is required!'),
    city: Yup.string().required('province is required!'),
    province: Yup.string().required('city is required!'),
    email: Yup.string().required('Email is required'),
    confirmEmail: Yup.string()
      .oneOf([Yup.ref('email'), null], 'Email must match')
      .required('Required'),
    phoneNumber: Yup.number().required('Required!!'),
    licenseImage: Yup.string().required('Required!!'),
    licenseNumber: Yup.string().required('Required!!'),
    licenseType: Yup.string().required('Required!!'),
    postalCode: Yup.string().required('Required!!'),
    penaltyPoints: Yup.string().required('Required!!'),
    banned: Yup.string().required('Required!!'),
    educationalCertificate: Yup.string().required('Required!!'),
    courseType: Yup.string().required('Required!!'),
    virtualClasses: Yup.boolean().required('Required!!'),
    prerequisites: Yup.boolean().required('Required!!'), // Make sure it's checked
    trainingLocation: Yup.string().required('Required!!'),
    price: Yup.string().required('Required!!'),
    paymentMethod: Yup.string().required('Required!!'),
    conditions: Yup.boolean().required('Required!!'),
  });
  const onSubmit = async (data, { setSubmitting }) => {
    try {
      if (parseInt(data.penaltyPoints) > 3) {
        alert("You're not eligible for this.");
        return; // Prevent form submission
      }
      const formData = new FormData();
      formData.append('licenseImage', data.licenseImage);
      formData.append('firstName', data.firstName);
      formData.append('lastName', data.lastName);
      formData.append('address', data.address);
      formData.append('addressLine2', data.addressLine2);
      formData.append('city', data.city);
      formData.append('province', data.province);
      formData.append('email', data.email);
      formData.append('confirmEmail', data.confirmEmail);
      formData.append('phoneNumber', data.phoneNumber);
      formData.append('licenseNumber', data.licenseNumber);
      formData.append('licenseType', data.licenseType);
      formData.append('postalCode', data.postalCode);
      formData.append('penaltyPoints', data.penaltyPoints);
      formData.append('banned', data.banned);
      formData.append('educationalCertificate', data.educationalCertificate);
      formData.append('courseRegistration', data.courseRegistration);
      formData.append('courseType', data.courseType);
      formData.append('virtualClasses', data.virtualClasses);
      formData.append('prerequisites', data.prerequisites);
      formData.append('trainingLocation', data.trainingLocation);
      formData.append('price', data.price);
      formData.append('paymentMethod', data.paymentMethod);
      formData.append('totalAmount', data.totalAmount);
      formData.append('conditions', data.conditions);
      console.log(data, 'data');
      await axios.post('https://localhost:3001/insRegistration', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      alert('data');
      console.log(data);
    } catch (error) {
      console.error(error);
    } finally {
      setSubmitting(false);
    }
  };
  const handlePackageSelection = (price) => {
    setSelectedPackagePrice(price);
  };

  //
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch training location data
        const trainingLocationResponse = await fetch(
          'https://csmserver.csmdrivingschool.ca/TrainingLocation.php'
        );
        if (trainingLocationResponse.ok) {
          const trainingLocationData = await trainingLocationResponse.json();
          setLocation(trainingLocationData);
        } else {
          throw new Error('Network response was not ok.');
        }
        // Fetch courseCampus data
        const campusResponse = await fetch(
          'https://csmserver.csmdrivingschool.ca/CourseCampus.php'
        );
        if (campusResponse.ok) {
          const campusData = await campusResponse.json();
          setListOfItems(campusData);
        } else {
          throw new Error('Network response was not ok.');
        }

        // Fetch states data
        const statesResponse = await fetch(
          `https://csmserver.csmdrivingschool.ca/CanadaStates.php?unlock=true`
        );
        if (statesResponse.ok) {
          const statesData = await statesResponse.json();
          if (statesData.length > 0) {
            setListOfStates(statesData);
          } else {
            console.log('No unlocked locations found.');
          }
        } else {
          throw new Error('Network response was not ok.');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();

    // Cleanup function if needed
    return () => {
      // cleanup code if necessary
    };
  }, [refresh]);

  const [penaltyPoints, setPenaltyPoints] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleInputChange = (event) => {
    const points = event.target.value;
    setPenaltyPoints(points);
    setErrorMessage('');
    // Check if points is greater than '3'
    if (points && parseInt(points) > 3) {
      setErrorMessage("You're not eligible for this.");
    } else {
      setErrorMessage('');
    }
  };
  //
  const handleRadioChange = (event) => {
    const value = event.target.value;
    setBanned(value);

    if (value === 'yes') {
      // Display alert message
      alert("You're not eligible for this.");
      // Disable the form
      setFormDisabled(true);
    } else {
      // Enable the form if previously disabled
      setFormDisabled(false);
    }
  };

  return (
    <div className="main">
      <div className="homeContainer">
        {/*  */}
        <div class="aboutInstructor">
          <div data-aos="fade-up" data-aos-delay="200">
            <div
              className="col-lg-12 d-flex align-items-center justify-content-center mb-5"
              style={{ marginTop: 50 }}
            >
              <h2>Apply Now</h2>
            </div>
          </div>
          {/*  */}
          <div class="untree_co-section instructorForm">
            <div class="">
              <div class="row justify-content-center">
                <div
                  class="col-10 order-1"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <Formik
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    validationSchema={validationSchema}
                    enableDevTools={true}
                    enableReinitialize={true}
                  >
                    {({ isSubmitting, values, setFieldValue }) => (
                      <Form action="#" class="form-box">
                        <h3>Name*</h3>
                        <div class="row">
                          <div class="col-lg-6 col-sm-12 mb-3">
                            <Field
                              type="text"
                              class="form-control"
                              placeholder="First Name"
                              name="firstName"
                              id="Rname"
                            />
                            <ErrorMessage
                              name="firstName"
                              component="span"
                              style={{ color: 'red' }}
                            ></ErrorMessage>
                          </div>
                          <div class="col-lg-6 col-sm-12 mb-3">
                            <Field
                              type="text"
                              class="form-control"
                              placeholder="Last Name"
                              name="lastName"
                              id="lname"
                            />
                            <ErrorMessage
                              name="lastName"
                              component="span"
                              style={{ color: 'red' }}
                            ></ErrorMessage>
                          </div>
                        </div>
                        <h3>Address*</h3>
                        <div className="row">
                          <div class="col-lg-6 col-sm-12 mb-3">
                            <Field
                              type="text"
                              className="form-control"
                              id="address"
                              placeholder="Address"
                              autoComplete="off"
                              name="address"
                            />
                            <ErrorMessage
                              name="address"
                              component="span"
                              style={{ color: 'red' }}
                            ></ErrorMessage>
                          </div>

                          <div class="col-lg-6 col-sm-12 mb-3">
                            <Field
                              type="text"
                              className="form-control"
                              id="addressLine2"
                              placeholder="Address Line2"
                              autoComplete="off"
                              name="addressLine2"
                            />
                            <ErrorMessage
                              name="addressLine2"
                              component="span"
                              style={{ color: 'red' }}
                            ></ErrorMessage>
                          </div>
                          <div class="col-lg-6 col-sm-12 mb-3">
                            <Field
                              type="text"
                              className="form-control"
                              id="city"
                              placeholder="City"
                              autoComplete="off"
                              name="city"
                            />
                            <ErrorMessage
                              name="city"
                              component="span"
                              style={{ color: 'red' }}
                            ></ErrorMessage>
                          </div>
                          <div class="col-lg-6 col-sm-12 mb-3">
                            {listOfStates.length > 0 ? (
                              <Field
                                as="select"
                                name="province"
                                id="province"
                                placeholder="Select Location from here"
                                className="custom-select"
                              >
                                <option>Select Location from here</option>
                                {listOfStates.map((location) => (
                                  <option
                                    key={location.id}
                                    value={location.stateName}
                                  >
                                    {location.stateName}
                                  </option>
                                ))}
                              </Field>
                            ) : (
                              <p>
                                No unlocked locations found or data is not as
                                expected.
                              </p>
                            )}

                            <ErrorMessage
                              name="province"
                              component="span"
                              style={{ color: 'red' }}
                            ></ErrorMessage>
                          </div>
                          <div class="col-lg-6 col-sm-12 mb-3">
                            <Field
                              class="form-control"
                              placeholder="Postal Code"
                              name="postalCode"
                              id="postalCode"
                            />
                            <ErrorMessage
                              name="postalCode"
                              component="span"
                              style={{ color: 'red' }}
                            ></ErrorMessage>
                          </div>
                        </div>

                        <div className="row  ">
                          <div class="col-lg-6 col-sm-12 mb-3">
                            <h3>Driver's License Number*</h3>
                            <Field
                              type="text"
                              class="form-control"
                              placeholder="License Number"
                              name="licenseNumber"
                              id="licenseNumber"
                            />
                            <ErrorMessage
                              name="licenseNumber"
                              component="span"
                              style={{ color: 'red' }}
                            ></ErrorMessage>
                          </div>
                          <div class="col-lg-6 col-sm-12 mb-3">
                            <h3> Phone Number*</h3>
                            <Field
                              type="text"
                              class="form-control"
                              placeholder="Phone Number"
                              name="phoneNumber"
                              id="phoneNumber"
                            />
                            <p className="text-justify">
                              Kindly let us know the best way to reach you on
                              your cell phone. Please be aware that we might
                              text you at this number to arrange for the
                              course's in-car segment or to send you reminders.
                              You agree to receive informational texts by
                              providing your phone number.
                            </p>
                            <ErrorMessage
                              name="phoneNumber"
                              component="span"
                              style={{ color: 'red' }}
                            ></ErrorMessage>
                          </div>
                        </div>
                        <h3>Email*</h3>
                        <div className="row">
                          <div class="col-lg-6 col-sm-12 mb-3">
                            <Field
                              type="text"
                              class="form-control"
                              placeholder="Email"
                              name="email"
                              id="Remail"
                            />
                            <ErrorMessage
                              name="email"
                              component="span"
                              style={{ color: 'red' }}
                            ></ErrorMessage>
                          </div>
                          <div class="col-lg-6 col-sm-12 mb-3">
                            <Field
                              type="text"
                              class="form-control"
                              placeholder=" Confirm Email"
                              name="confirmEmail"
                              id="confirmEmail"
                            />
                            <ErrorMessage
                              name="confirmEmail"
                              component="span"
                              style={{ color: 'red' }}
                            ></ErrorMessage>
                          </div>
                        </div>
                        <h3>License*</h3>
                        <div className="row">
                          <div class="col-12 mb-3">
                            <Field
                              name="licenseType"
                              id="licenseType"
                              as="select"
                              type="text"
                              class="custom-select"
                            >
                              <option value="">License Type</option>
                              <option value="3orMoreYearLicense">
                                Full Ontario (CA) for 3 or more years
                              </option>
                              <option value="3orlessyearslicense">
                                {' '}
                                Full Ontario (CA) for 3 or less years
                              </option>
                            </Field>
                            <ErrorMessage
                              name="licenseType"
                              component="span"
                              style={{ color: 'red' }}
                            ></ErrorMessage>
                          </div>

                          <div className="col-12 mb-3">
                            <Field
                              id="penaltyPoints"
                              name="penaltyPoints"
                              className="form-control"
                              placeholder="How many penalty points?"
                              type="text"
                              disabled={formDisabled} // Disable the field if form is disabled
                            />
                            <ErrorMessage
                              name="penaltyPoints"
                              component="span"
                              style={{ color: 'red' }}
                            ></ErrorMessage>
                          </div>

                          <div class="col-12">
                            <div>
                              <h3 className="mx-2">
                                Have you been banned from driving in the last
                                four years?
                              </h3>
                            </div>

                            <div className="row">
                              <Field
                                name="find"
                                className="d-flex mx-2"
                                render={({ field }) => (
                                  <div className="row">
                                    <div className="radio-item ml-4">
                                      <input
                                        {...field}
                                        id="yes"
                                        value="yes"
                                        checked={field.value === 'yes'}
                                        type="radio"
                                        className="mx-2"
                                      />
                                      <label htmlFor="yes">Yes</label>
                                    </div>
                                    <div className="radio-item">
                                      <input
                                        {...field}
                                        id="no"
                                        value="no"
                                        checked={field.value === 'no'}
                                        type="radio"
                                        className="mx-2"
                                      />
                                      <label htmlFor="no">No</label>
                                    </div>
                                  </div>
                                )}
                              />
                            </div>
                            {formDisabled && (
                              <span style={{ color: 'red' }}>
                                You're not eligible for this.
                              </span>
                            )}
                          </div>
                        </div>
                        <h3 className="mt-4">Driver's License Image</h3>
                        <p style={{ fontSize: 18 }}>
                          Please upload a picture or a scan of the front of your
                          driver's license
                        </p>

                        <div class="col-12 mb-3">
                          <label for="licenseImage" class="custom-file-upload">
                            <input
                              type="file"
                              id="licenseImage"
                              onChange={(event) => {
                                setFieldValue(
                                  'licenseImage',
                                  event.target.files[0]
                                );
                              }}
                              name="licenseImage"
                              class="input-File"
                            />
                            Choose File
                          </label>

                          <ErrorMessage
                            name="licenseImage"
                            component="span"
                            style={{ color: 'red' }}
                          ></ErrorMessage>
                        </div>
                        <div>
                          <h3 className="mt-5">Educational Certificate*</h3>
                          <p>
                            Please upload a photo or a scan of your diploma from
                            Ontario Secondary School or a translated, officially
                            recognized diploma from another nation.
                          </p>

                          <div class="col-12 mb-3">
                            <Field
                              class="form-control"
                              name="educationalCertificate"
                              id="educationalCertificate"
                              render={({ field }) => (
                                <div className="">
                                  <div className="radio-item">
                                    <input
                                      {...field}
                                      id="haveDiploma"
                                      value="haveDiploma"
                                      checked={field.value === 'haveDiploma'}
                                      type="radio"
                                      className="mx-2"
                                    />
                                    <label htmlFor="haveDiploma">
                                      I will upload Diploma.
                                    </label>
                                  </div>
                                  <div className="radio-item">
                                    <input
                                      {...field}
                                      id="dontHaveDiploma"
                                      value="dontHaveDiploma"
                                      checked={
                                        field.value === 'dontHaveDiploma'
                                      }
                                      type="radio"
                                      className="mx-2"
                                    />
                                    <label htmlFor="dontHaveDiploma">
                                      {' '}
                                      I do not have Diploma and require the
                                      equivalency test
                                    </label>
                                  </div>
                                </div>
                              )}
                            />
                            <ErrorMessage
                              name="educationalCertificate"
                              component="span"
                              style={{ color: 'red' }}
                            ></ErrorMessage>
                          </div>
                        </div>
                        <div>
                          <h3>Course Registration*</h3>
                          <p>
                            Please indicate if you want to take the classroom
                            instructor course or the in-car course, or if you
                            hold an instructor license and want to take the
                            course.
                          </p>
                          <div class="col-12 mb-3">
                            <Field
                              name="courseRegistration"
                              id="courseRegistration"
                              render={({ field }) => (
                                <div className="">
                                  <div className="radio-item">
                                    <input
                                      {...field}
                                      id="inCar"
                                      value="inCar"
                                      checked={field.value === 'inCar'}
                                      type="radio"
                                      className="mx-2"
                                    />
                                    <label htmlFor="inCar">
                                      In-Car Course (most common)
                                    </label>
                                  </div>
                                  <div className="radio-item">
                                    <input
                                      {...field}
                                      id="inClass"
                                      value="inClass"
                                      checked={field.value === 'inClass'}
                                      type="radio"
                                      className="mx-2"
                                    />
                                    <label htmlFor="inClass">
                                      {' '}
                                      In-Class course (already have a driving
                                      instructor's license)
                                    </label>
                                  </div>
                                </div>
                              )}
                            />
                            <ErrorMessage
                              name="courseRegistration"
                              component="span"
                              style={{ color: 'red' }}
                            ></ErrorMessage>
                          </div>
                        </div>
                        <div className="row">
                          {listOfItems.map((value, index) => (
                            <div key={index} className="px-2">
                              <h3>{value.heading}</h3>
                              <p className="text-justify">
                                {value.description}
                              </p>
                              {index === 0 && ( // Render dropdown only after the first iteration
                                <div className="col-12 mb-3">
                                  <Field
                                    className="form-control"
                                    placeholder="courseType"
                                    name="courseType"
                                    id="courseType"
                                    as="select" // Render as a select dropdown
                                  >
                                    <option value="">Select Course Type</option>
                                    <option value="In-Person">In-Person</option>
                                    <option value="Virtual Classes">
                                      Virtual (Zoom)
                                    </option>
                                  </Field>
                                  <ErrorMessage
                                    name="courseType"
                                    component="span"
                                    style={{ color: 'red' }}
                                  />
                                </div>
                              )}
                              {index === 1 && ( // Render radio buttons only after the second iteration
                                <div className="col-12 mb-3 mx-2">
                                  <Field
                                    className="form-check-input"
                                    type="checkbox"
                                    id="virtualClasses"
                                    name="virtualClasses"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="virtualClasses"
                                  >
                                    I agree to the PDIO Virtual Classroom
                                    Policies
                                  </label>
                                </div>
                              )}
                            </div>
                          ))}

                          <ErrorMessage
                            name="virtualClasses"
                            component="span"
                            style={{ color: 'red' }}
                          ></ErrorMessage>
                        </div>
                        <h3>Prerequisites*</h3>
                        <p>
                          Hold a valid "G" driver's license from Ontario and
                          four years of continuous driving experience.
                          <br />
                          Have a clean driving record with no demerit points.
                          <br />
                          Fulfill the MTO's (Ministry of Transportation) vision
                          requirements.
                          <br />
                          Pass the G1 knowledge exam (after two failed tries,
                          there is a one-year waiting time).
                          <br />
                          Obtain a Class G road (highway) license (after two
                          failed tries, there is a one-year waiting time).
                          <br />
                          Complete a vulnerable sector check at a police station
                          [don’t forget 2 pieces of ID for the police station]
                          <br />
                          Have a secondary school diploma from Ontario, or an
                          equivalent.
                        </p>
                        <div className="row">
                          <div className="col-12 mb-3 mx-3">
                            <Field
                              className="form-check-input"
                              type="checkbox"
                              id="prerequisites"
                              name="prerequisites"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="prerequisites"
                            >
                              I have read the above requirements, and will
                              fulfill them prior to the course start date
                            </label>
                            <ErrorMessage
                              name="prerequisites"
                              component="span"
                              style={{ color: 'red' }}
                            ></ErrorMessage>
                          </div>

                          {/* <div class="col-12 mb-3">
                            <Field
                              class="form-control"
                              placeholder="prerequisites"
                              name="prerequisites"
                              id="prerequisites"
                            />
                            <ErrorMessage
                              name="prerequisites"
                              component="span"
                              style={{ color: 'red' }}
                            ></ErrorMessage>
                          </div> */}
                          <div class="col-12 mb-3 mt-3">
                            <h3>In-Car Training Location*</h3>
                            {location.map((value, index) => (
                              <div key={index} className="mx-3">
                                <Field
                                  type="radio"
                                  id="trainingLocation"
                                  name="trainingLocation"
                                  value={value.location}
                                  className="form-check-input "
                                />
                                <label
                                  htmlFor="trainingLocation"
                                  className="form-check-label"
                                >
                                  {value.location}
                                </label>
                              </div>
                            ))}

                            <ErrorMessage
                              name="trainingLocation"
                              component="span"
                              style={{ color: 'red' }}
                            ></ErrorMessage>
                          </div>

                          <div class="col-12 mb-3">
                            <h3>Payment</h3>
                            <p className="text-justify">
                              On the first day of class, we presently accept
                              eTransfer payments for tuition. The cost of
                              tuition for the in-car and in-class instructor
                              course is $2800 (HST Exempt). A $500 deposit is
                              required along with your completed registration
                              form in order to reserve your spot. Until we
                              receive both a completed registration and a
                              deposit, no seats will be reserved. Please be
                              aware that eTransfer, cash, or check payments for
                              the remaining tuition are accepted at our (
                              location ) Campus on the first day of classes. The
                              office is able to arrange credit card payments;
                              however, there is a processing fee of 3%.
                            </p>

                            <h3>Deposit Payment</h3>
                            <Field
                              class="form-control"
                              placeholder="Price"
                              name="price"
                              id="price"
                            />
                            <ErrorMessage
                              name="price"
                              component="span"
                              style={{ color: 'red' }}
                            ></ErrorMessage>
                          </div>
                          <div class="col-12 mb-3">
                            <h3>Payment Method:</h3>
                            <p>
                              Please select how you would like to pay for your
                              deposit
                            </p>
                            <Field name="paymentMethod">
                              {({ field, form }) => (
                                <div>
                                  <div className="radio-item">
                                    <input
                                      {...field}
                                      id="creditCard"
                                      value="Credit Card Online"
                                      checked={
                                        field.value === 'Credit Card Online'
                                      }
                                      type="radio"
                                      className="mx-2"
                                      onChange={() =>
                                        form.setFieldValue(
                                          'paymentMethod',
                                          'Credit Card Online'
                                        )
                                      }
                                    />
                                    <label htmlFor="creditCard">
                                      Credit Card Online
                                    </label>
                                  </div>
                                  <div className="radio-item">
                                    <input
                                      {...field}
                                      id="etransfer"
                                      value="etransfer"
                                      checked={field.value === 'etransfer'}
                                      type="radio"
                                      className="mx-2"
                                      onChange={() =>
                                        form.setFieldValue(
                                          'paymentMethod',
                                          'etransfer'
                                        )
                                      }
                                    />
                                    <label htmlFor="etransfer">
                                      E Transfer
                                    </label>
                                  </div>
                                  <div className="radio-item">
                                    <input
                                      {...field}
                                      id="inPerson"
                                      value="In Person: Cash/Cheque/Debit"
                                      checked={
                                        field.value ===
                                        'In Person: Cash/Cheque/Debit'
                                      }
                                      type="radio"
                                      className="mx-2"
                                      onChange={() =>
                                        form.setFieldValue(
                                          'paymentMethod',
                                          'In Person: Cash/Cheque/Debit'
                                        )
                                      }
                                    />
                                    <label htmlFor="inPerson">
                                      In Person: Cash/Cheque/Debit
                                    </label>
                                  </div>
                                </div>
                              )}
                            </Field>

                            <ErrorMessage
                              name="paymentMethod"
                              component="span"
                              style={{ color: 'red' }}
                            ></ErrorMessage>
                            <div class="col-12 mb-3 mt-4">
                              <h3>Total Amount</h3>
                              <Field
                                class="form-control"
                                placeholder="totalAmount"
                                name="totalAmount"
                                id="totalAmount"
                              />
                              <ErrorMessage
                                name="totalAmount"
                                component="span"
                                style={{ color: 'red' }}
                              ></ErrorMessage>
                            </div>
                          </div>
                          <div class="col-12 my-4">
                            <label class="control control--checkbox">
                              <span class="caption">
                                Accept our <a href="#">terms and conditions</a>
                              </span>
                              <Field type="checkbox" name="conditions" />
                              <ErrorMessage
                                name="conditions"
                                component="span"
                                style={{ color: 'red' }}
                              ></ErrorMessage>
                              <div class="control__indicator"></div>
                            </label>
                          </div>
                          <div class="col-12">
                            <button
                              type="submit"
                              class="btn btn-primary"
                              disabled={formDisabled}
                            >
                              Apply
                            </button>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InstructorForm;
