import React from 'react';
import { Link, useParams } from 'react-router-dom';
import ROUTES from '../../navigations/Routes';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import axios from 'axios';
import * as Yup from 'yup';

function RegisterForm() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');

  const initialValues = {
    firstName: '',
    lastName: '',
    userName: '',
    email: '',
    password: '',
    confirmPassword: '',
    phoneNumber: '',
    licenseType: '',
    postalCode: '',
    penaltyPoints: '',
    banned: '',
    conditions: '',
    gType: '',
  };
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('First Name is required!'),
    lastName: Yup.string().required('Last Name is required!'),
    userName: Yup.string().required('UserName is required!'),
    email: Yup.string().required('Email is required'),
    phoneNumber: Yup.number().required('Required!!'),
    postalCode: Yup.number().required('Required'),
    licenseType: Yup.string().required('Required!!'),
    gType: Yup.string().required('Required!!'),
    postalCode: Yup.number().required('Required!!'),
    banned: Yup.string().required('Required!!'),
    password: Yup.string().min(5).required('Required!!'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('Required'),
  });
  function onSubmit(data, { resetForm }) {
    try {
      console.log('data', data);
      axios
        .post('http://csmserver.csmdrivingschool.ca/Instructor.php', data, {
        // .post('http://localhost:3001/Instructor', data, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then((res) => {
          alert('Successfully Registered');
          resetForm();
          navigate('/instructorLogin');
        });
    } catch (error) {
      alert('Error while submitting the form');
      console.error('Error!!!!', error);
    }
  }
  return (
    <div className="main">
      <div className="homeContainer">
        <div class="untree_co-section">
          <div class="container-fluid">
            <div class="row justify-content-center">
              <div
                class="col-lg-7 order-1"
                data-aos="fade-up"
                data-aos-delay="200"
                style={{ marginTop: 120 }}
              >
                <Formik
                  initialValues={initialValues}
                  onSubmit={onSubmit}
                  validationSchema={validationSchema}
                >
                  <Form action="#" class="form-box">
                    <div class="row">
                      <div class="col-6 mb-3">
                        <Field
                          type="text"
                          class="form-control"
                          placeholder="First Name"
                          name="firstName"
                          id="Rname"
                        />
                        <ErrorMessage
                          name="firstName"
                          component="span"
                          style={{ color: 'red' }}
                        ></ErrorMessage>
                      </div>
                      <div class="col-6 mb-3">
                        <Field
                          type="text"
                          class="form-control"
                          placeholder="Last Name"
                          name="lastName"
                          id="lname"
                        />
                        <ErrorMessage
                          name="lastName"
                          component="span"
                          style={{ color: 'red' }}
                        ></ErrorMessage>
                      </div>
                      <div class="col-12 mb-3">
                        <Field
                          type="text"
                          class="form-control"
                          placeholder="Email"
                          name="email"
                          id="Remail"
                        />
                        <ErrorMessage
                          name="email"
                          component="span"
                          style={{ color: 'red' }}
                        ></ErrorMessage>
                      </div>
                      <div class="col-12 mb-3">
                        <Field
                          type="text"
                          className="form-control"
                          id="userName"
                          placeholder="User Name"
                          autoComplete="off"
                          name="userName"
                        />
                        <ErrorMessage
                          name="userName"
                          component="span"
                          style={{ color: 'red' }}
                        ></ErrorMessage>
                      </div>

                      <div class="col-6 mb-3">
                        <Field
                          type="password"
                          className="form-control"
                          id="password"
                          placeholder="Password"
                          autoComplete="off"
                          name="password"
                        />
                        <ErrorMessage
                          name="password"
                          component="span"
                          style={{ color: 'red' }}
                        ></ErrorMessage>
                      </div>
                      <div class="col-6 mb-3">
                        <Field
                          type="password"
                          className="form-control"
                          id="confirmPassword"
                          placeholder="Confirm Password"
                          autoComplete="off"
                          name="confirmPassword"
                        />
                        <ErrorMessage
                          name="confirmPassword"
                          component="span"
                          style={{ color: 'red' }}
                        ></ErrorMessage>
                      </div>
                      <div class="col-12 mb-3">
                        <Field
                          type="text"
                          class="form-control"
                          placeholder="Phone Number"
                          name="phoneNumber"
                          id="phoneNumber"
                        />
                      </div>
                      <ErrorMessage
                        name="phoneNumber"
                        component="span"
                        style={{ color: 'red' }}
                      ></ErrorMessage>
                      <div class="col-12 mb-3">
                        <Field
                          name="licenseType"
                          id="licenseType"
                          as="select"
                          type="text"
                          class="custom-select"
                        >
                          <option value="">License Type</option>
                          <option value="3orMoreYearLicense">
                            Full Ontario (CA) for 3 or more years
                          </option>
                          <option value="3orlessyearslicense">
                            {' '}
                            Full Ontario (CA) for 3 or less years
                          </option>
                        </Field>
                        <ErrorMessage
                          name="licenseType"
                          component="span"
                          style={{ color: 'red' }}
                        ></ErrorMessage>
                      </div>
                      <div class="col-12 mb-3">
                        <Field
                          class="form-control"
                          placeholder="Postal Code"
                          name="postalCode"
                          id="postalCode"
                        />
                        <ErrorMessage
                          name="postalCode"
                          component="span"
                          style={{ color: 'red' }}
                        ></ErrorMessage>
                      </div>
                      <div class="col-12 mb-3">
                        <Field
                          class="form-control"
                          placeholder="How many penalty points?"
                          name="penaltyPoints"
                          id="penaltyPoints"
                        />
                        <ErrorMessage
                          name="penaltyPoints"
                          component="span"
                          style={{ color: 'red' }}
                        ></ErrorMessage>
                      </div>
                      <div class="col-12 mb-3">
                        <Field
                          name="gType"
                          id="gType"
                          as="select"
                          type="text"
                          class="custom-select"
                        >
                          <option value="">Gear Box Type</option>
                          <option value="both">
                          Both
                          </option>
                          <option value="manual">
                          Manual
                          </option>
                          <option value="automatic">
                            {' '}
                            Automatic
                          </option>
                        </Field>
                        <ErrorMessage
                          name="gType"
                          component="span"
                          style={{ color: 'red' }}
                        ></ErrorMessage>
                      </div>
                      <div class="col-12">
                        <div>
                          <label className="mx-2">
                            Have you been banned from driving in the last four
                            years?
                          </label>
                        </div>
                        <Field
                          name="banned"
                          className="d-flex"
                          render={({ field }) => (
                            <div className="row">
                              <div className="radio-item mx-4">
                                <input
                                  {...field}
                                  id="yes"
                                  value="yes"
                                  checked={field.value === 'yes'}
                                  type="radio"
                                  className="mx-2"
                                />
                                <label htmlFor="yes">Yes</label>
                              </div>
                              <div className="radio-item">
                                <input
                                  {...field}
                                  id="no"
                                  value="no"
                                  checked={field.value === 'no'}
                                  type="radio"
                                  className="mx-2"
                                />
                                <label htmlFor="no">No</label>
                              </div>
                            </div>
                          )}
                        />
                      </div>
                      <div class="col-5 my-4">
                        <label class="control control--checkbox">
                          <span class="caption">
                            Accept our <a href="#">terms and conditions</a>
                          </span>
                          <Field type="checkbox" name="conditions" />
                          <div class="control__indicator"></div>
                        </label>
                      </div>
                      <div class="col-12">
                        <button type="submit" class="btn btn-primary">
                          Apply
                        </button>
                      </div>
                    </div>
                  </Form>
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RegisterForm;
