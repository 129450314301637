import React from 'react';
import { useState, useEffect } from 'react';
function BookOnline() {
  const [listOfInstructors, setListOfInstructors] = useState([]);
  const [listOfSchedules, setListOfSchedules] = useState([]);
  const [progress, setProgress] = useState(20);
  const [editValues, setEditValues] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedUserDate, setSelectedUserDate] = useState('');
  const [selectedUserTime, setSelectedUserTime] = useState('');
  const [selectedInstructor, setSelectedInstructor] = useState(null);
  const [selectedButton, setSelectedButton] = useState(null);
  const [selectedSchedule, setSelectedSchedule] = useState(null);
  const [schedulesByDate, setSchedulesByDate] = useState({});

  const [loggedIn, setLoggedIn] = useState('');
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };
  const filteredData = listOfInstructors.filter((item) => {
    const values = Object.values(item).map((value) =>
      String(value).toLowerCase()
    );
    return values.some((value) => value.includes(searchTerm.toLowerCase()));
  });
  const showSchedule = async (currentInstUsername) => {
    console.log('hello');
    try {
      const response = await fetch(
        'https://csmserver.csmdrivingschool.ca/InstructorSchedule.php'
      );
      if (response.ok) {
        const data = await response.json();
        console.log('Fetched data:', data); // Log the fetched data for debugging
        if (Array.isArray(data)) {
          setListOfSchedules(data);
          processSchedules(data); // Process the schedules once fetched
        } else {
          console.error('Fetched data is not an array:', data);
        }
        setSelectedInstructor(currentInstUsername);
        //console.log(data);
      } else {
        throw new Error('Failed to fetch schedules');
      }
    } catch (error) {
      console.error('Error fetching schedules:', error);
    }
  };

  const processSchedules = (schedules) => {
    const processedSchedules = {};
    schedules.forEach((value) => {
      const sessionDate = new Date(value.sessionDate);
      if (isNaN(sessionDate.getTime())) {
        return;
      }
      const formattedDate = sessionDate.toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
        weekday: 'short',
      });
      if (!processedSchedules[formattedDate]) {
        processedSchedules[formattedDate] = [];
      }

      // Check if 'requests' property exists and is an array
      const isAccepted =
        Array.isArray(value.requests) &&
        value.requests.some((request) => request.isAccepted);

      processedSchedules[formattedDate].push({
        id: value.id,
        time: value.sessionTime,
        isAcc: isAccepted,
      });
    });
    setSchedulesByDate(processedSchedules); // Update state with processed schedules
  };

  useEffect(() => {
    if (selectedInstructor) {
      showSchedule(selectedInstructor);
      console.log('hello');
    }
  }, [selectedInstructor]);

  useEffect(() => {
    const fetchInstructors = async () => {
      try {
        const response = await fetch(
          'https://csmserver.csmdrivingschool.ca/Instructor.php'
        );
        if (response.ok) {
          const data = await response.json();
          console.log('Fetched instructors:', data); // Log the fetched data for debugging
          if (Array.isArray(data)) {
            setListOfInstructors(data);
          } else {
            console.error('Fetched data is not an array:', data);
          }
        } else {
          throw new Error('Failed to fetch instructors');
        }
      } catch (error) {
        console.error('Error fetching instructors:', error);
      }
    };

    fetchInstructors();
  }, []);
  const handleCurrentSchedule = (value, isAcptd, sDate, sTime) => {
    if (isAcptd) {
      alert('This schedule is already booked');
    } else {
      setSelectedSchedule(value); // Ensure 'value' is a valid schedule ID
      setSelectedUserDate(sDate);
      setSelectedUserTime(sTime);
      setSelectedButton(value);
      console.log('Selected Schedule:', value); // Log selected schedule ID
      console.log('Selected Date:', sDate); // Log selected date
      console.log('Selected Time:', sTime); // Log selected time
    }
  };

  const submitRequest = async () => {
    const data = {
      studentUserName: loggedIn,
      instructorUserName: selectedInstructor,
      scheduleId: selectedSchedule,
      isAccepted: false,
      isRejected: false,
      isInstructorNotif: true,
      isStudentNotif: false,
    };

    console.log('Submitting data:', data); // Check the data being sent

    try {
      const response = await fetch(
        'https://csmserver.csmdrivingschool.ca/StudentRequest.php',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        }
      );

      if (response.ok) {
        alert('Successfully Registered');
        console.log('Data saved successfully:', data);
      } else {
        throw new Error('Failed to save data');
      }
    } catch (error) {
      console.error('Error while submitting:', error);
    }
  };
  return (
    <div className="main">
      <div className="homeContainer">
        <div
          class="modal fade"
          id="detailModal"
          tabindex="-1"
          aria-labelledby="detailModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog scheduleModal">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="detailModalLabel">
                  Schedule
                </h5>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  style={{
                    backgroundColor: 'transparent',
                    border: 'none',
                    fontSize: 20,
                  }}
                >
                  <i class="fa-solid fa-xmark"></i>
                </button>
              </div>
              <div class="modal-body">
                <div className="d-flex justify-content-between">
                  <div className="">
                    {Object.entries(schedulesByDate).map(
                      ([date, timeSlots]) => {
                        const [monthDay, dayOfWeek] = date.split(', ');
                        return (
                          <div key={date} className="d-flex">
                            <div className="date">
                              <h5>{dayOfWeek}</h5>
                              <h6>{monthDay}</h6>
                            </div>
                            <div className="timeSlots d-flex">
                              {timeSlots.map(
                                (
                                  { id, time, isAcc },
                                  index // Destructure id and time from the object
                                ) => (
                                  //  isAcc == '0' && (
                                  <button
                                    key={index}
                                    className="timeSlot px-2"
                                    onClick={() =>
                                      handleCurrentSchedule(
                                        id,
                                        isAcc,
                                        date,
                                        time
                                      )
                                    }
                                    style={{
                                      opacity: isAcc ? 0.6 : 1,
                                      backgroundColor:
                                        selectedButton === id
                                          ? '#fcb48e'
                                          : '#fff',
                                    }}
                                  >
                                    {time}
                                  </button>
                                )
                                //)
                              )}
                            </div>
                          </div>
                        );
                      }
                    )}
                  </div>
                  <div
                    className="right"
                    // id="rightArea"
                    style={{
                      backgroundColor: '#C7C8CC',
                      height: 150,
                      width: 200,
                      padding: 10,
                    }}
                  >
                    <h5>You have selected</h5>
                    <p>Date: {selectedUserDate} </p>
                    <p>Time: {selectedUserTime}</p>
                  </div>
                </div>

                <div class="modal-footer">
                  <button
                    class="btn btn-primary"
                    data-bs-dismiss="modal"
                    onClick={() => submitRequest()}
                  >
                    Add Now
                  </button>
                  <button class="btn btn-primary" data-bs-dismiss="modal">
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*  */}
        <div class="untree_co-section container-fluid instructorList">
          <div class="container-fluid">
            <div className="d-flex justify-content-center">
              <h2>List Of Instructors</h2>
            </div>
            <div class="row ">
              <div class="col-12  " data-aos="fade-up" data-aos-delay="0">
                {' '}
                {/* search bar */}
                <input
                  className=" form-control col-3 my-4 mx-3"
                  type="text"
                  placeholder="Search Instructor by Postal Code"
                  value={searchTerm}
                  onChange={handleSearch}
                  style={{ height: 40 }}
                />
                <table class="table table-hover table-striped">
                  <thead className="bg-dark text-white">
                    <tr>
                      <th className="pl-5">First Name</th>
                      <th>Last Name</th>
                      <th>Pay Rate</th>
                      <th>Schedule</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredData.map((value, key) => {
                      return (
                        <tr key={key}>
                          <td className="pl-5">{value.firstName}</td>
                          <td>{value.lastName}</td>
                          <td>${value.payRate}/hr</td>

                          <td
                            data-bs-toggle="modal"
                            data-bs-target="#detailModal"
                            onClick={() => (
                              setSelectedInstructor(value.id),
                              showSchedule(value.userName)
                            )}
                          >
                            <i className="bi bi-calendar-check"></i>
                          </td>
                          <td></td>
                          {/* <td>
                            <button
                              className="btn btn-primary"
                              onClick={() => (
                                
                                submitRequest(value.userName)
                              )}
                            >
                              Send Request
                            </button>
                          </td> */}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BookOnline;
