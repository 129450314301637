import React from 'react';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
function InstructorAdmin() {
  const [listOfInstructors, setListOfInstructors] = useState([]);
  const [addValues, setAddValues] = useState('');
  const [currentClickedId, setCurrentClickedId] = useState('');
  const [editValues, setEditValues] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const initialValues = {
    firstName: '',
    lastName: '',
    userName: '',
    email: '',
    phoneNumber: '',
    licenseType: '',
    postalCode: '',
    penaltyPoints: '',
    banned: '',
    conditions: '',
    payRate: '',
  };
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
    userName: Yup.string().required('Last Name is required'),
    email: Yup.string().required('Email is required!'),
    phoneNumber: Yup.number().required('Phone Number is required'),
    licenseType: Yup.string().required('This field is required'),
    postalCode: Yup.number().required('Postal Code is required!'),
    penaltyPoints: Yup.number().required('This is required field'),
    banned: Yup.string().required('This is required field!'),
    conditions: Yup.string().required(),
  });
  const handleSubmit = (value) => {
    setAddValues(value);
  };
  const handleEdit = (value) => {
    setEditValues(value);
  };
  const showModalScreen = (msgId) => {
    setCurrentClickedId(msgId);
  };
  const hideModalScreen = () => {};
  const onDelete = () => {
    console.log('id', currentClickedId);
    axios
      .delete(`http://csmserver.csmdrivingschool.ca/Instructor.php/${currentClickedId}`)
      .then((response) => {
        setListOfInstructors(
          listOfInstructors.filter(function (item) {
            return item.id !== currentClickedId;
          })
        );
      });
    toast.success('Item deleted successfully', {
      position: 'bottom-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      style: {
        background: '#272829',
        color: 'white',
      },
    });
    hideModalScreen();
  };
  useEffect(() => {
    axios.get('http://csmserver.csmdrivingschool.ca/Instructor.php').then((response) => {
    // axios.get('http://localhost:3001/Instructor').then((response) => {
      setListOfInstructors(response.data);
    });
  }, []);
  //filtering data
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };
  const filteredData = listOfInstructors.filter((item) => {
    const values = Object.values(item).map((value) =>
      String(value).toLowerCase()
    );
    return values.some((value) => value.includes(searchTerm.toLowerCase()));
  });
  const onSubmit = async (data, { resetForm, setSubmitting }) => {
    try {
      console.log('data', data);
      await axios
        // .post('http://localhost:3001/Instructor', data, {})
        .post('http://csmserver.csmdrivingschool.ca/Instructor.php', data, {})
        .then((res) => {
          alert('data saved');
          console.log('it worked', res);
          resetForm();
        });
    } catch (error) {
      alert('Failed to save data. Please try again.');
      console.error('Error while submitting', error);
    }
  };
  const onUpdate = async (data, { resetForm }) => {
    try {
      console.log('data', data);
      // await axios.put('http://localhost:3001/Instructor', data).then((res) => {
      await axios.put('http://csmserver.csmdrivingschool.ca/Instructor.php', data).then((res) => {
        alert('data updated');
        resetForm();
      });
    } catch (error) {
      console.error('Error while updating the data', error);
    }
  };
  return (
    <div>
      <ToastContainer></ToastContainer>
      <div
        class="modal fade"
        id="deleteModal"
        tabindex="-1"
        aria-labelledby="deleteModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="deleteModalLabel">
                Delete Confirm
              </h5>
              <button
                type="button"
                // class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                style={{ border: 'none', backgroundColor: 'white' }}
              >
                <i class="bi bi-x-lg" style={{ fontSize: 20 }}></i>
              </button>
            </div>
            <div class="modal-body">
              <p>Are you sure you want to delete data?</p>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-primary mx-2"
                data-bs-dismiss="modal"
              >
                No
              </button>
              <button
                type="button"
                class="btn btn-primary"
                onClick={onDelete}
                data-bs-dismiss="modal"
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <h2 class="text-center">
            {' '}
            <i class="bi bi-person-lines-fill"></i> List Of Instructors
          </h2>
          <p class="text-center"> Manage Instructor List here.</p>
        </div>
      </div>
      <div class="row">
        <div
          class="modal fade "
          id="exampleSubmitModal"
          tabindex="-1"
          aria-labelledby="exampleSubmitModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog addModal">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleSubmitModalLabel">
                  Add Item
                </h5>
                <button
                  type="button"
                  aria-label="Close"
                  data-bs-dismiss="modal"
                >
                  <i class="bi bi-x-lg"></i>
                </button>
              </div>
              <div className="modal-body">
                {initialValues && (
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    enableReinitialize={true}
                    onSubmit={onSubmit}
                  >
                    {({ isSubmitting }) => (
                      <Form>
                        <div class="form-group fieldStyle ">
                          <label for="inputName" htmlFor="firstName">
                            First Name
                          </label>
                          <Field
                            type="text"
                            name="firstName"
                            id="firstName"
                            placeholder="First Name"
                            class="form-control"
                          />
                          <ErrorMessage
                            name="firstName"
                            component="span"
                            style={{ color: 'red' }}
                          />
                        </div>
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="lastName">
                            Last Name
                          </label>
                          <Field
                            type="text"
                            name="lastName"
                            id="lastName"
                            placeholder="Last Name"
                            class="form-control"
                          />
                          <ErrorMessage
                            name="lastName"
                            component="span"
                            style={{ color: 'red' }}
                          />
                        </div>
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="userName">
                            User Name
                          </label>
                          <Field
                            type="text"
                            name="userName"
                            id="userName"
                            placeholder="User Name"
                            class="form-control"
                          />
                          <ErrorMessage
                            name="userName"
                            component="span"
                            style={{ color: 'red' }}
                          />
                        </div>
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="email">
                            Email
                          </label>
                          <Field
                            type="text"
                            name="email"
                            id="email"
                            placeholder="Add Email here"
                            class="form-control"
                          />
                          <ErrorMessage
                            name="email"
                            component="span"
                            style={{ color: 'red' }}
                          />
                        </div>
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="phoneNumber">
                            Phone Number
                          </label>
                          <Field
                            type="text"
                            name="phoneNumber"
                            id="phoneNumber"
                            placeholder="Phone Number.."
                            class="form-control"
                          />
                          <ErrorMessage
                            name="phoneNumber"
                            component="span"
                            style={{ color: 'red' }}
                          />
                        </div>
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="licenseType">
                            License Type
                          </label>
                          <Field
                            name="licenseType"
                            id="licenseType"
                            as="select"
                            type="text"
                            class="custom-select"
                          >
                            <option value="">License Type</option>
                            <option value="3orMoreYearLicense">
                              Full Ontario (CA) for 3 or more years
                            </option>
                            <option value="3orlessyearslicense">
                              {' '}
                              Full Ontario (CA) for 3 or less years
                            </option>
                          </Field>
                          <ErrorMessage
                            name="licenseType"
                            component="span"
                            style={{ color: 'red' }}
                          />
                        </div>
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="postalCode">
                            Postal Code
                          </label>
                          <Field
                            type="text"
                            name="postalCode"
                            id="postalCode"
                            placeholder="Postal Code"
                            class="form-control"
                          />
                          <ErrorMessage
                            name="postalCode"
                            component="span"
                            style={{ color: 'red' }}
                          />
                        </div>
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="penaltyPoints">
                            How Many Penalty Points?
                          </label>
                          <Field
                            type="text"
                            name="penaltyPoints"
                            id="penaltyPoints"
                            placeholder="Penalty points"
                            class="form-control"
                          />
                          <ErrorMessage
                            name="penaltyPoints"
                            component="span"
                            style={{ color: 'red' }}
                          />
                        </div>
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="banned">
                            How you been banned before?
                          </label>
                          <Field
                            type="text"
                            name="banned"
                            id="banned"
                            placeholder="...."
                            class="form-control"
                          />
                          <ErrorMessage
                            name="banned"
                            component="span"
                            style={{ color: 'red' }}
                          />
                        </div>

                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="sessionDate">
                            Session Date
                          </label>
                          <Field name="sessionDate" autoComplete="off">
                            {({ field, form, meta }) => (
                              <div>
                                <DatePicker
                                  id="sessionDate"
                                  className="form-control  date-picker-input mx-3 "
                                  {...field}
                                  placeholderText="Session Date"
                                  selected={field.value}
                                  onChange={(sessionDate) => {
                                    const adjustedDate = new Date(
                                      Date.UTC(
                                        sessionDate.getUTCFullYear(),
                                        sessionDate.getUTCMonth(),
                                        sessionDate.getUTCDate()
                                      )
                                    );
                                    form.setFieldValue(
                                      field.name,
                                      adjustedDate
                                    );
                                  }}
                                />
                              </div>
                            )}
                          </Field>
                        </div>
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="sessionTime">
                            Session Time
                          </label>
                          <Field
                            type="text"
                            name="sessionTime"
                            id="sessionTime"
                            placeholder="Session Time"
                            class="form-control"
                          />
                        </div>
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="payRate">
                            Pay Rate
                          </label>
                          <Field
                            type="text"
                            name="payRate"
                            id="payRate"
                            placeholder="Pay Rate"
                            class="form-control"
                          />
                        </div>
                        <button
                          type="submit"
                          class="btn btn-primary"
                          //disabled={isSubmitting}
                        >
                          Submit
                        </button>
                      </Form>
                    )}
                  </Formik>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* detail modal */}
        <div
          class="modal fade"
          id="detailModal"
          tabindex="-1"
          aria-labelledby="detailModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="detailModalLabel">
                  Detail
                </h5>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i class="bi bi-x-circle"></i>
                </button>
              </div>
              <div class="modal-body">
                {editValues && (
                  <div>
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="firstName">
                        First Name
                      </label>
                      <b name="firstName">{editValues.firstName}</b>
                    </div>
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="lastName">
                        Last Name
                      </label>
                      <b name="lastName">{editValues.lastName}</b>
                    </div>
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="userName">
                        User Name
                      </label>
                      <b name="userName">{editValues.userName}</b>
                    </div>
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="email">
                        Email
                      </label>
                      <b name="email">{editValues.email}</b>
                    </div>
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="phoneNumber">
                        Phone Number
                      </label>

                      <b name="phoneNumber">{editValues.phoneNumber}</b>
                    </div>
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="licenseType">
                        License Type
                      </label>
                      <b name="licenseType">{editValues.licenseType}</b>
                    </div>
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="postalCode">
                        Postal Code
                      </label>
                      <b name="postalCode">{editValues.postalCode}</b>
                    </div>
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="penaltyPoints">
                        Penalty Points
                      </label>
                      <b name="penaltyPoints">{editValues.penaltyPoints}</b>
                    </div>
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="banned">
                        Banned?
                      </label>
                      <b name="banned">{editValues.banned}</b>
                    </div>

                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="payRate">
                        Pay Rate
                      </label>
                      <b name="payRate">{editValues.payRate}</b>
                    </div>
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="sessionDate">
                        Session Date
                      </label>
                      <b name="sessionDate">{editValues.sessionDate}</b>
                    </div>
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="sessionTime">
                        Session Time
                      </label>
                      <b name="sessionTime">{editValues.sessionTime}</b>
                    </div>
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="conditions">
                        Conditions
                      </label>
                      <b name="conditions">{editValues.conditions}</b>
                    </div>
                    <div class="modal-footer">
                      <div class="detailImageBoxContainer"></div>
                      <button class="btn btn-primary" data-bs-dismiss="modal">
                        Close
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* edit modal */}
        <div
          class="modal fade"
          id="exampleModal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  Edit Item
                </h5>
                <button
                  type="button"
                  aria-label="Close"
                  data-bs-dismiss="modal"
                >
                  <i class="bi bi-x-lg"></i>
                </button>
              </div>
              <div className="modal-body">
                {editValues && (
                  <Formik
                    initialValues={editValues}
                    enableReinitialize={true}
                    onSubmit={onUpdate}
                  >
                    {({ values, setFieldValue, isSubmitting }) => (
                      <Form>
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="firstName">
                            First Name
                          </label>
                          <Field
                            type="text"
                            name="firstName"
                            id="firstName"
                            placeholder="First Name"
                            class="form-control"
                          />
                        </div>
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="lastName">
                            Last Name
                          </label>
                          <Field
                            type="text"
                            name="lastName"
                            id="lastName"
                            placeholder="Last Name"
                            class="form-control"
                          />
                        </div>
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="userName">
                            User Name
                          </label>
                          <Field
                            type="text"
                            name="userName"
                            id="userName"
                            placeholder="UserName"
                            class="form-control"
                          />
                        </div>
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="email">
                            Email
                          </label>
                          <Field
                            type="text"
                            name="email"
                            id="email"
                            placeholder="Add Email here"
                            class="form-control"
                          />
                        </div>
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="phoneNumber">
                            Phone Number
                          </label>
                          <Field
                            type="text"
                            name="phoneNumber"
                            id="phoneNumber"
                            placeholder="Phone Number.."
                            class="form-control"
                          />
                        </div>
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="licenseType">
                            License Type
                          </label>
                          <Field
                            type="text"
                            name="licenseType"
                            id="licenseType"
                            placeholder="License Type"
                            class="form-control"
                          />
                        </div>
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="postalCode">
                            Postal Code
                          </label>
                          <Field
                            type="text"
                            name="postalCode"
                            id="postalCode"
                            placeholder="Postal Code"
                            class="form-control"
                          />
                        </div>
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="penaltyPoints">
                            How Many Penalty Points?
                          </label>
                          <Field
                            type="text"
                            name="penaltyPoints"
                            id="penaltyPoints"
                            placeholder="Penalty points"
                            class="form-control"
                          />
                        </div>
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="sessionDate">
                            Session Date
                          </label>
                          <Field name="sessionDate" autoComplete="off">
                            {({ field, form, meta }) => (
                              <div placeholder="Session Date">
                                <DatePicker
                                  id="sessionDate"
                                  className="form-control date-picker-input mx-2"
                                  {...field}
                                  placeholderText="Session Date"
                                  selected={field.value}
                                  onChange={(sessionDate) => {
                                    // Ensure the date is set to midnight in UTC
                                    const adjustedDate = new Date(
                                      Date.UTC(
                                        sessionDate.getUTCFullYear(),
                                        sessionDate.getUTCMonth(),
                                        sessionDate.getUTCDate()
                                      )
                                    );
                                    form.setFieldValue(
                                      field.name,
                                      adjustedDate
                                    );
                                  }}
                                />
                              </div>
                            )}
                          </Field>
                        </div>
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="sessionTime">
                            Session Time
                          </label>
                          <Field
                            type="text"
                            name="sessionTime"
                            id="sessionTime"
                            placeholder="Session Time"
                            class="form-control"
                          />
                        </div>
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="banned">
                            How you been banned before?
                          </label>
                          <Field
                            type="text"
                            name="banned"
                            id="banned"
                            placeholder="...."
                            class="form-control"
                          />
                        </div>
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="payRate">
                            Pay Rate
                          </label>
                          <Field
                            type="text"
                            name="payRate"
                            id="payRate"
                            placeholder="Pay Rate"
                            class="form-control"
                          />
                        </div>
                        <div class="modal-footer">
                          <button type="submit" class="btn btn-primary mx-3">
                            Update
                          </button>
                          <button
                            class="btn btn-primary"
                            data-bs-dismiss="modal"
                          >
                            Close
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                )}
              </div>
            </div>
          </div>
        </div>
        {/*  */}
        <div class="col-md-12 mt-5">
          <div className="row">
            <button
              className="btn btn-primary"
              data-bs-toggle="modal"
              data-bs-target="#exampleSubmitModal"
              onClick={() => handleSubmit()}
              type="button"
            >
              Add Instructor
            </button>
            {/* search bar */}
            <input
              className=" form-control col-3 mt-4 mx-3"
              type="text"
              placeholder="Search here"
              value={searchTerm}
              onChange={handleSearch}
              style={{ height: 40 }}
            />
          </div>
          <table class="table table-striped ">
            <thead className="bg-dark text-white">
              <tr>
                <th>First Name</th>
                <th>Last Name</th>
                <th>User Name</th>
                <th>Email</th>
                <th>Phone Number</th>
                <th> License Type</th>
                <th>Postal Code</th>
                <th>Penalty Points</th>
                <th>Banned?</th>
                <th>Pay Rate</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {filteredData.map((value, key, index) => {
                const studentNumber = index + 1;
                return (
                  <tr key={key}>
                    <td> {value.firstName}</td>
                    <td>{value.lastName}</td>
                    <td>{value.userName}</td>
                    <td>{value.email}</td>
                    <td>{value.phoneNumber}</td>
                    <td>{value.licenseType}</td>
                    <td>{value.postalCode}</td>
                    <td>{value.penaltyPoints}</td>
                    <td>{value.banned}</td>
                    <td>{value.payRate}</td>
                    <td>
                      <a class="nav-item dropdown">
                        <a
                          class="fa-solid fa-circle-chevron-down"
                          data-bs-toggle="dropdown"
                          href="#"
                          role="button"
                          aria-haspopup="true"
                          aria-expanded="false"
                        ></a>
                        <div class="dropdown-menu">
                          <Link
                            class="dropdown-item"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                            onClick={() => handleEdit(value)}
                          >
                            Edit
                          </Link>
                          <Link
                            class="dropdown-item"
                            data-bs-toggle="modal"
                            data-bs-target="#detailModal"
                            onClick={() => handleEdit(value)}
                          >
                            Detail
                          </Link>
                          <div class="dropdown-divider"></div>
                          <Link
                            class="dropdown-item"
                            onClick={() => showModalScreen(value.id)}
                            msgId={value.id}
                            data-bs-toggle="modal"
                            data-bs-target="#deleteModal"
                          >
                            Delete
                          </Link>
                        </div>
                      </a>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default InstructorAdmin;
