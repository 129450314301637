import React from 'react';
import axios from 'axios';
import ROUTES from '../../navigations/Routes';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
function PraticeModuleQues() {
  const [listOfItems, setListOfItems] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [userAnswers, setUserAnswers] = useState(
    Array(listOfItems.length).fill(null)
  );
  const { id, userName, name } = useParams();
  // useEffect(() => {
  //   const fetchAndShuffleQuestions = async () => {
  //     try {
  //       console.log('Module ID:', id);

  //       const response = await axios.get(
  //         `https://localhost:3001/questions/testQuestions/${id}`
  //       );
  //       const questions = response.data;

  //       // Shuffle the questions
  //       const shuffledQuestions = questions.sort(() => Math.random() - 0.5);

  //       setListOfItems(shuffledQuestions);
  //       setUserAnswers(Array(shuffledQuestions.length).fill(null));
  //       console.log(shuffledQuestions);
  //       console.log('Selected Module:', id);
  //       console.log(
  //         'Constructed image path:',
  //         `../${shuffledQuestions[currentQuestionIndex]?.image.replace(
  //           /\\/g,
  //           '/'
  //         )}`
  //       );
  //       console.log('hello');
  //     } catch (error) {
  //       console.error('Error fetching questions:', error);
  //     }
  //   };

  //   fetchAndShuffleQuestions();
  // }, [id]);
  useEffect(() => {
    const fetchAndShuffleQuestions = async () => {
      try {
        console.log('Module ID:', id);

        const response = await fetch(
          `https://csmserver.csmdrivingschool.ca/Questions.php?moduleId=${id}`
        );
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const questions = await response.json();

        // Shuffle the questions
        const shuffledQuestions = questions.sort(() => Math.random() - 0.5);
        setListOfItems(shuffledQuestions);
        setUserAnswers(Array(shuffledQuestions.length).fill(null));
        console.log(shuffledQuestions);
        console.log('Selected Module:', id);
        console.log(
          'Constructed image path:',
          `../${shuffledQuestions[currentQuestionIndex]?.image.replace(
            /\\/g,
            '/'
          )}`
        );
        console.log('hello');
      } catch (error) {
        console.error('Error fetching questions:', error);
      }
    };

    fetchAndShuffleQuestions();
  }, [id]);
  const handleNext = () => {
    if (currentQuestionIndex < listOfItems.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
  };

  const handlePrev = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };
  const changeOptionClass = (option) => {
    // Implement logic to change option class here
    console.log(`Option ${option} clicked`);
  };
  return (
    <div className="main">
      <div className="homeContainer">
        <div class="container-fluid QContainer">
          <div class="row1">
            {/* {listOfItems.map((value, key) => {
              return ( */}
            <div class="Item itemTest">
              <div class="ImageContainer1">
                <img
                  src={
                    listOfItems[currentQuestionIndex]?.image
                      ? `https://csmdrivingschool.ca/${listOfItems[currentQuestionIndex].image}`
                      : '/images/common.png'
                  }
                  alt="Image"
                />
              </div>
              <div class="contentContaner5">
                <p>
                  <b>Q:{listOfItems[currentQuestionIndex]?.question}</b>
                </p>
                <div class="options">
                  <input
                    type="radio"
                    id="radio1"
                    name="select1"
                    onClick={() => changeOptionClass('option1')}
                  />
                  <label for="radio1">
                    <p>{listOfItems[currentQuestionIndex]?.option1}</p>
                  </label>
                </div>

                <div class="options">
                  <input
                    type="radio"
                    id="radio2"
                    name="select1"
                    onClick={() => changeOptionClass('option2')}
                  />
                  <label for="radio2">
                    <p> {listOfItems[currentQuestionIndex]?.option2}</p>
                  </label>
                </div>

                <div class="options">
                  <input
                    type="radio"
                    id="radio3"
                    name="select1"
                    onClick={() => changeOptionClass('option3')}
                  />
                  <label for="radio3">
                    <p>{listOfItems[currentQuestionIndex]?.option3}</p>
                  </label>
                </div>

                <div class="options">
                  <input
                    type="radio"
                    id="radio4"
                    name="select1"
                    onClick={() => changeOptionClass('option4')}
                  />
                  <label for="radio4">
                    <p>{listOfItems[currentQuestionIndex]?.option4}</p>
                  </label>
                </div>
                <div>
                  <h6 className="mt-4">
                    <span className="font-weight-bold">Correct Answer:</span>
                    {listOfItems[currentQuestionIndex]?.correct}
                  </h6>
                  <span className="font-weight-bold">Explanation:</span>
                  {listOfItems[currentQuestionIndex]?.explanation}
                  <h5></h5>
                </div>
              </div>
            </div>
            {/* );
            })} */}

            <div class="buttonContainer">
              <button class="btn btn-outline-primary " onClick={handlePrev}>
                prev
              </button>
              <button class="btn btn-outline-primary" onClick={handleNext}>
                next
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PraticeModuleQues;
