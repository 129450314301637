import React from 'react';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import { Link } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
function G2PlusAdmin() {
  const [listOfG2PlusRegister, setlistOfG2PlusRegister] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentClickedId, setCurrentClickedId] = useState('');
  const [editValues, setEditValues] = useState(null);

  useEffect(() => {
    axios
      .get('http://csmserver.csmdrivingschool.ca/G2PlusRegister.php')
      .then((response) => {
        setlistOfG2PlusRegister(response.data);
      });
  }, []);
  //filtering data
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };
  const filteredData = listOfG2PlusRegister.filter((item) => {
    const values = Object.values(item).map((value) =>
      String(value).toLowerCase()
    );
    return values.some((value) => value.includes(searchTerm.toLowerCase()));
  });
  const showModalScreen = (msgId) => {
    setCurrentClickedId(msgId);
  };
  const handleEdit = (value) => {
    setEditValues(value);
  };
  const hideModalScreen = () => {};
  const onDelete = () => {
    console.log('id', currentClickedId);
    axios
      .delete(
        `http://csmserver.csmdrivingschool.ca/G2PlusRegister.php?id=${currentClickedId}`
      )
      .then((response) => {
        setlistOfG2PlusRegister(
          listOfG2PlusRegister.filter(function (item) {
            return item.id !== currentClickedId;
          })
        );
      });
    toast.success('Item deleted successfully', {
      position: 'top-right',
      autoClose: 5000, // in milliseconds, set to 5000 for 5 seconds
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      style: {
        background: 'rgba(0, 0, 0, 0.7)', // Transparent black background
        color: 'white',
      },
    });
    hideModalScreen();
  };
  return (
    <div>
      <ToastContainer></ToastContainer>
      <div
        class="modal fade"
        id="deleteModal"
        tabindex="-1"
        aria-labelledby="deleteModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="deleteModalLabel">
                Delete Confirm
              </h5>
              <button
                type="button"
                data-bs-dismiss="modal"
                aria-label="Close"
                style={{ border: 'none', backgroundColor: 'white' }}
              >
                <i
                  class="bi bi-x-lg"
                  style={{ fontSize: 22, color: 'black' }}
                ></i>
              </button>
            </div>
            <div class="modal-body">
              <p>Are you sure you want to delete data?</p>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-primary mx-2"
                data-bs-dismiss="modal"
              >
                No
              </button>
              <button
                type="button"
                class="btn btn-primary"
                onClick={onDelete}
                data-bs-dismiss="modal"
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>
      {/*  */}
      <h2 className="text-center my-4">G2 Plus List</h2>
      <input
        className=" form-control col-3 mt-4 mx-3"
        type="text"
        placeholder="Search here"
        value={searchTerm}
        onChange={handleSearch}
        style={{ height: 40 }}
      />
      <div style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
        <table className="table table-striped table-hover table-bordered">
          <thead className="bg-dark text-white">
            <tr>
              <th>First Name</th>
              <th>Middle Name</th>
              <th>Last Name</th>
              <th>Gender</th>
              <th>Date Of Birth</th>
              <th>License Number</th>
              <th>License Expiry Date</th>
              <th>Alternate Phone No.</th>
              <th>Email</th>
              <th>Guardian Phone</th>
              <th>Address</th>
              <th>City</th>
              <th>Province</th>
              <th>Postal Code</th>
              <th>Advance Course</th>
              <th>Add On</th>
              <th>G Package</th>
              <th>Price Of G Package</th>
              <th>Extra Lessons</th>
              <th>Lesson's Count</th>
              <th>Price</th>
              <th>Accommodation</th>
              <th>Accommodation Type</th>
              <th>Find</th>
              <th>Conditions</th>
              <th>Course Fee</th>
              <th>Road Test Fee</th>
              <th>HST Price</th>
              <th>Total</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((value, key) => (
              <tr key={key}>
                <td>{value.firstName}</td>
                <td>{value.middleName}</td>
                <td>{value.lastName}</td>
                <td>{value.gender}</td>
                <td>{value.dob}</td>
                <td>{value.licenseNumber}</td>
                <td>{value.licenseExpiryDate}</td>
                <td>{value.alternatePhone}</td>
                <td>{value.email}</td>
                <td>{value.guardianPhone}</td>
                <td>{value.address}</td>
                <td>{value.city}</td>
                <td>{value.province}</td>
                <td>{value.postalCode}</td>
                <td>{value.advanceCourse}</td>
                <td>{value.addon}</td>
                <td>{value.gPackage}</td>
                <td>{value.gPrice}</td>
                <td>{value.extraLesson}</td>
                <td>{value.extraLessonPrice}</td>
                <td></td>
                <td>{value.accommodation}</td>
                <td>{value.accommodationType}</td>
                <td>{value.find}</td>
                <td>{value.conditions}</td>
                <td>{value.courseFee}</td>
                <td>{value.roadTestPrice}</td>
                <td>{value.hstPrice}</td>
                <td>{value.totalAmount}</td>
                <td>
                  <a class="nav-item dropdown">
                    <a
                      class="fa-solid fa-circle-chevron-down"
                      data-bs-toggle="dropdown"
                      href="#"
                      role="button"
                      aria-haspopup="true"
                      aria-expanded="false"
                    ></a>
                    <div class="dropdown-menu">
                      <Link
                        class="dropdown-item"
                        onClick={() => showModalScreen(value.id)}
                        msgId={value.id}
                        data-bs-toggle="modal"
                        data-bs-target="#deleteModal"
                      >
                        Delete
                      </Link>
                    </div>
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default G2PlusAdmin;
