import React from 'react';
import { Link } from 'react-router-dom';
import ROUTES from '../../navigations/Routes';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { useState, useEffect } from 'react';
function StudentCourses() {
  const [progress, setProgress] = useState(20);
  useEffect(() => {
    const fetchDataTimeout = setTimeout(() => {
      setProgress(20);
    }, 2000);

    return () => {
      clearTimeout(fetchDataTimeout);
    };
  }, []);
  return (
    <div className="main">
      <div className="homeContainer">
        <div class="untree_co-section container-fluid courseList">
          <div class="container-fluid">
            <div className="d-flex justify-content-center">
              <h2>Courses</h2>
            </div>
            <div class="row align-items-stretch">
              <div
                class="col-lg-4 mb-4"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <div class="course d-flex">
                  <div class="course-body col-12">
                    <h2 class="my-3">
                      <Link to={ROUTES.bde.name} className="packageName">
                        CSM - BDE Program Plus
                      </Link>
                    </h2>
                    <ProgressBar
                      now={progress}
                      label={`${progress}%`}
                      className="mt-5"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StudentCourses;
