import React, { useEffect, useState, useReducer } from 'react';
import axios from 'axios';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from 'react-modal';
import ROUTES from '../../navigations/Routes';
import { useParams } from 'react-router-dom';
function AdminInsRegistration() {
  const [listOfItems, setListOfItems] = useState([]);
  const [currentClickedId, setCurrentClickedId] = useState('');
  const [addValues, setAddValues] = useState('');
  const [editValues, setEditValues] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedImage, setSelectedImage] = useState(null); // To show the Image full view in Modal

  const initialValues = {
    firstName: '',
    lastName: '',
    address: '',
    addressLine2: '',
    city: '',
    province: '',
    email: '',
    confirmEmail: '',
    phoneNumber: '',
    licenseNumber: '',
    licenseType: '',
    postalCode: '',
    penaltyPoints: '',
    banned: '',
    licenseImage: '',
    educationalCertificate: '',
    courseRegistration: '',
    virtualClasses: '',
    prerequisites: '',
    courseType: '',
    trainingLocation: '',
    price: '',
    paymentMethod: '',
    totalAmount: '',
    conditions: '',
  };
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('First Name is required!'),
    lastName: Yup.string().required('Last Name is required!'),
    address: Yup.string().required('address is required!'),
    addressLine2: Yup.string().required('address is required!'),
    city: Yup.string().required('province is required!'),
    province: Yup.string().required('city is required!'),
    email: Yup.string().required('Email is required'),
    confirmEmail: Yup.string()
      .oneOf([Yup.ref('email'), null], 'Email must match')
      .required('Required'),
    phoneNumber: Yup.number().required('Required!!'),
    licenseImage: Yup.string().required('Required!!'),
    licenseNumber: Yup.string().required('Required!!'),
    licenseType: Yup.string().required('Required!!'),
    postalCode: Yup.string().required('Required!!'),
    penaltyPoints: Yup.string().required('Required!!'),
    // banned: Yup.string().required('Required!!'),
    educationalCertificate: Yup.string().required('Required!!'),
    courseType: Yup.string().required('Required!!'),
    virtualClasses: Yup.boolean().required('Required!!'),
    prerequisites: Yup.boolean().required('Required!!'), // Make sure it's checked
    trainingLocation: Yup.string().required('Required!!'),
    price: Yup.string().required('Required!!'),
    paymentMethod: Yup.string().required('Required!!'),
    conditions: Yup.boolean().required('Required!!'),
  });
  const handleSubmit = (value) => {
    setAddValues(value);
  };
  const handleEdit = (value) => {
    setEditValues(value);
  };
  const showModalScreen = (msgId) => {
    setCurrentClickedId(msgId);
  };
  const openImageModal = (imageData) => {
    setSelectedImage(imageData);
  };
  const hideModalScreen = () => {};
  const onDelete = () => {
    console.log('id', currentClickedId);
    axios
      .delete(`http://localhost:3001/insRegistration/${currentClickedId}`)
      .then((response) => {
        setListOfItems(
          listOfItems.filter(function (item) {
            return item.id !== currentClickedId;
          })
        );
      });
    toast.success('Item deleted successfully', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      style: {
        background: 'rgba(0, 0, 0, 0.7)',
        color: 'white',
      },
    });
    hideModalScreen();
  };
  useEffect(() => {
    axios.get('http://localhost:3001/insRegistration').then((response) => {
      setListOfItems(response.data);
    });
  }, []);
  //filtering data
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };
  const filteredData = listOfItems.filter((item) => {
    const values = Object.values(item).map((value) =>
      String(value).toLowerCase()
    );
    return values.some((value) => value.includes(searchTerm.toLowerCase()));
  });
  const onSubmit = async (data, { resetForm, setSubmitting }) => {
    try {
      console.log('data', data);
      await axios
        .post('http://localhost:3001/insRegistration', data, {})
        .then((res) => {
          alert('data saved');
          console.log('it worked', res);
          resetForm();
        });
    } catch (error) {
      console.error('Error while submitting', error);
    }
  };
  const onUpdate = async (data, { resetForm }) => {
    try {
      console.log('data', data);
      await axios
        .put('http://localhost:3001/insRegistration', data)
        .then((res) => {
          alert('data updated');
          resetForm();
        });
    } catch (error) {
      console.error('Error while updating the data', error);
    }
  };
  return (
    <div>
      <ToastContainer></ToastContainer>
      <div
        class="modal fade"
        id="deleteModal"
        tabindex="-1"
        aria-labelledby="deleteModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="deleteModalLabel">
                Delete Confirm
              </h5>
              <button
                type="button"
                data-bs-dismiss="modal"
                aria-label="Close"
                style={{ border: 'none', backgroundColor: 'white' }}
              >
                <i class="fa-solid fa-xmark" style={{ fontSize: 20 }}></i>
              </button>
            </div>
            <div class="modal-body">
              <p>Are you sure you want to delete</p>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-primary mx-2"
                data-bs-dismiss="modal"
              >
                No
              </button>
              <button
                type="button"
                class="btn btn-primary"
                onClick={onDelete}
                data-bs-dismiss="modal"
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <h2 class="text-center">
            {' '}
            <i class="fa-solid fa-address-book"></i> List Of Instructor Course
            Registration
          </h2>
          <p class="text-center"> Manage your Course Registrations here.</p>
        </div>
      </div>
      <div class="row">
        <div
          class="modal fade"
          id="exampleSubmitModal"
          tabindex="-1"
          aria-labelledby="exampleSubmitModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleSubmitModalLabel">
                  Add Instructor
                </h5>
                <button
                  type="button"
                  aria-label="Close"
                  data-bs-dismiss="modal"
                >
                  <i class="bi bi-x-lg"></i>
                </button>
              </div>
              <div className="modal-body">
                {initialValues && (
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                  >
                    {({ values, setFieldValue, isSubmitting }) => (
                      <Form>
                        <ErrorMessage
                          name="firstName"
                          component="span"
                          style={{ color: 'red' }}
                        />
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="firstName">
                            First Name
                          </label>
                          <Field
                            type="text"
                            name="firstName"
                            id="firstName"
                            placeholder="Add First Name here"
                            class="form-control"
                          />
                        </div>
                        <ErrorMessage
                          name="lastName"
                          component="span"
                          style={{ color: 'red' }}
                        />
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="lastName">
                            Last Name
                          </label>
                          <Field
                            type="text"
                            name="lastName"
                            id="lastName"
                            placeholder="Add last Name here"
                            class="form-control"
                          />
                        </div>
                        <ErrorMessage
                          name="address"
                          component="span"
                          style={{ color: 'red' }}
                        />
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="address">
                            Address
                          </label>
                          <Field
                            type="text"
                            name="address"
                            id="address"
                            placeholder="Add Address here"
                            class="form-control"
                          />
                        </div>
                        <ErrorMessage
                          name="addressLine2"
                          component="span"
                          style={{ color: 'red' }}
                        />
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="addressLine2">
                            Address Line2
                          </label>
                          <Field
                            type="text"
                            name="addressLine2"
                            id="addressLine2"
                            placeholder="Add Address Line2 here"
                            class="form-control"
                          />
                        </div>
                        <ErrorMessage
                          name="city"
                          component="span"
                          style={{ color: 'red' }}
                        />
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="city">
                            City
                          </label>
                          <Field
                            type="text"
                            name="city"
                            id="city"
                            placeholder="Add city here"
                            class="form-control"
                          />
                        </div>
                        <ErrorMessage
                          name="province"
                          component="span"
                          style={{ color: 'red' }}
                        />
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="province">
                            province
                          </label>
                          <Field
                            type="text"
                            name="province"
                            id="province"
                            placeholder="Add province here"
                            class="form-control"
                          />
                        </div>
                        <ErrorMessage
                          name="email"
                          component="span"
                          style={{ color: 'red' }}
                        />
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="email">
                            Email
                          </label>
                          <Field
                            type="text"
                            name="email"
                            id="email"
                            placeholder="Add Email here"
                            class="form-control"
                          />
                        </div>
                        <ErrorMessage
                          name="confirmEmail"
                          component="span"
                          style={{ color: 'red' }}
                        />
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="confirmEmail">
                            Confirm Email
                          </label>
                          <Field
                            type="text"
                            name="confirmEmail"
                            id="confirmEmail"
                            placeholder="Confirm Email"
                            class="form-control"
                          />
                        </div>
                        <ErrorMessage
                          name="phoneNumber"
                          component="span"
                          style={{ color: 'red' }}
                        />
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="phoneNumber">
                            Phone Number
                          </label>
                          <Field
                            type="text"
                            name="phoneNumber"
                            id="phoneNumber"
                            placeholder="Add phoneNumber here"
                            class="form-control"
                          />
                        </div>
                        <ErrorMessage
                          name="licenseNumber"
                          component="span"
                          style={{ color: 'red' }}
                        />
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="licenseNumber">
                            License Number
                          </label>
                          <Field
                            type="text"
                            name="licenseNumber"
                            id="licenseNumber"
                            placeholder="License Number"
                            class="form-control"
                          />
                        </div>
                        <ErrorMessage
                          name="licenseType"
                          component="span"
                          style={{ color: 'red' }}
                        />
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="licenseType">
                            License Type
                          </label>
                          <Field
                            type="text"
                            name="licenseType"
                            id="licenseType"
                            placeholder="License Type"
                            class="form-control"
                          />
                        </div>
                        <ErrorMessage
                          name="postalCode"
                          component="span"
                          style={{ color: 'red' }}
                        />
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="postalCode">
                            Postal Code
                          </label>
                          <Field
                            type="text"
                            name="postalCode"
                            id="postalCode"
                            placeholder="Postal Code"
                            class="form-control"
                          />
                        </div>
                        <ErrorMessage
                          name="penaltyPoints"
                          component="span"
                          style={{ color: 'red' }}
                        />
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="penaltyPoints">
                            Penalty Points
                          </label>
                          <Field
                            type="text"
                            name="penaltyPoints"
                            id="penaltyPoints"
                            placeholder="Penalty Points"
                            class="form-control"
                          />
                        </div>
                        <ErrorMessage
                          name="message"
                          component="span"
                          style={{ color: 'red' }}
                        />
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="message">
                            Message
                          </label>
                          <Field
                            type="text"
                            name="message"
                            id="message"
                            placeholder="Add Subject here"
                            class="form-control"
                          />
                        </div>
                        <div class="modal-footer">
                          <button
                            type="submit"
                            class="btn btn-primary mx-3"
                            // disabled={isSubmitting}
                          >
                            Save
                          </button>
                          <button
                            class="btn btn-primary mx-3"
                            data-bs-dismiss="modal"
                          >
                            Close
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* detail modal */}
        <div
          class="modal fade"
          id="detailModal"
          tabindex="-1"
          aria-labelledby="detailModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog detailModal">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="detailModalLabel">
                  Detail
                </h5>
                <button
                  type="button"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  style={{ border: 'none', backgroundColor: 'white' }}
                >
                  <i
                    class="bi bi-x-lg"
                    style={{ color: 'black', fontSize: 20 }}
                  ></i>
                </button>
              </div>
              <div class="modal-body ">
                {editValues && (
                  <div>
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="firstName">
                        First Name
                      </label>
                      <b name="firstName">{editValues.firstName}</b>
                    </div>
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="lastName">
                        Last Name
                      </label>
                      <b name="lastName">{editValues.lastName}</b>
                    </div>
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="address">
                        Address
                      </label>
                      <b name="address">{editValues.address}</b>
                    </div>
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="addressLine2">
                        Address Line2
                      </label>
                      <b name="addressLine2">{editValues.addressLine2}</b>
                    </div>
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="city">
                        City
                      </label>
                      <b name="city">{editValues.city}</b>
                    </div>
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="province">
                        Province
                      </label>
                      <b name="province">{editValues.province}</b>
                    </div>
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="email">
                        Email
                      </label>
                      <b name="email">{editValues.email}</b>
                    </div>
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="confirmEmail">
                        Confirm Email
                      </label>
                      <b name="confirmEmail">{editValues.confirmEmail}</b>
                    </div>
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="phoneNumber">
                        Phone Number
                      </label>
                      <b name="phoneNumber">{editValues.phoneNumber}</b>
                    </div>
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="licenseNumber">
                        License Number
                      </label>
                      <b name="licenseNumber">{editValues.licenseNumber}</b>
                    </div>
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="licenseType">
                        License Type
                      </label>
                      <b name="licenseType">{editValues.licenseType}</b>
                    </div>
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="postalCode">
                        Postal Code
                      </label>
                      <b name="postalCode">{editValues.postalCode}</b>
                    </div>
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="penaltyPoints">
                        Penalty Points
                      </label>
                      <b name="penaltyPoints">{editValues.penaltyPoints}</b>
                    </div>
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="banned">
                        Banned?
                      </label>
                      <b name="banned">{editValues.banned}</b>
                    </div>
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="educationalCertificate">
                        Educational Certificate
                      </label>
                      <b name="educationalCertificate">
                        {editValues.educationalCertificate}
                      </b>
                    </div>
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="courseRegistration">
                        Course Registration
                      </label>
                      <b name="courseRegistration">
                        {editValues.courseRegistration}
                      </b>
                    </div>
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="courseType">
                        Course Type
                      </label>
                      <b name="courseType">{editValues.courseType}</b>
                    </div>
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="virtualClasses">
                        virtualClasses
                      </label>
                      <b name="virtualClasses">{editValues.virtualClasses}</b>
                    </div>
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="prerequisites">
                        prerequisites
                      </label>
                      <b name="prerequisites">{editValues.prerequisites}</b>
                    </div>
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="trainingLocation">
                        Training Location
                      </label>
                      <b name="trainingLocation">
                        {editValues.trainingLocation}
                      </b>
                    </div>
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="price">
                        price
                      </label>
                      <b name="price">{editValues.price}</b>
                    </div>
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="paymentMethod">
                        Payment Method
                      </label>
                      <b name="paymentMethod">{editValues.paymentMethod}</b>
                    </div>
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="totalAmount">
                        totalAmount
                      </label>
                      <b name="totalAmount">{editValues.totalAmount}</b>
                    </div>
                    <div class="modal-footer">
                      <div class="detailImageBoxContainer"></div>
                      <button class="btn btn-primary" data-bs-dismiss="modal">
                        Close
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {/*  */}
        <div class="col-md-12 mt-5">
          <div className="row">
            {/* <button
              class="btn btn-primary mx-3"
              data-bs-toggle="modal"
              data-bs-target="#exampleSubmitModal"
              onClick={() => handleSubmit()}
            >
              Add
            </button> */}
            {/* search bar */}
            <input
              className=" form-control col-3 mt-4"
              type="text"
              placeholder="Search here"
              value={searchTerm}
              onChange={handleSearch}
              style={{ height: 40 }}
            />
          </div>
          <div
            style={{
              overflowX: 'auto',
              whiteSpace: 'nowrap',
            }}
          >
            <table
              class="table table-striped table-hover"
              id="searchable-table"
            >
              <thead className="bg-dark text-white">
                <tr>
                  <th>License Image</th>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Address</th>
                  <th>Address Line 2</th>
                  <th>City</th>
                  <th>Province</th>
                  <th>Email</th>
                  <th>Confirmed Email</th>
                  <th>Phone Number</th>
                  <th>License Number</th>
                  <th>License Type</th>
                  <th>Postal Code</th>
                  <th>Penalty points</th>
                  <th>Banned?</th>
                  <th>Educational Certificate</th>
                  <th>Course Registration</th>
                  <th>Course Type</th>
                  <th>Virtual Classes</th>
                  <th>Prerequisites</th>
                  <th>Training Location</th>
                  <th>Price</th>
                  <th>Payment Method</th>
                  <th>Total Amount</th>
                  <th>Actions</th>
                </tr>
              </thead>

              <tbody>
                {filteredData.map((value, key) => {
                  return (
                    <tr key={key}>
                      {/* {Object.entries(value).map(([field, fieldValue]) => (
                      <td key={field}>{fieldValue}</td>
                    ))} */}
                      <td>
                        {' '}
                        {value.licenseImage && (
                          <img
                            src={value.licenseImage}
                            alt=""
                            onClick={() => openImageModal(value.licenseImage)}
                            style={{ cursor: 'pointer' }}
                            height="100px"
                            width="100px"
                          />
                        )}
                      </td>
                      <td>{value.firstName}</td>
                      <td>{value.lastName}</td>
                      <td>{value.address}</td>
                      <td>{value.addressLine2}</td>
                      <td>{value.city}</td>
                      <td>{value.province}</td>
                      <td>{value.email}</td>
                      <td>{value.confirmEmail}</td>
                      <td>{value.phoneNumber}</td>
                      <td>{value.licenseNumber}</td>
                      <td>{value.licenseType}</td>
                      <td>{value.postalCode}</td>
                      <td>{value.penaltyPoints}</td>
                      <td>{value.banned}</td>
                      <td>{value.educationalCertificate}</td>
                      <td>{value.courseRegistration}</td>
                      <td>{value.courseType}</td>
                      <td>{value.virtualClasses}</td>
                      <td>{value.prerequisites}</td>
                      <td>{value.trainingLocation}</td>
                      <td>{value.price}</td>
                      <td>{value.paymentMethod}</td>
                      <td>{value.totalAmount}</td>
                      <td>
                        <a class="nav-item dropdown">
                          <a
                            class="fa-solid fa-circle-chevron-down"
                            data-bs-toggle="dropdown"
                            href="#"
                            role="button"
                            aria-haspopup="true"
                            aria-expanded="false"
                          ></a>
                          <div class="dropdown-menu">
                            <Link
                              class="dropdown-item"
                              data-bs-toggle="modal"
                              data-bs-target="#detailModal"
                              onClick={() => handleEdit(value)}
                            >
                              Detail
                            </Link>

                            <div class="dropdown-divider"></div>
                            <Link
                              class="dropdown-item"
                              onClick={() => showModalScreen(value.id)}
                              msgId={value.id}
                              data-bs-toggle="modal"
                              data-bs-target="#deleteModal"
                            >
                              Delete
                            </Link>
                          </div>
                        </a>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
              <div
                style={{
                  position: 'fixed',
                  bottom: 0,
                  left: 0,
                  width: '100%',
                  overflowX: 'auto',
                  whiteSpace: 'nowrap',
                  backgroundColor: 'rgba(255, 255, 255, 0.8)',
                }}
              >
                {/* Empty div, serves as a placeholder for scrollbar height */}
              </div>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminInsRegistration;
