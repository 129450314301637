import React from 'react';
import Navbar from '../Navbar';
import { Outlet, useParams } from 'react-router-dom';
import Footer from '../Footer';

function Index() {
  const { userName } = useParams();
  return (
    <div>
      <Navbar userName={userName}></Navbar>
      <Outlet></Outlet>
      <Footer></Footer>
    </div>
  );
}

export default Index;
