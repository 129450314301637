import React from 'react';

function GuideToPracticalTest() {
  return (
    <div className="main">
      <div className="homeContainer">
        <div
          class="untree_co-hero inner-page container-fluid TheoryTest"
          style={{ height: 'auto' }}
        >
          <div class="row align-items-center justify-content-center">
            <div class="col-12" data-aos="fade-up" data-aos-delay="200">
              <div class="row refesherLesson">
                <div class="col-lg-6 mb-5">
                  <div
                    class="section-title mb-3"
                    data-aos="fade-up"
                    data-aos-delay="0"
                  >
                    <h2 class="my-4 text-black">Practical Driving Test</h2>
                  </div>
                  <h4
                    data-aos="fade-up"
                    data-aos-delay="100"
                    className=" text-black"
                    style={{ fontSize: 27 }}
                  >
                    Passing the practical
                  </h4>
                </div>
                <div class="col-lg-6" data-aos="fade-up" data-aos-delay="0">
                  <figure class="img-wrap">
                    <img
                      src="images/instructing.jpg"
                      alt="Image"
                      class="img-fluid"
                      //   width="400"
                      //height="400"
                    />
                    <div class="dotted"></div>
                  </figure>
                </div>
              </div>
              <div className="lessonContent">
                <h3>Practical Test Guide</h3>
                <p>
                  That's it, folks! You've passed the theoretical test and you
                  have your class G1 license. Now the final step before getting
                  your class G2 and Class G license- the practical driving exam.
                </p>
                <p>
                  It can be a little daunting, but your driving instructor
                  believe, you can do it. We think you're capable of it. That
                  being said, here is all the information you require for your
                  practical driving test to assist you in getting ready for your
                  knowledge exam and guarantee a passing score on your first
                  attempt!
                </p>

                <h3>How do I book my driving test?</h3>
                <p>
                  Once you've completed the required waiting periods and gained
                  sufficient experience, you can schedule a{' '}
                  <a href="drivetest.ca/book-a-road-test/overview/">
                    road test.
                  </a>{' '}
                  You will need your driver's license number and the funds to
                  make the reservation. After their driver's license expires,
                  novice drivers in the graduated licensing program are not
                  permitted to schedule a driving test.
                </p>
                <h3>What should I do before you go to raod test?</h3>
                <p>
                  Beginner Driver Education (BDE) courses are optional but
                  recommended by the Ministry of Transportation (MTO). Before
                  taking any road test, make sure you study the Official MTO
                  Driver's Handbook or Practice on CSM knowledge portal.
                </p>
                <p>
                  As you get ready for the test, we advise you to practice the
                  following abilities in a range of settings, at different times
                  of day (as your license permits), and under different driving
                  circumstances:
                </p>
                <p>1. Starting, stopping and turning</p>
                <p>2. Traffic signs and lights.</p>
                <p>
                  3. Passing vehicles, including bicycles, and driving in
                  passing lanes.
                </p>
                <p>
                  4. Travelling through controlled and uncontrolled
                  intersections.
                </p>
                <p>5. Parking, reversing, and three-point turns.</p>
                <p>6. Forseeing hazardous conditions and being.</p>
                <p>7. Highways.</p>

                <h3>Is there a minimum amount of lessons I can take?</h3>
                <p>
                  There is no minimum amount of lessons you should take, or
                  hours of practice you should have under your belt. The rule of
                  thumb is to take your test when you're ready. Your driving
                  instructor is best placed to decide this.
                </p>
                <h3>What do I need to bring to the practical driving test?</h3>
                <p>
                  On the day, you need to take your current driving licence. and
                  print out your road test confirmation. Don't forget your
                  glasses if you need them to drive.
                </p>
                <h3>Can I take the driving test in my own car?</h3>
                <p>
                  Provided it meets rules set by the MTO or Drive Test centre,
                  you can take the practical test in your own vehicle. Most
                  people use their instructor's car, which will already be
                  test-ready.
                </p>
                <h3>What are the 'show me, tell me' safety questions?</h3>
                <p>
                  The examiner will ask you two safety questions. These are
                  called 'show me, tell me' because one question will require a
                  demonstration of a basic safety check, and the other requires
                  an explanation.
                </p>
                <p>
                  ● 'Tell me': There are 14 possible questions, and the examiner
                  will ask you to explain a safety feature. For example, you
                  might be asked to describe how you'd check the headlights and
                  taillights are working.
                </p>
                <p>
                  ● 'Show me': There are seven possible questions, and you'll be
                  asked to carry out a specific activity. For example, you might
                  be asked to demist the rear window.
                </p>
                <h3>How will I be tested on general driving ability?</h3>
                <p>
                  After 'show me, tell me', it's time to get on the road. Much
                  like in your driving lessons, your examiner will instruct you
                  where to go, and what to do. You may be asked to perform
                  certain manoeuvres, such as pulling over at the side of the
                  road; pulling away from behind another vehicle; and performing
                  a hill start.
                </p>
                <p>
                  You may also have to perform an emergency stop or road side
                  stop in this part of the test. The examiner will give you
                  warning beforehand, and will only ask you to perform this when
                  it's safe to do so.
                </p>
                <h3>What score do I need to get in my driving test?</h3>
                <p>
                  As the examiner is tallying up mistakes or 'faults', the
                  closer you get to zero, the better. For the most part, the
                  examiner won't tell you when you've made a fault - you're
                  usually told at the end of the test when you get your result.
                </p>
                <p>There are three types of fault:</p>
                <ul className="mx-3">
                  <p>
                    <li>
                      A driving fault, or minor: Although not necessarily
                      dangerous, these are scorable errors. Although one or two
                      won't cause you to fail, making the same mistake a few
                      times could constitute a serious fault. This would cause
                      you to fail. You will also fail if you get more than 15
                      minor faults across the board.
                    </li>
                  </p>
                  <p>
                    <li>
                      A dangerous fault: You really don't want to do this. A
                      dangerous fault is one which puts you, the examiner,
                      property and/or members of the public in actual danger. As
                      keeping everybody safe is the examiner's main priority,
                      this is an instant fail, and the test will be stopped.
                    </li>
                  </p>
                </ul>
                <h3>How does the driving test end?</h3>
                <p>
                  With any luck, your test won't end prematurely, and your
                  examiner will guide you back to the test centre. After you've
                  parked, the examiner will tell you whether you've passed or
                  failed, and give you your result. A pass is 15 minors or
                  fewer, and no serious or dangerous faults. You'll also be
                  given feedback on your performance.
                </p>
                <h3>What happens if I've passed?</h3>
                <p>
                  Many congratulations - you're a qualified driver! You'll be
                  presented with a pass certificate. You'll also be asked if
                  you'd like to be sent your licence automatically.
                </p>
                <h3>What happens if I've failed?</h3>
                <p>If you fail, don't be too down about it.</p>
                <p>
                  And you can rebook your test and have another chance. It's a
                  really good idea to take the examiner's feedback on board, as
                  this will usually give you an insight into what to do better
                  next time. Good luck - we're all rooting for you!
                </p>
                <div className="container-fluid Faq">
                  <h2 className="text-center mt-5">Practical Test FAQ's</h2>
                  <div className="d-flex justify-content-around flex-wrap">
                    <div className="col-md-5 mt-5">
                      <h5 style={{ color: '#ec661f' }}>
                        How do I change my practical test booking?
                      </h5>
                      <p>
                        You can change your booking online{' '}
                        <a
                          href="https://drivetest.ca/book-a-road-test/overview/"
                          className="mr-1"
                        >
                          here
                        </a>
                        or by calling
                        <br />
                        <span style={{ color: '#ec661f' }}>
                          1-888-570-6110
                        </span>{' '}
                        or{' '}
                        <span style={{ color: '#ec661f' }}>647-776-0331</span>
                        <br />
                        Live operators are available on Monday to Friday, from
                        <br />
                        8:30 a.m. to 5:00 p.m
                      </p>
                    </div>
                    <div className="col-md-5 mt-5">
                      <h5 style={{ color: '#ec661f' }}>
                        How do I cancel my practical test booking?
                      </h5>
                      <p>
                        you can change your booking online{' '}
                        <a
                          href=" https://drivetest.ca/book-a-road-test/overview/"
                          className="mr-1"
                        >
                          here
                        </a>
                        or by calling
                        <br />
                        <span style={{ color: '#ec661f' }}>
                          1-888-570-6110
                        </span>{' '}
                        or{' '}
                        <span style={{ color: '#ec661f' }}>647-776-0331</span>
                        <br />
                        Live operators are available on Monday to Friday, from
                        8:30 a.m. to 5:00 p.m
                      </p>
                    </div>
                    <div className="col-12 mt-5 text-center">
                      <h5 style={{ color: '#ec661f' }}>
                        How do I cancel my practical test booking?
                      </h5>
                      <p>
                        You can find your nearest theory test centre by using
                        the following government website
                        <a
                          href=" https://drivetest.ca/find-a-drivetest-centre/find-a-drivetest-centre/"
                          className="mx-1"
                        >
                          here
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="bookBox container-fluid"
          data-aos="fade-up"
          data-aos-delay="200"
        >
          <h3 className="text-white">Book your lessons online!</h3>
          <h5 className="text-white mt-3">
            Check availability and book your first lesson
          </h5>
          <button className="btn btn-primary btnPrice">See Prices</button>
        </div>
      </div>
    </div>
  );
}

export default GuideToPracticalTest;
