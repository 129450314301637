import React, { useState, useEffect } from 'react';

function TimeRemaining({ dateString, timeString }) {
  const convertTo24HourFormat = (timeString) => {
    const [time, meridiem] = timeString.split(' ');
    const [hour, minute, second] = time.split(':');
    let hour24 = parseInt(hour);

    if (meridiem === 'PM' && hour24 !== 12) {
      hour24 += 12;
    } else if (meridiem === 'AM' && hour24 === 12) {
      hour24 = 0;
    }

    return `${hour24}:${minute}:${second || '00'}`;
  };

  const calculateTimeRemaining = () => {
    try {
      // Parse the session date
      const sessionDate = new Date(dateString);

      // Convert session time to 24-hour format
      const sessionTime24 = convertTo24HourFormat(timeString);

      // Set the session time in the session date
      sessionDate.setHours(
        parseInt(sessionTime24.split(':')[0], 10),
        parseInt(sessionTime24.split(':')[1], 10),
        parseInt(sessionTime24.split(':')[2] || 0, 10)
      );

      // Get current time
      const currentTime = new Date();

      // Calculate time difference in milliseconds
      const timeDiff = sessionDate.getTime() - currentTime.getTime();

      if (timeDiff <= 0) {
        setTimeRemaining('Missed');
        setIsPassed(true);
        return;
      }

      // Convert milliseconds to days, hours, minutes, and seconds
      const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);

      // Construct the message based on non-zero units
      let message = '';
      if (days > 0) {
        message += `${days} days, `;
      }
      if (hours > 0) {
        message += `${hours} hours, `;
      }
      if (minutes > 0) {
        message += `${minutes} minutes, `;
      }
      if (seconds > 0 || (days === 0 && hours === 0 && minutes === 0)) {
        message += `${seconds} seconds`;
      }

      setTimeRemaining(message.trim()); // Remove trailing comma and whitespace
    } catch (error) {
      console.error('Error calculating time remaining:', error);
      setTimeRemaining('Error'); // Display error message in case of calculation failure
    }
  };

  const [timeRemaining, setTimeRemaining] = useState('');
  const [isPassed, setIsPassed] = useState(false);

  useEffect(() => {
    calculateTimeRemaining();
    // Update time remaining every second
    const interval = setInterval(calculateTimeRemaining, 1000);

    // Cleanup function
    return () => clearInterval(interval);
  }, [dateString, timeString]);

  return (
    <div style={{ color: isPassed ? 'red' : 'black' }}>
      {timeRemaining.length > 0 ? timeRemaining : 'Calculating...'}
    </div>
  );
}

export default TimeRemaining;
