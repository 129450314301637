import React from 'react';
import { useState, useEffect, useRef, useMemo } from 'react';
import axios from 'axios';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import JoditEditor from 'jodit-react';
import 'react-quill/dist/quill.snow.css';
function BDEPackage({ placeholder }) {
  const [listOfBDEPackages, setlistOfBDEPackages] = useState([]);
  const [currentClickedId, setCurrentClickedId] = useState('');
  const [editValues, setEditValues] = useState(null);
  const editor = useRef(null);
  const [content, setContent] = useState('');
  const config = {
    readonly: false,
    placeholder: placeholder || 'Start Typing...',
  };
  //jodit editor
  const [description, setDescription] = useState('');
  const removeHtmlTags = (html) => {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    const listItems = Array.from(doc.body.querySelectorAll('li'))
      .map((li) => li.textContent.trim())
      .join('\n');
    return listItems;
  };
  const editorRef = React.useRef();
  const initialValues = {
    packageName: '',
    packagePrice: '',
    description: '',
    discount: '',
    save: '',
  };
  const validationSchema = Yup.object().shape({
    packageName: Yup.string().required('Package Name is required'),
    packagePrice: Yup.string().required('Package Price is required'),
    description: Yup.string().required('Description is required'),
  });
  const handleEdit = (value) => {
    setEditValues(value);
  };
  const showModalScreen = (msgId) => {
    setCurrentClickedId(msgId);
  };
  const hideModalScreen = () => {};
  const onDelete = () => {
    console.log('id', currentClickedId);
    fetch(
      `https://csmserver.csmdrivingschool.ca/BDEPackage.php?id=${currentClickedId}`,
      {
        method: 'DELETE',
      }
    )
      .then((response) => {
        if (response.ok) {
          setlistOfBDEPackages(
            listOfBDEPackages.filter(function (item) {
              return item.id !== currentClickedId;
            })
          );

          toast.success('Item deleted successfully', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            style: {
              background: 'rgba(0, 0, 0, 0.7)',
              color: 'white',
            },
          });
          hideModalScreen();
        } else {
          throw new Error('Network response was not ok.');
        }
      })
      .catch((error) => {
        console.error('Error while deleting', error);
        // Handle error here
      });
  };

  useEffect(() => {
    fetch('https://csmserver.csmdrivingschool.ca/BDEPackage.php')
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('Network response was not ok.');
        }
      })
      .then((data) => {
        setlistOfBDEPackages(data);
      })
      .catch((error) => {
        console.error('Error while fetching data', error);
        // Handle error here
      });
  }, []);

  const handleBlur = (newContent) => {
    setDescription(newContent);
  };
  const onSubmit = async (data, { resetForm, setSubmitting }) => {
    try {
      console.log('data', data);
      data.description = removeHtmlTags(data.description);
      const response = await fetch(
        'http://csmserver.csmdrivingschool.ca/BDEPackage.php',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        }
      );

      if (response.ok) {
        alert('data saved');
        console.log('it worked', await response.json());
        resetForm();
      } else {
        throw new Error('Network response was not ok.');
      }
    } catch (error) {
      alert('error');
      console.error('Error while submitting', error);
    }
  };

  const onUpdate = async (data, { resetForm, setSubmitting }) => {
    try {
      console.log('data', data);
      data.description = removeHtmlTags(data.description);
      await fetch('https://csmserver.csmdrivingschool.ca/BDEPackage.php', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      // If you need to perform actions after the update
      alert('data updated');
      resetForm();
    } catch (error) {
      alert('error');
      console.error('Error while updating the data', error);
    }
  };

  return (
    <div>
      <ToastContainer></ToastContainer>
      <div
        class="modal fade"
        id="deleteModal"
        tabindex="-1"
        aria-labelledby="deleteModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="deleteModalLabel">
                Delete Confirm
              </h5>
              <button
                type="button"
                //class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                style={{ border: 'none', backgroundColor: 'white' }}
              >
                <i
                  class="bi bi-x-lg"
                  style={{ fontSize: 20, color: 'black' }}
                ></i>
              </button>
            </div>
            <div class="modal-body">
              <p>Are you sure you want to delete data?</p>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-primary mx-2"
                data-bs-dismiss="modal"
              >
                No
              </button>
              <button
                type="button"
                class="btn btn-primary"
                onClick={onDelete}
                data-bs-dismiss="modal"
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <h2 class="text-center">
            {' '}
            <i class="fa-solid fa-comment"></i> BDE Packages
          </h2>
          <p class="text-center"> Manage your packages here.</p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">Add a Package</h4>
            </div>
            <div class="card-body">
              <Formik
                initialValues={initialValues}
                onSubmit={onSubmit}
                validationSchema={validationSchema}
              >
                {({ values, setFieldValue, isSubmitting, handleChange }) => (
                  <Form>
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="packageName">
                        Package Name
                      </label>
                      <Field
                        type="text"
                        name="packageName"
                        id="packageName"
                        placeholder="Add a Name here"
                        class="form-control"
                      />
                      <ErrorMessage
                        name="packageName"
                        component="span"
                        style={{ color: 'red' }}
                      />
                    </div>
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="packagePrice">
                        Package Price
                      </label>
                      <Field
                        type="text"
                        name="packagePrice"
                        id="packagePrice"
                        placeholder="Add Package Price here"
                        class="form-control"
                      />
                      <ErrorMessage
                        name="packagePrice"
                        component="span"
                        style={{ color: 'red' }}
                      />
                    </div>
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="discount">
                        Discount
                      </label>
                      <Field
                        type="text"
                        name="discount"
                        id="discount"
                        placeholder="Add Discount here"
                        class="form-control"
                      />
                    </div>
                    {values.packagePrice !== '' && (
                      <div className="form-group fieldStyle">
                        <label htmlFor="save">Save</label>
                        <Field
                          type="text"
                          name="save"
                          id="save"
                          placeholder="...."
                          className="form-control"
                          value={
                            values.discount !== null &&
                            values.discount !== undefined // Check if discount is provided
                              ? (
                                  (parseFloat(
                                    (values.packagePrice || '')
                                      .replace('$', '')
                                      .replace(',', '')
                                  ) || 0) -
                                  (parseFloat(
                                    (values.discount || '')
                                      .replace('$', '')
                                      .replace(',', '')
                                  ) || 0)
                                ).toString()
                              : 'No Discount'
                          }
                          readOnly
                        />
                      </div>
                    )}
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="description">
                        Description
                      </label>
                      <Field name="description">
                        {({ field, form: { setFieldValue } }) => (
                          <div>
                            <JoditEditor
                              ref={editorRef}
                              value={field.value}
                              onBlur={() => {
                                setFieldValue(
                                  'description',
                                  editorRef.current.value
                                );
                              }}
                            />
                            <div>{removeHtmlTags(field.value)}</div>
                          </div>
                        )}
                      </Field>
                      <ErrorMessage
                        name="description"
                        component="span"
                        style={{ color: 'red' }}
                      />
                    </div>
                    <button
                      type="submit"
                      class="btn btn-primary"
                      disabled={isSubmitting}
                    >
                      Submit
                    </button>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
        {/* edit modal */}
        <div
          class="modal fade"
          id="exampleModal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  Edit Item
                </h5>
                <button
                  type="button"
                  aria-label="Close"
                  data-bs-dismiss="modal"
                  style={{ border: 'none', backgroundColor: 'white' }}
                >
                  <i class="bi bi-x-lg" style={{ fontSize: 20 }}></i>
                </button>
              </div>
              <div className="modal-body">
                {editValues && (
                  <Formik
                    initialValues={editValues}
                    enableReinitialize={true}
                    onSubmit={onUpdate}
                  >
                    {({ values, setFieldValue, isSubmitting }) => (
                      <Form>
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="packageName">
                            Package Name
                          </label>
                          <Field
                            type="text"
                            name="packageName"
                            id="packageName"
                            placeholder="Add a Name here"
                            class="form-control"
                          />
                        </div>
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="packagePrice">
                            Package Price
                          </label>
                          <Field
                            type="text"
                            name="packagePrice"
                            id="packagePrice"
                            placeholder="Add Package Price here"
                            class="form-control"
                          />
                        </div>
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="discount">
                            Discount
                          </label>
                          <Field
                            type="text"
                            name="discount"
                            id="discount"
                            placeholder="Add Discount here"
                            class="form-control"
                          />
                        </div>
                        {values.packagePrice !== '' &&
                          values.discount !== '' && (
                            <div className="form-group fieldStyle">
                              <label htmlFor="save">Save</label>
                              <Field
                                type="text"
                                name="save"
                                id="save"
                                placeholder="...."
                                className="form-control"
                                value={(
                                  (parseFloat(
                                    values.packagePrice
                                      .replace('$', '')
                                      .replace(',', '')
                                  ) || 0) -
                                  (parseFloat(
                                    values.discount
                                      .replace('$', '')
                                      .replace(',', '')
                                  ) || 0)
                                ).toString()}
                                readOnly
                              />
                            </div>
                          )}
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="description">
                            Description
                          </label>
                          <Field name="description">
                            {({ field, form: { setFieldValue } }) => (
                              <div>
                                <JoditEditor
                                  ref={editorRef}
                                  value={field.value}
                                  onBlur={() => {
                                    setFieldValue(
                                      'description',
                                      editorRef.current.value
                                    );
                                  }}
                                />
                                <div>{removeHtmlTags(field.value)}</div>
                              </div>
                            )}
                          </Field>
                        </div>
                        <div class="modal-footer">
                          <button
                            type="submit"
                            class="btn btn-primary mx-3"
                            // disabled={isSubmitting}
                          >
                            Update
                          </button>
                          <button
                            class="btn btn-primary"
                            data-bs-dismiss="modal"
                          >
                            Close
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* detail modal */}
        <div
          class="modal fade"
          id="detailModal"
          tabindex="-1"
          aria-labelledby="detailModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="detailModalLabel">
                  Detail
                </h5>
                <button
                  type="button"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  style={{ border: 'none', backgroundColor: 'white' }}
                >
                  <i class="bi bi-x-lg" style={{ fontSize: 20 }}></i>
                </button>
              </div>
              <div class="modal-body">
                {editValues && (
                  <div>
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="packageName">
                        Package Name
                      </label>
                      <b name="packageName">{editValues.packageName}</b>
                    </div>
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="packagePrice">
                        Package Price
                      </label>
                      <b name="packagePrice">{editValues.packagePrice}</b>
                    </div>
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="discount">
                        Discount
                      </label>
                      <b name="discount">{editValues.discount}</b>
                    </div>
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="save">
                        Save
                      </label>
                      <b name="save">{editValues.save}</b>
                    </div>
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="description">
                        Description
                      </label>
                      <p name="description" className="mx-2">
                        {editValues.description}
                      </p>
                    </div>
                    <div class="modal-footer">
                      <div class="detailImageBoxContainer"></div>
                      <button class="btn btn-primary" data-bs-dismiss="modal">
                        Close
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {/*  */}
        <div class="col-md-12 mt-5">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">Packages List</h4>
            </div>
            <div class="card-body">
              <table class="table table-striped ">
                <thead className="bg-dark text-white">
                  <tr>
                    <th> Package Name</th>
                    <th>Package Price</th>
                    <th>Description</th>
                    <th>Discount</th>
                    <th>Save</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {listOfBDEPackages.map((value, key) => {
                    return (
                      <tr key={key}>
                        <td>{value.packageName}</td>
                        <td>{value.packagePrice}</td>
                        <td>{value.description}</td>
                        <td>{value.discount}</td>
                        <td>{value.save}</td>
                        <td>
                          <a class="nav-item dropdown">
                            <a
                              class="fa-solid fa-circle-chevron-down"
                              data-bs-toggle="dropdown"
                              href="#"
                              role="button"
                              aria-haspopup="true"
                              aria-expanded="false"
                            ></a>
                            <div class="dropdown-menu">
                              <Link
                                class="dropdown-item"
                                data-bs-toggle="modal"
                                data-bs-target="#exampleModal"
                                onClick={() => handleEdit(value)}
                              >
                                Edit
                              </Link>
                              <Link
                                class="dropdown-item"
                                data-bs-toggle="modal"
                                data-bs-target="#detailModal"
                                onClick={() => handleEdit(value)}
                              >
                                Detail
                              </Link>
                              <div class="dropdown-divider"></div>
                              <Link
                                class="dropdown-item"
                                onClick={() => showModalScreen(value.id)}
                                msgId={value.id}
                                data-bs-toggle="modal"
                                data-bs-target="#deleteModal"
                              >
                                Delete
                              </Link>
                            </div>
                          </a>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default BDEPackage;
