import React, { useState } from 'react';
function Counter() {
  const [count, setCount] = useState(0);
  const inc = () => {
    setCount(count + 1);
  };
  const dec = () => {
    setCount(count > 0 ? count - 1 : 0);
  };
  return (
    <div className="d-flex d-inline-block">
      <button onClick={inc} className="btn btn-dark mr-2" type="button">
        <h2 className="mb-4">+</h2>+
      </button>
      <div className="form-control col-2"> {count}</div>
      <button onClick={dec} className="btn btn-dark ml-2" type="button">
        <h2>-</h2>
      </button>
    </div>
  );
}
export default Counter;
