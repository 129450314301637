import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import { useState } from 'react';
import ROUTES from '../../navigations/Routes';
function JoinOurFleet() {
  const navigate = useNavigate();
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');

  const login = () => {
    const data = { userName: userName, password: password };
    axios
      .post('http://localhost:3001/Instructor/login', data)
      .then((response) => {
        if (response.data.error) {
          alert(response.data.error);
        } else {
          sessionStorage.setItem('accessToken', response.data);
          // setAuthState(true);
          navigate(`/instructorPanel/${userName}`);
        }
      });
  };
  return (
    <div className="main">
      <div className="homeContainer">
        <div
          class="untree_co-hero inner-page container-fluid"
          style={{
            backgroundImage: `url('images/studentRegister.jpg')`,
            zIndex: 1,
          }}
        >
          <div class="container-fluid ">
            <div class="row align-items-center justify-content-center">
              <div class="col-12">
                <div class="row justify-content-center ">
                  <div class="col-lg-6 text-center ">
                    <h1
                      class="heading text-white"
                      data-aos="fade-up"
                      data-aos-delay="100"
                      style={{ marginTop: 140 }}
                    >
                      Login
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="untree_co-section logInBox">
          <div class="container-fluid">
            <div class="row mb-5 justify-content-center">
              <div
                class="col-lg-5 mx-auto order-1"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <form action="#" class="form-box">
                  <div class="row">
                    <div class="col-12 mb-3">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Username"
                        onChange={(event) => {
                          setUserName(event.target.value);
                        }}
                      />
                    </div>
                    <div class="col-12 mb-3">
                      <input
                        type="password"
                        class="form-control"
                        placeholder="Password"
                        onChange={(event) => {
                          setPassword(event.target.value);
                        }}
                      />
                    </div>
                    <div class="col-12 mb-1">
                      <label class="control control--checkbox">
                        <span class="caption">Remember me</span>
                        <input type="checkbox" checked="checked" />
                        <div class="control__indicator"></div>
                      </label>
                    </div>
                    <div class="col-12">
                      <button class="btn btn-primary" onClick={login}>
                        Log In
                      </button>
                    </div>
                    <div class="col-12 mt-3">
                      <h5>If you do not have an account, then</h5>
                      <Link to={ROUTES.fleetRegister.name}>Register Here</Link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default JoinOurFleet;
