import React from 'react';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import ROUTES from '../../navigations/Routes';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function RejectedRequests() {
  const [listOfRejectedRequests, setListOfRejectedRequests] = useState([]);
  const [editValues, setEditValues] = useState(null);
  const [currentClickedId, setCurrentClickedId] = useState('');
  // const [userName, setUserName] = useState('');
  let { userName } = useParams();
  const [loggedIn, setLoggedIn] = useState('');
  const [loggedInUser, setLoggedInUser] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const initialValues = {
    userName: '',
    address: '',
    phoneNumber: '',
    email: '',
    lessonSheets: '',
    calendar: '',
  };
  const handleEdit = (value) => {
    setEditValues(value);
  };
  useEffect(() => {
    const usr = localStorage.getItem('id');
    setLoggedIn(usr);
    console.log(usr);
    const userName = localStorage.getItem('currentInsName');
    setLoggedInUser(userName);
    console.log(userName);
    onNavigate();
  }, []);

  const onNavigate = (value) => {
    // const usr = localStorage.getItem('id');
    // setLoggedIn(usr);
    // localStorage.setItem('id', usr);
    const userName = localStorage.getItem('currentInsName');
    setLoggedInUser(userName);
    localStorage.setItem('currentInsName', userName);
    console.log(userName);
    // console.log(value.id);
  };
  const fetchStudentName = async (studentUserName) => {
    try {
      const response = await fetch(
        `https://csmserver.csmdrivingschool.ca/StudentRegister.php?id=${studentUserName}`
      );
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching student name:', error);
      return [];
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://csmserver.csmdrivingschool.ca/StudentRequest.php?id=${loggedInUser}&rejected`
        );

        if (!response.ok) {
          throw new Error('Failed to fetch rejected requests');
        }

        const studentData = await response.json();
        console.log('Fetched student data:', studentData);

        if (!studentData.data || studentData.data.length === 0) {
          console.log('No students available');
          setListOfRejectedRequests([]); // Ensure the state is set
          setLoading(false);
          return;
        }

        // Loop through each student and fetch their name
        const requestsWithStudentNames = await Promise.all(
          studentData.data.map(async (student) => {
            try {
              const studentNameResponse = await fetchStudentName(
                student.studentUserName
              );
              const studentName =
                studentNameResponse[0]?.userName || 'Unknown User'; // Ensure this matches your data structure
              return { ...student, studentUserName: studentName };
            } catch (error) {
              console.error('Error fetching student name:', error);
              // Handle error or return placeholder data
              return { ...student, studentUserName: 'Unknown' };
            }
          })
        );

        setListOfRejectedRequests(requestsWithStudentNames);
        setLoading(false);
        setError(null); // Clear any previous error
      } catch (error) {
        console.error('Error fetching student details:', error);
        setError(error.message);
        setLoading(false);
      }
    };

    fetchData();
  }, [loggedInUser]);

  const hideModalScreen = () => {};
  const showModalScreen = (msgId) => {
    setCurrentClickedId(msgId);
  };
  const onDelete = () => {
    console.log('id', currentClickedId);
    axios
      .delete(
        `https://csmserver.csmdrivingschool.ca/StudentRegister.php?id=${currentClickedId}`
      )
      .then((response) => {
        setListOfRejectedRequests(
          listOfRejectedRequests.filter(function (item) {
            return item.id !== currentClickedId;
          })
        );
      });
    toast.success('Item deleted successfully', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      style: {
        background: 'rgba(0, 0, 0, 0.7)',
        color: 'white',
      },
    });
    hideModalScreen();
  };
  //
  function formatDate(dateString) {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      month: 'short',
      day: '2-digit',
      year: 'numeric',
    });
  }
  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }
  return (
    <div className="main">
      <div className="homeContainer">
        <ToastContainer></ToastContainer>
        <div
          class="modal fade"
          id="deleteModal"
          tabindex="-1"
          aria-labelledby="deleteModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="deleteModalLabel">
                  Delete Confirm
                </h5>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i class="fa-solid fa-xmark"></i>
                </button>
              </div>
              <div class="modal-body">
                <p>Are you sure you want to delete</p>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary mx-2"
                  data-bs-dismiss="modal"
                >
                  No
                </button>
                <button
                  type="button"
                  class="btn btn-primary"
                  onClick={onDelete}
                  data-bs-dismiss="modal"
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
        {/*  */}
        <div
          class="modal fade"
          id="detailModal"
          tabindex="-1"
          aria-labelledby="detailModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="detailModalLabel">
                  Detail
                </h5>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
                {editValues && (
                  <div>
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="userName">
                        Name
                      </label>

                      <b name="userName">{editValues.userName}</b>
                    </div>
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="address">
                        Address
                      </label>

                      <b name="address">{editValues.address}</b>
                    </div>
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="phoneNumber">
                        Phone Number
                      </label>

                      <b name="phoneNumber">{editValues.phoneNumber}</b>
                    </div>
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="email">
                        Email
                      </label>

                      <b name="email">{editValues.email}</b>
                    </div>
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="lessonSheets">
                        Lesson Sheets
                      </label>

                      <b name="lessonSheets">{editValues.lessonSheets}</b>
                    </div>
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="calendar">
                        calendar
                      </label>

                      <b name="calendar">{editValues.calendar}</b>
                    </div>

                    <div class="modal-footer">
                      <button class="btn btn-secondary" data-bs-dismiss="modal">
                        Close
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>{' '}
        <div className="container-fluid studentList">
          <div className="d-flex justify-content-center">
            <h2 className="mb-5">List Of Rejected Requests</h2>
          </div>
          <div class="card w-100" data-aos="fade-up" data-aos-delay="100">
            <div class="card-body">
              <table className="table table-striped table-hover">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Time</th>
                    <th>Date</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(listOfRejectedRequests) &&
                  listOfRejectedRequests.length > 0 ? (
                    listOfRejectedRequests.map((student) => (
                      <tr key={student.id}>
                        <td>{student.studentUserName}</td>
                        <td>{student.sessionTime}</td>
                        <td>{formatDate(student.sessionDate)}</td>
                        <td>
                          <button className="btn btn-primary">Accept</button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="4">No students available</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RejectedRequests;
