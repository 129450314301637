import React, { useEffect, useState, useReducer } from 'react';
import axios from 'axios';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from 'react-modal';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
function AdminInstructorRegister() {
  const [listOfItems, setListOfItems] = useState([]); // To store list of all items
  const [currentClickedId, setCurrentClickedId] = useState(''); // To Store the Clicked Id for Deletion
  const [editValues, setEditValues] = useState(null); // To store the values for Edit Modal (FORM)
  const [reducerValue, forceUpdate] = useReducer((x) => x + 1, 0); // To tell the useEffect that a row has been added
  const [selectedImage, setSelectedImage] = useState(null); // To show the Image full view in Modal
  const [addValues, setAddValues] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const handleSubmit = (value) => {
    setAddValues(value);
  };
  const initialValues = {
    firstname: '',
    lastname: '',
    username: '',
    licenseno: '',
    instructorlicenseno: '',
    carlicenseplateno: '',
    carpicture: '',
    ownership: '',
    cityvehiclelicensno: '',
    vehiclestandardcertificate: '',
    vehicleinsurancecertificate: '',
    phonenumber: '',
    alternativephonenumber: '',
    email: '',
    roofsign: '',
    licenseexpirydate: null,
    carmake: '',
    carmodel: '',
    carcolor: '',
    municiplelicenseexpiry: null,
    vehiclestandardcertificateexpiry: null,
    vehicleinsurancecertificateexpiry: null,
    contract: '',
    subscription: '',
    payperuse: '',
    conditions: '',
  };

  const validationSchema = Yup.object().shape({
    firstname: Yup.string().required(),
    lastname: Yup.string().required(),
    username: Yup.string().required(),
    licenseno: Yup.string().required(),
    instructorlicenseno: Yup.string().required(),
    carlicenseplateno: Yup.string().required(),
    carpicture: Yup.string().required(),
    ownership: Yup.string().required(),
    cityvehiclelicensno: Yup.string().required(),
    vehiclestandardcertificate: Yup.string().required(),
    vehicleinsurancecertificate: Yup.string().required(),
    phonenumber: Yup.string().required(),
    alternativephonenumber: Yup.string().required(),
    email: Yup.string().required(),
    roofsign: Yup.string().required(),
    licenseexpirydate: Yup.date().required(),
    carmake: Yup.string().required(),
    carmodel: Yup.string().required(),
    carcolor: Yup.string().required(),
    municiplelicenseexpiry: Yup.date().required(),
    vehiclestandardcertificateexpiry: Yup.date().required(),
    vehicleinsurancecertificateexpiry: Yup.date().required(),
    contract: Yup.string().required(),
    subscription: Yup.string().required(),
    payperuse: Yup.string().required(),
    conditions: Yup.string().required(),
  });

  useEffect(() => {
    // axios.get('http://csmserver.csmdrivingschool.ca/instructorregister.php').then((response) => {
    axios.get('http://localhost:3001/instructorregister').then((response) => {
      setListOfItems(response.data);
    });
  }, [reducerValue]);

  const showTostify = (msg) => {
    toast.success(msg, {
      position: 'bottom-right',
      autoClose: 5000, // in milliseconds, set to 5000 for 5 seconds
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      style: {
        background: 'rgba(0, 0, 0, 0.7)', // Transparent black background
        color: 'white',
      },
    });
  };
  const openImageModal = (imageData) => {
    setSelectedImage(imageData);
  };
  const closeImageModal = () => {
    setSelectedImage(null);
  };

  const onSubmit = async (data, { setSubmitting }) => {
    try {
      const formData = new FormData();
      formData.append('firstname', data.firstname);
      formData.append('lastname', data.lastname);
      formData.append('username', data.username);
      formData.append('licenseno', data.licenseno);
      formData.append('instructorlicenseno', data.instructorlicenseno);
      formData.append('carlicenseplateno', data.carlicenseplateno);
      formData.append('carpicture', data.carpicture);
      formData.append('ownership', data.ownership);
      formData.append('cityvehiclelicensno', data.cityvehiclelicensno);
      formData.append(
        'vehiclestandardcertificate',
        data.vehiclestandardcertificate
      );
      formData.append(
        'vehicleinsurancecertificate',
        data.vehicleinsurancecertificate
      );
      formData.append('phonenumber', data.phonenumber);
      formData.append('alternativephonenumber', data.alternativephonenumber);
      formData.append('email', data.email);
      formData.append('roofsign', data.roofsign);
      formData.append('licenseexpirydate', data.licenseexpirydate);
      formData.append('carmake', data.carmake);
      formData.append('carmodel', data.carmodel);
      formData.append('carcolor', data.carcolor);
      formData.append('municiplelicenseexpiry', data.municiplelicenseexpiry);
      formData.append(
        'vehiclestandardcertificateexpiry',
        data.vehiclestandardcertificateexpiry
      );
      formData.append(
        'vehicleinsurancecertificateexpiry',
        data.vehicleinsurancecertificateexpiry
      );
      formData.append('contract', data.contract);
      formData.append('subscription', data.subscription);
      formData.append('payperuse', data.payperuse);
      formData.append('conditions', data.conditions);

      await axios.post('http://localhost:3001/instructorregister', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      forceUpdate();
      showTostify('InstructorRegister saved');
    } catch (error) {
      console.error(error);
    } finally {
      setSubmitting(false);
    }
  };

  const onUpdate = async (data) => {
    try {
      const formData = new FormData();
      formData.append('id', data.id);
      formData.append('firstname', data.firstname);
      formData.append('lastname', data.lastname);
      formData.append('username', data.username);
      formData.append('licenseno', data.licenseno);
      formData.append('instructorlicenseno', data.instructorlicenseno);
      formData.append('carlicenseplateno', data.carlicenseplateno);
      formData.append('carpicture', data.carpicture);
      formData.append('ownership', data.ownership);
      formData.append('cityvehiclelicensno', data.cityvehiclelicensno);
      formData.append(
        'vehiclestandardcertificate',
        data.vehiclestandardcertificate
      );
      formData.append(
        'vehicleinsurancecertificate',
        data.vehicleinsurancecertificate
      );
      formData.append('phonenumber', data.phonenumber);
      formData.append('alternativephonenumber', data.alternativephonenumber);
      formData.append('email', data.email);
      formData.append('roofsign', data.roofsign);
      formData.append('licenseexpirydate', data.licenseexpirydate);
      formData.append('carmake', data.carmake);
      formData.append('carmodel', data.carmodel);
      formData.append('carcolor', data.carcolor);
      formData.append('municiplelicenseexpiry', data.municiplelicenseexpiry);
      formData.append(
        'vehiclestandardcertificateexpiry',
        data.vehiclestandardcertificateexpiry
      );
      formData.append(
        'vehicleinsurancecertificateexpiry',
        data.vehicleinsurancecertificateexpiry
      );
      formData.append('contract', data.contract);
      formData.append('subscription', data.subscription);
      formData.append('payperuse', data.payperuse);
      formData.append('conditions', data.conditions);

      await axios.put('http://localhost:3001/instructorregister', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      window.location.reload();
    } catch (error) {
      console.error('Error updating', error);
    }
  };

  const handleEditClick = (value) => {
    setEditValues(value);
  };

  const showModalScreen = (itemId) => {
    setCurrentClickedId(itemId);
  };

  const hideModalScreen = () => {};

  const onDelete = () => {
    axios
      .delete(`http://localhost:3001/InstructorRegister/${currentClickedId}`)
      .then((response) => {
        setListOfItems(
          listOfItems.filter(function (item) {
            return item.id != currentClickedId;
          })
        );
      });

    toast.success('Item deleted successfully', {
      position: 'bottom-right',
      autoClose: 5000, // in milliseconds, set to 5000 for 5 seconds
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      style: {
        background: 'rgba(0, 0, 0, 0.7)', // Transparent black background
        color: 'white',
      },
    });
    hideModalScreen();
  };
  //
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredData = listOfItems.filter((item) => {
    const values = Object.values(item).map((value) =>
      String(value).toLowerCase()
    );
    return values.some((value) => value.includes(searchTerm.toLowerCase()));
  });
  return (
    <div className="bg-light">
      <ToastContainer />
      <section className="middleArea">
        {/* Modal for delete */}

        <div
          class="modal fade"
          id="deleteModal"
          tabindex="-1"
          aria-labelledby="deleteModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="deleteModalLabel">
                  Delete Confirm
                </h5>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  style={{ backgroundColor: 'white', border: 'none' }}
                >
                  <i class="bi bi-x-lg" style={{ fontSize: 24 }}></i>
                </button>
              </div>
              <div class="modal-body">
                <p>Are you sure you want to delete this?</p>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-primary mx-2"
                  data-bs-dismiss="modal"
                >
                  No
                </button>
                <button
                  type="button"
                  class="btn btn-primary"
                  onClick={onDelete}
                  data-bs-dismiss="modal"
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* Modal for delete */}

        {/* Modal for displaying the full-size image */}
        <Modal
          isOpen={selectedImage !== null}
          onRequestClose={closeImageModal}
          contentLabel="Full Size Image"
          style={{
            content: {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              border: '1px solid #ccc',
              background: '#fff',
              borderRadius: '4px',
              padding: '20px',
              maxWidth: '80%',
              maxHeight: '80%',
              overflow: 'auto',
              margin: 'auto',
            },
          }}
        >
          {selectedImage && (
            <>
              <img
                src={selectedImage}
                alt="Full Size"
                style={{
                  maxWidth: '100%',
                  maxHeight: '100%',
                  display: 'block',
                  margin: 'auto',
                }}
              />
              <button
                onClick={closeImageModal}
                style={{
                  position: 'absolute',
                  top: '10px',
                  right: '15px',
                  background: 'none',
                  border: 'none',
                  fontSize: '16px',
                  cursor: 'pointer',
                }}
              >
                Close
              </button>
            </>
          )}
        </Modal>

        {/* Modal for Edit */}
        <div
          class="modal fade"
          id="exampleModal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog editModal">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  Edit Item
                </h5>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  style={{ backgroundColor: 'white', border: 'none' }}
                >
                  <i class="bi bi-x-lg" style={{ fontSize: 22 }}></i>
                </button>
              </div>
              <div class="modal-body">
                {editValues && (
                  <Formik
                    initialValues={editValues}
                    validationSchema={validationSchema}
                    enableReinitialize={true}
                    onSubmit={onUpdate}
                  >
                    {({ values, setFieldValue, isSubmitting }) => (
                      <Form>
                        <ErrorMessage name="firstname" component="span" />
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="firstname">
                            First Name
                          </label>

                          <Field
                            type="text"
                            name="firstname"
                            id="firstname"
                            placeholder="Enter a firstName"
                            class="form-control"
                          />
                        </div>
                        <ErrorMessage name="lastname" component="span" />
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="lastname">
                            Last Name
                          </label>

                          <Field
                            type="text"
                            name="lastname"
                            id="lastname"
                            placeholder="Enter a lastName"
                            class="form-control"
                          />
                        </div>
                        <ErrorMessage name="username" component="span" />
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="username">
                            User Name
                          </label>

                          <Field
                            type="text"
                            name="username"
                            id="username"
                            placeholder="Enter a userName"
                            class="form-control"
                          />
                        </div>
                        <div class="form-group fieldStyle">
                          <label for="licenseno" htmlFor="licenseno">
                            License No.
                          </label>

                          <input
                            type="file"
                            onChange={(event) => {
                              setFieldValue('licenseno', event.target.files[0]);
                            }}
                          ></input>
                        </div>
                        <div class="form-group fieldStyle">
                          <label
                            for="instructorlicenseno"
                            htmlFor="instructorlicenseno"
                          >
                            Instructor License No.
                          </label>

                          <input
                            type="file"
                            onChange={(event) => {
                              setFieldValue(
                                'instructorlicenseno',
                                event.target.files[0]
                              );
                            }}
                          ></input>
                        </div>
                        <div class="form-group fieldStyle">
                          <label
                            for="carlicenseplateno"
                            htmlFor="carlicenseplateno"
                          >
                            Car License Plate No.
                          </label>

                          <input
                            type="file"
                            onChange={(event) => {
                              setFieldValue(
                                'carlicenseplateno',
                                event.target.files[0]
                              );
                            }}
                          ></input>
                        </div>
                        <div class="form-group fieldStyle">
                          <label for="carpicture" htmlFor="carpicture">
                            Car Picture
                          </label>

                          <input
                            type="file"
                            onChange={(event) => {
                              setFieldValue(
                                'carpicture',
                                event.target.files[0]
                              );
                            }}
                          ></input>
                        </div>
                        <div class="form-group fieldStyle">
                          <label for="ownership" htmlFor="ownership">
                            Ownership
                          </label>

                          <input
                            type="file"
                            onChange={(event) => {
                              setFieldValue('ownership', event.target.files[0]);
                            }}
                          ></input>
                        </div>
                        <ErrorMessage
                          name="cityvehiclelicensno"
                          component="span"
                        />
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="cityvehiclelicensno">
                            City Vehicle License No.
                          </label>

                          <Field
                            type="text"
                            name="cityvehiclelicensno"
                            id="cityvehiclelicensno"
                            placeholder="Enter a cityVehicleLicensNo"
                            class="form-control"
                          />
                        </div>
                        <div class="form-group fieldStyle">
                          <label
                            for="vehiclestandardcertificate"
                            htmlFor="vehiclestandardcertificate"
                          >
                            Vehicle Standard Certificate
                          </label>

                          <input
                            type="file"
                            onChange={(event) => {
                              setFieldValue(
                                'vehiclestandardcertificate',
                                event.target.files[0]
                              );
                            }}
                          ></input>
                        </div>
                        <div class="form-group fieldStyle">
                          <label
                            for="vehicleinsurancecertificate"
                            htmlFor="vehicleinsurancecertificate"
                          >
                            Vehicle Insurance Certificate
                          </label>

                          <input
                            type="file"
                            onChange={(event) => {
                              setFieldValue(
                                'vehicleinsurancecertificate',
                                event.target.files[0]
                              );
                            }}
                          ></input>
                        </div>
                        <ErrorMessage name="phonenumber" component="span" />
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="phonenumber">
                            Phone Number
                          </label>

                          <Field
                            type="text"
                            name="phonenumber"
                            id="phonenumber"
                            placeholder="Enter a phoneNumber"
                            class="form-control"
                          />
                        </div>
                        <ErrorMessage
                          name="alternativephonenumber"
                          component="span"
                        />
                        <div class="form-group fieldStyle">
                          <label
                            for="inputName"
                            htmlFor="alternativephonenumber"
                          >
                            Alternative Phone Number
                          </label>

                          <Field
                            type="text"
                            name="alternativephonenumber"
                            id="alternativephonenumber"
                            placeholder="Enter a alternativePhoneNumber"
                            class="form-control"
                          />
                        </div>
                        <ErrorMessage name="email" component="span" />
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="email">
                            Email
                          </label>

                          <Field
                            type="text"
                            name="email"
                            id="email"
                            placeholder="Enter a email"
                            class="form-control"
                          />
                        </div>
                        <div class="form-group fieldStyle">
                          <label for="roofsign" htmlFor="roofsign">
                            Roof Sign
                          </label>

                          <input
                            type="file"
                            onChange={(event) => {
                              setFieldValue('roofsign', event.target.files[0]);
                            }}
                          ></input>
                        </div>
                        <ErrorMessage
                          name="licenseexpirydate"
                          component="span"
                        />
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="licenseexpirydate">
                            License Expiry Date
                          </label>

                          <Field name="licenseexpirydate" autoComplete="off">
                            {({ field, form, meta }) => (
                              <div placeholder="License Expiry Date">
                                <DatePicker
                                  id="licenseexpirydate"
                                  className="form-control date-picker-input mx-2"
                                  {...field}
                                  placeholderText="license expiry date"
                                  selected={field.value}
                                  onChange={(licenseexpirydate) => {
                                    // Ensure the date is set to midnight in UTC
                                    const adjustedDate = new Date(
                                      Date.UTC(
                                        licenseexpirydate.getUTCFullYear(),
                                        licenseexpirydate.getUTCMonth(),
                                        licenseexpirydate.getUTCDate()
                                      )
                                    );
                                    form.setFieldValue(
                                      field.name,
                                      adjustedDate
                                    );
                                  }}
                                />
                              </div>
                            )}
                          </Field>
                        </div>
                        <ErrorMessage name="carmake" component="span" />
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="carmake">
                            Car Make
                          </label>

                          <Field
                            type="text"
                            name="carmake"
                            id="carmake"
                            placeholder="Enter a carMake"
                            class="form-control"
                          />
                        </div>
                        <ErrorMessage name="carmodel" component="span" />
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="carmodel">
                            Car Model
                          </label>

                          <Field
                            type="text"
                            name="carmodel"
                            id="carmodel"
                            placeholder="Enter a carModel"
                            class="form-control"
                          />
                        </div>
                        <ErrorMessage name="carcolor" component="span" />
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="carcolor">
                            Car Color
                          </label>

                          <Field
                            type="text"
                            name="carcolor"
                            id="carcolor"
                            placeholder="Enter a carColor"
                            class="form-control"
                          />
                        </div>
                        <ErrorMessage
                          name="municiplelicenseexpiry"
                          component="span"
                        />
                        <div class="form-group fieldStyle">
                          <label
                            for="inputName"
                            htmlFor="municiplelicenseexpiry"
                          >
                            Municiple License Expiry Date
                          </label>

                          <Field
                            name="municiplelicenseexpiry"
                            autoComplete="off"
                          >
                            {({ field, form, meta }) => (
                              <div placeholder="municiplelicenseexpiry">
                                <DatePicker
                                  id="municiplelicenseexpiry"
                                  className="form-control date-picker-input mx-2"
                                  {...field}
                                  placeholderText="municiplelicenseexpiry"
                                  selected={field.value}
                                  onChange={(municiplelicenseexpiry) => {
                                    // Ensure the date is set to midnight in UTC
                                    const adjustedDate = new Date(
                                      Date.UTC(
                                        municiplelicenseexpiry.getUTCFullYear(),
                                        municiplelicenseexpiry.getUTCMonth(),
                                        municiplelicenseexpiry.getUTCDate()
                                      )
                                    );
                                    form.setFieldValue(
                                      field.name,
                                      adjustedDate
                                    );
                                  }}
                                />
                              </div>
                            )}
                          </Field>
                        </div>
                        <ErrorMessage
                          name="vehiclestandardcertificateexpiry"
                          component="span"
                        />
                        <div class="form-group fieldStyle">
                          <label
                            for="inputName"
                            htmlFor="vehiclestandardcertificateexpiry"
                          >
                            Vehicle Standard Certificate Expiry Date
                          </label>

                          <Field
                            name="vehiclestandardcertificateexpiry"
                            autoComplete="off"
                          >
                            {({ field, form, meta }) => (
                              <div placeholder="vehiclestandardcertificateexpiry">
                                <DatePicker
                                  id="dob"
                                  className="form-control date-picker-input mx-2"
                                  {...field}
                                  placeholderText="vehiclestandardcertificateexpiry"
                                  selected={field.value}
                                  onChange={(
                                    vehiclestandardcertificateexpiry
                                  ) => {
                                    // Ensure the date is set to midnight in UTC
                                    const adjustedDate = new Date(
                                      Date.UTC(
                                        vehiclestandardcertificateexpiry.getUTCFullYear(),
                                        vehiclestandardcertificateexpiry.getUTCMonth(),
                                        vehiclestandardcertificateexpiry.getUTCDate()
                                      )
                                    );
                                    form.setFieldValue(
                                      field.name,
                                      adjustedDate
                                    );
                                  }}
                                />
                              </div>
                            )}
                          </Field>
                        </div>
                        <ErrorMessage
                          name="vehicleinsurancecertificateexpiry"
                          component="span"
                        />
                        <div class="form-group fieldStyle">
                          <label
                            for="inputName"
                            htmlFor="vehicleinsurancecertificateexpiry"
                          >
                            Vehicle Insurance Certificate Expiry
                          </label>

                          <Field
                            name="vehicleinsurancecertificateexpiry"
                            autoComplete="off"
                          >
                            {({ field, form, meta }) => (
                              <div placeholder="vehicleinsurancecertificateexpiry">
                                <DatePicker
                                  id="vehicleinsurancecertificateexpiry"
                                  className="form-control date-picker-input mx-2"
                                  {...field}
                                  placeholderText="vehicleinsurancecertificateexpiry"
                                  selected={field.value}
                                  onChange={(
                                    vehicleinsurancecertificateexpiry
                                  ) => {
                                    // Ensure the date is set to midnight in UTC
                                    const adjustedDate = new Date(
                                      Date.UTC(
                                        vehicleinsurancecertificateexpiry.getUTCFullYear(),
                                        vehicleinsurancecertificateexpiry.getUTCMonth(),
                                        vehicleinsurancecertificateexpiry.getUTCDate()
                                      )
                                    );
                                    form.setFieldValue(
                                      field.name,
                                      adjustedDate
                                    );
                                  }}
                                />
                              </div>
                            )}
                          </Field>
                        </div>
                        <div class="form-group fieldStyle">
                          <label for="contract" htmlFor="contract">
                            Contract
                          </label>

                          <input
                            type="file"
                            onChange={(event) => {
                              setFieldValue('contract', event.target.files[0]);
                            }}
                          ></input>
                        </div>
                        <ErrorMessage name="subscription" component="span" />
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="subscription">
                            Subscription
                          </label>

                          <Field
                            type="text"
                            name="subscription"
                            id="subscription"
                            placeholder="Enter a subscription"
                            class="form-control"
                          />
                        </div>
                        <ErrorMessage name="payperuse" component="span" />
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="payperuse">
                            Pay Per Use
                          </label>

                          <Field
                            type="text"
                            name="payperuse"
                            id="payperuse"
                            placeholder="Enter a payPerUse"
                            class="form-control"
                          />
                        </div>
                        <ErrorMessage name="conditions" component="span" />
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="conditions">
                            Conditions
                          </label>

                          <Field
                            type="text"
                            name="conditions"
                            id="conditions"
                            placeholder="Enter a conditions"
                            class="form-control"
                          />
                        </div>

                        <div class="modal-footer">
                          <button type="submit" class="btn btn-primary mx-3">
                            Update
                          </button>
                          <button
                            class="btn btn-primary"
                            data-bs-dismiss="modal"
                          >
                            Close
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* Modal for Edit */}

        {/* Modal for Detail */}
        <div
          class="modal fade"
          id="detailModal"
          tabindex="-1"
          aria-labelledby="detailModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog detailModal">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="detailModalLabel">
                  Detail
                </h5>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  style={{ backgroundColor: 'white', border: 'none' }}
                >
                  <i class="bi bi-x-lg" style={{ fontSize: 22 }}></i>
                </button>
              </div>
              <div class="modal-body">
                {editValues && (
                  <div>
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="firstname">
                        First Name
                      </label>

                      <b name="firstname">{editValues.firstname}</b>
                    </div>
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="lastname">
                        Last Name
                      </label>

                      <b name="lastname">{editValues.lastname}</b>
                    </div>
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="username">
                        User Name
                      </label>

                      <b name="username">{editValues.username}</b>
                    </div>
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="cityvehiclelicensno">
                        City Vehicle LicenseNo.
                      </label>

                      <b name="cityvehiclelicensno">
                        {editValues.cityvehiclelicensno}
                      </b>
                    </div>
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="phonenumber">
                        Phone Number
                      </label>

                      <b name="phonenumber">{editValues.phonenumber}</b>
                    </div>
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="alternativephonenumber">
                        Alternative Phone Number
                      </label>

                      <b name="alternativephonenumber">
                        {editValues.alternativephonenumber}
                      </b>
                    </div>
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="email">
                        Email
                      </label>

                      <b name="email">{editValues.email}</b>
                    </div>
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="licenseexpirydate">
                        License Expiry Date
                      </label>

                      <b name="licenseexpirydate">
                        {editValues.licenseexpirydate}
                      </b>
                    </div>
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="carmake">
                        Car Make
                      </label>

                      <b name="carmake">{editValues.carmake}</b>
                    </div>
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="carmodel">
                        Car Model
                      </label>

                      <b name="carmodel">{editValues.carmodel}</b>
                    </div>
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="carcolor">
                        Car Color
                      </label>

                      <b name="carcolor">{editValues.carcolor}</b>
                    </div>
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="municiplelicenseexpiry">
                        Municiple License Expiry
                      </label>

                      <b name="municiplelicenseexpiry">
                        {editValues.municiplelicenseexpiry}
                      </b>
                    </div>
                    <div class="form-group fieldStyle">
                      <label
                        for="inputName"
                        htmlFor="vehiclestandardcertificateexpiry"
                      >
                        Vehicle Standard Certificate Expiry
                      </label>

                      <b name="vehiclestandardcertificateexpiry">
                        {editValues.vehiclestandardcertificateexpiry}
                      </b>
                    </div>
                    <div class="form-group fieldStyle">
                      <label
                        for="inputName"
                        htmlFor="vehicleinsurancecertificateexpiry"
                      >
                        Vehicle Insurance Certificate Expiry
                      </label>

                      <b name="vehicleinsurancecertificateexpiry">
                        {editValues.vehicleinsurancecertificateexpiry}
                      </b>
                    </div>
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="subscription">
                        Subscription
                      </label>

                      <b name="subscription">{editValues.subscription}</b>
                    </div>
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="payperuse">
                        Pay Per Use
                      </label>

                      <b name="payperuse">{editValues.payperuse}</b>
                    </div>
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="conditions">
                        Conditions
                      </label>

                      <b name="conditions">{editValues.conditions}</b>
                    </div>

                    <div class="modal-footer">
                      <div class="detailImageBoxContainer">
                        <div class="detailImageBox">
                          <img
                            src={editValues.licenseno}
                            alt="licenseno"
                            style={{ cursor: 'pointer' }}
                          />
                        </div>
                        <div class="detailImageBox">
                          <img
                            src={editValues.instructorlicenseno}
                            alt="instructorlicenseno"
                            style={{ cursor: 'pointer' }}
                          />
                        </div>
                        <div class="detailImageBox">
                          <img
                            src={editValues.carlicenseplateno}
                            alt="carlicenseplateno"
                            style={{ cursor: 'pointer' }}
                          />
                        </div>
                        <div class="detailImageBox">
                          <img
                            src={editValues.carpicture}
                            alt="carpicture"
                            style={{ cursor: 'pointer' }}
                          />
                        </div>
                        <div class="detailImageBox">
                          <img
                            src={editValues.ownership}
                            alt="ownership"
                            style={{ cursor: 'pointer' }}
                          />
                        </div>
                        <div class="detailImageBox">
                          <img
                            src={editValues.vehiclestandardcertificate}
                            alt="vehiclestandardcertificate"
                            style={{ cursor: 'pointer' }}
                          />
                        </div>
                        <div class="detailImageBox">
                          <img
                            src={editValues.vehicleinsurancecertificate}
                            alt="vehicleinsurancecertificate"
                            style={{ cursor: 'pointer' }}
                          />
                        </div>
                        <div class="detailImageBox">
                          <img
                            src={editValues.roofsign}
                            alt="roofsign"
                            style={{ cursor: 'pointer' }}
                          />
                        </div>
                        <div class="detailImageBox">
                          <img
                            src={editValues.contract}
                            alt="contract"
                            style={{ cursor: 'pointer' }}
                          />
                        </div>
                      </div>
                      <button class="btn btn-primary" data-bs-dismiss="modal">
                        Close
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* Modal for Detail */}

        <div class=" mt-5">
          <div class="row">
            <div class="col-12">
              <h2 class="text-center">
                {' '}
                <i class="fa-solid fa-list"></i> Instructor Registration
              </h2>
              <p class="text-center"> Manage Instructor Registration here.</p>
            </div>
          </div>

          <div class="row">
            <div
              class="modal fade"
              id="exampleSubmitModal"
              tabindex="-1"
              aria-labelledby="exampleSubmitModalLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog addModal">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="exampleSubmitModalLabel">
                      Add Instructor
                    </h5>
                    <button
                      type="button"
                      aria-label="Close"
                      data-bs-dismiss="modal"
                      style={{ backgroundColor: 'white', border: 'none' }}
                    >
                      <i class="bi bi-x-lg" style={{ fontSize: 22 }}></i>
                    </button>
                  </div>
                  <div className="modal-body">
                    <Formik
                      initialValues={initialValues}
                      onSubmit={onSubmit}
                      validationSchema={validationSchema}
                    >
                      {({ values, setFieldValue, isSubmitting }) => (
                        <Form>
                          <ErrorMessage name="firstname" component="span" />
                          <div class="form-group fieldStyle">
                            <label for="inputName" htmlFor="firstname">
                              First Name
                            </label>

                            <Field
                              type="text"
                              name="firstname"
                              id="firstname"
                              placeholder="Enter a firstName"
                              class="form-control"
                            />
                          </div>
                          <ErrorMessage name="lastname" component="span" />
                          <div class="form-group fieldStyle">
                            <label for="inputName" htmlFor="lastname">
                              Last Name
                            </label>

                            <Field
                              type="text"
                              name="lastname"
                              id="lastname"
                              placeholder="Enter a lastName"
                              class="form-control"
                            />
                          </div>
                          <ErrorMessage name="username" component="span" />
                          <div class="form-group fieldStyle">
                            <label for="inputName" htmlFor="username">
                              User Name
                            </label>

                            <Field
                              type="text"
                              name="username"
                              id="username"
                              placeholder="Enter a userName"
                              class="form-control"
                            />
                          </div>
                          <div class="form-group fieldStyle">
                            <label for="licenseno" htmlFor="licenseno">
                              License No.
                            </label>

                            <input
                              type="file"
                              onChange={(event) => {
                                setFieldValue(
                                  'licenseno',
                                  event.target.files[0]
                                );
                              }}
                            ></input>
                          </div>
                          <div class="form-group fieldStyle">
                            <label
                              for="instructorlicenseno"
                              htmlFor="instructorlicenseno"
                            >
                              Instructor LicenseNo.
                            </label>

                            <input
                              type="file"
                              onChange={(event) => {
                                setFieldValue(
                                  'instructorlicenseno',
                                  event.target.files[0]
                                );
                              }}
                            ></input>
                          </div>
                          <div class="form-group fieldStyle">
                            <label
                              for="carlicenseplateno"
                              htmlFor="carlicenseplateno"
                            >
                              Car License Plate No.
                            </label>

                            <input
                              type="file"
                              onChange={(event) => {
                                setFieldValue(
                                  'carlicenseplateno',
                                  event.target.files[0]
                                );
                              }}
                            ></input>
                          </div>
                          <div class="form-group fieldStyle">
                            <label for="carpicture" htmlFor="carpicture">
                              Car Picture
                            </label>

                            <input
                              type="file"
                              onChange={(event) => {
                                setFieldValue(
                                  'carpicture',
                                  event.target.files[0]
                                );
                              }}
                            ></input>
                          </div>
                          <div class="form-group fieldStyle">
                            <label for="ownership" htmlFor="ownership">
                              Ownership
                            </label>

                            <input
                              type="file"
                              onChange={(event) => {
                                setFieldValue(
                                  'ownership',
                                  event.target.files[0]
                                );
                              }}
                            ></input>
                          </div>
                          <ErrorMessage
                            name="cityvehiclelicensno"
                            component="span"
                          />
                          <div class="form-group fieldStyle">
                            <label
                              for="inputName"
                              htmlFor="cityvehiclelicensno"
                            >
                              City Vehicle License No.
                            </label>

                            <Field
                              type="text"
                              name="cityvehiclelicensno"
                              id="cityvehiclelicensno"
                              placeholder="Enter a cityVehicleLicensNo"
                              class="form-control"
                            />
                          </div>
                          <div class="form-group fieldStyle">
                            <label
                              for="vehiclestandardcertificate"
                              htmlFor="vehiclestandardcertificate"
                            >
                              Vehicle Standard Certificate
                            </label>

                            <input
                              type="file"
                              onChange={(event) => {
                                setFieldValue(
                                  'vehiclestandardcertificate',
                                  event.target.files[0]
                                );
                              }}
                            ></input>
                          </div>
                          <div class="form-group fieldStyle">
                            <label
                              for="vehicleinsurancecertificate"
                              htmlFor="vehicleinsurancecertificate"
                            >
                              Vehicle Insurance Certificate
                            </label>

                            <input
                              type="file"
                              onChange={(event) => {
                                setFieldValue(
                                  'vehicleinsurancecertificate',
                                  event.target.files[0]
                                );
                              }}
                            ></input>
                          </div>
                          <ErrorMessage name="phonenumber" component="span" />
                          <div class="form-group fieldStyle">
                            <label for="inputName" htmlFor="phonenumber">
                              Phone Number
                            </label>

                            <Field
                              type="text"
                              name="phonenumber"
                              id="phonenumber"
                              placeholder="Enter a phoneNumber"
                              class="form-control"
                            />
                          </div>
                          <ErrorMessage
                            name="alternativephonenumber"
                            component="span"
                          />
                          <div class="form-group fieldStyle">
                            <label
                              for="inputName"
                              htmlFor="alternativephonenumber"
                            >
                              Alternative Phone Number
                            </label>

                            <Field
                              type="text"
                              name="alternativephonenumber"
                              id="alternativephonenumber"
                              placeholder="Enter a alternativePhoneNumber"
                              class="form-control"
                            />
                          </div>
                          <ErrorMessage name="email" component="span" />
                          <div class="form-group fieldStyle">
                            <label for="inputName" htmlFor="email">
                              Email
                            </label>

                            <Field
                              type="text"
                              name="email"
                              id="email"
                              placeholder="Enter a email"
                              class="form-control"
                            />
                          </div>
                          <div class="form-group fieldStyle">
                            <label for="roofsign" htmlFor="roofsign">
                              Roof Sign
                            </label>
                            <input
                              type="file"
                              onChange={(event) => {
                                setFieldValue(
                                  'roofsign',
                                  event.target.files[0]
                                );
                              }}
                            ></input>
                          </div>
                          <ErrorMessage
                            name="licenseexpirydate"
                            component="span"
                          />
                          <div class="form-group fieldStyle">
                            <label for="inputName" htmlFor="licenseexpirydate">
                              License Expiry Date
                            </label>

                            <Field
                              type="text"
                              name="licenseexpirydate"
                              id="licenseexpirydate"
                              placeholder="Enter a licenseExpiryDate"
                              class="form-control"
                            />
                          </div>
                          <ErrorMessage name="carmake" component="span" />
                          <div class="form-group fieldStyle">
                            <label for="inputName" htmlFor="carmake">
                              Car Make
                            </label>

                            <Field
                              type="text"
                              name="carmake"
                              id="carmake"
                              placeholder="Enter a carMake"
                              class="form-control"
                            />
                          </div>
                          <ErrorMessage name="carmodel" component="span" />
                          <div class="form-group fieldStyle">
                            <label for="inputName" htmlFor="carmodel">
                              Car Model
                            </label>

                            <Field
                              type="text"
                              name="carmodel"
                              id="carmodel"
                              placeholder="Enter a carModel"
                              class="form-control"
                            />
                          </div>
                          <ErrorMessage name="carcolor" component="span" />
                          <div class="form-group fieldStyle">
                            <label for="inputName" htmlFor="carcolor">
                              Car Color
                            </label>

                            <Field
                              type="text"
                              name="carcolor"
                              id="carcolor"
                              placeholder="Enter a carColor"
                              class="form-control"
                            />
                          </div>
                          <ErrorMessage
                            name="municiplelicenseexpiry"
                            component="span"
                          />
                          <div class="form-group fieldStyle">
                            <label
                              for="inputName"
                              htmlFor="municiplelicenseexpiry"
                            >
                              Municiple License Expiry Date
                            </label>

                            <Field
                              type="text"
                              name="municiplelicenseexpiry"
                              id="municiplelicenseexpiry"
                              placeholder="Enter a municipleLicenseExpiry"
                              class="form-control"
                            />
                          </div>
                          <ErrorMessage
                            name="vehiclestandardcertificateexpiry"
                            component="span"
                          />
                          <div class="form-group fieldStyle">
                            <label
                              for="inputName"
                              htmlFor="vehiclestandardcertificateexpiry"
                            >
                              Vehicle Standard Certificate Expiry Date
                            </label>

                            <Field
                              type="text"
                              name="vehiclestandardcertificateexpiry"
                              id="vehiclestandardcertificateexpiry"
                              placeholder="Enter a vehicleStandardCertificateExpiry"
                              class="form-control"
                            />
                          </div>
                          <ErrorMessage
                            name="vehicleinsurancecertificateexpiry"
                            component="span"
                          />
                          <div class="form-group fieldStyle">
                            <label
                              for="inputName"
                              htmlFor="vehicleinsurancecertificateexpiry"
                            >
                              Vehicle Insurance Certificate Expiry
                            </label>

                            <Field
                              type="text"
                              name="vehicleinsurancecertificateexpiry"
                              id="vehicleinsurancecertificateexpiry"
                              placeholder="Enter a vehicleInsuranceCertificateExpiry"
                              class="form-control"
                            />
                          </div>
                          <div class="form-group fieldStyle">
                            <label for="contract" htmlFor="contract">
                              Contract
                            </label>

                            <input
                              type="file"
                              onChange={(event) => {
                                setFieldValue(
                                  'contract',
                                  event.target.files[0]
                                );
                              }}
                            ></input>
                          </div>
                          <ErrorMessage name="subscription" component="span" />
                          <div class="form-group fieldStyle">
                            <label for="inputName" htmlFor="subscription">
                              Subscription
                            </label>

                            <Field
                              type="text"
                              name="subscription"
                              id="subscription"
                              placeholder="Enter a subscription"
                              class="form-control"
                            />
                          </div>
                          <ErrorMessage name="payperuse" component="span" />
                          <div class="form-group fieldStyle">
                            <label for="inputName" htmlFor="payperuse">
                              Pay Per Use
                            </label>

                            <Field
                              type="text"
                              name="payperuse"
                              id="payperuse"
                              placeholder="Enter a payPerUse"
                              class="form-control"
                            />
                          </div>
                          <ErrorMessage name="conditions" component="span" />
                          <div class="form-group fieldStyle">
                            <label for="inputName" htmlFor="conditions">
                              Conditions
                            </label>

                            <Field
                              type="text"
                              name="conditions"
                              id="conditions"
                              placeholder="Enter a conditions"
                              class="form-control"
                            />
                          </div>

                          <button
                            type="submit"
                            class="btn btn-primary"
                            disabled={isSubmitting}
                          >
                            Add
                          </button>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex">
            <button
              class="btn btn-primary mx-3"
              data-bs-toggle="modal"
              data-bs-target="#exampleSubmitModal"
              onClick={() => onSubmit()}
            >
              Add
            </button>
            {/* search bar */}
            <input
              className=" form-control col-3 mt-4"
              type="text"
              placeholder="Search here"
              value={searchTerm}
              onChange={handleSearch}
              style={{ height: 40 }}
            />
          </div>
          <div
            class="insRegisterTable"
            style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}
          >
            <table class="table table-striped table-hover">
              <thead className="bg-secondary text-white">
                <tr>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>User Name</th>
                  <th>License No.</th>
                  <th>Instructor License No.</th>
                  <th>Car License Plate No</th>
                  <th>Car Picture</th>
                  <th>Ownership</th>
                  <th>City Vehicle License No.</th>
                  <th>Vehicle Standard Certificate</th>
                  <th>Vehicle Insurance Certificate</th>
                  <th>Phone Number</th>
                  <th>Alternative Phone Number</th>
                  <th>Email</th>
                  <th>Roof Sign</th>
                  <th>License Expiry Date</th>
                  <th>Car Make</th>
                  <th>Car Model</th>
                  <th>Car Color</th>
                  <th>Municiple License Expiry</th>
                  <th>Vehicle Standard Certificate Expiry</th>
                  <th>Vehicle Insurance Certificate Expiry</th>
                  <th>Contract</th>
                  <th>Subscription</th>
                  <th>Pay PerUse</th>
                  <th>Conditions</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {filteredData.map((value, key) => {
                  return (
                    <tr key={key}>
                      <td>{value.firstname}</td>
                      <td>{value.lastname}</td>
                      <td>{value.username}</td>
                      <td>
                        {value.licenseno && (
                          <img
                            src={value.licenseno}
                            alt="InstructorRegister"
                            onClick={() => openImageModal(value.licenseno)}
                            style={{ cursor: 'pointer' }}
                            height="100px"
                            width="100px"
                          />
                        )}
                      </td>
                      <td>
                        {value.instructorlicenseno && (
                          <img
                            src={value.instructorlicenseno}
                            alt="InstructorRegister"
                            onClick={() =>
                              openImageModal(value.instructorlicenseno)
                            }
                            style={{ cursor: 'pointer' }}
                            height="100px"
                            width="100px"
                          />
                        )}
                      </td>
                      <td>
                        {value.carlicenseplateno && (
                          <img
                            src={value.carlicenseplateno}
                            alt="InstructorRegister"
                            onClick={() =>
                              openImageModal(value.carlicenseplateno)
                            }
                            style={{ cursor: 'pointer' }}
                            height="100px"
                            width="100px"
                          />
                        )}
                      </td>
                      <td>
                        {value.carpicture && (
                          <img
                            src={value.carpicture}
                            alt="InstructorRegister"
                            onClick={() => openImageModal(value.carpicture)}
                            style={{ cursor: 'pointer' }}
                            height="100px"
                            width="100px"
                          />
                        )}
                      </td>
                      <td>
                        {value.ownership && (
                          <img
                            src={value.ownership}
                            alt="InstructorRegister"
                            onClick={() => openImageModal(value.ownership)}
                            style={{ cursor: 'pointer' }}
                            height="100px"
                            width="100px"
                          />
                        )}
                      </td>
                      <td>{value.cityvehiclelicensno}</td>
                      <td>
                        {value.vehiclestandardcertificate && (
                          <img
                            src={value.vehiclestandardcertificate}
                            alt="InstructorRegister"
                            onClick={() =>
                              openImageModal(value.vehiclestandardcertificate)
                            }
                            style={{ cursor: 'pointer' }}
                            height="100px"
                            width="100px"
                          />
                        )}
                      </td>
                      <td>
                        {value.vehicleinsurancecertificate && (
                          <img
                            src={value.vehicleinsurancecertificate}
                            alt="InstructorRegister"
                            onClick={() =>
                              openImageModal(value.vehicleinsurancecertificate)
                            }
                            style={{ cursor: 'pointer' }}
                            height="100px"
                            width="100px"
                          />
                        )}
                      </td>
                      <td>{value.phonenumber}</td>
                      <td>{value.alternativephonenumber}</td>
                      <td>{value.email}</td>
                      <td>
                        {value.roofsign && (
                          <img
                            src={value.roofsign}
                            alt="InstructorRegister"
                            onClick={() => openImageModal(value.roofsign)}
                            style={{ cursor: 'pointer' }}
                            height="100px"
                            width="100px"
                          />
                        )}
                      </td>
                      <td>{value.licenseexpirydate}</td>
                      <td>{value.carmake}</td>
                      <td>{value.carmodel}</td>
                      <td>{value.carcolor}</td>
                      <td>{value.municiplelicenseexpiry}</td>
                      <td>{value.vehiclestandardcertificateexpiry}</td>
                      <td>{value.vehicleinsurancecertificateexpiry}</td>
                      <td>
                        {value.contract && (
                          <img
                            src={value.contract}
                            alt="InstructorRegister"
                            onClick={() => openImageModal(value.contract)}
                            style={{ cursor: 'pointer' }}
                            height="100px"
                            width="100px"
                          />
                        )}
                      </td>
                      <td>{value.subscription}</td>
                      <td>{value.payperuse}</td>
                      <td>{value.conditions}</td>

                      <td>
                        <a class="nav-item dropdown">
                          <a
                            class="fa-solid fa-circle-chevron-down"
                            data-bs-toggle="dropdown"
                            href="#"
                            role="button"
                            aria-haspopup="true"
                            aria-expanded="false"
                          ></a>
                          <div class="dropdown-menu">
                            <Link
                              class="dropdown-item"
                              data-bs-toggle="modal"
                              data-bs-target="#exampleModal"
                              onClick={() => handleEditClick(value)}
                            >
                              Edit
                            </Link>

                            <Link
                              class="dropdown-item"
                              data-bs-toggle="modal"
                              data-bs-target="#detailModal"
                              onClick={() => handleEditClick(value)}
                            >
                              Detail
                            </Link>

                            <div class="dropdown-divider"></div>
                            <Link
                              class="dropdown-item"
                              onClick={() => showModalScreen(value.id)}
                              msgId={value.id}
                              data-bs-toggle="modal"
                              data-bs-target="#deleteModal"
                            >
                              Delete
                            </Link>
                          </div>
                        </a>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </div>
  );
}

export default AdminInstructorRegister;
