import React from 'react';
import axios from 'axios';
import ROUTES from '../../navigations/Routes';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
function PracticeCategories() {
  const [listOfItems, setListOfItems] = useState([]);
  const [listOfImages, setListOfImages] = useState([]);
  const [loggedIn, setLoggedIn] = useState('');
  useEffect(() => {
    const fetchTheoryTestCategories = async () => {
      try {
        const response = await fetch(
          'https://csmserver.csmdrivingschool.ca/TheoryTestCategories.php'
        );

        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }

        const data = await response.json();
        setListOfItems(data);
        setListOfImages(Array(data.length).fill(null));
        console.log(data);
      } catch (error) {
        console.error('Error fetching theory test categories:', error);
      }
    };

    fetchTheoryTestCategories();
  }, []);

  return (
    <div className="main">
      <div className="homeContainer">
        <div class="container-fluid QContainer">
          <div class="row1">
            {/* {listOfItems.map((value, key) => {
              return ( */}

            <div className="row testCategory">
              {listOfItems.map((value, key) => {
                return (
                  <div
                    className="col-lg-5 col-10 py-4 px-1"
                    data-aos="fade-up"
                    data-aos-delay="0"
                  >
                    <div className="categoryBox my-2 bg-white">
                      <div className="categoryname">
                        <h2 style={{ fontSize: 30 }}>{value.categoryname}</h2>
                        <Link
                          to={`${ROUTES.practiceModules.name}/${value.id}`}
                          // onClick={onNavigate}
                        >
                          Start Learning
                        </Link>
                      </div>
                      <div className="testImg">
                        <img
                          src={`../${value.image.replace(/\\/g, '/')}`} // Convert backslashes to forward slashes
                          alt="Questions"
                          className="testImage"
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PracticeCategories;
