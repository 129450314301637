import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
function Contact() {
  const Navigation = useNavigate();
  const initialValues = {
    name: '',
    email: '',
    subject: '',
    message: '',
  };
  const validationSchema = Yup.object().shape({
    name: Yup.string().required(),
    email: Yup.string().required(),
    subject: Yup.string().min(3).max(15).required(),
    message: Yup.string().min(3).required(),
  });
  const onSubmit = (data) => {
    axios
      .post('http://csmserver.csmdrivingschool.ca/Contact.php', data)
      .then((response) => {
        console.log('IT WORKED');
      });
  };

  useEffect(() => {
    // getAll();
  }, []);

  return (
    <div className="main">
      <div className="homeContainer">
        <div
          class="untree_co-hero inner-page container-fluid contactPage"
          style={{
            backgroundImage: `url('/images/road.jpg')`,
            height: '70vh',
            paddingBottom: 90,
          }}
        >
          <div class="container-fluid">
            <div class="row align-items-center justify-content-center">
              <div class="col-12">
                <div class="row  ">
                  <div
                    class="col-lg-6 heading text-white contactBox px-5"
                    data-aos="fade-up"
                    data-aos-delay="100"
                    style={{ paddingTop: 50 }}
                  >
                    <h1 className="text-white">CSM Driving School</h1>
                    <h5 className="mt-5">License No:</h5>
                    <h4 className="text-white mt-3"> Contact Info:</h4>
                    <div className="d-flex my-2">
                      <i class="bi bi-envelope mx-3"></i>
                      <h5 className="pt-2"> csmdrivingschool@outlook.com</h5>
                    </div>
                    <div className="d-flex my-2">
                      <i class="bi bi-telephone-fill mx-3"></i>
                      <h5 className="pt-2"> 437-887-0487</h5>
                    </div>
                    <div className="d-flex my-2">
                      <i class="fa-solid fa-fax mx-3"></i>
                      <h5 className="pt-2"> 647 490-0094</h5>
                    </div>
                    <div className="d-flex my-2">
                      <i class="fa-solid fa-headset mx-3"></i>
                      <h5 className="pt-2"> (886)791-9291</h5>
                    </div>
                    <button className="btn btnApply">Apply Now</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="container-fluid page-header  py-5 wow fadeIn"
          data-wow-delay="0.1s"
        ></div>
        {/* <div
          className="container-fluid bg-white py-5"
          style={{ marginTop: 120 }}
        >
          <div className="container-fluid">
            <div className="row g-5">
              <div
                className="col-lg-6 wow fadeInUp"
                data-wow-delay="0.1s"
                style={{ minHeight: 450 }}
              >
                <div className="position-relative h-100">
                  <iframe
                    className="position-relative w-100 h-100"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d43048455.57069026!2d-135.7196459051398!3d48.811108246309374!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4b0d03d337cc6ad9%3A0x9968b72aa2438fa5!2sCanada!5e0!3m2!1sen!2sin!4v1699454040784!5m2!1sen!2sin"
                    style={{ border: 0, minHeight: 450 }}
                    tabIndex="0"
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </div>
              <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
                <h6 className="text-primary text-uppercase mb-2">Contact Us</h6>
                <h1 className="display-6 mb-4">
                  If You Have Any Query, Please Contact Us
                </h1>
                <Formik
                  initialValues={initialValues}
                  onSubmit={onSubmit}
                  validationSchema={validationSchema}
                >
                  <Form>
                    <div className="row g-3">
                      <div className="col-md-6">
                        <div className="form-floating">
                          <Field
                            type="text"
                            className="form-control border-1 mb-3"
                            id="txtname"
                            placeholder="Your Name"
                            autoComplete="off"
                            name="name"
                          />
                          <ErrorMessage
                            name="name"
                            component="span"
                            style={{ color: 'red' }}
                          ></ErrorMessage>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-floating">
                          <Field
                            type="email"
                            className="form-control border-1 mb-3"
                            id="txtemail"
                            placeholder="Your Email"
                            name="email"
                          />
                          <ErrorMessage
                            name="email"
                            component="span"
                            style={{ color: 'red' }}
                          ></ErrorMessage>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-floating">
                          <Field
                            type="text"
                            className="form-control border-1 mb-3"
                            id="txtsubject"
                            placeholder="Subject"
                            name="subject"
                          />
                          <ErrorMessage
                            name="subject"
                            component="span"
                            style={{ color: 'red' }}
                          ></ErrorMessage>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-floating">
                          <Field
                            className="form-control border-1 mb-3"
                            placeholder="Leave a message here"
                            id="txtmessage"
                            type="true"
                            style={{ height: 150 }}
                            name="message"
                          />
                          <ErrorMessage
                            name="message"
                            component="span"
                            style={{ color: 'red' }}
                          ></ErrorMessage>
                        </div>
                      </div>
                      <div className="col-12">
                        <button
                          className="btn btn-primary py-3 px-5"
                          type="submit"
                        >
                          Send Message
                        </button>
                      </div>
                    </div>
                  </Form>
                </Formik>
              </div>
            </div>
          </div>
        </div> */}
        {/* Contact End --> */}
      </div>
    </div>
  );
}
export default Contact;
