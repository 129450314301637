import React from 'react';
import axios from 'axios';
import ROUTES from '../../navigations/Routes';
import { useState, useEffect } from 'react';
function PracticeTest() {
  const [listOfItems, setListOfItems] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

  useEffect(() => {
    axios
      .get('https://csmserver.csmdrivingschool.ca/Questions.php')
      .then((response) => {
        setListOfItems(response.data);
      });
  }, []);
  const handleNext = () => {
    if (currentQuestionIndex < listOfItems.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
  };

  const handlePrev = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };
  const changeOptionClass = (option) => {
    // Implement logic to change option class here
    console.log(`Option ${option} clicked`);
  };
  return (
    <div className="main">
      <div className="homeContainer">
        <div class="container-fluid QContainer">
          <div class="row1">
            {/* {listOfItems.map((value, key) => {
              return ( */}
            <div class="Item itemTest">
              <div class="ImageContainer1">
                <img src={listOfItems[currentQuestionIndex]?.image} alt="" />
              </div>
              <div class="contentContaner5">
                <p>
                  <b>Q:{listOfItems[currentQuestionIndex]?.question}</b>
                </p>
                <div class="options">
                  <input
                    type="radio"
                    id="radio1"
                    name="select1"
                    onClick={() => changeOptionClass('option1')}
                  />
                  <label for="radio1">
                    <p>{listOfItems[currentQuestionIndex]?.option1}</p>
                  </label>
                </div>

                <div class="options">
                  <input
                    type="radio"
                    id="radio2"
                    name="select1"
                    onClick={() => changeOptionClass('option2')}
                  />
                  <label for="radio2">
                    <p> {listOfItems[currentQuestionIndex]?.option2}</p>
                  </label>
                </div>

                <div class="options">
                  <input
                    type="radio"
                    id="radio3"
                    name="select1"
                    onClick={() => changeOptionClass('option3')}
                  />
                  <label for="radio3">
                    <p>{listOfItems[currentQuestionIndex]?.option3}</p>
                  </label>
                </div>

                <div class="options">
                  <input
                    type="radio"
                    id="radio4"
                    name="select1"
                    onClick={() => changeOptionClass('option4')}
                  />
                  <label for="radio4">
                    <p>{listOfItems[currentQuestionIndex]?.option4}</p>
                  </label>
                </div>
                <div>
                  <h6 className="mt-4">
                    <span className="font-weight-bold">Correct Answer:</span>
                    {listOfItems[currentQuestionIndex]?.correct}
                  </h6>
                  <span className="font-weight-bold">Explanation:</span>
                  {listOfItems[currentQuestionIndex]?.explanation}
                  <h5></h5>
                </div>
              </div>
            </div>
            {/* );
            })} */}

            <div class="buttonContainer">
              <button class="btn btn-outline-primary " onClick={handlePrev}>
                prev
              </button>
              <button class="btn btn-outline-primary" onClick={handleNext}>
                next
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PracticeTest;
