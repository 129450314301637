import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
const SliderText = () => {
  const settings = {
    // dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };
  const images = [
    {
      text: 'CSM Driving School',
      text3:
        'Students at CSM Driving School receive the strong foundation they require from a team of knowledgeable instructors who provide both in-car and online instruction. Students who attend CSM Driving School learn how to drive safely and maintain a spotless driving record.',
    },
    {
      text: 'MTO Approved',
      text1: 'Begginer Drive Education',
      text2: 'Course Provider',
      para: 'Providing Ministry-Approved Initial Driver Education Programs: Enroll Now!',
    },
    {
      text: '98%',
      text1: 'Passing ratio',
      para: 'The GTA and Ontario Province are the top choices for high school students.',
    },
  ];
  return (
    <Slider {...settings}>
      {images.map((item, index) => (
        <div key={index} className="slider-TextItem">
          <div className="container-fluid">
            <div className="image-overlay">
              <h2 className="image-text mx-1">{item.text}</h2>
              <h3 className="image-heading mx-1">{item.text1}</h3>
              <h3 className="image-heading2 mx-1">{item.text2}</h3>
              <p className="image-para">{item.text3}</p>
              <p className="para">{item.para}</p>
            </div>
          </div>
        </div>
      ))}
    </Slider>
  );
};

export default SliderText;
