import React from 'react';
import ROUTES from '../../navigations/Routes';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
function AdminPanel() {
  const ToggleB = () => {
    let sidebar = document.querySelector('.Sideheader');
    let AdminB = document.querySelector('.bodyContainer');
    let name = document.querySelectorAll('.name');
    sidebar.classList.toggle('Sideheader1');
    AdminB.classList.toggle('bodyContainer1');
    name.forEach((name) => {
      name.classList.toggle('name1');
    });
  };

  const navigate = useNavigate();
  useEffect(() => {
    const handleBackButton = (event) => {
      event.preventDefault();
      navigate('/adminLogin');
    };

    window.addEventListener('popstate', handleBackButton);

    return () => {
      window.removeEventListener('popstate', handleBackButton);
    };
  }, []);

  const handleLogout = () => {
    const confirmLogout = window.confirm('Are you sure you want to logout?');
    if (confirmLogout) {
      localStorage.removeItem('id');
      navigate('/adminLogin');
    }
  };
  return (
    <div class="AdminMContainer">
      <div class="Sideheader">
        <button class="btn2" onClick={ToggleB}>
          <i class="fa-solid fa-bars-staggered"></i>
        </button>

        <ul>
          <li>
            <Link class="anchor" title="Home Page" to={ROUTES.adminhome.name}>
              <i class="fa-solid fa-house-chimney "></i>{' '}
              <span class="name">Home</span>{' '}
            </Link>
          </li>
          <li>
            <Link class="anchor" title="BDE List" to={ROUTES.bdeRegister.name}>
              <i class="bi bi-car-front"></i>
              <span class="name">BDE Register</span>
            </Link>
          </li>
          <li>
            <Link
              class="anchor"
              title="BDE Package"
              to={ROUTES.bdePackage.name}
            >
              <i class="fa-solid fa-id-card"></i>
              <span class="name">BDE Package</span>{' '}
            </Link>
          </li>
          <li>
            <Link
              class="anchor"
              title="Instructors List"
              to={ROUTES.instructorAdmin.name}
            >
              <i class="fa-solid fa-users"></i>
              <span class="name">Instructors List</span>{' '}
            </Link>
          </li>
          <li>
            <Link
              class="anchor"
              title="Contact List"
              to={ROUTES.admincontact.name}
            >
              <i class="fa-regular fa-address-book"></i>
              <span class="name">Contact list</span>{' '}
            </Link>
          </li>

          <li>
            <Link class="anchor" title="Location" to={ROUTES.location.name}>
              <i class="bi bi-geo-alt-fill"></i>
              <span class="name">Location</span>{' '}
            </Link>
          </li>
          <li>
            <Link
              class="anchor"
              title="Instructor Detail"
              to={ROUTES.adminInsructorDetail.name}
            >
              <i class="bi bi-info-circle-fill"></i>
              <span class="name">Instructor Detail</span>{' '}
            </Link>
          </li>

          <li>
            <a
              className="anchor"
              title="Log Off from Admin panel & Go to Home Page"
              href="/"
              onClick={handleLogout}
            >
              <i className="fa-solid fa-power-off"></i>{' '}
              <span className="name">Log Out</span>{' '}
            </a>
          </li>
        </ul>
      </div>
      <div class="bodyContainer">
        <div class="AdminContainer">
          <Outlet></Outlet>
        </div>
      </div>
    </div>
  );
}
export default AdminPanel;
