import React from 'react';
import { Formik, Field, ErrorMessage, Form } from 'formik';
import StripeCheckout from 'react-stripe-checkout';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
function Payment() {
  const [listOfPayment, setListOfPayment] = useState([]);
  const { id } = useParams();
  useEffect(() => {
    //const id = 16;
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `http://localhost:3001/payment/pay/${id}`
        );

        const paymentData = response.data;
        fetchData(id);
        console.log('Fetched Payment:', paymentData);

        if (paymentData.length === 0) {
          console.log('No Amount');
        }

        setListOfPayment(paymentData);
        console.log(paymentData, 'data');
      } catch (error) {
        console.error('Error fetching Amount:', error);
      }
    };

    fetchData();
  }, [setListOfPayment]);
  return (
    <div className="main">
      <div className="homeContainer">
        <div
          class="container-fluid d-flex"
          style={{ height: 'auto', paddingTop: 100, paddingBottom: 30 }}
        >
          <div class="container-fluid col-lg-4">
            <div
              class="row align-items-center justify-content-center"
              style={{
                backgroundImage: `url('/images/bank.jpg')`,
                height: 1000,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
              }}
            >
              {/* <img src="/images/bank.jpg" height="80vh"></img> */}
              <div class="row justify-content-center ">
                <div class="text-center">
                  <h1
                    class="heading"
                    data-aos="fade-up"
                    data-aos-delay="100"
                    style={{
                      //  margin: 'auto',
                      fontSize: 44,
                      color: 'white',
                    }}
                  >
                    Payment
                  </h1>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid bg-white">
            <div className="payment-form">
              <Formik
              // initialValues={initialValues}
              // validationSchema={validationSchema}
              // onSubmit={handleSubmit}
              >
                <Form>
                  <div class="col-12">
                    <div class="text-center" style={{ fontSize: 22 }}>
                      Summary
                    </div>
                    <div class="card-body summaryBox col-12">
                      {listOfPayment.length === 0 ? (
                        <div>
                          <p>No Amount</p>
                        </div>
                      ) : (
                        listOfPayment.map((amount) => (
                          <div key={amount.id}>
                            <div className="row d-flex justify-content-between bd-highlight">
                              <h5 class="mr-5">Package Name</h5>
                              <h6 className="">
                                {amount.bdePackage.packageName}
                              </h6>
                            </div>
                            <div className="row d-flex justify-content-between bd-highlight ">
                              <h5 class="card-text bd-highlight">Course Fee</h5>
                              <h6 className="text-right">
                                ${amount.bdeRegister.courseFee}
                              </h6>
                            </div>
                            <div className="row d-flex  mb-1">
                              <h5 class="card-text bd-highlight">
                                Road Test Price
                              </h5>
                              <h6 className="ml-auto px-2 bd-highlight">
                                ${amount.bdeRegister.roadTestPrice}
                              </h6>
                            </div>
                            <div className="row d-flex  mb-1">
                              <h5 class="card-text bd-highlight">HST Price</h5>
                              <h6 className="ml-auto px-2 bd-highlight">
                                ${amount.bdeRegister.hstPrice}
                              </h6>
                            </div>

                            <div className="text-right ml-1 border-top mt-2">
                              <h5>Total: ${amount.bdeRegister.totalAmount}</h5>
                            </div>
                          </div>
                        ))
                      )}
                    </div>
                  </div>
                  <div className="form-group" style={{ marginTop: 50 }}>
                    <label htmlFor="cardNumber">Card Number</label>
                    <Field
                      type="text"
                      id="cardNumber"
                      name="cardNumber"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="cardNumber"
                      component="div"
                      className="error"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="postalCode">Postal Code</label>

                    <Field
                      type="text"
                      id="postalCode"
                      name="postalCode"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="postalCode"
                      component="div"
                      className="error"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="postalCode">Payment Method</label>

                    <Field
                      type="text"
                      id="postalCode"
                      name="postalCode"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="postalCode"
                      component="div"
                      className="error"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="expiryDate">Expiry Date</label>
                    <Field
                      type="text"
                      id="expiryDate"
                      name="expiryDate"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="expiryDate"
                      component="div"
                      className="error"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="cvv">CVV</label>
                    <Field
                      type="text"
                      id="cvv"
                      name="cvv"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="cvv"
                      component="div"
                      className="error"
                    />
                  </div>
                  <button className="btn btn-primary" type="submit">
                    Pay
                  </button>
                </Form>
              </Formik>
              {/* <StripeCheckout
            stripeKey="YOUR_STRIPE_PUBLIC_KEY"
            token={handleToken}
            amount={100} // Amount in cents
            currency="USD"
            name="Your Company Name"
            description="Payment for Services"
          >
            <button>Pay with Card</button>
          </StripeCheckout> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Payment;
