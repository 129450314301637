import React from 'react';
import { Link } from 'react-router-dom';
import ROUTES from '../../navigations/Routes';
function GuideToBDE() {
  return (
    <div className="main">
      <div className="homeContainer">
        <div
          class="untree_co-hero inner-page container-fluid TheoryTest"
          style={{ height: 'auto' }}
        >
          <div class="row align-items-center justify-content-center">
            <div class="col-12">
              <div class="row refesherLesson">
                <div class="col-lg-6 col-sm-12 mb-5">
                  <div
                    class="section-title mb-3"
                    data-aos="fade-up"
                    data-aos-delay="0"
                  >
                    <h2 class="my-4 text-black">Guide To Driver Course</h2>
                  </div>
                  <h3>Learn with CSM</h3>
                </div>
                <div
                  class="col-lg-6 col-sm-12"
                  data-aos="fade-up"
                  data-aos-delay="0"
                >
                  <figure class="img-wrap">
                    <img
                      src="images/s.jpg"
                      alt="Image"
                      class="img-fluid"
                      //   width="400"
                      //height="400"
                    />
                    <div class="dotted"></div>
                  </figure>
                </div>
              </div>
              <div className="lessonContent">
                <h3>Guide of Beginner Driver Education:</h3>
                <p>
                  Driving schools licensed by the government can assist you in
                  developing into a responsible and safe driver. They might also
                  enable you to take your G1 exit road test sooner and save
                  money on insurance costs.
                </p>

                <h3>Licensed driving schools by the government are:</h3>
                <p>1. Licensed.</p>
                <p>
                  2. Fulfill the requirements set out by the Ministry of
                  Transportation for: classroom and in-car training (which needs
                  to consist of 40 hours of instruction).
                </p>
                <h3>
                  Programs for Beginning Driver Education (BDE) consist of:
                </h3>
                <p>1. Digital or In-person classroom education.</p>
                <p>2. Hands-on- In-car training.</p>
                <p>3. Adjustable or flexible instruction duration</p>
                <p>4. Online or in-person education in the classroom</p>

                <h3>
                  In a driving education program authorized by the government,
                  you will:
                </h3>
                <p>
                  Receive a minimum of 20 hours of in-person or{' '}
                  <Link to={ROUTES.bde.name} className="mr-1">
                    online classroom
                  </Link>
                  training.
                  <br />
                  Get a copy of the
                  <a
                    href="https://www.ontario.ca/document/official-mto-drivers-handbook"
                    className="ml-1"
                  >
                    MTO Driver's Handbook
                  </a>
                  , the sole official handbook for drivers, as well as the
                  course textbook.
                </p>
                <h3>Inside-the-car lesson you will:</h3>
                <p>
                  <ul className="mx-3">
                    <li>
                      Receive a minimum of 10 hours of instruction in a vehicle
                      from a qualified teacher.
                    </li>
                    <li>
                      Please Note: You are only allowed to finish 2 hours of
                      in-car training every day. You are not allowed to have
                      more than 1 hour of in-car training on a single day
                      following 5 hours of classroom instruction.
                    </li>
                    <li>
                      Complete your in-car training. within 1 year from start of
                      your classroom education.
                    </li>
                    <li>
                      Drive a vehicle with a maximum of 2 students for per
                      instructor (no more than 2 students).
                    </li>
                  </ul>
                </p>
                <h3>Adaptable training</h3>
                <p>
                  Together with the required 20 hours of classroom training and
                  10 hours of in-car lessons, A government-approved novice
                  driver education course must offer 10 hours of flexible
                  instruction during the course.
                </p>
                <h5 style={{ color: '#ec661f' }}>This can be used for:</h5>
                <p>1. Classroom Driving Instruction</p>
                <p>2. Digital Instruction</p>
                <p>3.In-Vehicle Instruction</p>
                <p>4.Driving Simulator Instruction</p>
                <p>5. Homelinks</p>
                <h3>Following the completion of the course</h3>
                <p>
                  After finishing a government-approved novice driving
                  instruction course with success:
                </p>
                <p>
                  The Ministry of Transportation will update your student driver
                  record after the driving school certify you online.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="bookBox container-fluid">
          <h3 className="text-white">Book your lessons online!</h3>
          <h5 className="text-white mt-3">
            Check availability and book your first lesson
          </h5>
          <button className="btn btn-primary btnPrice">See Prices</button>
        </div>
      </div>
    </div>
  );
}

export default GuideToBDE;
