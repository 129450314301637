import React, { useEffect, useState, useReducer } from 'react';
import axios from 'axios';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from 'react-modal';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
function AdminInstructorDetail() {
  const [listOfItems, setListOfItems] = useState([]); // To store list of all items
  const [currentClickedId, setCurrentClickedId] = useState(''); // To Store the Clicked Id for Deletion
  const [editValues, setEditValues] = useState(null); // To store the values for Edit Modal (FORM)
  const [reducerValue, forceUpdate] = useReducer((x) => x + 1, 0); // To tell the useEffect that a row has been added
  const [selectedImage, setSelectedImage] = useState(null); // To show the Image full view in Modal
  const [selectedDates, setSelectedDates] = useState([]);
  const tileContent = ({ date, view }) => {
    if (view === 'month') {
      const dateString = date.toISOString().split('T')[0];
      const isDateSelected = selectedDates.includes(dateString);

      return isDateSelected ? (
        <div className="selected-date">{date.getDate()}</div>
      ) : null;
    }

    return null;
  };
  const initialValues = {
    firstName: '',
    lastName: '',
    userName: '',
    licenseNo: '',
    instructorLicenseNo: '',
    carLicensePlateNo: '',
    carPicture: '',
    ownerShip: '',
    cityVehicleLicenseNo: null,
    vehicleStandardCertificate: '',
    vehicleInsuranceCertificate: '',
    phoneNumber: null,
    alternativePhoneNumber: null,
    email: '',
    roofSign: '',
    licenseExpiryDate: null,
    carMake: '',
    carModel: '',
    carColor: '',
    municipleLicenseExpiry: null,
    vehicleStandardCertificateExpiry: null,
    vehicleInsuranceCertificateExpiry: null,
    contract: '',
    subscription: '',
    payPerUse: '',
    conditions: false,
  };
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required(),
    lastName: Yup.string().required(),
    userName: Yup.string().required(),
    licenseNo: Yup.string().required(),
    instructorLicenseNo: Yup.string().required(),
    carLicensePlateNo: Yup.string().required(),
    carPicture: Yup.string().required(),
    ownerShip: Yup.string().required(),
    cityVehicleLicenseNo: Yup.number().required(),
    vehicleStandardCertificate: Yup.string().required(),
    vehicleInsuranceCertificate: Yup.string().required(),
    phoneNumber: Yup.number().required(),
    email: Yup.string().required(),
  });
  useEffect(() => {
    axios.get('http://localhost:3001/instructorRegister').then((response) => {
      setListOfItems(response.data);
    });
  }, [reducerValue]);
  const showTostify = (msg) => {
    toast.success(msg, {
      position: 'bottom-right',
      autoClose: 5000, // in milliseconds, set to 5000 for 5 seconds
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      style: {
        background: 'rgba(0, 0, 0, 0.7)', // Transparent black background
        color: 'white',
      },
    });
  };
  const openImageModal = (imageData) => {
    setSelectedImage(imageData);
  };
  const closeImageModal = () => {
    setSelectedImage(null);
  };
  const onSubmit = async (data, { setSubmitting }) => {
    try {
      const formData = new FormData();
      formData.append('firstName', data.firstName);
      formData.append('lastName', data.lastName);
      formData.append('userName', data.userName);
      formData.append('licenseNo', data.licenseNo);
      formData.append('instructorLicenseNo', data.instructorLicenseNo);
      formData.append('carLicensePlateNo', data.carLicensePlateNo);
      formData.append('carPicture', data.carPicture);
      formData.append('ownerShip', data.ownerShip);
      formData.append('cityVehicleLicenseNo', data.cityVehicleLicenseNo);
      formData.append('cityVehicleLicenseNo', data.cityVehicleLicenseNo);
      formData.append(
        'vehicleStandardCertificate',
        data.vehicleStandardCertificate
      );
      formData.append(
        'vehicleInsuranceCertificate',
        data.vehicleInsuranceCertificate
      );
      formData.append('phoneNumber', data.phoneNumber);
      formData.append('alternativePhoneNumber', data.alternativePhoneNumber);
      formData.append('email', data.email);
      formData.append('roofSign', data.roofSign);
      formData.append('licenseExpiryDate', data.licenseExpiryDate);
      formData.append('carMake', data.carMake);
      formData.append('carModel', data.carModel);
      formData.append('carColor', data.carColor);
      formData.append('municipleLicenseExpiry', data.municipleLicenseExpiry);
      formData.append(
        'vehicleStandardCertificateExpiry',
        data.vehicleStandardCertificateExpiry
      );
      formData.append('contract', data.contract);
      formData.append('subscription', data.subscription);
      formData.append('payPerUse', data.payPerUse);
      formData.append('conditions', data.conditions);

      await axios.post('http://localhost:3001/instructorRegister', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      forceUpdate();
      showTostify('Instructor Register saved');
    } catch (error) {
      console.error(error);
    } finally {
      setSubmitting(false);
    }
  };
  const onUpdate = async (data) => {
    try {
      const formData = new FormData();
      formData.append('id', data.id);
      formData.append('firstName', data.firstName);
      formData.append('lastName', data.lastName);
      formData.append('userName', data.userName);
      formData.append('licenseNo', data.licenseNo);
      formData.append('instructorLicenseNo', data.instructorLicenseNo);
      formData.append('carLicensePlateNo', data.carLicensePlateNo);
      formData.append('carPicture', data.carPicture);
      formData.append('ownerShip', data.ownerShip);
      formData.append('cityVehicleLicenseNo', data.cityVehicleLicenseNo);
      formData.append('cityVehicleLicenseNo', data.cityVehicleLicenseNo);
      formData.append(
        'vehicleStandardCertificate',
        data.vehicleStandardCertificate
      );
      formData.append(
        'vehicleInsuranceCertificate',
        data.vehicleInsuranceCertificate
      );
      formData.append('phoneNumber', data.phoneNumber);
      formData.append('alternativePhoneNumber', data.alternativePhoneNumber);
      formData.append('email', data.email);
      formData.append('roofSign', data.roofSign);
      formData.append('licenseExpiryDate', data.licenseExpiryDate);
      formData.append('carMake', data.carMake);
      formData.append('carModel', data.carModel);
      formData.append('carColor', data.carColor);
      formData.append('municipleLicenseExpiry', data.municipleLicenseExpiry);
      formData.append(
        'vehicleStandardCertificateExpiry',
        data.vehicleStandardCertificateExpiry
      );
      formData.append('contract', data.contract);
      formData.append('subscription', data.subscription);
      formData.append('payPerUse', data.payPerUse);
      formData.append('conditions', data.conditions);

      await axios.put('http://localhost:3001/instructorRegister', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      window.location.reload();
    } catch (error) {
      console.error('Error updating', error);
    }
  };
  const handleEditClick = (value) => {
    setEditValues(value);
  };
  const showModalScreen = (itemId) => {
    setCurrentClickedId(itemId);
  };
  const hideModalScreen = () => {};
  const onDelete = () => {
    axios
      .delete(`http://localhost:3001/instructorRegister/${currentClickedId}`)
      .then((response) => {
        setListOfItems(
          listOfItems.filter(function (item) {
            return item.id != currentClickedId;
          })
        );
      });
    toast.success('Item deleted successfully', {
      position: 'bottom-right',
      autoClose: 5000, // in milliseconds, set to 5000 for 5 seconds
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      style: {
        background: 'rgba(0, 0, 0, 0.7)', // Transparent black background
        color: 'white',
      },
    });
    hideModalScreen();
  };
  return (
    <div className="AdminContainer container">
      <ToastContainer />
      <section className="middleArea">
        {/* Modal for delete */}
        <div
          class="modal fade"
          id="deleteModal"
          tabindex="-1"
          aria-labelledby="deleteModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="deleteModalLabel">
                  Delete Confirm
                </h5>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
                <p>Are you sure you want to delete</p>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-primary mx-2"
                  data-bs-dismiss="modal"
                >
                  No
                </button>
                <button
                  type="button"
                  class="btn btn-primary"
                  onClick={onDelete}
                  data-bs-dismiss="modal"
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* Modal for delete */}
        <Modal
          isOpen={selectedImage !== null}
          onRequestClose={closeImageModal}
          contentLabel="Full Size Image"
          style={{
            content: {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              border: '1px solid #ccc',
              background: '#fff',
              borderRadius: '4px',
              padding: '20px',
              maxWidth: '80%',
              maxHeight: '80%',
              overflow: 'auto',
              margin: 'auto',
            },
          }}
        >
          {selectedImage && (
            <>
              <img
                src={selectedImage}
                alt="Full Size"
                style={{
                  maxWidth: '100%',
                  maxHeight: '100%',
                  display: 'block',
                  margin: 'auto',
                }}
              />
              <button
                onClick={closeImageModal}
                style={{
                  position: 'absolute',
                  top: '10px',
                  right: '15px',
                  background: 'none',
                  border: 'none',
                  fontSize: '16px',
                  cursor: 'pointer',
                }}
              >
                Close
              </button>
            </>
          )}
        </Modal>
        {/* Modal for Edit */}
        <div
          class="modal fade"
          id="exampleModal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  Edit Item
                </h5>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
                {editValues && (
                  <Formik
                    initialValues={editValues}
                    validationSchema={validationSchema}
                    enableReinitialize={true}
                    onSubmit={onUpdate}
                  >
                    {({ values, setFieldValue, isSubmitting }) => (
                      <Form>
                        <div class="form-group fieldStyle">
                          <label
                            for="instructorpicture"
                            htmlFor="instructorpicture"
                          >
                            Instructor Picture
                          </label>
                          <input
                            type="file"
                            onChange={(event) => {
                              setFieldValue(
                                'instructorpicture',
                                event.target.files[0]
                              );
                            }}
                          ></input>
                        </div>
                        <ErrorMessage name="firstName" component="span" />
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="firstName">
                            First Name
                          </label>
                          <Field
                            type="text"
                            name="firstName"
                            id="firstName"
                            placeholder="Enter First Name"
                            class="form-control"
                          />
                        </div>
                        <ErrorMessage name="lastName" component="span" />
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="lastName">
                            Last Name
                          </label>
                          <Field
                            type="text"
                            name="lastName"
                            id="lastName"
                            placeholder="Enter Last Name"
                            class="form-control"
                          />
                        </div>
                        <ErrorMessage name="userName" component="span" />
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="userName">
                            User Name
                          </label>
                          <Field
                            type="text"
                            name="userName"
                            id="userName"
                            placeholder="Enter User Name"
                            class="form-control"
                          />
                        </div>
                        <ErrorMessage name="licenseNo" component="span" />
                        <div class="form-group fieldStyle">
                          <label for="licenseNo" htmlFor="licenseNo">
                            License Number
                          </label>
                          <input
                            type="file"
                            onChange={(event) => {
                              setFieldValue('licenseNo', event.target.files[0]);
                            }}
                          ></input>
                        </div>
                        <ErrorMessage
                          name="instructorLicenseNo"
                          component="span"
                        />
                        <div class="form-group fieldStyle">
                          <label
                            for="instructorLicenseNo"
                            htmlFor="instructorLicenseNo"
                          >
                            Instructor LicenseNo
                          </label>
                          <input
                            type="file"
                            onChange={(event) => {
                              setFieldValue(
                                'instructorLicenseNo',
                                event.target.files[0]
                              );
                            }}
                          ></input>
                        </div>
                        <ErrorMessage
                          name="carLicensePlateNo"
                          component="span"
                        />
                        <div class="form-group fieldStyle">
                          <label
                            for="carLicensePlateNo"
                            htmlFor="carLicensePlateNo"
                          >
                            Car License PlateNo
                          </label>
                          <input
                            type="file"
                            onChange={(event) => {
                              setFieldValue(
                                'carLicensePlateNo',
                                event.target.files[0]
                              );
                            }}
                          ></input>
                        </div>
                        <ErrorMessage name="carPicture" component="span" />
                        <div class="form-group fieldStyle">
                          <label for="carPicture" htmlFor="carPicture">
                            Car Picture
                          </label>
                          <input
                            type="file"
                            onChange={(event) => {
                              setFieldValue(
                                'carPicture',
                                event.target.files[0]
                              );
                            }}
                          ></input>
                        </div>
                        <ErrorMessage name="ownerShip" component="span" />
                        <div class="form-group fieldStyle">
                          <label for="ownerShip" htmlFor="ownerShip">
                            Owner Ship
                          </label>
                          <input
                            type="file"
                            onChange={(event) => {
                              setFieldValue('ownerShip', event.target.files[0]);
                            }}
                          ></input>
                        </div>
                        <ErrorMessage
                          name="cityVehicleLicenseNo"
                          component="span"
                        />
                        <div class="form-group fieldStyle">
                          <label
                            for="cityVehicleLicenseNo"
                            htmlFor="cityVehicleLicenseNo"
                          >
                            City Vehicle LicenseNo
                          </label>
                          <input
                            type="file"
                            onChange={(event) => {
                              setFieldValue(
                                'cityVehicleLicenseNo',
                                event.target.files[0]
                              );
                            }}
                          ></input>
                        </div>
                        <ErrorMessage
                          name="cityVehicleLicenseNo"
                          component="span"
                        />
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="cityVehicleLicenseNo">
                            cityVehicleLicenseNo
                          </label>
                          <Field
                            type="text"
                            name="cityVehicleLicenseNo"
                            id="cityVehicleLicenseNo"
                            placeholder="Enter cityVehicleLicenseNo"
                            class="form-control"
                          />
                        </div>
                        <ErrorMessage
                          name="vehicleStandardCertificate"
                          component="span"
                        />
                        <div class="form-group fieldStyle">
                          <label
                            for="vehicleStandardCertificate"
                            htmlFor="vehicleStandardCertificate"
                          >
                            vehicleStandardCertificate
                          </label>
                          <input
                            type="file"
                            onChange={(event) => {
                              setFieldValue(
                                'vehicleStandardCertificate',
                                event.target.files[0]
                              );
                            }}
                          ></input>
                        </div>
                        <ErrorMessage
                          name="vehicleInsuranceCertificate"
                          component="span"
                        />
                        <div class="form-group fieldStyle">
                          <label
                            for="vehicleInsuranceCertificate"
                            htmlFor="vehicleInsuranceCertificate"
                          >
                            vehicleInsuranceCertificate
                          </label>
                          <input
                            type="file"
                            onChange={(event) => {
                              setFieldValue(
                                'vehicleInsuranceCertificate',
                                event.target.files[0]
                              );
                            }}
                          ></input>
                        </div>
                        <ErrorMessage name="phoneNumber" component="span" />
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="phoneNumber">
                            phoneNumber
                          </label>
                          <Field
                            type="text"
                            name="phoneNumber"
                            id="phoneNumber"
                            placeholder="Enter phoneNumber"
                            class="form-control"
                          />
                        </div>
                        <ErrorMessage
                          name="alternativePhoneNumber"
                          component="span"
                        />
                        <div class="form-group fieldStyle">
                          <label
                            for="inputName"
                            htmlFor="alternativePhoneNumber"
                          >
                            alternativePhoneNumber
                          </label>
                          <Field
                            type="text"
                            name="alternativePhoneNumber"
                            id="alternativePhoneNumber"
                            placeholder="Enter alternativePhoneNumber"
                            class="form-control"
                          />
                        </div>
                        <ErrorMessage name="email" component="span" />
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="email">
                            email
                          </label>
                          <Field
                            type="text"
                            name="email"
                            id="email"
                            placeholder="Enter email"
                            class="form-control"
                          />
                        </div>
                        <ErrorMessage name="roofSign" component="span" />
                        <div class="form-group fieldStyle">
                          <label for="picture" htmlFor="roofSign">
                            roofSign
                          </label>
                          <input
                            type="file"
                            onChange={(event) => {
                              setFieldValue('roofSign', event.target.files[0]);
                            }}
                          ></input>
                        </div>
                        <ErrorMessage
                          name="licenseExpiryDate"
                          component="span"
                        />
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="licenseExpiryDate">
                            licenseExpiryDate
                          </label>
                          <Field
                            type="text"
                            name="licenseExpiryDate"
                            id="licenseExpiryDate"
                            placeholder="Enter licenseExpiryDate"
                            class="form-control"
                          />
                        </div>
                        <ErrorMessage name="carMake" component="span" />
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="carMake">
                            carMake
                          </label>
                          <Field
                            type="text"
                            name="carMake"
                            id="carMake"
                            placeholder="Enter carMake"
                            class="form-control"
                          />
                        </div>
                        <ErrorMessage name="carModel" component="span" />
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="carModel">
                            carModel
                          </label>
                          <Field
                            type="text"
                            name="carModel"
                            id="carModel"
                            placeholder="Enter carModel"
                            class="form-control"
                          />
                        </div>
                        <ErrorMessage name="carColor" component="span" />
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="carColor">
                            carColor
                          </label>
                          <Field
                            type="text"
                            name="carColor"
                            id="carColor"
                            placeholder="Enter carColor"
                            class="form-control"
                          />
                        </div>
                        <ErrorMessage
                          name="municipleLicenseExpiry"
                          component="span"
                        />
                        <div class="form-group fieldStyle">
                          <label
                            for="inputName"
                            htmlFor="municipleLicenseExpiry"
                          >
                            municipleLicenseExpiry
                          </label>
                          <Field
                            type="text"
                            name="municipleLicenseExpiry"
                            id="municipleLicenseExpiry"
                            placeholder="Enter municipleLicenseExpiry"
                            class="form-control"
                          />
                        </div>
                        <ErrorMessage
                          name="vehicleStandardCertificateExpiry"
                          component="span"
                        />
                        <div class="form-group fieldStyle">
                          <label
                            for="inputName"
                            htmlFor="vehicleStandardCertificateExpiry"
                          >
                            vehicleStandardCertificateExpiry
                          </label>
                          <Field
                            type="text"
                            name="vehicleStandardCertificateExpiry"
                            id="vehicleStandardCertificateExpiry"
                            placeholder="Enter vehicleStandardCertificateExpiry"
                            class="form-control"
                          />
                        </div>
                        <ErrorMessage
                          name="vehicleInsuranceCertificateExpiry"
                          component="span"
                        />
                        <div class="form-group fieldStyle">
                          <label
                            for="inputName"
                            htmlFor="vehicleInsuranceCertificateExpiry"
                          >
                            vehicleInsuranceCertificateExpiry
                          </label>
                          <Field
                            type="text"
                            name="vehicleInsuranceCertificateExpiry"
                            id="vehicleInsuranceCertificateExpiry"
                            placeholder="Enter vehicleInsuranceCertificateExpiry"
                            class="form-control"
                          />
                        </div>
                        <ErrorMessage name="contract" component="span" />
                        <div class="form-group fieldStyle">
                          <label for="contract" htmlFor="contract">
                            contract
                          </label>
                          <input
                            type="file"
                            onChange={(event) => {
                              setFieldValue('contract', event.target.files[0]);
                            }}
                          ></input>
                        </div>
                        <ErrorMessage name="subscription" component="span" />
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="subscription">
                            subscription
                          </label>
                          <Field
                            type="text"
                            name="subscription"
                            id="subscription"
                            placeholder="subscription"
                            class="form-control"
                          />
                        </div>
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="payPerUse">
                            payPerUse
                          </label>
                          <Field
                            type="text"
                            name="payPerUse"
                            id="payPerUse"
                            placeholder="payPerUse"
                            class="form-control"
                          />
                        </div>
                        <div class="modal-footer">
                          <button type="submit" class="btn btn-primary mx-3">
                            Update
                          </button>
                          <button
                            class="btn btn-primary"
                            data-bs-dismiss="modal"
                          >
                            Close
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* Modal for Detail */}
        <div
          class="modal fade"
          id="detailModal"
          tabindex="-1"
          aria-labelledby="detailModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="detailModalLabel">
                  Detail
                </h5>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
                {editValues && (
                  <div>
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="firstName">
                        First Name
                      </label>
                      <b name="firstName">{editValues.firstName}</b>
                    </div>
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="lastName">
                        Last Name
                      </label>
                      <b name="lastName">{editValues.lastName}</b>
                    </div>
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="userName">
                        User Name
                      </label>
                      <b name="userName">{editValues.userName}</b>
                    </div>
                    <div class="detailImageBoxContainer">
                      <label for="inputName" htmlFor="licenseNo">
                        License No
                      </label>
                      <b name="licenseNo">{editValues.licenseNo}</b>
                      <div class="detailImageBox">
                        <img
                          src={editValues.licenseNo}
                          alt="licenseNo"
                          style={{ cursor: 'pointer' }}
                        />
                      </div>
                    </div>
                    <div class="detailImageBoxContainer">
                      <label for="inputName" htmlFor="instructorLicenseNo">
                        instructorLicenseNo
                      </label>
                      <b name="instructorLicenseNo">
                        {editValues.instructorLicenseNo}
                      </b>
                      <div class="detailImageBox">
                        <img
                          src={editValues.instructorLicenseNo}
                          alt="instructorLicenseNo"
                          style={{ cursor: 'pointer' }}
                        />
                      </div>
                    </div>
                    <div class="detailImageBoxContainer">
                      <label for="inputName" htmlFor="carLicensePlateNo">
                        carLicensePlateNo
                      </label>
                      <b name="carLicensePlateNo">
                        {editValues.carLicensePlateNo}
                      </b>
                      <div class="detailImageBox">
                        <img
                          src={editValues.carLicensePlateNo}
                          alt="carLicensePlateNo"
                          style={{ cursor: 'pointer' }}
                        />
                      </div>
                    </div>
                    <div class="detailImageBoxContainer">
                      <label for="inputName" htmlFor="carPicture">
                        carPicture
                      </label>
                      <b name="carPicture">{editValues.carPicture}</b>
                      <div class="detailImageBox">
                        <img
                          src={editValues.carPicture}
                          alt="carPicture"
                          style={{ cursor: 'pointer' }}
                        />
                      </div>
                    </div>
                    <div class="detailImageBoxContainer">
                      <label for="inputName" htmlFor="ownerShip">
                        ownerShip
                      </label>
                      <b name="ownerShip">{editValues.ownerShip}</b>
                      <div class="detailImageBox">
                        <img
                          src={editValues.ownerShip}
                          alt="ownerShip"
                          style={{ cursor: 'pointer' }}
                        />
                      </div>
                    </div>
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="cityVehicleLicenseNo">
                        cityVehicleLicenseNo
                      </label>
                      <b name="cityVehicleLicenseNo">
                        {editValues.cityVehicleLicenseNo}
                      </b>
                    </div>
                    <div class="detailImageBoxContainer">
                      <label
                        for="inputName"
                        htmlFor="vehicleStandardCertificate"
                      >
                        vehicleStandardCertificate
                      </label>
                      <b name="ownerShip">
                        {editValues.vehicleStandardCertificate}
                      </b>
                      <div class="detailImageBox">
                        <img
                          src={editValues.vehicleStandardCertificate}
                          alt="vehicleStandardCertificate"
                          style={{ cursor: 'pointer' }}
                        />
                      </div>
                    </div>
                    <div class="detailImageBoxContainer">
                      <label
                        for="inputName"
                        htmlFor="vehicleInsuranceCertificate"
                      >
                        vehicleInsuranceCertificate
                      </label>
                      <b name="ownerShip">
                        {editValues.vehicleInsuranceCertificate}
                      </b>
                      <div class="detailImageBox">
                        <img
                          src={editValues.vehicleInsuranceCertificate}
                          alt="vehicleInsuranceCertificate"
                          style={{ cursor: 'pointer' }}
                        />
                      </div>
                    </div>
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="phoneNumber">
                        phoneNumber
                      </label>
                      <b name="phoneNumber">{editValues.phoneNumber}</b>
                    </div>
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="alternativePhoneNumber">
                        alternativePhoneNumber
                      </label>
                      <b name="alternativePhoneNumber">
                        {editValues.alternativePhoneNumber}
                      </b>
                    </div>
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="email">
                        email
                      </label>
                      <b name="email">{editValues.email}</b>
                    </div>{' '}
                    <div class="detailImageBoxContainer">
                      <label for="inputName" htmlFor="roofSign">
                        roofSign
                      </label>
                      <b name="ownerShip">{editValues.roofSign}</b>
                      <div class="detailImageBox">
                        <img
                          src={editValues.roofSign}
                          alt="roofSign"
                          style={{ cursor: 'pointer' }}
                        />
                      </div>
                    </div>
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="licenseExpiryDate">
                        licenseExpiryDate
                      </label>
                      <b name="licenseExpiryDate">
                        {editValues.licenseExpiryDate}
                      </b>
                    </div>{' '}
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="carMake">
                        carMake
                      </label>
                      <b name="carMake">{editValues.carMake}</b>
                    </div>{' '}
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="carModel">
                        carModel
                      </label>
                      <b name="carModel">{editValues.carModel}</b>
                    </div>{' '}
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="carColor">
                        carColor
                      </label>
                      <b name="carColor">{editValues.carColor}</b>
                    </div>{' '}
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="municipleLicenseExpiry">
                        municipleLicenseExpiry
                      </label>
                      <b name="municipleLicenseExpiry">
                        {editValues.municipleLicenseExpiry}
                      </b>
                    </div>
                    <div class="form-group fieldStyle">
                      <label
                        for="inputName"
                        htmlFor="vehicleStandardCertificateExpiry"
                      >
                        vehicleStandardCertificateExpiry
                      </label>
                      <b name="municipleLicenseExpiry">
                        {editValues.vehicleStandardCertificateExpiry}
                      </b>
                    </div>
                    <div class="form-group fieldStyle">
                      <label
                        for="inputName"
                        htmlFor="vehicleInsuranceCertificateExpiry"
                      >
                        vehicleInsuranceCertificateExpiry
                      </label>
                      <b name="vehicleInsuranceCertificateExpiry">
                        {editValues.vehicleInsuranceCertificateExpiry}
                      </b>
                    </div>
                    <div class="detailImageBoxContainer">
                      <label for="inputName" htmlFor="contract">
                        contract
                      </label>
                      <b name="contract">{editValues.contract}</b>
                      <div class="detailImageBox">
                        <img
                          src={editValues.contract}
                          alt="contract"
                          style={{ cursor: 'pointer' }}
                        />
                      </div>
                    </div>
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="subscription">
                        subscription
                      </label>
                      <b name="subscription">{editValues.subscription}</b>
                    </div>
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="payPerUse">
                        payPerUse
                      </label>
                      <b name="payPerUse">{editValues.payPerUse}</b>
                    </div>
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="conditions">
                        conditions
                      </label>
                      <b name="conditions">{editValues.conditions}</b>
                    </div>
                    <div class="modal-footer">
                      <button class="btn btn-primary" data-bs-dismiss="modal">
                        Close
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* Modal for Detail */}
        <div class="container mt-5">
          <div class="row">
            <div class="col-12">
              <h2 class="text-center">
                {' '}
                <i class="fa-solid fa-list"></i> Instructor Details
              </h2>
              <p class="text-center"> Manage Instructor Details here.</p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="card">
                <div class="card-header">
                  <h4 class="card-title">Add Instructor Details</h4>
                </div>
                <div class="card-body">
                  <Formik
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    validationSchema={validationSchema}
                  >
                    {({ values, setFieldValue, isSubmitting }) => (
                      <Form>
                        <ErrorMessage name="firstName" component="span" />
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="firstName">
                            First Name
                          </label>
                          <Field
                            type="text"
                            name="firstName"
                            id="firstName"
                            placeholder="Enter First Name"
                            class="form-control"
                          />
                        </div>
                        <ErrorMessage name="lastName" component="span" />
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="lastName">
                            Last Name
                          </label>
                          <Field
                            type="text"
                            name="lastName"
                            id="lastName"
                            placeholder="Enter Last Name"
                            class="form-control"
                          />
                        </div>
                        <ErrorMessage name="userName" component="span" />
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="userName">
                            User Name
                          </label>
                          <Field
                            type="text"
                            name="userName"
                            id="userName"
                            placeholder="Enter User Name"
                            class="form-control"
                          />
                        </div>
                        <ErrorMessage name="licenseNo" component="span" />
                        <div class="form-group fieldStyle">
                          <label for="licenseNo" htmlFor="licenseNo">
                            License Number
                          </label>
                          <input
                            type="file"
                            onChange={(event) => {
                              setFieldValue('licenseNo', event.target.files[0]);
                            }}
                          ></input>
                        </div>
                        <ErrorMessage
                          name="instructorLicenseNo"
                          component="span"
                        />
                        <div class="form-group fieldStyle">
                          <label
                            for="instructorLicenseNo"
                            htmlFor="instructorLicenseNo"
                          >
                            Instructor LicenseNo
                          </label>
                          <input
                            type="file"
                            onChange={(event) => {
                              setFieldValue(
                                'instructorLicenseNo',
                                event.target.files[0]
                              );
                            }}
                          ></input>
                        </div>
                        <ErrorMessage
                          name="carLicensePlateNo"
                          component="span"
                        />
                        <div class="form-group fieldStyle">
                          <label
                            for="carLicensePlateNo"
                            htmlFor="carLicensePlateNo"
                          >
                            Car License PlateNo
                          </label>
                          <input
                            type="file"
                            onChange={(event) => {
                              setFieldValue(
                                'carLicensePlateNo',
                                event.target.files[0]
                              );
                            }}
                          ></input>
                        </div>
                        <ErrorMessage name="carPicture" component="span" />
                        <div class="form-group fieldStyle">
                          <label for="carPicture" htmlFor="carPicture">
                            Car Picture
                          </label>
                          <input
                            type="file"
                            onChange={(event) => {
                              setFieldValue(
                                'carPicture',
                                event.target.files[0]
                              );
                            }}
                          ></input>
                        </div>
                        <ErrorMessage name="ownerShip" component="span" />
                        <div class="form-group fieldStyle">
                          <label for="ownerShip" htmlFor="ownerShip">
                            Owner Ship
                          </label>
                          <input
                            type="file"
                            onChange={(event) => {
                              setFieldValue('ownerShip', event.target.files[0]);
                            }}
                          ></input>
                        </div>
                        <ErrorMessage
                          name="cityVehicleLicenseNo"
                          component="span"
                        />
                        <div class="form-group fieldStyle">
                          <label
                            for="cityVehicleLicenseNo"
                            htmlFor="cityVehicleLicenseNo"
                          >
                            City Vehicle LicenseNo
                          </label>
                          <input
                            type="file"
                            onChange={(event) => {
                              setFieldValue(
                                'cityVehicleLicenseNo',
                                event.target.files[0]
                              );
                            }}
                          ></input>
                        </div>
                        <ErrorMessage
                          name="cityVehicleLicenseNo"
                          component="span"
                        />
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="cityVehicleLicenseNo">
                            cityVehicleLicenseNo
                          </label>
                          <Field
                            type="text"
                            name="cityVehicleLicenseNo"
                            id="cityVehicleLicenseNo"
                            placeholder="Enter cityVehicleLicenseNo"
                            class="form-control"
                          />
                        </div>
                        <ErrorMessage
                          name="vehicleStandardCertificate"
                          component="span"
                        />
                        <div class="form-group fieldStyle">
                          <label
                            for="vehicleStandardCertificate"
                            htmlFor="vehicleStandardCertificate"
                          >
                            vehicleStandardCertificate
                          </label>
                          <input
                            type="file"
                            onChange={(event) => {
                              setFieldValue(
                                'vehicleStandardCertificate',
                                event.target.files[0]
                              );
                            }}
                          ></input>
                        </div>
                        <ErrorMessage
                          name="vehicleInsuranceCertificate"
                          component="span"
                        />
                        <div class="form-group fieldStyle">
                          <label
                            for="vehicleInsuranceCertificate"
                            htmlFor="vehicleInsuranceCertificate"
                          >
                            vehicleInsuranceCertificate
                          </label>
                          <input
                            type="file"
                            onChange={(event) => {
                              setFieldValue(
                                'vehicleInsuranceCertificate',
                                event.target.files[0]
                              );
                            }}
                          ></input>
                        </div>
                        <ErrorMessage name="phoneNumber" component="span" />
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="phoneNumber">
                            phoneNumber
                          </label>
                          <Field
                            type="text"
                            name="phoneNumber"
                            id="phoneNumber"
                            placeholder="Enter phoneNumber"
                            class="form-control"
                          />
                        </div>
                        <ErrorMessage
                          name="alternativePhoneNumber"
                          component="span"
                        />
                        <div class="form-group fieldStyle">
                          <label
                            for="inputName"
                            htmlFor="alternativePhoneNumber"
                          >
                            alternativePhoneNumber
                          </label>
                          <Field
                            type="text"
                            name="alternativePhoneNumber"
                            id="alternativePhoneNumber"
                            placeholder="Enter alternativePhoneNumber"
                            class="form-control"
                          />
                        </div>
                        <ErrorMessage name="email" component="span" />
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="email">
                            email
                          </label>
                          <Field
                            type="text"
                            name="email"
                            id="email"
                            placeholder="Enter email"
                            class="form-control"
                          />
                        </div>
                        <ErrorMessage name="roofSign" component="span" />
                        <div class="form-group fieldStyle">
                          <label for="picture" htmlFor="roofSign">
                            roofSign
                          </label>
                          <input
                            type="file"
                            onChange={(event) => {
                              setFieldValue('roofSign', event.target.files[0]);
                            }}
                          ></input>
                        </div>
                        <ErrorMessage
                          name="licenseExpiryDate"
                          component="span"
                        />
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="licenseExpiryDate">
                            licenseExpiryDate
                          </label>
                          <Field
                            type="text"
                            name="licenseExpiryDate"
                            id="licenseExpiryDate"
                            placeholder="Enter licenseExpiryDate"
                            class="form-control"
                          />
                        </div>
                        <ErrorMessage name="carMake" component="span" />
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="carMake">
                            carMake
                          </label>
                          <Field
                            type="text"
                            name="carMake"
                            id="carMake"
                            placeholder="Enter carMake"
                            class="form-control"
                          />
                        </div>
                        <ErrorMessage name="carModel" component="span" />
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="carModel">
                            carModel
                          </label>
                          <Field
                            type="text"
                            name="carModel"
                            id="carModel"
                            placeholder="Enter carModel"
                            class="form-control"
                          />
                        </div>
                        <ErrorMessage name="carColor" component="span" />
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="carColor">
                            carColor
                          </label>
                          <Field
                            type="text"
                            name="carColor"
                            id="carColor"
                            placeholder="Enter carColor"
                            class="form-control"
                          />
                        </div>
                        <ErrorMessage
                          name="municipleLicenseExpiry"
                          component="span"
                        />
                        <div class="form-group fieldStyle">
                          <label
                            for="inputName"
                            htmlFor="municipleLicenseExpiry"
                          >
                            municipleLicenseExpiry
                          </label>
                          <Field
                            type="text"
                            name="municipleLicenseExpiry"
                            id="municipleLicenseExpiry"
                            placeholder="Enter municipleLicenseExpiry"
                            class="form-control"
                          />
                        </div>
                        <ErrorMessage
                          name="vehicleStandardCertificateExpiry"
                          component="span"
                        />
                        <div class="form-group fieldStyle">
                          <label
                            for="inputName"
                            htmlFor="vehicleStandardCertificateExpiry"
                          >
                            vehicleStandardCertificateExpiry
                          </label>
                          <Field
                            type="text"
                            name="vehicleStandardCertificateExpiry"
                            id="vehicleStandardCertificateExpiry"
                            placeholder="Enter vehicleStandardCertificateExpiry"
                            class="form-control"
                          />
                        </div>
                        <ErrorMessage
                          name="vehicleInsuranceCertificateExpiry"
                          component="span"
                        />
                        <div class="form-group fieldStyle">
                          <label
                            for="inputName"
                            htmlFor="vehicleInsuranceCertificateExpiry"
                          >
                            vehicleInsuranceCertificateExpiry
                          </label>
                          <Field
                            type="text"
                            name="vehicleInsuranceCertificateExpiry"
                            id="vehicleInsuranceCertificateExpiry"
                            placeholder="Enter vehicleInsuranceCertificateExpiry"
                            class="form-control"
                          />
                        </div>
                        <ErrorMessage name="contract" component="span" />
                        <div class="form-group fieldStyle">
                          <label for="contract" htmlFor="contract">
                            contract
                          </label>
                          <input
                            type="file"
                            onChange={(event) => {
                              setFieldValue('contract', event.target.files[0]);
                            }}
                          ></input>
                        </div>
                        <ErrorMessage name="subscription" component="span" />
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="subscription">
                            subscription
                          </label>
                          <Field
                            type="text"
                            name="subscription"
                            id="subscription"
                            placeholder="subscription"
                            class="form-control"
                          />
                        </div>
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="payPerUse">
                            payPerUse
                          </label>
                          <Field
                            type="text"
                            name="payPerUse"
                            id="payPerUse"
                            placeholder="payPerUse"
                            class="form-control"
                          />
                        </div>
                        <div class="modal-footer">
                          <button type="submit" class="btn btn-primary mx-3">
                            Save
                          </button>
                          <button
                            class="btn btn-primary"
                            data-bs-dismiss="modal"
                          >
                            Close
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="card">
                <div class="card-header">
                  <h4 class="card-title">Instructor Details List</h4>
                </div>
                <div class="card-body">
                  <table class="table">
                    <thead>
                      <tr>
                        <th>Instructor Picture</th>
                        <th>Name</th>
                        <th>Car Model</th>
                        <th>Car Color</th>
                        <th>Picture</th>
                        <th>Calendar</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {listOfItems.map((value, key) => {
                        return (
                          <tr key={key}>
                            <td>
                              {value.instructorpicture && (
                                <img
                                  src={value.instructorpicture}
                                  alt=""
                                  onClick={() =>
                                    openImageModal(value.instructorpicture)
                                  }
                                  style={{ cursor: 'pointer' }}
                                  height="100px"
                                  width="100px"
                                />
                              )}
                            </td>
                            <td>{value.name}</td>
                            <td>{value.carModel}</td>
                            <td>{value.carColor}</td>
                            <td>
                              {value.picture && (
                                <img
                                  src={value.picture}
                                  alt=""
                                  onClick={() => openImageModal(value.picture)}
                                  style={{ cursor: 'pointer' }}
                                  height="100px"
                                  width="100px"
                                />
                              )}
                            </td>
                            <td>{value.calendar}</td>
                            <td>
                              <a class="nav-item dropdown">
                                <a
                                  class="fa-solid fa-circle-chevron-down"
                                  data-bs-toggle="dropdown"
                                  href="#"
                                  role="button"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                ></a>
                                <div class="dropdown-menu">
                                  <Link
                                    class="dropdown-item"
                                    data-bs-toggle="modal"
                                    data-bs-target="#exampleModal"
                                    onClick={() => handleEditClick(value)}
                                  >
                                    Edit
                                  </Link>
                                  <Link
                                    class="dropdown-item"
                                    data-bs-toggle="modal"
                                    data-bs-target="#detailModal"
                                    onClick={() => handleEditClick(value)}
                                  >
                                    Detail
                                  </Link>
                                  <div class="dropdown-divider"></div>
                                  <Link
                                    class="dropdown-item"
                                    onClick={() => showModalScreen(value.id)}
                                    msgId={value.id}
                                    data-bs-toggle="modal"
                                    data-bs-target="#deleteModal"
                                  >
                                    Delete
                                  </Link>
                                </div>
                              </a>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default AdminInstructorDetail;
