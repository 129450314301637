import React, { useEffect, useState, useReducer } from 'react';
import axios from 'axios';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function AdminTestResult() {
  const [listOfItems, setListOfItems] = useState([]); // To store list of all items
  const [currentClickedId, setCurrentClickedId] = useState(''); // To Store the Clicked Id for Deletion
  const [editValues, setEditValues] = useState(null); // To store the values for Edit Modal (FORM)
  const [reducerValue, forceUpdate] = useReducer((x) => x + 1, 0); // To tell the useEffect that a row has been added
  const [modules, setModules] = useState([]);
  const [categories, setCategory] = useState([]);
  const [users, setUsers] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [
          usersResponse,
          modulesResponse,
          categoriesResponse,
          testResultsResponse,
        ] = await Promise.all([
          fetch('http://csmserver.csmdrivingschool.ca/StudentRegister.php'),
          fetch('http://csmserver.csmdrivingschool.ca/Module.php'),
          fetch(
            'http://csmserver.csmdrivingschool.ca/TheoryTestCategories.php'
          ),
          fetch('http://csmserver.csmdrivingschool.ca/TestResults.php'),
        ]);

        if (!usersResponse.ok) throw new Error('Error fetching users');
        if (!modulesResponse.ok) throw new Error('Error fetching modules');
        if (!categoriesResponse.ok)
          throw new Error('Error fetching theoryCategories');
        if (!testResultsResponse.ok)
          throw new Error('Error fetching test results');

        const [usersData, modulesData, categoriesData, testResultsData] =
          await Promise.all([
            usersResponse.json(),
            modulesResponse.json(),
            categoriesResponse.json(),
            testResultsResponse.json(),
          ]);

        setUsers(usersData);
        setModules(modulesData);
        setCategory(categoriesData);
        setListOfItems(testResultsData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const showTostify = (msg) => {
    toast.success(msg, {
      position: 'bottom-right',
      autoClose: 5000, // in milliseconds, set to 5000 for 5 seconds
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      style: {
        background: 'rgba(0, 0, 0, 0.7)', // Transparent black background
        color: 'white',
      },
    });
  };
  const getUserName = (userId) => {
    const user = users.find((usr) => usr.id === userId);
    return user ? user.userName : 'Unknown User';
  };
  const getCategoryName = (testCategoryId) => {
    const category = categories.find((cat) => cat.id === testCategoryId);
    return category ? category.categoryname : 'Unknown Category';
  };
  const getModuleName = (moduleId) => {
    const module = modules.find((mod) => mod.id === moduleId);
    return module ? module.name : 'Unknown Module';
  };

  const handleEditClick = (value) => {
    setEditValues(value);
  };

  const showModalScreen = (itemId) => {
    setCurrentClickedId(itemId);
  };

  const hideModalScreen = () => {};

  const onDelete = async () => {
    try {
      const response = await fetch(
        `http://csmserver.csmdrivingschool.ca/TestResult.php?id=${currentClickedId}`,
        {
          method: 'DELETE',
        }
      );

      if (!response.ok) {
        throw new Error('Failed to delete item');
      }

      setListOfItems(listOfItems.filter((item) => item.id != currentClickedId));

      toast.success('Item deleted successfully', {
        position: 'bottom-right',
        autoClose: 5000, // in milliseconds, set to 5000 for 5 seconds
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          background: 'rgba(0, 0, 0, 0.7)', // Transparent black background
          color: 'white',
        },
      });

      hideModalScreen();
    } catch (error) {
      console.error('Error deleting item:', error);
    }
  };

  return (
    <div className="AdminContainer container">
      <ToastContainer />
      <section className="middleArea">
        {/* Modal for delete */}

        <div
          class="modal fade"
          id="deleteModal"
          tabindex="-1"
          aria-labelledby="deleteModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="deleteModalLabel">
                  Delete Confirm
                </h5>
                <button
                  type="button"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  style={{
                    backgroundColor: 'transparent',
                    fontSize: 20,
                    border: 'none',
                  }}
                >
                  <i class="fa-solid fa-xmark"></i>
                </button>
              </div>
              <div class="modal-body">
                <p>Are you sure you want to delete</p>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-primary mx-2"
                  data-bs-dismiss="modal"
                >
                  No
                </button>
                <button
                  type="button"
                  class="btn btn-primary"
                  onClick={onDelete}
                  data-bs-dismiss="modal"
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* Modal for delete */}

        {/* Modal for Edit */}
        {/* <div
          class="modal fade"
          id="exampleModal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  Edit Item
                </h5>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
                {editValues && (
                  <Formik
                    initialValues={editValues}
                    validationSchema={validationSchema}
                    enableReinitialize={true}
                    onSubmit={onUpdate}
                  >
                    {({ values, setFieldValue, isSubmitting }) => (
                      <Form>
                        <ErrorMessage name="moduleid" component="span" />
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="moduleid">
                            moduleid
                          </label>

                          <Field
                            type="text"
                            name="moduleid"
                            id="moduleid"
                            placeholder="Enter a moduleid"
                            class="form-control"
                          />
                        </div>
                        <ErrorMessage name="correctans" component="span" />
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="correctans">
                            correctAns
                          </label>

                          <Field
                            type="text"
                            name="correctans"
                            id="correctans"
                            placeholder="Enter a correctAns"
                            class="form-control"
                          />
                        </div>
                        <ErrorMessage name="incorrectans" component="span" />
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="incorrectans">
                            incorrectAns
                          </label>

                          <Field
                            type="text"
                            name="incorrectans"
                            id="incorrectans"
                            placeholder="Enter a incorrectAns"
                            class="form-control"
                          />
                        </div>
                        <ErrorMessage name="date" component="span" />
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="date">
                            date
                          </label>

                          <Field
                            type="text"
                            name="date"
                            id="date"
                            placeholder="Enter a date"
                            class="form-control"
                          />
                        </div>
                        <ErrorMessage name="resultstatus" component="span" />
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="resultstatus">
                            resultStatus
                          </label>

                          <Field
                            type="text"
                            name="resultstatus"
                            id="resultstatus"
                            placeholder="Enter a result Status"
                            class="form-control"
                          />
                        </div>

                        <div class="modal-footer">
                          <button type="submit" class="btn btn-primary mx-3">
                            Update
                          </button>
                          <button
                            class="btn btn-primary"
                            data-bs-dismiss="modal"
                          >
                            Close
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                )}
              </div>
            </div>
          </div>
        </div> */}
        {/* Modal for Edit */}

        {/* Modal for Detail */}
        <div
          class="modal fade"
          id="detailModal"
          tabindex="-1"
          aria-labelledby="detailModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="detailModalLabel">
                  Detail
                </h5>
                <button
                  type="button"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  style={{
                    backgroundColor: 'transparent',
                    fontSize: 20,
                    border: 'none',
                  }}
                >
                  <i class="fa-solid fa-xmark"></i>
                </button>
              </div>
              <div class="modal-body">
                {editValues && (
                  <div>
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="userId">
                        User Name
                      </label>

                      <b name="userId">{getUserName(editValues.userId)}</b>
                    </div>
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="testCategoryId">
                        Category
                      </label>
                      <b name="testCategoryId">
                        {getCategoryName(editValues.testCategoryId)}
                      </b>
                    </div>
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="moduleid">
                        Module
                      </label>
                      <b name="moduleid">
                        {getModuleName(editValues.moduleid)}
                      </b>
                    </div>
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="correctans">
                        Correct Ans
                      </label>

                      <b name="correctans">{editValues.correctans}</b>
                    </div>
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="incorrectans">
                        Incorrect Ans
                      </label>

                      <b name="incorrectans">{editValues.incorrectans}</b>
                    </div>
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="date">
                        Date
                      </label>

                      <b name="date">
                        {' '}
                        {new Date(editValues.date).toLocaleString()}
                      </b>
                    </div>

                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="resultstatus">
                        Result Status
                      </label>

                      <b name="resultstatus">{editValues.resultstatus}</b>
                    </div>

                    <div class="modal-footer">
                      <div class="detailImageBoxContainer"></div>
                      <button class="btn btn-primary" data-bs-dismiss="modal">
                        Close
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* Modal for Detail */}

        <div class="container mt-5">
          <div class="row">
            <div class="col-12">
              <h2 class="text-center">
                {' '}
                <i class="fa-solid fa-list"></i> Test Result
              </h2>
              <p class="text-center"> Manage Test Result here.</p>
            </div>
          </div>

          <div class="row">
            <div class="">
              <div class="card">
                <div class="card-header">
                  <h4 class="card-title">Test Result List</h4>
                </div>
                <div class="card-body">
                  <table class="table">
                    <thead>
                      <tr>
                        <th>UserName</th>
                        <th>Test Category</th>
                        <th>Module</th>
                        <th>Correct Answers</th>
                        <th>Incorrect Ans</th>
                        <th>date</th>
                        <th>Result Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {listOfItems.map((value, key) => {
                        return (
                          <tr key={key}>
                            <td>{getUserName(value.userId)}</td>
                            <td>{getCategoryName(value.testCategoryId)}</td>
                            <td>{getModuleName(value.moduleid)}</td>
                            <td>{value.correctans}</td>
                            <td>{value.incorrectans}</td>
                            <td> {new Date(value.date).toLocaleString()}</td>
                            <td>{value.resultstatus}</td>

                            <td>
                              <a class="nav-item dropdown">
                                <a
                                  class="fa-solid fa-circle-chevron-down"
                                  data-bs-toggle="dropdown"
                                  href="#"
                                  role="button"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                ></a>
                                <div class="dropdown-menu">
                                  <Link
                                    class="dropdown-item"
                                    data-bs-toggle="modal"
                                    data-bs-target="#detailModal"
                                    onClick={() => handleEditClick(value)}
                                  >
                                    Detail
                                  </Link>

                                  <div class="dropdown-divider"></div>
                                  <Link
                                    class="dropdown-item"
                                    onClick={() => showModalScreen(value.id)}
                                    msgId={value.id}
                                    data-bs-toggle="modal"
                                    data-bs-target="#deleteModal"
                                  >
                                    Delete
                                  </Link>
                                </div>
                              </a>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default AdminTestResult;
