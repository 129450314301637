import React, { useEffect, useState, useReducer } from 'react';
import axios from 'axios';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ROUTES from '../../navigations/Routes';
import { useParams } from 'react-router-dom';
function Questions() {
  const [listOfItems, setListOfItems] = useState([]); // To store list of all items
  const [reducerValue, forceUpdate] = useReducer((x) => x + 1, 0); // To tell the useEffect that a row has been added
  const [testCategories, setTestCategories] = useState([]);
  const [module, setModule] = useState([]);
  const initialValues = {
    categoryId: 0,
    moduleId: 0,
    image: '',
    question: '',
    option1: '',
    option2: '',
    option3: '',
    option4: '',
    correct: '',
    explanation: '',
  };

  const validationSchema = Yup.object().shape({
    moduleId: Yup.number().required(),
    // image: Yup.string().required(),
    question: Yup.string().required(),
    option1: Yup.string().required(),
    option2: Yup.string().required(),
    option3: Yup.string().required(),
    option4: Yup.string().required(),
    correct: Yup.string().required(),
    explanation: Yup.string().required(),
  });
  useEffect(() => {
    axios.get('http://localhost:3001/questions').then((response) => {
      setListOfItems(response.data);
    });
  }, [reducerValue]);

  const showTostify = (msg) => {
    toast.success(msg, {
      position: 'bottom-right',
      autoClose: 5000, // in milliseconds, set to 5000 for 5 seconds
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      style: {
        background: 'rgba(0, 0, 0, 0.7)', // Transparent black background
        color: 'white',
      },
    });
  };

  // const onSubmit = async (data, { setSubmitting }) => {
  //   try {
  //     const formData = new FormData();
  //     formData.append('image', data.image);
  //     formData.append('categoryId', data.categoryId);
  //     formData.append('moduleId', data.moduleId);
  //     formData.append('question', data.question);
  //     formData.append('option1', data.option1);
  //     formData.append('option2', data.option2);
  //     formData.append('option3', data.option3);
  //     formData.append('option4', data.option4);
  //     formData.append('correct', data.correct);
  //     formData.append('explanation', data.explanation);

  //     console.log(data);
  //     await // axios.post('http://localhost:3001/questions', formData,
  //     axios.post(
  //       'http://csmserver.csmdrivingschool.ca/Questions.php',
  //       formData,
  //       {
  //         headers: {
  //           'Content-Type': 'multipart/form-data',
  //         },
  //       }
  //     );
  //     forceUpdate();
  //     showTostify('Questions saved');
  //   } catch (error) {
  //     console.error(error);
  //   } finally {
  //     setSubmitting(false);
  //   }
  // };

  const onSubmit = async (data, { setSubmitting }) => {
    try {
      const formData = new FormData();
      formData.append('image', data.image);
      formData.append('categoryId', data.categoryId);
      formData.append('moduleId', data.moduleId);
      formData.append('question', data.question);
      formData.append('option1', data.option1);
      formData.append('option2', data.option2);
      formData.append('option3', data.option3);
      formData.append('option4', data.option4);
      formData.append('correct', data.correct);
      formData.append('explanation', data.explanation);

      console.log(data);

      const response = await fetch(
        'https://csmserver.csmdrivingschool.ca/Questions.php',
        {
          method: 'POST',
          body: formData,
          headers: {
            // 'Content-Type': 'multipart/form-data'
            //- Note: Do not set this header, as it will be set automatically by the browser
          },
        }
      );

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      forceUpdate();
      showTostify('Questions saved');
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    const fetchTestCategories = async () => {
      try {
        const response = await fetch(
          'https://csmserver.csmdrivingschool.ca/TheoryTestCategories.php'
        );
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setTestCategories(data);
        console.log(data);
      } catch (error) {
        console.error('Error fetching test categories:', error);
      }
    };

    fetchTestCategories();
  }, []);

  useEffect(() => {
    const fetchModules = async () => {
      try {
        const response = await fetch(
          'https://csmserver.csmdrivingschool.ca/Module.php'
        );
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setModule(data);
        console.log(data);
      } catch (error) {
        console.error('Error fetching modules:', error);
      }
    };

    fetchModules();
  }, []);

  return (
    <div className="AdminContainer ">
      <ToastContainer />
      <section className="middleArea">
        {/* Modal for displaying the full-size image */}

        <div class="container mt-5">
          <div class="row">
            <div class="col-12">
              <h2 class="text-center">
                {' '}
                <i class="fa-solid fa-list"></i> Questions
              </h2>
              <p class="text-center"> Manage Questions here.</p>
            </div>
          </div>
          <div className="row px-1">
            {testCategories.map((category) => (
              <Link
                to={`${ROUTES.adminTestQuestions.name}/${
                  category.id || 'defaultId'
                }`}
                className="btn btn-primary mx-1"
                key={category.id}
              >
                {category.categoryname}
              </Link>
            ))}
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="card">
                <div class="card-header">
                  <h4 class="card-title">Add Questions</h4>
                </div>
                <div class="card-body">
                  <Formik
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    validationSchema={validationSchema}
                  >
                    {({ values, setFieldValue, isSubmitting }) => (
                      <Form>
                        <div className="form-group fieldStyle">
                          <label htmlFor="categoryId">Test Category</label>
                          <Field
                            as="select"
                            name="categoryId"
                            id="categoryId"
                            className="form-control"
                          >
                            <option value="">Select a test category</option>
                            {testCategories.map((category) => (
                              <option key={category.id} value={category.id}>
                                {category.categoryname}
                              </option>
                            ))}
                          </Field>
                          <ErrorMessage name="testCategory" component="span" />
                        </div>
                        <div className="form-group fieldStyle">
                          <label htmlFor="moduleId">Module</label>
                          <Field
                            as="select"
                            name="moduleId"
                            id="moduleId"
                            className="form-control"
                          >
                            <option value="">Select a module</option>
                            {module.map((value) => (
                              <option key={value.id} value={value.id}>
                                {value.name}
                              </option>
                            ))}
                          </Field>
                          <ErrorMessage name="testCategory" component="span" />
                        </div>
                        <div class="form-group fieldStyle">
                          <label for="image" htmlFor="image">
                            image
                          </label>

                          <input
                            type="file"
                            onChange={(event) => {
                              setFieldValue('image', event.target.files[0]);
                            }}
                          ></input>
                        </div>
                        <ErrorMessage name="question" component="span" />
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="question">
                            question
                          </label>

                          <Field
                            type="text"
                            name="question"
                            id="question"
                            placeholder="Enter a question"
                            class="form-control"
                          />
                        </div>
                        <ErrorMessage name="option1" component="span" />
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="option1">
                            option1
                          </label>

                          <Field
                            type="text"
                            name="option1"
                            id="option1"
                            placeholder="Enter a option1"
                            class="form-control"
                          />
                        </div>
                        <ErrorMessage name="option2" component="span" />
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="option2">
                            option2
                          </label>

                          <Field
                            type="text"
                            name="option2"
                            id="option2"
                            placeholder="Enter a option2"
                            class="form-control"
                          />
                        </div>
                        <ErrorMessage name="option3" component="span" />
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="option3">
                            option3
                          </label>

                          <Field
                            type="text"
                            name="option3"
                            id="option3"
                            placeholder="Enter a option3"
                            class="form-control"
                          />
                        </div>
                        <ErrorMessage name="option4" component="span" />
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="option4">
                            option4
                          </label>

                          <Field
                            type="text"
                            name="option4"
                            id="option4"
                            placeholder="Enter a option4"
                            class="form-control"
                          />
                        </div>
                        <ErrorMessage name="correct" component="span" />
                        <div className="form-group fieldStyle">
                          <label htmlFor="correct">Correct:</label>
                          <div>
                            <label
                              className={`optionArea  ${
                                values.correct === values.option1
                                  ? 'selected'
                                  : ''
                              }`}
                            >
                              <Field
                                type="radio"
                                name="correct"
                                value={values.option1}
                                onClick={() =>
                                  setFieldValue('correct', values.option1)
                                }
                              />
                              Option 1
                            </label>
                            <label
                              className={`optionArea px-3 ${
                                values.correct === values.option2
                                  ? 'selected'
                                  : ''
                              }`}
                            >
                              <Field
                                type="radio"
                                name="correct"
                                value={values.option2}
                                onClick={() =>
                                  setFieldValue('correct', values.option2)
                                }
                              />
                              Option 2
                            </label>
                            <label
                              className={`optionArea px-3 ${
                                values.correct === values.option3
                                  ? 'selected'
                                  : ''
                              }`}
                            >
                              <Field
                                type="radio"
                                name="correct"
                                value={values.option3}
                                onClick={() =>
                                  setFieldValue('correct', values.option3)
                                }
                              />
                              Option 3
                            </label>
                            <label
                              className={`optionArea px-3 ${
                                values.correct === values.option4
                                  ? 'selected'
                                  : ''
                              }`}
                            >
                              <Field
                                type="radio"
                                name="correct"
                                value={values.option4}
                                onClick={() =>
                                  setFieldValue('correct', values.option4)
                                }
                              />
                              Option 4
                            </label>
                          </div>
                        </div>

                        <ErrorMessage name="explanation" component="span" />
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="explanation">
                            explanation
                          </label>

                          <Field
                            type="text"
                            name="explanation"
                            id="explanation"
                            placeholder="Enter a explanation"
                            class="form-control"
                          />
                        </div>

                        <button
                          type="submit"
                          class="btn btn-primary"
                          disabled={isSubmitting}
                        >
                          Add
                        </button>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Questions;
