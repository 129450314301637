import React from 'react';
import { useState, useEffect, useRef, useMemo } from 'react';
import axios from 'axios';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
function StudentUser() {
  const [listOfStudentUsers, setListOfStudentUsers] = useState([]);
  const [currentClickedId, setCurrentClickedId] = useState('');
  const [addValues, setAddValues] = useState('');
  const [editValues, setEditValues] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const initialValues = {
    userName: '',
    email: '',
    dob: '',
    postalCode: '',
  };
  const validationSchema = Yup.object().shape({
    name: Yup.string().required(),
    email: Yup.string().required(),
    dob: Yup.date().required(),
    postalCode: Yup.number().required(),
  });
  const handleSubmit = (value) => {
    setAddValues(value);
  };
  const handleEdit = (value) => {
    setEditValues(value);
  };
  const showModalScreen = (msgId) => {
    setCurrentClickedId(msgId);
  };
  const hideModalScreen = () => {};
  const onDelete = () => {
    console.log('id', currentClickedId);
    fetch(
      `http://csmserver.csmdrivingschool.ca/StudentRegister.php?id=${currentClickedId}`,
      {
        method: 'DELETE',
      }
    )
      .then((response) => {
        if (response.ok) {
          setListOfStudentUsers(
            listOfStudentUsers.filter((item) => item.id !== currentClickedId)
          );
          toast.success('Item deleted successfully', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            style: {
              background: 'rgba(0, 0, 0, 0.7)',
              color: 'white',
            },
          });
          hideModalScreen();
        } else {
          throw new Error('Network response was not ok.');
        }
      })
      .catch((error) => {
        console.error('Error while deleting:', error);
      });
  };

  useEffect(() => {
    fetch('http://csmserver.csmdrivingschool.ca/StudentRegister.php')
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('Network response was not ok.');
        }
      })
      .then((data) => {
        setListOfStudentUsers(data);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);

  const onSubmit = async (data, { resetForm }) => {
    try {
      console.log('data', data);
      const response = await fetch(
        'http://csmserver.csmdrivingschool.ca/StudentRegister.php',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        }
      );
      if (response.ok) {
        alert('data saved');
        console.log('it worked', response);
        resetForm();
      } else {
        throw new Error('Network response was not ok.');
      }
    } catch (error) {
      console.error('Error while submitting:', error);
    }
  };

  const onUpdate = async (data, { resetForm }) => {
    try {
      console.log('data', data);
      const response = await fetch(
        'http://csmserver.csmdrivingschool.ca/StudentRegister.php',
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        }
      );
      if (response.ok) {
        alert('data updated');
        resetForm();
      } else {
        throw new Error('Network response was not ok.');
      }
    } catch (error) {
      console.error('Error while updating the data:', error);
    }
  };

  //filtering data
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };
  const filteredData = listOfStudentUsers.filter((item) => {
    const values = Object.values(item).map((value) =>
      String(value).toLowerCase()
    );
    return values.some((value) => value.includes(searchTerm.toLowerCase()));
  });

  return (
    <div>
      <ToastContainer></ToastContainer>
      <div
        class="modal fade"
        id="deleteModal"
        tabindex="-1"
        aria-labelledby="deleteModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="deleteModalLabel">
                Delete Confirm
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i class="fa-solid fa-xmark"></i>
              </button>
            </div>
            <div class="modal-body">
              <p>Are you sure you want to delete</p>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-primary mx-2"
                data-bs-dismiss="modal"
              >
                No
              </button>
              <button
                type="button"
                class="btn btn-primary"
                onClick={onDelete}
                data-bs-dismiss="modal"
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <h2 class="text-center">
            {' '}
            <i class="fa-solid fa-address-book"></i> List Of Student Users
          </h2>
          <p class="text-center"> Manage your Student Request here.</p>
        </div>
      </div>
      <div class="row">
        <div
          class="modal fade"
          id="exampleSubmitModal"
          tabindex="-1"
          aria-labelledby="exampleSubmitModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleSubmitModalLabel">
                  Add Student
                </h5>
                <button
                  type="button"
                  aria-label="Close"
                  data-bs-dismiss="modal"
                >
                  <i class="bi bi-x-lg"></i>
                </button>
              </div>
              <div className="modal-body">
                {initialValues && (
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                  >
                    {({ values, setFieldValue, isSubmitting }) => (
                      <Form>
                        <ErrorMessage
                          name="name"
                          component="span"
                          style={{ color: 'red' }}
                        />
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="userName">
                            userName
                          </label>
                          <Field
                            type="text"
                            name="userName"
                            id="userName"
                            placeholder="Add UserName here"
                            class="form-control"
                          />
                        </div>
                        <ErrorMessage
                          name="email"
                          component="span"
                          style={{ color: 'red' }}
                        />
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="email">
                            Email
                          </label>
                          <Field
                            type="text"
                            name="email"
                            id="email"
                            placeholder="Add Email here"
                            class="form-control"
                          />
                        </div>
                        <ErrorMessage
                          name="dob"
                          component="span"
                          style={{ color: 'red' }}
                        ></ErrorMessage>
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="dob">
                            Date Of Birth
                          </label>
                          <Field name="dob" autoComplete="off">
                            {({ field, form, meta }) => (
                              <div placeholder="Date of birth">
                                <DatePicker
                                  id="dob"
                                  className="form-control date-picker-input mx-2"
                                  {...field}
                                  placeholderText="Date Of Birth"
                                  selected={field.value}
                                  onChange={(dob) => {
                                    // Ensure the date is set to midnight in UTC
                                    const adjustedDate = new Date(
                                      Date.UTC(
                                        dob.getUTCFullYear(),
                                        dob.getUTCMonth(),
                                        dob.getUTCDate()
                                      )
                                    );
                                    form.setFieldValue(
                                      field.name,
                                      adjustedDate
                                    );
                                  }}
                                />
                              </div>
                            )}
                          </Field>
                        </div>
                        <ErrorMessage
                          name="message"
                          component="span"
                          style={{ color: 'red' }}
                        />
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="postalCode">
                            Postal Code
                          </label>
                          <Field
                            type="text"
                            name="postalCode"
                            id="postalCode"
                            placeholder="Add Postal Code here"
                            class="form-control"
                          />
                        </div>
                        <div class="modal-footer">
                          <button
                            type="submit"
                            class="btn btn-primary mx-3"
                            // disabled={isSubmitting}
                          >
                            Save
                          </button>
                          <button
                            class="btn btn-primary mx-3"
                            data-bs-dismiss="modal"
                          >
                            Close
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* edit modal */}
        <div
          class="modal fade"
          id="exampleModal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  Edit Item
                </h5>
                <button
                  type="button"
                  aria-label="Close"
                  data-bs-dismiss="modal"
                >
                  <i class="bi bi-x-lg"></i>
                </button>
              </div>
              <div className="modal-body">
                {editValues && (
                  <Formik
                    initialValues={editValues}
                    enableReinitialize={true}
                    onSubmit={onUpdate}
                  >
                    {({ values, setFieldValue, isSubmitting }) => (
                      <Form>
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="userName">
                            userName
                          </label>
                          <Field
                            type="text"
                            name="userName"
                            id="userName"
                            placeholder="Add User Name here"
                            class="form-control"
                          />
                        </div>
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="email">
                            Email
                          </label>
                          <Field
                            type="text"
                            name="email"
                            id="email"
                            placeholder="Add Email here"
                            class="form-control"
                          />
                        </div>
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="dob">
                            Date Of Birth
                          </label>
                          <Field name="dob" autoComplete="off">
                            {({ field, form, meta }) => (
                              <div placeholder="Date of birth">
                                <DatePicker
                                  id="dob"
                                  className="form-control date-picker-input mx-2"
                                  {...field}
                                  placeholderText="Date Of Birth"
                                  selected={field.value}
                                  onChange={(dob) => {
                                    // Ensure the date is set to midnight in UTC
                                    const adjustedDate = new Date(
                                      Date.UTC(
                                        dob.getUTCFullYear(),
                                        dob.getUTCMonth(),
                                        dob.getUTCDate()
                                      )
                                    );
                                    form.setFieldValue(
                                      field.name,
                                      adjustedDate
                                    );
                                  }}
                                />
                              </div>
                            )}
                          </Field>
                        </div>
                        <div class="form-group fieldStyle">
                          <label for="inputName" htmlFor="postalCode">
                            postalCode
                          </label>
                          <Field
                            type="text"
                            name="postalCode"
                            id="postalCode"
                            placeholder="Add Postal Code here"
                            class="form-control"
                          />
                        </div>
                        <div class="modal-footer">
                          <button
                            type="submit"
                            class="btn btn-primary mx-3"
                            // disabled={isSubmitting}
                          >
                            Update
                          </button>
                          <button
                            class="btn btn-primary mx-3"
                            data-bs-dismiss="modal"
                          >
                            Close
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* detail modal */}
        <div
          class="modal fade"
          id="detailModal"
          tabindex="-1"
          aria-labelledby="detailModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="detailModalLabel">
                  Detail
                </h5>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i class="bi bi-x-circle"></i>
                </button>
              </div>
              <div class="modal-body">
                {editValues && (
                  <div>
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="userName">
                        User Name
                      </label>
                      <b name="name">{editValues.name}</b>
                    </div>
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="email">
                        Email
                      </label>
                      <b name="email">{editValues.email}</b>
                    </div>
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="dob">
                        Date of Birth
                      </label>
                      <b name="dob">{editValues.dob}</b>
                    </div>
                    <div class="form-group fieldStyle">
                      <label for="inputName" htmlFor="postalCode">
                        Postal Code
                      </label>
                      <b name="postalCode">{editValues.postalCode}</b>
                    </div>
                    <div class="modal-footer">
                      <div class="detailImageBoxContainer"></div>
                      <button class="btn btn-primary" data-bs-dismiss="modal">
                        Close
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {/*  */}
        <div class="col-md-12 mt-5">
          <div className="row">
            <button
              class="btn btn-primary mx-3"
              data-bs-toggle="modal"
              data-bs-target="#exampleSubmitModal"
              onClick={() => handleSubmit()}
            >
              Add
            </button>
            {/* search bar */}
            <input
              className=" form-control col-3 mt-4"
              type="text"
              placeholder="Search here"
              value={searchTerm}
              onChange={handleSearch}
              style={{ height: 40 }}
            />
          </div>
          <table class="table table-striped table-hover" id="searchable-table">
            <thead className="bg-dark text-white">
              <tr>
                <th>User Name</th>
                <th>Email</th>
                <th>Date Of Birth</th>
                <th>Postal Code</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {filteredData.map((value, key) => {
                return (
                  <tr key={key}>
                    {/* {Object.entries(value).map(([field, fieldValue]) => (
                      <td key={field}>{fieldValue}</td>
                    ))} */}
                    <td>{value.userName}</td>
                    <td>{value.email}</td>
                    <td>{value.dob}</td>
                    <td>{value.postalCode}</td>
                    <td>
                      <a class="nav-item dropdown">
                        <a
                          class="fa-solid fa-circle-chevron-down"
                          data-bs-toggle="dropdown"
                          href="#"
                          role="button"
                          aria-haspopup="true"
                          aria-expanded="false"
                        ></a>
                        <div class="dropdown-menu">
                          <Link
                            class="dropdown-item"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                            onClick={() => handleEdit(value)}
                            // onClick={() => showModalScreen(value.id)}
                          >
                            Edit
                          </Link>
                          <Link
                            class="dropdown-item"
                            data-bs-toggle="modal"
                            data-bs-target="#detailModal"
                            onClick={() => handleEdit(value)}
                          >
                            Detail
                          </Link>
                          <div class="dropdown-divider"></div>
                          <Link
                            class="dropdown-item"
                            onClick={() => showModalScreen(value.id)}
                            msgId={value.id}
                            data-bs-toggle="modal"
                            data-bs-target="#deleteModal"
                          >
                            Delete
                          </Link>
                        </div>
                      </a>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default StudentUser;
