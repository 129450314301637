import React, { useState, useEffect } from 'react';
import ROUTES from '../../navigations/Routes';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
function GetPostCode() {

  const [instructors, setListOfInstructors] = useState([]);
  const [selectedOption, setSelectedOption] = useState('manual');
  const [isSelectingInstructor, setIsSelectingInstructor] = useState(false);
  const [selectedHours, setSelectedHours] = useState(null);
  const [selectedPricing, setSelectedPricing] = useState(null);
  const [selectedInstructor, setSelectedInstructor] = useState(''); // Start with an empty string

  const pricingOptions = [
    { hours: 2, discount: 0.08 },
    { hours: 6, discount: 0.10 },
    { hours: 10, discount: 0.15 },
  ];

  // Fetch the instructors from the API
  useEffect(() => {
    fetch('https://csmserver.csmdrivingschool.ca/Instructor.php')
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        if (Array.isArray(data)) {
          setListOfInstructors(data);
          // Set the default selected instructor after data is fetched
          const defaultInstructor = data.find(
            (instructor) =>
              instructor.licenseType === selectedOption || instructor.gType === 'both'
          );
          setSelectedInstructor(defaultInstructor?.firstName || ''); // Set default instructor safely
        } else {
          console.error('Unexpected data format:', data);
        }
      })
      .catch((error) => {
        console.error('Error fetching instructor data:', error);
      });
  }, [selectedOption]); // Re-run when the selectedOption changes

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    const defaultInstructor = instructors.find((instructor) =>
      instructor.gType === option || instructor.gType === 'both'
    );
    setSelectedInstructor(defaultInstructor?.firstName || '');
  };


  const handleInstructorChangeClick = () => {
    setIsSelectingInstructor((prev) => !prev);
  };

  const handleInstructorSelection = (firstName) => {
    setSelectedInstructor(firstName);
    setIsSelectingInstructor(false);
  };
  const handlePricingSelection = (hours) => {
    setSelectedHours(hours);

    const selectedInstructorDetails = instructors.find(
      (instructor) => instructor.firstName === selectedInstructor
    );

    if (selectedInstructorDetails) {
      const basePrice = selectedInstructorDetails.payRate * hours;
      const discount = pricingOptions.find((option) => option.hours === hours)?.discount || 0;
      const discountedPrice = basePrice * (1 - discount);
      setSelectedPricing(discountedPrice.toFixed(0));
    }
  };

  const navigate = useNavigate();
  const handleNavigate = () => {
    const selectedInstructorDetails = instructors.find(
      (instructor) => instructor.firstName === selectedInstructor
    );

    if (selectedInstructorDetails) {
      navigate('/GetTime', {
        state: {
          instructorId: selectedInstructorDetails.id,
          pricing: selectedPricing,
          hours: selectedHours,
          gearBoxName: selectedOption,
        },
      });
    }
  };

  const selectedInstructors = instructors.filter(
    (instructor) =>
      instructor.gType === selectedOption || instructor.gType === 'both'
  );

  // Safely find the selected instructor details
  const selectedInstructorDetails = selectedInstructors.find(
    (instructor) => instructor.firstName === selectedInstructor
  );

  return (
    <div className="main bg-light">
      <div className="homeContainer pb-5">
        <div className="container-fluid">
          <div className="row align-items-center justify-content-center">
            <div className="col-12">
              <div className="row justify-content-center">
                <div className="col-lg-6 text-center">
                  <div className="mb-5 text-white desc mx-auto" data-aos="fade-up" data-aos-delay="200"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='container'>
          <div className='bookOnlineBox5'>
            <div className='HalfOne'>
              <img src='images/car22.jpg' alt="Car" />
            </div>
            <div className='HalfTwo'>
              <h2>Welcome</h2>
              <h5 className='mt-3'>SELECT A GEARBOX TO SEE INSTRUCTORS</h5>
              <div className='d-flex'>
                <button
                  onClick={() => handleOptionClick('manual')}
                  className={`btn ${selectedOption === 'manual' ? 'btnGearActive' : 'btnGear'} mt-2`}
                >
                  Manual
                </button>
                <button
                  onClick={() => handleOptionClick('automatic')}
                  className={`btn ${selectedOption === 'automatic' ? 'btnGearActive' : 'btnGear'} mt-2 mx-2`}
                >
                  Automatic
                </button>
              </div>

              <div className='innerHalf'>
                <p className='mt-3 mb-0'>YOUR INSTRUCTOR</p>

                <div className="instSet px-3 row py-2 mt-2">
                  <div className="col-6 p-0">
                    <h6>{selectedInstructorDetails ? selectedInstructorDetails.firstName : ''} {selectedInstructorDetails ? selectedInstructorDetails.lastName : ''}</h6>
                    <p>Instructor</p>
                  </div>
                  <div className="col-3 p-0 text-center">
                    <h6>${selectedInstructorDetails ? selectedInstructorDetails.payRate : 0}/hr</h6>
                    <p>Standard Rate</p>
                  </div>
                  <div className="col-3 p-0 ">
                    <h6 className="textI">
                      <p className='chageA' onClick={handleInstructorChangeClick}>
                        <i className="fa-solid fa-arrow-right-arrow-left"></i> Change
                      </p>
                    </h6>
                    <p className="textI">From</p>
                  </div>
                </div>

                <div className="position-relative dropPack px-3">
                  {isSelectingInstructor && (
                    <div className="floatDropDown row py-2 mt-2">
                      <div className="col-12 textEnd p-0">
                        <button className='btnBox' onClick={handleInstructorChangeClick}>
                          <i className="fa-solid fa-xmark"></i>
                        </button>
                      </div>
                      {selectedInstructors.map((instructor) => (
                        <div
                          key={instructor.email}
                          onClick={() => handleInstructorSelection(instructor.firstName)}
                          className="dropItem"
                          style={{
                            backgroundColor: selectedInstructor === instructor.firstName ? '#f8c6aa' : '',
                          }}
                        >
                          <div className="col-6 p-0">
                            <h6>{`${instructor.firstName} ${instructor.lastName}`}</h6>
                            <p>Instructor</p>
                          </div>
                          <div className="col-4 p-0 text-center">
                            <h6>${instructor.payRate}/hr</h6>
                            <p>Standard Rate</p>
                          </div>
                          <div className="col-2 d-flex align-items-center justify-content-end pe-4">
                            <i className="fa-solid fa-chevron-right"></i>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>

                <p className='mt-4'>CHOOSE YOUR PACKAGE HOURS</p>

                <div className='pricingOnM row'>
                  {pricingOptions.map((option) => {
                    const basePrice = selectedInstructorDetails ? (selectedInstructorDetails.payRate * option.hours) : 0;
                    const discountedPrice = basePrice * (1 - option.discount); // Apply discount
                    return (
                      <div className='col-6 col-md-4' key={option.hours}>
                        <button
                          className={selectedHours === option.hours ? 'activePricingBox' : 'pricingBox'}
                          onClick={() => handlePricingSelection(option.hours)}
                        >
                          <h3 className='m-0'>{option.hours}</h3>
                          <p className='m-0'>Hours</p>
                          <div>
                            <span className='m-0 highlight'>${discountedPrice.toFixed(2)} </span>
                            <span className='highlight1'>${basePrice.toFixed(0)}</span>
                          </div>
                        </button>
                      </div>
                    );
                  })}
                </div>
              </div>

              <div className='refundMsg mt-3 row py-3'>
                <div className='col-1'>
                  <i className="fa-solid fa-hand-holding-dollar fa-2x"></i>
                </div>
                <div className='col-11 px-4 py-1 d-flex flex-column '>
                  <h6>Full refund on unused hours</h6>
                  <p>We'll refund all your unused hours. No hidden charges. No messing around. Our Terms may apply.</p>
                </div>
              </div>
              <button
                className='btn btn-primary mt-4'
                onClick={handleNavigate}
                disabled={!selectedHours} 
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default GetPostCode;
