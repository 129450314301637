import React from 'react';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import ROUTES from '../../navigations/Routes';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TimeRemaining from '../Student/TimeRemaining';
function SchedulesStatus() {
  const [listOfStudentRequests, setListOfStudentRequests] = useState([]);
  const [loggedInUser, setLoggedInUser] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  let { userName } = useParams();

  useEffect(() => {
    const userName = localStorage.getItem('currentInsName');
    setLoggedInUser(userName);
    console.log('Logged in user:', userName);
  }, []);
  const fetchStudentName = async (studentUserName) => {
    try {
      const response = await fetch(
        `https://csmserver.csmdrivingschool.ca/StudentRegister.php?id=${studentUserName}`
      );
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching student name:', error);
      return [];
    }
  };
  useEffect(() => {
    const fetchStudentData = async () => {
      try {
        const response = await fetch(
          `https://csmserver.csmdrivingschool.ca/StudentRequest.php?id=${loggedInUser}&accepted`
        );

        if (!response.ok) {
          throw new Error('Failed to fetch student details');
        }

        const studentData = await response.json();
        console.log('Fetched student data:', studentData);

        if (!studentData.data || studentData.data.length === 0) {
          console.log('No students available');
          setListOfStudentRequests([]); // Ensure the state is set
          setLoading(false);
          return;
        }

        // Loop through each student and fetch their name
        const requestsWithStudentNames = await Promise.all(
          studentData.data.map(async (student) => {
            try {
              const studentNameResponse = await fetchStudentName(
                student.studentUserName
              );
              const studentName =
                studentNameResponse[0]?.userName || 'Unknown User'; // Ensure this matches your data structure
              return { ...student, studentUserName: studentName };
            } catch (error) {
              console.error('Error fetching student name:', error);
              // Handle error or return placeholder data
              return { ...student, studentUserName: 'Unknown' };
            }
          })
        );

        setListOfStudentRequests(requestsWithStudentNames);
        setLoading(false);
        setError(null); // Clear any previous error
      } catch (error) {
        console.error('Error fetching student details:', error);
        setError(error.message);
        setLoading(false);
      }
    };

    fetchStudentData();
  }, [loggedInUser]);

  function isDateTimeBeforeNow(dateString, timeString) {
    // Convert timeString to Date object
    const [hours, minutes] = timeString.split(':');
    const time = new Date();
    time.setHours(parseInt(hours, 10));
    time.setMinutes(parseInt(minutes, 10));

    // Parse dateString to Date object and check if it's today
    const date = new Date(dateString);
    const today = new Date();
    const isToday =
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear();

    // Check if the date is today and the time is before now
    return isToday && time < new Date();
  }

  //
  function formatDate(dateString) {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      month: 'short',
      day: '2-digit',
      year: 'numeric',
    });
  }

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }
  return (
    <div class="card w-100" data-aos="fade-up" data-aos-delay="100">
      <div class="card-body">
        <table className="table table-striped table-hover">
          <thead>
            <tr>
              <th>Name</th>
              <th>Time</th>
              <th>Date</th>
              <th>Action</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(listOfStudentRequests) &&
            listOfStudentRequests.length > 0 ? (
              listOfStudentRequests.map((student) => (
                <tr key={student.id}>
                  <td>{student.studentUserName}</td>
                  <td>{student.sessionTime}</td>{' '}
                  <td>{formatDate(student.sessionDate)}</td>{' '}
                  {/* <td>
                    {student.schedule
                      ? formatDate(student.schedule.sessionDate)
                      : ''}
                  </td>{' '} */}
                  {/* Conditional rendering */}
                  <td>
                    <button
                      className="btn btn-info"
                      onClick={() => {
                        console.log('Start Session clicked');
                      }}
                    >
                      Start Session
                    </button>
                  </td>
                  <td>
                    {student.scheduleId ? (
                      <TimeRemaining
                        dateString={student.sessionDate}
                        timeString={student.sessionTime}
                      />
                    ) : (
                      'N/A'
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5">No students available</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default SchedulesStatus;
